import { useEffect, useMemo, useState } from 'react'
import { Keyboard, Pressable, StatusBar, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import moment from 'moment'

import {
  DatePicker,
  HeaderInfo,
  InfoModal,
  ModalTeenagerNotification,
  Select,
  TextInput,
  Typography
} from '@components'
import { isIOS } from '@components/utils'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'
import { calculateAgeByDateOfBirth, SEX } from '@utils'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1,
    justifyContent: 'space-between'
  },
  inputWrapper: {
    height: 50,
    marginBottom: 34,
    width: '100%'
  },
  inputsContainer: {
    flex: 1
  },
  mainWrapper: {
    flex: 1,
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

export const GeneralInformation = () => {
  const { generalInformation } = useDataLayer()
  const generalInformationState = useSelector(selectCurrentUserData)
  const [isTeenagerNotificationVisible, setIsTeenagerNotificationVisible] = useState(false)
  const [showModal, setShowModal] = useState(true)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [selectedSex, setSelectedSex] = useState<string>('Выбрать')
  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [dob, setDob] = useState('')

  const isAdult = useMemo(() => calculateAgeByDateOfBirth(dob) >= 18, [dob])

  const openDatePickerModal = () => {
    Keyboard.dismiss()
    setShowDatePicker(true)
  }

  const handleUsernameChange = (text: string) => {
    const processedText = text.trim().toLowerCase()
    setUsername(processedText)
  }

  const handleDatePickerResult = (date?: Date) => {
    if (date && date instanceof Date) {
      setDob(moment(date).format('DD.MM.YYYY'))
    }

    setShowDatePicker(false)
  }

  const closeModal = () => {
    setShowModal(false)
    setIsTeenagerNotificationVisible(false)
  }

  const setGeneralInfo = () => {
    closeModal()
    generalInformation(name, dob, selectedSex, username, {
      page: 'Goals',
      params: undefined
    })
  }

  const isStepFilled = name && username && dob && selectedSex !== 'Выбрать'

  const submit = () => {
    if (!isStepFilled) {
      return
    }

    if (isAdult) {
      setGeneralInfo()
      return
    }

    setIsTeenagerNotificationVisible(true)
  }

  useEffect(() => {
    if (!generalInformationState) {
      return
    }

    setName(generalInformationState.name)
    setDob(generalInformationState.dof)
    setUsername(generalInformationState.login)
    setSelectedSex(generalInformationState.sex)
  }, [generalInformationState])

  return (
    <View style={styles.mainWrapper}>
      <StatusBar backgroundColor='#F3F2F9' barStyle='dark-content' />
      <InfoModal visible={showModal} onClose={closeModal} />
      <ModalTeenagerNotification
        visible={isTeenagerNotificationVisible}
        onClose={closeModal}
        onSubmit={setGeneralInfo}
      />
      <HeaderInfo showProgressBar step={1} />
      <View style={styles.centerWrapper}>
        <Typography f27 bold marginTop={24} marginBottom={12}>
          Общая информация
        </Typography>
        <View style={styles.inputsContainer}>
          <View style={styles.inputWrapper}>
            <TextInput
              placeholder='Введите текст через пробел'
              label='Имя и фамилия'
              value={name}
              onChange={text => setName(text)}
              helperText='Фамилия отображается в профиле инициалами'
            />
          </View>
          <View style={styles.inputWrapper}>
            <TextInput
              value={username}
              onChange={handleUsernameChange}
              placeholder='Введите текст'
              label='Имя пользователя'
              helperText='Помогите другим вас найти'
              autoCapitalize='none'
            />
          </View>

          <Pressable onPress={openDatePickerModal}>
            <View style={styles.inputWrapper} pointerEvents='none'>
              <TextInput
                value={dob}
                placeholder='Дата рождения'
                label='Дата рождения'
                helperText='Введите корректную дату, для правильной работы алгоритма'
              />
            </View>
          </Pressable>
          <Select
            options={SEX}
            onChange={option => setSelectedSex(option.id as string)}
            value={selectedSex}
            label='Ваш пол'
          />
        </View>
        <SubmitButton isEditing={false} label='Продолжить' onPress={submit} disabled={!isStepFilled} />
      </View>
      <DatePicker isVisible={showDatePicker} handleResult={(date?: Date) => handleDatePickerResult(date)} />
    </View>
  )
}

/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getAllUsersSuccess } from '@redux/actions'
import { GET_ALL_USERS_REQUEST, GetAllUsersRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { UserProfile } from '@types'

type SagaEffects = CallEffect | PutEffect

function* getAllUsers(action: GetAllUsersRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.getUsersByLogin], action.payload.login, action.payload.filter)
    const users: UserProfile[] = response.data
    yield put(getAllUsersSuccess(users ? users : []))
  } catch (error: any) {
    console.log('getAllUsersSaga', error)
  }
}

export function* getAllUsersSaga() {
  yield takeLatest(GET_ALL_USERS_REQUEST, getAllUsers)
}

import { useEffect, useState } from 'react'
import { StatusBar, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Checkbox, HeaderInfo, ProfileOptionItem, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useCurrentUserAge } from '@hooks'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'
import { GOALS, OptionId } from '@utils'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1
  },
  checkBoxWrapper: {
    alignItems: 'center',
    marginBottom: 21
  },
  goals: {
    gap: 16
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

export const Goals = () => {
  const { route } = useNavigator<'Goals'>()
  const edit = route.params?.edit
  const { userUpdate } = useDataLayer()
  const { isAdult } = useCurrentUserAge()
  const currentUser = useSelector(selectCurrentUserData)
  const [selectedGoals, setSelectedGoals] = useState<OptionId[]>([])
  const [readyWithForeigners, setReadyWithForeigners] = useState(false)

  useEffect(() => {
    if (!currentUser) {
      return
    }

    setSelectedGoals(currentUser.profile.goal)
    setReadyWithForeigners(currentUser.profile.readyWithForeigners)
  }, [currentUser])

  const submit = () => {
    if (!currentUser) {
      return
    }

    userUpdate(
      {
        profile: {
          ...currentUser.profile,
          goal: selectedGoals,
          readyWithForeigners
        }
      },
      { page: edit ? 'InformationYourself' : 'PersonalPhoto' }
    )
  }

  const handlePress = (goalId: OptionId) => {
    setSelectedGoals(prevGoals =>
      prevGoals.includes(goalId) ? prevGoals.filter(item => item !== goalId) : [...prevGoals, goalId]
    )
  }

  const renderGoals = () =>
    GOALS.map(goal => {
      if (isAdult || goal.id === 1) {
        return <ProfileOptionItem key={goal.id} data={goal} selected={selectedGoals} onSelect={handlePress} />
      }
    })

  return (
    <View style={styles.mainWrapper}>
      <StatusBar backgroundColor='#F3F2F9' barStyle='dark-content' />
      <HeaderInfo showProgressBar={!edit} step={2} />
      <View style={styles.centerWrapper}>
        <Typography f27 bold marginTop={28} marginBottom={8}>
          Цели
        </Typography>
        <Typography f15 normal marginBottom={20}>
          Эта информация не отображается на странице
        </Typography>
        <View style={styles.goals}>{renderGoals()}</View>
      </View>
      <SubmitButton isEditing={edit} onPress={submit} label='Продолжить' disabled={selectedGoals.length == 0}>
        <View style={styles.checkBoxWrapper}>
          <Checkbox
            label='Готов(а) к знакомству с иностранцем'
            checked={readyWithForeigners}
            onPress={() => setReadyWithForeigners(prev => !prev)}
          />
        </View>
      </SubmitButton>
    </View>
  )
}

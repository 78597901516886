import { Linking, Pressable, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { HeaderInfo, PhotoGallery, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { selectCurrentUserGallerySize } from '@redux/selectors'
import { SubmitButton } from '@screens'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isIOS ? 50 : 15
  },
  requirements: {
    alignItems: 'center',
    marginBottom: 16
  }
})

export const PersonalPhoto = () => {
  const { route, navigation } = useNavigator<'PersonalPhoto'>()
  const edit = route.params?.edit
  const gallerySize = useSelector(selectCurrentUserGallerySize)

  const goToLocation = () => {
    navigation.navigate(edit ? 'InformationYourself' : 'Location')
  }

  const openURL = () => {
    Linking.openURL('https://info.connectapp.me/media-requirements')
  }

  return (
    <View style={styles.mainWrapper}>
      <HeaderInfo showProgressBar={!edit} step={3} />
      <View style={styles.centerWrapper}>
        <Typography f27 bold marginTop={28} marginBottom={36}>
          Личные фото/видео
        </Typography>
        <PhotoGallery />
      </View>
      <SubmitButton isEditing={edit} label='Продолжить' onPress={goToLocation} center disabled={!gallerySize}>
        <View style={styles.requirements}>
          <Typography f13 normal>
            Нажимая &quot;Продолжить&quot; вы соглашаетесь с{' '}
          </Typography>
          <Pressable onPress={openURL}>
            <Typography f13 normal style={{ textDecorationLine: 'underline' }}>
              Правилами для фото и видео
            </Typography>
          </Pressable>
        </View>
      </SubmitButton>
    </View>
  )
}

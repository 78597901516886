import { StyleSheet, View } from 'react-native'

import { SearchConnectSvg } from '@assets/icons'
import { Typography } from '@components'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    maxHeight: 266
  }
})

interface EmptySearchProps {
  mainTitle: string
  subTitle: string
}

export const EmptySearch = ({ mainTitle, subTitle }: EmptySearchProps) => (
  <View style={styles.container}>
    <SearchConnectSvg width={175} height={175} />
    <Typography f17 semibold color='#2c2c2c' marginTop={20}>
      {mainTitle}
    </Typography>
    <Typography f14 normal textAlign='center' color='#a4a4a4' marginTop={10}>
      {subTitle}
    </Typography>
  </View>
)

/* eslint-disable func-style */
import { DeviceEventEmitter as Event } from 'react-native'
import { eventChannel } from 'redux-saga'
import { put, select, take, takeLatest } from 'redux-saga/effects'

import {
  deleteMessagesSuccess,
  exploreMessagesRequest,
  getMyChatsRequest,
  getMyLikesRequest,
  getUserChatsRequest,
  getUsersRequest,
  notificationAction,
  receiveDiscussion,
  receiveMessagesForChannel,
  receiveMessagesForChat
} from '@redux/actions'
import { RootState } from '@redux/reducer'
import {
  CHAT_CONNECT_REQUEST,
  ChatConnectRequestAction,
  DELETE_MESSAGES_REQUEST,
  DELETE_MESSAGES_SUCCESS,
  DeleteMessagesRequestAction,
  DeleteMessagesSuccessAction,
  EXPLORE_MESSAGES,
  ExploreMessagesRequestAction,
  GET_MESSAGES_FOR_CHAT_REQUEST,
  GetMessagesForChatRequestAction,
  Notification,
  NOTIFICATION_SUCCESS,
  PHONE_CONNECT_REQUEST,
  PhoneConnectRequestAction,
  RECEIVE_DISCUSSION,
  RECEIVE_LIKE,
  RECEIVE_MESSAGE,
  RECEIVE_MESSAGES_FOR_CHAT,
  RECEIVE_READ_MESSAGE,
  SEND_DISCUSSION_MESSAGE_REQUEST,
  SEND_LIKE_REQUEST,
  SEND_MESSAGE_REQUEST,
  SEND_READ_MESSAGE_REQUEST,
  SEND_READ_MESSAGES_REQUEST,
  SendDiscussionMessageRequestAction,
  SendLikeRequestAction,
  SendMessageRequestAction,
  SendReadMessageRequestAction,
  SendReadMessagesRequestAction
} from '@redux/types'
import {
  Channel,
  ChannelMessage,
  DeleteMessagesAttributes,
  DiscussionResponseAttributes,
  MessagesAttributes,
  UserProfile
} from '@types'
import { getFromLS } from '@utils'

export function* handleSocket() {
  const channel = eventChannel(emit => {
    Event.addListener(RECEIVE_LIKE, async () => {
      const user: UserProfile = JSON.parse((await getFromLS('@user')) as string)
      emit(getUserChatsRequest(user.userChatId))
      emit(getMyChatsRequest())
      emit(getMyLikesRequest())
    })

    Event.addListener(RECEIVE_MESSAGES_FOR_CHAT, (payload: MessagesAttributes[]) => {
      emit(receiveMessagesForChat(payload))
    })

    Event.addListener(RECEIVE_MESSAGE, async (payload: MessagesAttributes) => {
      const user: UserProfile = JSON.parse((await getFromLS('@user')) as string)
      emit(exploreMessagesRequest(payload))
      emit(getUserChatsRequest(user.userChatId))
    })

    Event.addListener(RECEIVE_DISCUSSION, async (payload: DiscussionResponseAttributes) => {
      emit(receiveDiscussion(payload))
    })

    Event.addListener(NOTIFICATION_SUCCESS, (payload: Notification) => {
      emit(notificationAction(payload))
    })

    Event.addListener(DELETE_MESSAGES_SUCCESS, (payload: DeleteMessagesAttributes) => {
      emit(deleteMessagesSuccess(payload))
    })

    Event.addListener(RECEIVE_READ_MESSAGE, async () => {
      const user: UserProfile = JSON.parse((await getFromLS('@user')) as string)
      emit(getUserChatsRequest(user.userChatId))
    })

    return () => {}
  })

  while (true) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const action = yield take(channel)
    yield put(action)
  }
}

function* sendLike(action: SendLikeRequestAction) {
  try {
    Event.emit(SEND_LIKE_REQUEST, action.payload)
    yield put(getUsersRequest())
  } catch (error) {
    console.log('80', error)
  }
}

export function* sendLikeSaga() {
  yield takeLatest<SendLikeRequestAction>(SEND_LIKE_REQUEST, sendLike)
}

function phoneConnect(action: PhoneConnectRequestAction) {
  try {
    Event.emit(PHONE_CONNECT_REQUEST, action.payload.id)
  } catch (error) {
    console.log('92', error)
  }
}

export function* phoneConnectSaga() {
  yield takeLatest<PhoneConnectRequestAction>(PHONE_CONNECT_REQUEST, phoneConnect)
}

function chatConnect(action: ChatConnectRequestAction) {
  try {
    Event.emit(CHAT_CONNECT_REQUEST, action.payload.id)
  } catch (error) {
    console.log('107', error)
  }
}

export function* chatConnectSaga() {
  yield takeLatest<ChatConnectRequestAction>(CHAT_CONNECT_REQUEST, chatConnect)
}

function sendGetMessagesForChat(action: GetMessagesForChatRequestAction) {
  try {
    Event.emit(GET_MESSAGES_FOR_CHAT_REQUEST, action.payload)
  } catch (error) {
    console.log('119', error)
  }
}

export function* sendGetMessagesForChatSaga() {
  yield takeLatest<GetMessagesForChatRequestAction>(GET_MESSAGES_FOR_CHAT_REQUEST, sendGetMessagesForChat)
}

// TODO: Need to be refactored
function* exploreMessages(action: ExploreMessagesRequestAction) {
  try {
    const messages: MessagesAttributes[] = yield select((state: RootState) => state.chatMessages.messages)
    const channel: Channel = yield select((state: RootState) => state.channel)
    const isChannel = channel?.id === action.payload.chat_id

    if (!action.payload) {
      return
    }

    if (!isChannel && (!messages.length || action.payload.chat_id !== messages[0].chat_id)) {
      return
    }

    const existingMessageIndex = messages.findIndex(message => message.message_id === action.payload.message_id)

    if (!isChannel && existingMessageIndex !== -1) {
      return
    }

    if (isChannel) {
      const newChannelMessages = [action.payload as ChannelMessage, ...(channel?.history || [])]
      yield put(receiveMessagesForChannel(newChannelMessages))
    } else {
      const newChatMessages = [...messages, action.payload]
      yield put(receiveMessagesForChat(newChatMessages))
    }
  } catch (error) {
    console.log('150', error)
  }
}

export function* exploreMessagesSaga() {
  yield takeLatest<ExploreMessagesRequestAction>(EXPLORE_MESSAGES, exploreMessages)
}

function sendMessage(action: SendMessageRequestAction) {
  try {
    Event.emit(SEND_MESSAGE_REQUEST, action.payload)
  } catch (error) {
    console.log('165', error)
  }
}

export function* sendMessageSaga() {
  yield takeLatest<SendMessageRequestAction>(SEND_MESSAGE_REQUEST, sendMessage)
}

function sendDiscussionMessage(action: SendDiscussionMessageRequestAction) {
  try {
    Event.emit(SEND_DISCUSSION_MESSAGE_REQUEST, action.payload)
  } catch (error) {
    console.log('sendDiscussionMessage', error)
  }
}

export function* sendDiscussionMessageSaga() {
  yield takeLatest<SendDiscussionMessageRequestAction>(SEND_DISCUSSION_MESSAGE_REQUEST, sendDiscussionMessage)
}

// TODO: Need to be refactored
function* getDeletedMessages(action: DeleteMessagesSuccessAction) {
  try {
    const messages: MessagesAttributes[] = yield select((state: RootState) => state.chatMessages.messages)

    const channel: Channel = yield select((state: RootState) => state.channel)
    const isChannel = channel?.id === action.payload.chat_id

    const newChannelMessages = channel?.history?.filter(
      message => message.message_id && !action.payload.messages.includes(message.message_id)
    )
    const newChatMessages = messages.filter(
      message => message.message_id && !action.payload.messages.includes(message.message_id)
    )

    yield put(isChannel ? receiveMessagesForChannel(newChannelMessages) : receiveMessagesForChat(newChatMessages))
  } catch (error) {
    console.log('deleteMessages', error)
  }
}

export function* getDeletedMessagesSaga() {
  yield takeLatest<DeleteMessagesSuccessAction>(DELETE_MESSAGES_SUCCESS, getDeletedMessages)
}

function sendDeleteMessages(action: DeleteMessagesRequestAction) {
  Event.emit(DELETE_MESSAGES_REQUEST, { chat_id: action.payload.chat_id, messages: action.payload.messages })
}

export function* deleteMessagesSaga() {
  yield takeLatest<DeleteMessagesRequestAction>(DELETE_MESSAGES_REQUEST, sendDeleteMessages)
}

function sendReadMessage(action: SendReadMessageRequestAction) {
  try {
    Event.emit(SEND_READ_MESSAGE_REQUEST, action.payload)
  } catch (error) {
    console.log('177', error)
  }
}

export function* sendReadMessageSaga() {
  yield takeLatest<SendReadMessageRequestAction>(SEND_READ_MESSAGE_REQUEST, sendReadMessage)
}

function sendReadMessages(action: SendReadMessagesRequestAction) {
  try {
    Event.emit(SEND_READ_MESSAGES_REQUEST, action.payload)
  } catch (error) {
    console.log('sendReadMessages', error)
  }
}

export function* sendReadMessagesSaga() {
  yield takeLatest<SendReadMessagesRequestAction>(SEND_READ_MESSAGES_REQUEST, sendReadMessages)
}

import { useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet, ViewStyle } from 'react-native'
import * as Google from 'expo-auth-session/providers/google'
import * as WebBrowser from 'expo-web-browser'

import { Button } from '@components'
import { AntDesign } from '@expo/vector-icons'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { SocialAuth, SocialProvider } from '@types'

const GOOGLE_CLIENT_ID_ANDROID = '803948881123-eo13h1pvlc43on12s058ep3lif8noa8h.apps.googleusercontent.com'
const GOOGLE_CLIENT_ID_IOS = '803948881123-0knl849rp5kargdeq48lq26ie32a86sa.apps.googleusercontent.com'
const GOOGLE_CLIENT_ID_WEB = '803948881123-sb5s42rr08o9dnnnarq0invf1hcqjcvg.apps.googleusercontent.com'

WebBrowser.maybeCompleteAuthSession()

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#fff',
    paddingHorizontal: 0,
    width: 60
  }
})

interface GoogleAuthButtonProps {
  mode?: 'login' | 'connect'
  isDisabled?: boolean
  buttonStyles?: ViewStyle
}

export const GoogleAuthButton = ({ mode = 'login', isDisabled, buttonStyles }: GoogleAuthButtonProps) => {
  const [isAuthInProgress, setIsAuthInProgress] = useState(false)
  const { registration, connectSocial } = useDataLayer()
  const { navigation } = useNavigator()

  const [, response, promptAsync] = Google.useAuthRequest({
    androidClientId: GOOGLE_CLIENT_ID_ANDROID,
    iosClientId: GOOGLE_CLIENT_ID_IOS,
    webClientId: GOOGLE_CLIENT_ID_WEB
  })

  const onPress = () => {
    setIsAuthInProgress(true)
    promptAsync()
  }

  const handleSignIn = () => {
    setIsAuthInProgress(false)

    if (response?.type !== 'success' || !response?.authentication) {
      return
    }

    const params: SocialAuth = { provider: SocialProvider.GOOGLE, token: response.authentication.accessToken }

    if (mode === 'connect') {
      connectSocial(params)
      return
    }

    registration({ socialAuth: params }, () => navigation.navigate('Tabs'))
  }

  useEffect(() => {
    if (response) {
      handleSignIn()
    }
  }, [response])

  const Icon = <AntDesign name='google' size={30} color='black' />

  if (isDisabled) {
    return <Button isView style={[styles.button, buttonStyles]} iconLeft={Icon} />
  }

  return (
    <Button
      onPress={onPress}
      style={[styles.button, buttonStyles]}
      iconLeft={isAuthInProgress ? <ActivityIndicator /> : Icon}
    />
  )
}

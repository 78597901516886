import {
  DELETE_FULL_CHAT_USER,
  FullChatUserState,
  GET_FULL_CHAT_USER_REQUEST,
  GET_FULL_CHAT_USER_SUCCESS,
  GetFullChatUserActionTypes
} from '@redux/types'

const initialState: FullChatUserState = null

export const fullChatUserReducer = (state = initialState, action: GetFullChatUserActionTypes) => {
  switch (action.type) {
    case GET_FULL_CHAT_USER_REQUEST:
      return null
    case GET_FULL_CHAT_USER_SUCCESS:
      return action.payload
    case DELETE_FULL_CHAT_USER:
      return null
    default:
      return state
  }
}

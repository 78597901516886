import { useEffect, useState } from 'react'
import { Pressable, StatusBar, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Autocomplete, Checkbox, HeaderInfo, SelectFilterModal, TextInput, Typography } from '@components'
import { isIOS, isWeb } from '@components/utils'
import { useCurrentUserAge } from '@hooks'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'
import { LANGUAGES, OptionId, ProfileOption } from '@utils'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

export const Languages = () => {
  const { route } = useNavigator<'Languages'>()
  const edit = route.params?.edit
  const { userUpdate } = useDataLayer()
  const { isAdult } = useCurrentUserAge()
  const currentUser = useSelector(selectCurrentUserData)

  const [readyToUseTranslator, setReadyToUseTranslator] = useState(false)
  const [langs, setLangs] = useState<OptionId[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(-1)

  useEffect(() => {
    if (currentUser && currentUser.profile.langs.length) {
      setLangs(currentUser.profile.langs)
    }
  }, [currentUser])

  const handleLanguageChange = (langId: OptionId, idx: number) => {
    const newLangs = [...langs]
    newLangs[idx] = langId
    setLangs(newLangs)
    setModalOpen(false)
    setSelectedLanguageIndex(-1)
  }

  const getNextPage = () => {
    if (edit) {
      return 'InformationYourself'
    }

    return isAdult ? 'SexualOrientation' : 'Hobbies'
  }

  const goTogoToSexualOrientation = () => {
    if (!currentUser) {
      return
    }

    userUpdate(
      {
        profile: {
          ...currentUser.profile,
          langs,
          readyToUseTranslator
        }
      },
      { page: getNextPage() }
    )
  }

  const onModalOpen = (idx: number) => {
    setSelectedLanguageIndex(idx)
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
    setSelectedLanguageIndex(-1)
  }

  const renderLanguages = () =>
    [...langs, -1].map((languageId, idx) => {
      if (idx > 4) {
        return null
      }

      const language = LANGUAGES.find(l => l.id === languageId)
      const placeholder = idx === 0 ? 'Основной язык' : 'Дополнительный язык'
      const label = language?.label || placeholder
      const options = LANGUAGES.filter(l => !langs.slice(0, idx).includes(l.id))

      return (
        <View key={languageId} style={{ marginBottom: 16 }}>
          {isWeb ? (
            <Pressable onPress={() => onModalOpen(idx)}>
              <TextInput autoCapitalize='none' pointerEvents='none' value={label} />
            </Pressable>
          ) : (
            <Autocomplete
              placeholder=''
              label={placeholder}
              options={options}
              renderOption={option => option.label}
              value={languageId}
              onChange={option => handleLanguageChange(option.id, idx)}
            />
          )}
        </View>
      )
    })

  return (
    <View style={styles.mainWrapper}>
      <StatusBar backgroundColor='#F3F2F9' barStyle='dark-content' />
      <HeaderInfo showProgressBar step={5} />
      <View style={styles.centerWrapper}>
        <Typography f27 bold marginTop={24} marginBottom={8}>
          Языки
        </Typography>
        {renderLanguages()}
      </View>
      <SubmitButton
        isEditing={edit}
        buttonLabelCondition={!!langs.length}
        onPress={goTogoToSexualOrientation}
        marginTop={11}
      >
        <Checkbox
          label='Готов(а) общаться на других языках с встроенным переводчиком'
          checked={readyToUseTranslator}
          onPress={() => setReadyToUseTranslator(prev => !prev)}
        />
      </SubmitButton>
      <SelectFilterModal
        style={{ maxHeight: 500 }}
        isModalOpen={isWeb && modalOpen}
        options={LANGUAGES.filter(l => !langs.includes(l.id))}
        onClose={onModalClose}
        onSelect={(option: ProfileOption) => handleLanguageChange(option.id, selectedLanguageIndex)}
      />
    </View>
  )
}

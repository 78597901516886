import { useEffect, useRef } from 'react'
import { ScrollView } from 'react-native'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { Message } from '@components'
import { useDataLayer } from '@redux/dataLayer'
import { selectChatMessages } from '@redux/selectors'

interface ChatSpaceProps {
  chatId: number
  ownerId: number
}

export const ChatSpace = ({ ownerId, chatId }: ChatSpaceProps) => {
  const scrollViewRef = useRef<ScrollView | null>(null)
  const { sendReadMessages, getChatMessages } = useDataLayer()
  const messagesState = useSelector(selectChatMessages)

  useEffect(() => {
    getChatMessages(chatId)
  }, [messagesState.length])

  useEffect(() => {
    if (messagesState.length === 0) {
      return
    }

    try {
      const unreadMessageIds = messagesState
        .filter(message => {
          if (message.user_id !== ownerId) {
            return !message.read.some(readMessage => readMessage.user_id === ownerId)
          }
        })
        .map(m => m.message_id)

      sendReadMessages({
        chat_id: chatId,
        messages: unreadMessageIds,
        user_id: ownerId
      })
    } catch (err) {
      console.log('Cant read messages')
    }
  }, [messagesState])

  const renderMessages = () =>
    messagesState
      .sort((a, b) => moment(a.createdAt).diff(b.createdAt))
      .map(message => <Message key={message.message_id} message={message} ownerId={ownerId} />)

  return (
    <ScrollView
      ref={scrollViewRef}
      style={{ flex: 1, paddingHorizontal: 8 }}
      contentContainerStyle={{ paddingBottom: 10, alignItems: 'flex-start', gap: 10 }}
      showsVerticalScrollIndicator={false}
      onContentSizeChange={() => scrollViewRef.current?.scrollToEnd({ animated: false })}
    >
      {renderMessages()}
    </ScrollView>
  )
}

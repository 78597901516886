/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getMyChatsSuccess } from '@redux/actions'
import { ChatInterlocutorResponse, GET_MY_CHATS_REQUEST, GetMyChatsRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect

function* getMyChats(): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.getMyChats])
    const notifications: ChatInterlocutorResponse = response.data
    yield put(getMyChatsSuccess(notifications))
  } catch (error: any) {
    console.log('getMyChatsSaga', error)
  }
}

export function* getMyChatsSaga() {
  yield takeLatest<GetMyChatsRequestAction>(GET_MY_CHATS_REQUEST, getMyChats)
}

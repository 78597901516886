import { Image, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Typography } from '@components'
import { getUserChatId } from '@hooks'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { ShortUser, UserChat } from '@types'
import { formatDateForChat } from '@utils'

const styles = StyleSheet.create({
  badge: {
    alignItems: 'center',
    backgroundColor: '#000',
    borderRadius: 50,
    minWidth: 16,
    padding: 2
  },
  chatContainer: {
    flexDirection: 'row',
    marginBottom: 16
  },
  image: {
    borderRadius: 32,
    height: 64,
    marginRight: 16,
    width: 64
  },
  message: {
    fontSize: 13,
    fontWeight: '500',
    maxWidth: '90%',
    overflow: 'hidden'
  },
  messageContainer: {
    flex: 1,
    justifyContent: 'space-between',
    paddingVertical: 10
  },
  userName: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
})

export interface FormatChat {
  id: number
  name: string
  message: any
  time: string
  photo: any
  unreadMessages: number
  participants: ShortUser[]
  chat: UserChat
}

interface ChatListProps {
  chats: FormatChat[]
}

export const ChatList = ({ chats }: ChatListProps) => {
  const userChatId = useSelector(getUserChatId)
  const { getFullChatUser } = useDataLayer()
  const { navigation } = useNavigator<'Chat'>()

  const goToChat = (chat: UserChat) => {
    const id = chat.creator.user_id === userChatId ? chat.participants[0].user_id : chat.creator.user_id
    getFullChatUser(id)
    navigation.navigate('Chat', {
      chat,
      userId: userChatId as number
    })
  }

  const Badge = ({ content }: { content: number }) =>
    content ? (
      <View style={styles.badge}>
        <Text style={{ color: 'white', fontSize: 10 }}>{content}</Text>
      </View>
    ) : null

  const renderChats = () =>
    chats.map(chat => (
      <TouchableOpacity onPress={() => goToChat(chat.chat)} key={chat.id}>
        <View style={styles.chatContainer}>
          <Image style={styles.image} source={chat.photo} />
          <View style={styles.messageContainer}>
            <View style={styles.userName}>
              <Typography f17 semibold>
                {chat.name}
              </Typography>
              <Typography f13>{formatDateForChat(chat.time)}</Typography>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text style={styles.message} numberOfLines={1} ellipsizeMode='tail'>
                {chat.message}
              </Text>
              <Badge content={chat.unreadMessages} />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    ))

  return (
    <View style={{ flex: 1 }}>
      <Text style={{ fontSize: 22, fontWeight: '500', marginBottom: 16 }}>Сообщения</Text>
      <ScrollView showsVerticalScrollIndicator={false}>{renderChats()}</ScrollView>
    </View>
  )
}

import { RootStackParamList } from '@navigation'
import { createNavigationContainerRef } from '@react-navigation/native'

export interface PageAttributes {
  page: keyof RootStackParamList
  params?: any
}

export const navigationRef = createNavigationContainerRef()

// TODO: IMPROVE TYPES
export const navigate = (next: PageAttributes) => {
  if (navigationRef.isReady()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigationRef.navigate(next.page, next.params)
  }
}

import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import Svg, { Circle } from 'react-native-svg'

const COLORS = [
  '#E80000',
  '#E80400',
  '#E90900',
  '#E90D00',
  '#E91200',
  '#EA1600',
  '#EA1B00',
  '#EA1F00',
  '#EB2400',
  '#EB2800',
  '#EB2C00',
  '#EC3100',
  '#EC3500',
  '#EC3A00',
  '#EC3E00',
  '#ED4300',
  '#ED4700',
  '#ED4B00',
  '#EE5000',
  '#EE5400',
  '#EE5900',
  '#EF5D00',
  '#EF6200',
  '#EF6600',
  '#F06B00',
  '#F06F00',
  '#F07300',
  '#F17800',
  '#F17C00',
  '#F18100',
  '#F18500',
  '#F28A00',
  '#F28E00',
  '#F29300',
  '#F39700',
  '#F39B00',
  '#F3A000',
  '#F3A400',
  '#F4A900',
  '#F4AD00',
  '#F4B200',
  '#F4B600',
  '#F5BA00',
  '#F5BF00',
  '#F5C300',
  '#F6C800',
  '#F6CC00',
  '#F6D100',
  '#F6D500',
  '#F7DA00',
  '#F7DE00',
  '#F2DE00',
  '#EDDE00',
  '#E8DF01',
  '#E3DF01',
  '#DEDF01',
  '#D9DF01',
  '#D5DF01',
  '#D0E002',
  '#CBE002',
  '#C6E002',
  '#C1E002',
  '#BCE002',
  '#B7E103',
  '#B2E103',
  '#ADE103',
  '#A8E103',
  '#A3E103',
  '#9EE204',
  '#9AE204',
  '#95E205',
  '#90E205',
  '#8BE204',
  '#86E306',
  '#81E305',
  '#7CE305',
  '#77E307',
  '#72E307',
  '#6DE406',
  '#68E406',
  '#63E406',
  '#5EE406',
  '#59E406',
  '#54E507',
  '#4FE507',
  '#4AE507',
  '#45E507',
  '#40E507',
  '#3CE608',
  '#37E608',
  '#32E608',
  '#2DE608',
  '#28E608',
  '#23E709',
  '#1EE709',
  '#19E709',
  '#14E709',
  '#0FE709',
  '#0AE80A',
  '#05E80A'
]

interface ICircle {
  cx: number
  cy: number
  r: number
  fillOpacity: number
}

interface CircularProgressProps {
  percentage: number
  size?: number
  strokeWidth?: number
}

const CircularProgressComponent = ({ percentage, size = 60, strokeWidth = 3 }: CircularProgressProps) => {
  const [circles, setCircles] = useState<ICircle[]>([])
  const UNIT = 3.6

  useEffect(() => {
    const innerRaduis = size - strokeWidth / 2
    const elementsParams = COLORS.map((_, idx) => {
      const angle = UNIT * idx - 90
      const x = 60 + innerRaduis * Math.cos((angle * Math.PI) / 180)
      const y = 60 + innerRaduis * Math.sin((angle * Math.PI) / 180)
      return { cx: x, cy: y, r: strokeWidth, fillOpacity: idx <= percentage ? 1 : 0 }
    })
    setCircles(elementsParams)
  }, [percentage, size, strokeWidth])

  const circlesElements = useMemo(
    () =>
      circles.map((circle, index) => (
        <Circle
          key={COLORS[index]}
          cx={circle.cx}
          cy={circle.cy}
          r={circle.r}
          fill={COLORS[index]}
          fillOpacity={circle.fillOpacity}
        />
      )),
    [circles]
  )

  return (
    <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Svg width={size} height={size} viewBox='-2 -2 124 124'>
        {circlesElements}
      </Svg>
    </View>
  )
}

export const CircularProgress = React.memo(CircularProgressComponent)

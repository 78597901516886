import { ReactNode } from 'react'
import { Linking, StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import { ArrowRightBlackSvg } from '@assets/icons'
import { Typography } from '@components'
import { RootStackParamList, useNavigator } from '@navigation'

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: 'center',
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8
  },
  contentWithSubtitle: {
    flex: 1,
    justifyContent: 'space-between'
  },
  horizontalLine: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  leftContent: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  mainWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 8
  },
  noBorder: {
    borderBottomWidth: 0
  }
})

export interface ContentItem {
  title: string
  photoBlock?: ReactNode
  nextScreen?: keyof RootStackParamList
  subtitle?: string | string[]
  url?: string
  onPress?: () => void
}

interface InfoBlockProps {
  contentArray: ContentItem[]
  edit?: boolean
}

export const InfoBlock = ({ contentArray, edit }: InfoBlockProps) => {
  const { navigation } = useNavigator<'ProfileActionModal'>()

  const handleItemPress = (screen?: keyof RootStackParamList, onPress?: () => void, goTo?: string) => {
    if (goTo) {
      Linking.openURL(goTo)
      return
    }

    if (onPress) {
      onPress()
      return
    }

    if (!screen) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate(screen, { edit })
  }

  return (
    <View style={styles.mainWrapper}>
      {contentArray.map((content, index) => (
        <TouchableOpacity
          onPress={() => handleItemPress(content.nextScreen, content.onPress, content.url)}
          key={content.title}
          style={[styles.contentContainer, index === contentArray.length - 1 && styles.noBorder]}
        >
          <View style={styles.contentWithSubtitle}>
            <View style={styles.horizontalLine}>
              <View style={styles.leftContent}>
                {content.photoBlock}
                <Typography f14 medium marginLeft={content.photoBlock ? 16 : 0}>
                  <Text>{content.title}</Text>
                </Typography>
              </View>
              <ArrowRightBlackSvg />
            </View>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  )
}

import { useEffect, useState } from 'react'
import { KeyboardAvoidingView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { CustomMaps, HeaderInfo, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

interface ILocation {
  name: string
  placeId: string
  position: {
    lat: number
    lng: number
  }
}

export const Location = () => {
  const { route } = useNavigator<'Location'>()
  const edit = route.params?.edit
  const { userUpdate } = useDataLayer()
  const currentUser = useSelector(selectCurrentUserData)
  const [location, setLocation] = useState<ILocation>({
    name: '',
    placeId: '',
    position: {
      lat: 39.78825,
      lng: -122.4324
    }
  })

  useEffect(() => {
    if (currentUser?.profile.userGeo) {
      setLocation(currentUser.profile.userGeo)
    }
  }, [])

  const goToLanguages = () => {
    if (!currentUser) {
      return
    }

    userUpdate(
      {
        profile: { ...currentUser.profile, userGeo: location }
      },
      { page: edit ? 'InformationYourself' : 'Languages' }
    )
  }

  return (
    <View style={styles.container}>
      <HeaderInfo showProgressBar={!edit} step={4} />
      <KeyboardAvoidingView behavior={isIOS ? 'padding' : undefined} style={{ flex: 1 }}>
        <Typography f27 bold marginTop={24} marginBottom={8}>
          Местоположение
        </Typography>
        <Typography f15 normal marginBottom={20}>
          Найдите свой город в списке или укажите место на карте
        </Typography>
        <CustomMaps placeholder='Название' isVisible location={location} setLocation={setLocation} types='(cities)' />
        <SubmitButton isEditing={edit} label='Продолжить' onPress={goToLanguages} disabled={!location.name} />
      </KeyboardAvoidingView>
    </View>
  )
}

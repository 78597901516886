import { StyleSheet, View } from 'react-native'

import { SButton, Typography } from '@components'
import { Colors } from '@components/utils'

interface TabsProps {
  items: Array<{
    id: number
    title: string
  }>
  activeTab: number
  setActiveTab: (val: number) => void
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 26,
    paddingVertical: 12
  },
  tab: {
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  tabs: {
    backgroundColor: Colors.GrayBg,
    borderRadius: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 4
  },
  title: {
    flexWrap: 'nowrap'
  }
})

export const Tabs = ({ items, activeTab, setActiveTab }: TabsProps) => {
  const renderTabs = () =>
    items.map(({ id, title }) => (
      <SButton
        onPress={() => setActiveTab(id)}
        key={id}
        borderRadius={40}
        styleBtn={{
          ...styles.tab,
          width: items.length > 2 && id === 1 ? '50%' : '25%',
          flex: items.length === 2 && 1
        }}
        baseColor={activeTab === id ? Colors.White : Colors.transparent}
      >
        <Typography f14 semibold textAlign='center' style={[styles.title, activeTab !== id && { color: '#B6B6BB' }]}>
          {title}
        </Typography>
      </SButton>
    ))

  return (
    <View style={styles.container}>
      <View style={styles.tabs}>{renderTabs()}</View>
    </View>
  )
}

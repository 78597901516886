import { StyleSheet, View } from 'react-native'

import { Typography } from '@components'

interface InfoItemProps {
  icon?: any
  text: string
  bgColor?: string
  textColor?: string
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'flex-end',
    borderRadius: 40,
    flexDirection: 'row',
    gap: 8,
    paddingHorizontal: 18,
    paddingVertical: 12
  }
})

export const InfoItem = ({ icon, text, bgColor, textColor }: InfoItemProps) => (
  <View style={[styles.wrapper, { backgroundColor: bgColor }]}>
    {icon}
    <Typography f16 normal color={textColor || '#535354'}>
      {text}
    </Typography>
  </View>
)

import { useMemo, useState } from 'react'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'

import { BackSvg } from '@assets/icons'
import { TextInput, Typography } from '@components'
import { useNavigator } from '@navigation'
import { CODE_DICTS, CountryInfo } from '@utils'

const styles = StyleSheet.create({
  countryWrapper: {
    borderBottomWidth: 1,
    borderColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingBottom: 10
  },
  input: {
    marginBottom: 16
  },
  mainContainer: {
    paddingBottom: 26,
    paddingHorizontal: 26,
    paddingVertical: 50
  },
  topWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16
  }
})

export const CountryList = () => {
  const [inputText, setInputText] = useState('')
  const { navigation } = useNavigator<'EnterPhone'>()

  const goToHome = ({ dialCode, len }: CountryInfo) => {
    navigation.navigate('EnterPhone', { dialCode, len })
  }

  const goBack = () => {
    navigation.goBack()
  }

  const filteredCodeDicts = useMemo(
    () =>
      CODE_DICTS.map(codes => {
        const filteredCodes = codes
          .map(c => {
            if (c.dialCode.includes(inputText) || c.name.toLowerCase().includes(inputText)) {
              return c
            }
          })
          .filter(Boolean)

        return filteredCodes.length ? filteredCodes : null
      }).filter(Boolean),
    [inputText]
  )

  const renderCountries = (codes: (CountryInfo | undefined)[]) =>
    codes.map(country => {
      if (!country) {
        return null
      }

      return (
        <TouchableOpacity key={country.name} onPress={() => goToHome(country)}>
          <View style={styles.countryWrapper}>
            <Typography f17 normal>
              {country.name}
            </Typography>
            <Typography f17 normal>
              {country.dialCode}
            </Typography>
          </View>
        </TouchableOpacity>
      )
    })

  const renderCodes = () =>
    filteredCodeDicts.map(codes => {
      if (!codes?.[0]) {
        return
      }

      const letter = codes[0].name[0]

      return (
        <View key={letter}>
          <Typography f17 semibold marginTop={20} marginBottom={4}>
            {letter}
          </Typography>
          {renderCountries(codes)}
        </View>
      )
    })

  return (
    <View style={styles.mainContainer}>
      <View style={styles.topWrapper}>
        <TouchableOpacity onPress={goBack}>
          <BackSvg />
        </TouchableOpacity>
        <Typography f17 normal>
          Выберите код страны
        </Typography>
        <View style={{ width: 40 }}></View>
      </View>
      <TextInput
        style={styles.input}
        autoCapitalize='none'
        placeholder='Искать по стране или коду'
        onChange={text => setInputText(text.toLowerCase())}
      />
      {filteredCodeDicts.length ? (
        <ScrollView showsVerticalScrollIndicator={false}>{renderCodes()}</ScrollView>
      ) : (
        <Typography marginTop={16} f22 normal textAlign='center'>
          Код страны не найден
        </Typography>
      )}
    </View>
  )
}

import { useEffect, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { IconCloseSvg } from '@assets/icons'
import { Chip, CustomMaps, HeaderInfo, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1
  },
  chipWrapper: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    marginTop: -230
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

interface Location {
  name: string
  placeId: string
  position: {
    lat: number
    lng: number
  }
}

export const FuturePlaces = () => {
  const { route } = useNavigator<'FuturePlaces'>()
  const edit = route.params?.edit
  const { userUpdate } = useDataLayer()
  const currentUser = useSelector(selectCurrentUserData)
  const [locations, setLocations] = useState<Location[]>([])
  const [locationsTravel, setLocationsTravel] = useState<Location[]>([])
  const [location, setLocation] = useState<Location>({
    name: '',
    placeId: '',
    position: {
      lat: 39.78825,
      lng: -122.4324
    }
  })
  const [locationTravel, setLocationTravel] = useState<Location>({
    name: '',
    placeId: '',
    position: {
      lat: 39.78825,
      lng: -122.4324
    }
  })

  useEffect(() => {
    if (currentUser) {
      setLocations(currentUser.profile.travel)
      setLocationsTravel(currentUser.profile.travelFuture)
    }
  }, [currentUser])

  const goToTellYourself = () => {
    if (!currentUser) {
      return
    }

    userUpdate(
      {
        profile: {
          ...currentUser.profile,
          travel: locations,
          travelFuture: locationsTravel
        }
      },
      { page: edit ? 'InformationYourself' : 'TellYourself' }
    )
  }

  useEffect(() => {
    if (location.placeId) {
      setLocations([...locations, location])
    }
  }, [location])

  useEffect(() => {
    if (locationTravel.placeId) {
      setLocationsTravel([...locationsTravel, locationTravel])
    }
  }, [locationTravel])

  const deletePlace = (placeId: string) => {
    const filteredLocations = locations.filter(el => el.placeId !== placeId)
    setLocations(filteredLocations)
  }

  const deletePlaceFuture = (placeId: string) => {
    const filteredLocations = locationsTravel.filter(el => el.placeId !== placeId)
    setLocationsTravel(filteredLocations)
  }

  return (
    <View style={styles.mainWrapper}>
      <HeaderInfo showProgressBar={!edit} step={15} />
      <View style={styles.centerWrapper}>
        <Typography f27 bold marginTop={24} marginBottom={8}>
          Отметь места, куда любишь ездить
        </Typography>
        <View style={{ zIndex: 2 }}>
          <CustomMaps
            placeholder='Название'
            isVisible={false}
            location={location}
            setLocation={setLocation}
            types='(cities)'
          />
          <View style={styles.chipWrapper}>
            {locations.map(el => (
              <Chip
                key={el.placeId}
                label={el.name}
                rightIcon={
                  <Pressable onPress={() => deletePlace(el.placeId)}>
                    <IconCloseSvg width={24} height={24} />
                  </Pressable>
                }
                bgColor='#e6e4ed'
              />
            ))}
          </View>
        </View>

        <Typography f27 bold marginTop={112} marginBottom={8}>
          Отметь места, куда хочешь поехать
        </Typography>
        <View style={{ zIndex: 1 }}>
          <CustomMaps
            placeholder='Название'
            isVisible={false}
            location={locationTravel}
            setLocation={setLocationTravel}
            types='(cities)'
          />
          <View style={styles.chipWrapper}>
            {locationsTravel.map(el => (
              <Chip
                key={el.placeId}
                label={el.name}
                rightIcon={
                  <Pressable onPress={() => deletePlaceFuture(el.placeId)}>
                    <IconCloseSvg width={24} height={24} />
                  </Pressable>
                }
                bgColor='#e6e4ed'
              />
            ))}
          </View>
        </View>
      </View>
      <SubmitButton
        isEditing={edit}
        onPress={goToTellYourself}
        buttonLabelCondition={!!locations.length || !!locationsTravel.length}
      />
    </View>
  )
}

import Svg, { Path } from 'react-native-svg'

export const SearchSvg = ({ fill = 'black' }: { fill: string }) => (
  <Svg width={24} height={24} viewBox='0 0 24 24'>
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.4774 2.89888C6.29049 2.89888 2.89894 6.28868 2.89894 10.467C2.89894 14.6454 6.29049 18.0352 10.4774 18.0352C12.5662 18.0352 14.4554 17.1929 15.8273 15.8273C17.2055 14.4554 18.0558 12.5614 18.0558 10.467C18.0558 6.28868 14.6642 2.89888 10.4774 2.89888ZM0.285706 10.467C0.285706 4.84258 4.85008 0.285645 10.4774 0.285645C16.1046 0.285645 20.669 4.84258 20.669 10.467C20.669 12.8127 19.8738 14.9744 18.5401 16.6953L21.331 19.4832C21.8416 19.9931 21.8421 20.8204 21.3321 21.331C20.8221 21.8415 19.9949 21.8421 19.4843 21.3321L16.6886 18.5396C14.9694 19.8613 12.8145 20.6484 10.4774 20.6484C4.85008 20.6484 0.285706 16.0915 0.285706 10.467Z'
      fill={fill}
    />
  </Svg>
)

import { moodIcons } from '@assets/icons/mood'

export const MOOD_RANGE = [-100, 100]

export const normalizeMoodValue = (value: string | undefined) => {
  if (!value) {
    return 0
  }

  const floatValue = +parseFloat(value).toFixed(2)
  if (floatValue < MOOD_RANGE[0]) {
    return MOOD_RANGE[0]
  }

  if (floatValue > MOOD_RANGE[1]) {
    return MOOD_RANGE[1]
  }

  return floatValue
}

export const getMoodIconByMoodValue = (value: number | string) => {
  const number = typeof value === 'number' ? value : parseFloat(value)
  const breakpoints = [9, 21, 33, 50, 67, 84]
  let icon = moodIcons[moodIcons.length - 1]

  for (let i = 0; i < breakpoints.length; i++) {
    if (number < MOOD_RANGE[0] + (breakpoints[i] / 100) * (MOOD_RANGE[1] - MOOD_RANGE[0])) {
      icon = moodIcons[i]
      break
    }
  }

  return icon
}

export const formatUserName = (name: string) => {
  const words = name.split(' ')
  let nameCut = words[0]
  if (words[1]) {
    nameCut += ` ${words[1][0]}.`
  }
  return nameCut
}

import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Avatar, FeedTab, HeaderInfoProfile, Tabs, Typography } from '@components'
import { selectCurrentUserData, userProfileSelector } from '@redux/selectors'
import { ProfileInfo } from '@screens'

const styles = StyleSheet.create({
  userInfo: {
    alignItems: 'center',
    marginTop: 20
  }
})

export const MainProfile = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { login, profileImageUrl, name } = useSelector(userProfileSelector)
  const currentUser = useSelector(selectCurrentUserData)

  const TABS = [
    { id: 0, title: 'Лента', component: FeedTab },
    { id: 1, title: 'Инфо', component: ProfileInfo }
  ]

  const Tab = TABS[activeTab].component

  return (
    <>
      <HeaderInfoProfile username={login} />
      <View style={styles.userInfo}>
        <Avatar size={100} uri={profileImageUrl} />
        <Typography f22 bold marginTop={12} marginBottom={20}>
          {name}
        </Typography>
      </View>
      <Tabs items={TABS} activeTab={activeTab} setActiveTab={index => setActiveTab(index)} />
      <Tab user={currentUser} />
    </>
  )
}

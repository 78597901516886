import { SafeAreaView, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native'

import { BackWhiteSvg, SettingsButtonSvg } from '@assets/icons'
import { Typography } from '@components'
import { isWeb, screenWidth } from '@components/utils'
import { useNavigator } from '@navigation'

const styles = StyleSheet.create({
  mainWrapper: {
    alignItems: 'center',
    backgroundColor: '#2f2f2f',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 26,
    paddingVertical: 10
  },
  webContainer: {
    marginBottom: 60,
    zIndex: 11
  },
  webWrapper: {
    left: 0,
    marginHorizontal: 'auto',
    maxWidth: screenWidth,
    position: 'fixed',
    right: 0,
    top: 0
  }
})

interface HeaderInfoProfileProps {
  username: string | undefined
}

export const HeaderInfoProfile = ({ username }: HeaderInfoProfileProps) => {
  const { navigation } = useNavigator<'Settings'>()

  const goToHome = () => {
    navigation.navigate('Tabs')
  }

  const goToSettings = () => {
    navigation.navigate('Settings')
  }

  return (
    <SafeAreaView style={isWeb && styles.webContainer}>
      <StatusBar backgroundColor='#2f2f2f' barStyle='light-content' />
      <View style={[styles.mainWrapper, isWeb && styles.webWrapper]}>
        <TouchableOpacity onPress={goToHome}>
          <BackWhiteSvg />
        </TouchableOpacity>
        <Typography f17 semibold color='#fff'>
          {username ? '@' + username : ''}
        </Typography>
        <TouchableOpacity onPress={goToSettings}>
          <SettingsButtonSvg />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  )
}

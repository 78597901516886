/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { navigate } from '@navigation'
import { notificationAction } from '@redux/actions'
import { GET_DATE_COMPATIBILITY_REQUEST, GetDateCompatibilityRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { DateComp } from '@types'

type SagaEffects = CallEffect | PutEffect

function* dateCompatibility(action: GetDateCompatibilityRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.getDateCompatibility], action.payload.date)
    const comp: DateComp = response.data
    navigate({ page: action.payload.next.page, params: { ...action.payload.next.params, comp } })
  } catch (error: any) {
    yield put(
      notificationAction({
        type: 'error',
        text: error.response.data.message,
        image: ''
      })
    )
  }
}

export function* getDateCompatibilitySaga() {
  yield takeLatest(GET_DATE_COMPATIBILITY_REQUEST, dateCompatibility)
}

import { useMemo, useState } from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'

import { Chip, MoodScaleModal, Typography } from '@components'
import { getMoodIconByMoodValue, normalizeMoodValue } from '@components/utils'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { getStatusById, StatusItem } from '@utils'

const styles = StyleSheet.create({
  chipsWrapper: {
    marginTop: 10
  },
  headerText: {
    color: '#000',
    fontSize: 14,
    fontWeight: '600',
    marginBottom: 5
  },
  infoText: {
    color: '#2C2C2C',
    fontSize: 12
  },
  mainWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    marginBottom: 25,
    paddingHorizontal: 16,
    paddingVertical: 12
  }
})

export const MoodBlock = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { userUpdate } = useDataLayer()
  const user = useSelector(selectCurrentUserData)

  const moodStatusId = user?.moodStatus[0] || 0
  const description = user?.mood?.text || ''
  const average = user?.mood?.average || ''

  const changeStatus = (newStatus: number) => userUpdate({ moodStatus: [newStatus] }, { page: 'none' })

  const moodStatus: StatusItem = useMemo(() => getStatusById(moodStatusId), [moodStatusId])
  const { icon: Icon, text } = moodStatus

  const MoodIcon = useMemo(() => getMoodIconByMoodValue(average), [average])
  const moodTextTrim = useMemo(
    () => (description.length > 120 ? description.substring(0, 117) + '...' : description),
    [description]
  )

  return (
    <>
      <TouchableOpacity style={styles.mainWrapper} onPress={() => setIsModalOpen(true)}>
        <Typography style={styles.headerText}>Настроение и статус</Typography>
        <Text style={styles.infoText}>{moodTextTrim}</Text>
        <ScrollView
          contentContainerStyle={{ gap: 12 }}
          horizontal
          style={styles.chipsWrapper}
          showsHorizontalScrollIndicator={false}
        >
          <Chip leftIcon={<Icon fill='black' />} label={text.ru} bgColor='#f3f2f8' fontSize={16} />
          <Chip
            leftIcon={<MoodIcon />}
            label={normalizeMoodValue(average).toString()}
            bgColor='#f3f2f8'
            fontSize={16}
          />
        </ScrollView>
      </TouchableOpacity>
      <MoodScaleModal
        isMyProfile
        isVisible={isModalOpen}
        moodStatus={moodStatus}
        average={average}
        moodText={description}
        setStatus={changeStatus}
        close={() => setIsModalOpen(false)}
      />
    </>
  )
}

import { ReactNode } from 'react'
import { StyleSheet, View } from 'react-native'

import { dimensionsHeight, isWeb, WEB_CONTAINER_HEIGHT, WEB_CONTAINER_WIDTH } from '@components/utils'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 'auto',
    maxHeight: WEB_CONTAINER_HEIGHT,
    maxWidth: WEB_CONTAINER_WIDTH,
    width: '100%'
  }
})

interface WebAppContainerProps {
  children: ReactNode
}

export const WebAppContainer = ({ children }: WebAppContainerProps) => {
  if (!isWeb) {
    return children
  }

  const marginTop = (dimensionsHeight - WEB_CONTAINER_HEIGHT) / 2

  return <View style={[styles.container, marginTop > 0 && { marginTop }]}>{children}</View>
}

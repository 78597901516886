import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Checkbox, CustomRadioButton, HeaderInfo, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'
import { FINANCIAL, OptionId } from '@utils'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1
  },
  checkBoxWrapper: {
    alignItems: 'center',
    marginBottom: 21
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

export const Financial = () => {
  const { route } = useNavigator<'Financial'>()
  const edit = route.params?.edit
  const { userUpdate } = useDataLayer()
  const currentUser = useSelector(selectCurrentUserData)

  const [chosenOption, setChosenOption] = useState<boolean>(true)
  const [selectedFinancial, setSelectedFinancial] = useState<OptionId>()

  useEffect(() => {
    if (!currentUser) {
      return
    }

    setSelectedFinancial(currentUser.profile.financial)
    setChosenOption(currentUser.profile.algorithmFinancial)
  }, [currentUser])

  const goToEducation = () => {
    if (!currentUser) {
      return
    }

    userUpdate(
      {
        profile: {
          ...currentUser.profile,
          financial: selectedFinancial,
          algorithmFinancial: chosenOption
        }
      },
      { page: edit ? 'InformationYourself' : 'Education' }
    )
  }

  const handleCheckBoxPress = () => {
    setChosenOption(prev => !prev)
  }

  const handleFinancialSelect = (financial: OptionId) => {
    setSelectedFinancial(financial)
  }

  const renderFinancials = () =>
    FINANCIAL.map(financial => (
      <CustomRadioButton
        key={financial.id}
        textRadio={financial.label}
        isSelected={selectedFinancial === financial.id}
        onPress={() => handleFinancialSelect(financial.id)}
      />
    ))

  return (
    <View style={styles.mainWrapper}>
      <HeaderInfo showProgressBar={!edit} step={9} />
      <View style={styles.centerWrapper}>
        <Typography f27 bold marginTop={28} marginBottom={8}>
          Финансовое положение
        </Typography>
        <Typography f15 normal marginBottom={20}>
          Эта информация не отображается на странице
        </Typography>
        {renderFinancials()}
      </View>
      <SubmitButton
        isEditing={edit}
        buttonLabelCondition={chosenOption || (selectedFinancial as number) > -1}
        onPress={goToEducation}
      >
        <View style={styles.checkBoxWrapper}>
          <Checkbox label='Учитывать в работе алгоритма' checked={!!chosenOption} onPress={handleCheckBoxPress} />
        </View>
      </SubmitButton>
    </View>
  )
}

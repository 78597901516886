import { StyleSheet } from 'react-native'

import { Fonts } from '@components/utils'

export const typography = StyleSheet.create({
  description: {
    fontFamily: Fonts.regular,
    fontSize: 15
  },
  h1: {
    fontFamily: Fonts.bold,
    fontSize: 24
  },
  h1Light: {
    fontFamily: Fonts.light,
    fontSize: 24
  },
  h2: {
    fontFamily: Fonts.semiBold,
    fontSize: 17
  },
  h3: {
    fontFamily: Fonts.regular,
    fontSize: 14
  },
  h3SemiBold: {
    fontFamily: Fonts.semiBold,
    fontSize: 14
  },
  h3bold: {
    fontFamily: Fonts.bold,
    fontSize: 16
  },
  h3medium: {
    fontFamily: Fonts.medium,
    fontSize: 16
  },
  subtitle: {
    fontFamily: Fonts.bold,
    fontSize: 20
  }
})

import { useEffect } from 'react'

import { isWeb } from '@components/utils'
import { NavigationState, useNavigationState } from '@react-navigation/native'

const PAGE_COLOR_SMAP: any = {
  Tab: '#f3f2f9',
  ModelScreen: 'black',
  Chat: '#e8e7ed',
  MainProfile: '#2f2f2f',
  Main: 'white'
}

const getActiveRouteName = (state: NavigationState): string => {
  const route = state.routes[state.index]

  if (route.state) {
    return getActiveRouteName(route.state as NavigationState)
  }

  return route.name
}

export const useWebStatusBarColor = () => {
  const currentRouteName = useNavigationState(state => getActiveRouteName(state))

  useEffect(() => {
    if (isWeb) {
      const meta = document.head.querySelector('meta[name="theme-color"]')
      meta?.setAttribute('content', PAGE_COLOR_SMAP[currentRouteName] || '#f3f2f9')
    }
  }, [currentRouteName])
}

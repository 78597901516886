import {
  GET_MY_LIKES_REQUEST,
  GET_MY_LIKES_SUCCESS,
  GetMyLikesRequestAction,
  GetMyLikesSuccessAction
} from '@redux/types'
import { Chat } from '@types'

export const getMyLikesRequest = (): GetMyLikesRequestAction => ({
  type: GET_MY_LIKES_REQUEST
})

export const getMyLikesSuccess = (likes: Chat[]): GetMyLikesSuccessAction => ({
  type: GET_MY_LIKES_SUCCESS,
  payload: {
    likes
  }
})

import { StyleSheet, TouchableOpacity } from 'react-native'

import { Typography } from '@components'
import { ModalUserSelectedOptions } from '@components/utils'

const styles = StyleSheet.create({
  option: {
    alignItems: 'center',
    borderTopColor: '#fff',
    borderTopWidth: 1,
    flexGrow: 1,
    justifyContent: 'center',
    padding: 18,
    width: '100%'
  }
})

interface ModalUserActionsMainProps {
  close: () => void
  setSelectedOption: (option: ModalUserSelectedOptions) => void
  isBlocked: boolean
  showChatOption?: boolean
}

export const ModalUserActionsMain = ({
  close,
  setSelectedOption,
  isBlocked,
  showChatOption
}: ModalUserActionsMainProps) => (
  <>
    <TouchableOpacity
      style={[styles.option, { borderTopWidth: 0 }]}
      onPress={() => setSelectedOption(ModalUserSelectedOptions.COMPLAINT)}
    >
      <Typography f16 style={{ fontWeight: '700' }}>
        Пожаловаться
      </Typography>
    </TouchableOpacity>
    <TouchableOpacity style={styles.option} onPress={() => setSelectedOption(ModalUserSelectedOptions.BLOCK)}>
      <Typography f16 style={{ fontWeight: '700' }}>
        {isBlocked ? 'Разблокировать' : 'Заблокировать'}
      </Typography>
    </TouchableOpacity>
    {showChatOption && (
      <TouchableOpacity style={styles.option} onPress={() => setSelectedOption(ModalUserSelectedOptions.CHAT)}>
        <Typography f16 style={{ fontWeight: '700', color: '#F64A3F' }}>
          Удалить чат
        </Typography>
      </TouchableOpacity>
    )}
    <TouchableOpacity style={styles.option} onPress={close}>
      <Typography f16 style={{ opacity: 0.5 }}>
        Отмена
      </Typography>
    </TouchableOpacity>
  </>
)

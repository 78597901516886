import { useEffect, useState } from 'react'
import { KeyboardAvoidingView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { ArrowDownBlackSvg } from '@assets/icons'
import { Button, HeaderInfo, SocialAuthButtonList, TextInput, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectAuthUser } from '@redux/selectors'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: 50
  },
  input: {
    flex: 1
  },
  inputsWrapper: {
    flexDirection: 'row',
    gap: 8,
    marginTop: 24
  },
  selectCode: {
    alignItems: 'center',
    borderColor: '#a2a0ac',
    borderRadius: 40,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingVertical: 15
  }
})

export const EnterPhoneLogin = () => {
  const { route, navigation } = useNavigator<'EnterPhoneLogin'>()
  const { registration } = useDataLayer()
  const authUser = useSelector(selectAuthUser)
  const dialCode = route.params?.dialCode ?? '+380'
  const phoneLength = route.params?.len ?? 9

  const [number, setNumber] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const isError = submitted && number.length < 8

  const handleChangeNumber = (newNumber: string) => {
    setNumber(newNumber)
    setSubmitted(false)
  }

  const handleSubmit = () => {
    setSubmitted(true)
  }

  const normalizePhoneNumber = (phone: string) => phone.replace(/^\+/, '')

  const goToNewPage = () => {
    if (number.length < 8) {
      handleSubmit()
      return
    }

    registration({ phone: normalizePhoneNumber(dialCode + number) })
    setRedirect(true)
  }

  useEffect(() => {
    if (!redirect) {
      return
    }

    const isDone = authUser.status === 'done'
    const isCreating = authUser.status === 'creating'
    const isRegistration = authUser.status === 'registration'
    const isDeleted = !!authUser.deleted

    if (!isDone && !isCreating && !isRegistration) {
      return
    }

    let navigateTo: 'PasswordLogin' | 'EnterCode' | 'RestoreAccount'

    if (isDeleted) {
      navigateTo = 'RestoreAccount'
    } else if (isDone || isCreating) {
      navigateTo = 'PasswordLogin'
    } else {
      navigateTo = 'EnterCode'
    }

    navigation.navigate(navigateTo, {
      phone: normalizePhoneNumber(dialCode + number)
    })
  }, [authUser, redirect])

  return (
    <View style={styles.container}>
      <HeaderInfo />
      <KeyboardAvoidingView behavior={isIOS ? 'padding' : undefined} style={{ flex: 1 }}>
        <Typography f27 bold marginTop={52}>
          Укажите свой номер
        </Typography>
        <View style={styles.inputsWrapper}>
          <TouchableOpacity style={styles.selectCode} onPress={() => navigation.navigate('ChoseCountry')}>
            <Typography f17 normal>
              {dialCode}
            </Typography>
            <ArrowDownBlackSvg />
          </TouchableOpacity>
          <TextInput
            style={styles.input}
            onChange={handleChangeNumber}
            error={isError}
            placeholder='Номер телефона'
            errorMessage='Неправильный номер телефона'
            maxLength={phoneLength}
            keyboardType='numeric'
          />
        </View>
        <SocialAuthButtonList />
        <View style={{ flexGrow: 1 }} />
        <Button text='Продолжить' disabled={number.length !== phoneLength} onPress={goToNewPage} />
      </KeyboardAvoidingView>
    </View>
  )
}

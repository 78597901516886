import { StyleSheet, TouchableOpacity } from 'react-native'

import { BellSvg } from '@assets/icons'
import { Button, ModalWrapper, Typography } from '@components'

const styles = StyleSheet.create({
  buttonWrapper: {
    width: '100%'
  }
})

interface InfoModalProps {
  visible: boolean
  onClose: () => void
}

export const InfoModal = ({ visible, onClose }: InfoModalProps) => {
  return (
    <ModalWrapper isVisible={visible} close={onClose} contentStyle={{ paddingTop: 44 }}>
      <BellSvg />
      <Typography f20 semibold marginTop={40} marginBottom={12}>
        Важно
      </Typography>
      <Typography f15 normal textAlign='center' marginBottom={52}>
        Работа алгоритма соцсети зависит от того, насколько подробно вы заполните данные о себе
      </Typography>
      <TouchableOpacity style={styles.buttonWrapper}>
        <Button text='Продолжить' onPress={onClose} />
      </TouchableOpacity>
    </ModalWrapper>
  )
}

import Svg, { Path } from 'react-native-svg'

export const HomeSvg = ({ fill = 'black' }: { fill: string }) => (
  <Svg width={24} height={24} viewBox='0 0 24 24'>
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.189 1.60497C12.8692 0.695805 11.1303 0.695805 9.81053 1.60497L2.61054 6.56476C1.55437 7.29232 0.922852 8.49774 0.922852 9.78619V19.1737C0.922852 21.3294 2.65861 23.0769 4.79977 23.0769H19.1998C21.3409 23.0769 23.0767 21.3294 23.0767 19.1737V9.78619C23.0767 8.49774 22.4452 7.29232 21.389 6.56476L14.189 1.60497ZM8.99977 17C8.44749 17 7.99977 17.4477 7.99977 18C7.99977 18.5523 8.44749 19 8.99977 19H14.9998C15.5521 19 15.9998 18.5523 15.9998 18C15.9998 17.4477 15.5521 17 14.9998 17H8.99977Z'
      fill={fill}
    />
  </Svg>
)

import { useState } from 'react'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import { ImageEditor } from 'expo-crop-image'
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator'
import * as ImagePicker from 'expo-image-picker'

import { Close, Plus } from '@assets/images'
import { isWeb, screenWidth } from '@components/utils'
import { useDataLayer } from '@redux/dataLayer'

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#E8E7ED',
    borderRadius: 11,
    height: screenWidth * 0.27,
    justifyContent: 'center',
    position: 'relative',
    width: screenWidth * 0.27
  },
  deleteBlock: {
    position: 'absolute',
    right: -12,
    top: -12,
    zIndex: 3
  },
  icon: {
    height: 30,
    width: 30,
    zIndex: 10
  },
  imageContainer: {
    backgroundColor: 'transparent',
    borderRadius: 11,
    height: '100%',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 3
  },
  uploadBlock: {
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.4,
    zIndex: 3
  }
})

const dataURItoBlob = (dataURI: string) => {
  const [type, data] = dataURI.split(',')
  // convert base64/URLEncoded data component to raw binary data held in a string
  const byteString = type.includes('base64') ? atob(data) : unescape(data)

  // separate out the mime component
  const mimeString = type.split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  const res = new Blob([ia], { type: mimeString })
  return res
}

interface ProfilePhotoProps {
  image: string
  onClickIcon: () => void
}

export const ProfilePhoto = ({ image, onClickIcon }: ProfilePhotoProps) => {
  const { uploadImage } = useDataLayer()
  const [uri, setUri] = useState('')
  const [isEditorVisible, setIsEditorVisible] = useState(false)

  const uploadPhoto = async (newUri: string) => {
    const formData = new FormData()
    const localUri = (
      await manipulateAsync(newUri, [{ resize: { height: 1920, width: 1080 } }], { format: SaveFormat.PNG })
    ).uri

    const filename = localUri.split('/').pop()

    formData.append(
      'image',
      isWeb ? dataURItoBlob(localUri) : ({ uri: localUri, name: filename, type: 'image/png' } as unknown as Blob)
    )

    uploadImage(formData)
  }

  const pickImage = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        quality: 1
      })

      if (!result.canceled) {
        setUri(result.assets[0].uri)
        setIsEditorVisible(true)
      }
    } catch (err) {
      console.log('IMAGE: ', err)
    }
  }

  const icon = image ? Close : Plus
  const action = image ? onClickIcon : pickImage
  const style = image ? styles.deleteBlock : styles.uploadBlock

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={pickImage} style={styles.imageContainer}>
        {image && <Image style={{ flex: 1 }} source={{ uri: image }} />}
      </TouchableOpacity>
      <TouchableOpacity onPress={action} style={style}>
        <Image source={icon} style={[styles.icon, !!image && { width: 24, height: 24 }]} />
      </TouchableOpacity>
      {uri && (
        <ImageEditor
          imageUri={uri}
          isVisible={isEditorVisible}
          fixedAspectRatio={9 / 16}
          onEditingCancel={() => {
            setIsEditorVisible(false)
            setUri('')
          }}
          onEditingComplete={img => {
            setIsEditorVisible(false)
            setUri('')
            uploadPhoto(img.uri)
          }}
        />
      )}
    </View>
  )
}

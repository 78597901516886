import { useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { AlertSvg, ArrowLeftSvg } from '@assets/icons'
import { ModalUserActions, SButton, SFlex, Typography } from '@components'
import { Colors } from '@components/utils'
import { useNavigator } from '@navigation'

const styles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    height: 40,
    justifyContent: 'center',
    width: 40
  },
  nameWrapper: {
    alignItems: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    zIndex: -1
  }
})

interface ModelInfoHeaderProps {
  goBack: () => void
  name: string
}

export const ModelInfoHeader = ({ goBack, name }: ModelInfoHeaderProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { route } = useNavigator<'ModelScreen'>()
  const { user } = route.params

  return (
    <>
      <SFlex justifyContent='space-between' paddingLeft={26} paddingRight={26}>
        <SButton onPress={goBack} baseColor={Colors.LightGray} borderRadius={50} styleBtn={styles.btn}>
          <ArrowLeftSvg width={20} height={20} />
        </SButton>
        <View style={styles.nameWrapper}>
          <Typography f17 semibold color='#FFFFFF'>
            {name}
          </Typography>
        </View>
        <SButton
          onPress={() => setIsModalVisible(true)}
          baseColor={Colors.LightGray}
          borderRadius={50}
          styleBtn={styles.btn}
        >
          <AlertSvg width={20} height={20} />
        </SButton>
      </SFlex>
      <ModalUserActions isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} targetId={user._id} />
    </>
  )
}

import { PageAttributes } from '@navigation'
import { CHANGE_PASSWORD_REQUEST, ChangePasswordRequestAction } from '@redux/types'

export const changePasswordRequest = (
  phone: string,
  oldPassword: string,
  newPassword: string,
  next: PageAttributes
): ChangePasswordRequestAction => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload: {
    phone,
    oldPassword,
    newPassword,
    next
  }
})

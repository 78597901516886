import { useMemo } from 'react'

import { ChatSvg, HomeSvg, SearchSvg } from '@components'
import { isIOS } from '@components/utils'
import { useWebStatusBarColor } from '@hooks'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { MainScreen, MyTelegram, Search } from '@screens'

const inactiveColor = 'rgb(182, 182, 182)'
const activeColor = 'rgb(39, 39, 39)'

const Tab = createBottomTabNavigator()

export const TabNavigator = () => {
  useWebStatusBarColor()

  const tabsList = [
    { name: 'Main', Component: MainScreen, Icon: HomeSvg },
    { name: 'Search', Component: Search, Icon: SearchSvg },
    { name: 'Message', Component: MyTelegram, Icon: ChatSvg }
  ]

  const tabs = useMemo(
    () =>
      tabsList.map(({ name, Component, Icon }) => (
        <Tab.Screen
          key={name}
          name={name}
          component={Component}
          options={{
            tabBarIcon: ({ focused }) => <Icon fill={focused ? activeColor : inactiveColor} />
          }}
        />
      )),
    []
  )

  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarShowLabel: false,
        tabBarStyle: {
          width: '100%',
          height: '100%',
          maxHeight: isIOS ? 90 : 60,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          backgroundColor: '#fff'
        }
      }}
      initialRouteName='Message'
      sceneContainerStyle={{
        backgroundColor: '#f3f2f9'
      }}
    >
      {tabs}
    </Tab.Navigator>
  )
}

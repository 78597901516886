import { createSelector } from 'reselect'

import { RootState } from '@redux/reducer'

export const selectCurrentUserData = createSelector(
  (state: RootState) => state.currentUser,
  currentUser => currentUser
)

export const selectCurrentUserStatus = createSelector(
  (state: RootState) => state.currentUser?.status,
  userStatus => userStatus
)

export const selectCurrentUserMoodStatus = createSelector(
  (state: RootState) => state.currentUser?.moodStatus?.[0] || 0,
  moodStatus => moodStatus
)

export const selectCurrentUserGallery = createSelector(
  (state: RootState) => state.currentUser?.profileImage,
  gallery => gallery
)

export const selectCurrentUserBlockedUserIds = createSelector(
  (state: RootState) => state.currentUser?.blockedUserIds,
  blockedUserIds => blockedUserIds
)

export const selectPhone = createSelector(
  (state: RootState) => (state.currentUser ? state.currentUser.phone : ''),
  restorePhone => restorePhone
)

export const selectUserChatId = createSelector(
  (state: RootState) => (state.currentUser ? state.currentUser?.userChatId : -1),
  restorePhone => restorePhone
)

export const selectCurrentUserId = createSelector(
  (state: RootState) => (state.currentUser ? state.currentUser?._id : ''),
  id => id
)

export const userProfileSelector = createSelector(
  (state: RootState) => state.currentUser,
  data => ({
    login: data?.login,
    profileImageUrl: data?.profileImage[0]?.name,
    name: data?.name,
    description: data?.description
  })
)

export const selectAuthUser = createSelector(
  (state: RootState) => state.auth,
  auth => auth
)

export const selectMyLikes = createSelector(
  (state: RootState) => state.connectRequests,
  myLikes => myLikes
)

export const selectNotificationState = createSelector(
  (state: RootState) => state.notification,
  notification => notification
)

export const selectUserProfile = createSelector(
  (state: RootState) => state.currentUser?.profile,
  profile => profile
)

export const selectNotificationHistoryData = createSelector(
  (state: RootState) => state.notificationHistory.data,
  notificationHistoryData => notificationHistoryData
)

export const selectNotificationHistoryCounter = createSelector(
  (state: RootState) => state.notificationHistory.counter,
  notificationHistoryCounter => notificationHistoryCounter
)

export const selectChatMessages = createSelector(
  (state: RootState) => state.chatMessages.messages,
  chatMessagesData => chatMessagesData
)

export const selectChatsState = createSelector(
  (state: RootState) => state.chats,
  newChatStateData => newChatStateData
)

export const selectUsers = createSelector(
  (state: RootState) => state.users,
  newUsers => newUsers
)

export const selectAllUsers = createSelector(
  (state: RootState) => state.allUsers,
  newAllUsers => newAllUsers
)

export const selectAllDeletedUsers = createSelector(
  (state: RootState) => state.allDeletedUsers,
  newAllDeletedUsers => newAllDeletedUsers
)

export const selectCodeStatus = createSelector(
  (state: RootState) => state.code,
  checkCodeData => checkCodeData
)

export const selectStatus = createSelector(
  (state: RootState) => state.currentUser?.status,
  registrationData => registrationData
)

export const selectLoginState = createSelector(
  (state: RootState) => state.currentUser,
  loginData => loginData
)

export const selectCurrentUserGallerySize = createSelector(
  (state: RootState) => state.currentUser?.profileImage.length,
  gallerySize => gallerySize
)

export const fullChatUserData = createSelector(
  (state: RootState) => state.fullChatUser,
  user => user
)

export const selectChannel = createSelector(
  (state: RootState) => state.channel,
  channel => channel
)

export const selectDiscussion = createSelector(
  (state: RootState) => state.discussion,
  discussion => discussion
)

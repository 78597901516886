import { Image, Pressable, StyleSheet, View } from 'react-native'

import { ArrowOff, ArrowOn } from '@assets/images'
import { Typography } from '@components'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row'
  }
})

interface CheckboxProps {
  label: string
  checked: boolean
  onPress: () => void
}

export const Checkbox = ({ label, checked, onPress }: CheckboxProps) => {
  const arrowIcon = checked ? ArrowOn : ArrowOff

  return (
    <View style={styles.container}>
      <Pressable onPress={onPress}>
        <Image source={arrowIcon} style={{ width: 19, height: 19 }} />
      </Pressable>
      <Typography f13 normal marginLeft={12}>
        {label}
      </Typography>
    </View>
  )
}

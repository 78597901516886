export { default as ArrowOff } from './ArrowOff.png'
export { default as ArrowOn } from './ArrowOn.png'
export { default as BackgroundFirsScreen } from './BackgroundFirsScreen.png'
export { default as BackgroundStartSelection } from './BackgroundStartSelection.png'
export { default as Close } from './close.png'
export { default as CompatBackLayer } from './CompatBackLayer.png'
export { default as HideEye } from './HideEye.png'
export { default as Plus } from './Plus.png'
export { default as ShowEye } from './ShowEye.png'
export { default as UserDeleted } from './userDeleted.png'

import {
  ChannelActionTypes,
  ChannelState,
  DiscussionActionTypes,
  GET_CHANNEL_BY_USER_ID_REQUEST,
  GET_CHANNEL_BY_USER_ID_SUCCESS,
  RECEIVE_DISCUSSION,
  RECEIVE_MESSAGES_FOR_CHANNEL
} from '@redux/types'
import { Channel, ChannelMessage } from '@types'

const initialState: ChannelState = null

export const channelReducer = (
  state = initialState,
  action: ChannelActionTypes | DiscussionActionTypes
): ChannelState => {
  switch (action.type) {
    case GET_CHANNEL_BY_USER_ID_REQUEST:
      return state
    case GET_CHANNEL_BY_USER_ID_SUCCESS:
      return action.payload
    case RECEIVE_MESSAGES_FOR_CHANNEL:
      if (!state) {
        return state
      }
      return { ...(state as Channel), history: [...action.payload] }
    case RECEIVE_DISCUSSION:
      if (!state) {
        return state
      }

      return {
        ...(state as Channel),
        history: state.history?.map((post: ChannelMessage) => {
          if (post.discussionChat?.id !== action.payload.message.chat_id) {
            return post
          }

          const newMessage = { ...action.payload.message, User: action.payload.user }
          const messages = [...post.discussionChat.messages, newMessage]
          const discussionChat = { ...post.discussionChat, messages }

          return { ...post, discussionChat }
        })
      }
    default:
      return state
  }
}

import { DeviceEventEmitter as Event } from 'react-native'
// eslint-disable-next-line import/no-named-as-default
import io from 'socket.io-client'

import {
  Notification,
  NOTIFICATION_SUCCESS,
  PHONE_CONNECT_REQUEST,
  RECEIVE_LIKE,
  SEND_LIKE_REQUEST
} from '@redux/types'
import { DOMAIN } from '@services/apiService'
import { UserProfile } from '@types'
import { getFromLS } from '@utils'

const socket = io(`https://ws.${DOMAIN}`)

Event.addListener(PHONE_CONNECT_REQUEST, payload => {
  socket.emit('likeconnect', payload)
})

Event.addListener(SEND_LIKE_REQUEST, payload => {
  socket.emit('like', payload)
})

socket.on('connect', async () => {
  // TODO: PROVIDE LOCAL STORAGE
  try {
    const user: UserProfile = JSON.parse((await getFromLS('@user')) as string)
    socket.emit('likeconnect', user._id)
  } catch (err) {
    console.log('Cant connect socket service')
  }

  socket.on('like', (payload: any) => {
    Event.emit(RECEIVE_LIKE, payload)
  })

  socket.on('notification', (payload: Notification) => {
    Event.emit(NOTIFICATION_SUCCESS, payload)
  })
})

import axios from 'axios'
import Constants from 'expo-constants'

import { CreateChatUserAtributes } from '@redux/types'
import {
  AttachmentMessageData,
  AuthMethods,
  Base64File,
  ComplaintAttributes,
  SocialAuth,
  UserChatAttributes
} from '@types'
import { saveToLS } from '@utils'

export const DOMAIN = Constants.expoConfig?.extra?.env.EXPO_PUBLIC_DOMAIN || 'connectapp.me'
export const SERVER_URL = `https://api.${DOMAIN}`

class ApiService {
  #api

  constructor() {
    this.#api = axios.create({
      baseURL: SERVER_URL
    })
  }

  setToken = async (token: string) => {
    await saveToLS('@token', token)
    this.#api.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  getCurrentUser = async () => {
    const res = await this.#api.get('/user')
    return res
  }

  getUsers = async (filter: any) => {
    const res = await this.#api.get(`/users/filtered/${filter ? JSON.stringify(filter) : 'null'}`)
    return res
  }

  getAllUsers = async () => {
    const res = await this.#api.get('/users/all')
    return res
  }

  getAllDeletedUsers = async () => {
    const res = await this.#api.get('/users/deleted')
    return res
  }

  getUsersByLogin = async (login: string, filter: any) => {
    const res = await this.#api.post('/users/get-by-login', { login, filter })
    return res
  }

  registration = async (authMethods: AuthMethods) => {
    const res = await this.#api.post('/user/registration', authMethods)

    if (res.data.token) {
      await this.setToken(res.data.token)
      await saveToLS('@user', JSON.stringify(res.data.user))
    }

    return res
  }

  login = async (authMethods: AuthMethods) => {
    const res = await this.#api.post('/user/login', authMethods)

    if (res.data.token) {
      await this.setToken(res.data.token)
      await saveToLS('@user', JSON.stringify(res.data.user))
    }

    return res
  }

  connectSocial = async (socialAuth: SocialAuth) => {
    const res = await this.#api.post('/user/connect-social', { socialAuth })
    return res
  }

  checkCode = async (phone: string, code: string) => {
    const res = await this.#api.post('/user/registration/confirm-code', { phone, code })
    return res
  }

  createPassword = async (phone: string, password: string) => {
    const res = await this.#api.post('/user/password-create', {
      phone,
      password
    })

    if (res.data.token) {
      await this.setToken(res.data.token)
      await saveToLS('@user', JSON.stringify(res.data.user))
    }

    return res
  }

  changePassword = async (phone: string, oldPassword: string, newPassword: string) => {
    const res = await this.#api.post('/user/password-change', {
      phone,
      oldPassword,
      newPassword
    })
    return res
  }

  restore = async (phone: string) => {
    const res = await this.#api.post('/user/password-restore', { phone })
    return res
  }

  createNewChatUser = async (profile: CreateChatUserAtributes) => {
    const res = await this.#api.post('/chat/user/create', { profile })
    return res
  }

  sendAttachmentsToChat = async (data: AttachmentMessageData, files: Base64File[]) => {
    const res = await this.#api.post('/chat/attachments', { data, files })
    return res
  }

  generalInformation = async (name: string, dateOfBirth: string, sex: string, log: string) => {
    const res = await this.#api.post('/general-info', {
      name,
      dateOfBirth,
      sex,
      login: log
    })
    return res
  }

  uploadImage = async (files: FormData) => {
    const res = await this.#api.post('/upload-image', files, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: this.#api.defaults.headers.common.Authorization
      }
    })
    return res
  }

  deleteImage = async (id: string) => {
    const res = await this.#api.delete(`/delete-image?id=${id}`)
    return res
  }

  userDelete = async (userId: string) => {
    const res = await this.#api.post('/user/delete', { userId })
    return res
  }

  updateUser = async (update: any) => {
    const res = await this.#api.post('/user/update', { userUpdate: { ...update } })
    return res
  }

  getUserByChatId = async (chatId: number) => {
    const res = await this.#api.get(`/user/chat-id/${chatId}`)
    return res
  }

  getDateCompatibility = async (date: string) => {
    const res = await this.#api.get(`/user/date-compatibility/${date}`)
    return res
  }

  getMyChats = async () => {
    const res = await this.#api.get('/mychats')
    return res
  }

  readNotifications = async (likes: string[]) => {
    const res = await this.#api.post('/read-notifications', { likes })
    return res
  }

  getAllUserChats = async () => {
    const res = await this.#api.get('/allchats')
    return res
  }

  getMyLikes = async () => {
    const res = await this.#api.get('/mylikes')
    return res
  }

  updateChat = async (status: string, createdBy: string) => {
    const res = await this.#api.post('/update-chat', { status, createdBy })
    return res
  }

  newChatUser = async (payload: UserChatAttributes) => {
    const res = await this.#api.post('/chat/user/create', payload)
    return res
  }

  getAllChatUsers = async () => {
    const res = await this.#api.get('/chat/users')
    return res
  }

  getChatUser = async (id: number) => {
    const res = await this.#api.get(`/chat/user/${id}`)
    return res
  }

  getAllChats = async () => {
    const res = await this.#api.get('/chat')
    return res
  }

  getUserChats = async (id: number) => {
    const res = await this.#api.get(`/chat/user-chats/${id}`)
    return res
  }

  getChat = async (id: number) => {
    const res = await this.#api.get(`/chat/${id}`)
    return res
  }

  translate = async (text: string) => {
    const res = await axios.post('https://api.reverso.net/translate/v1/translation', {
      format: 'text',
      from: 'eng',
      input: text,
      options: {
        contextResults: true,
        languageDetection: true,
        origin: 'reversomobile',
        sentenceSplitter: false
      },
      to: 'rus'
    })
    return res.data.translation[0]
  }

  createComplaint = async (payload: ComplaintAttributes) => {
    const res = await this.#api.post('/complaints/create', payload)
    return res
  }

  blockUser = async (userId: string) => {
    const res = await this.#api.post('/user/block', { userId })
    return res
  }

  unblockUser = async (userId: string) => {
    const res = await this.#api.post('/user/unblock', { userId })
    return res
  }

  getChannelByUserId = async (chatUserId: number) => {
    const res = await this.#api.get(`/channel/${chatUserId}`)
    return res
  }
}

export const apiService = new ApiService()

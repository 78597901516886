import { useState } from 'react'
import { useSelector } from 'react-redux'

import {
  ModalUserActionsBlock,
  ModalUserActionsChat,
  ModalUserActionsComplaint,
  ModalUserActionsMain,
  ModalWrapper
} from '@components'
import { ModalUserSelectedOptions } from '@components/utils'
import { selectCurrentUserBlockedUserIds } from '@redux/selectors'

interface ModalUserActionsProps {
  isVisible: boolean
  onClose: () => void
  targetId: string
  showChatOption?: boolean
}

export const ModalUserActions = ({ isVisible, onClose, targetId, showChatOption }: ModalUserActionsProps) => {
  const [selectedOption, setSelectedOption] = useState<ModalUserSelectedOptions>(ModalUserSelectedOptions.MAIN)
  const blockedUserIds = useSelector(selectCurrentUserBlockedUserIds)
  const isBlocked = !!blockedUserIds?.includes(targetId)

  const close = () => {
    onClose()
    setSelectedOption(ModalUserSelectedOptions.MAIN)
  }

  const renderContent = () => {
    switch (selectedOption) {
      case ModalUserSelectedOptions.MAIN:
        return (
          <ModalUserActionsMain
            close={close}
            setSelectedOption={setSelectedOption}
            showChatOption={showChatOption}
            isBlocked={isBlocked}
          />
        )
      case ModalUserSelectedOptions.CHAT:
        return <ModalUserActionsChat close={close} />
      case ModalUserSelectedOptions.COMPLAINT:
        return <ModalUserActionsComplaint close={close} targetId={targetId} />
      case ModalUserSelectedOptions.BLOCK:
        return <ModalUserActionsBlock close={close} targetId={targetId} isBlocked={isBlocked} />
    }
  }

  return (
    <ModalWrapper isVisible={isVisible} close={onClose}>
      {renderContent()}
    </ModalWrapper>
  )
}

import { useEffect, useState } from 'react'
import { KeyboardAvoidingView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { CustomMaps, HeaderInfo, TextInput, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1
  },
  inputWrapper: {
    height: 50,
    marginBottom: 16,
    width: '100%'
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

interface Location {
  name: string
  placeId: string
  position: {
    lat: number
    lng: number
  }
}

export const Works = () => {
  const { route, navigation } = useNavigator<'Works'>()
  const edit = route.params?.edit
  const currentUser = useSelector(selectCurrentUserData)
  const [redirect] = useState(false)
  const [company, setCompany] = useState('')
  const [position, setPosition] = useState('')
  const [location, setLocation] = useState<Location>({
    name: '',
    placeId: '',
    position: {
      lat: 39.78825,
      lng: -122.4324
    }
  })

  useEffect(() => {
    if (!currentUser?.profile.workGeo) {
      return
    }

    setCompany(currentUser.profile.workName)
    setPosition(currentUser.profile.workPosition)
    setLocation(currentUser.profile.workGeo)
  }, [currentUser])

  useEffect(() => {
    if (!redirect || !currentUser) {
      return
    }

    navigation.navigate(edit ? 'InformationYourself' : 'Financial')
  }, [currentUser])

  return (
    <View style={styles.mainWrapper}>
      <HeaderInfo showProgressBar={!edit} step={8} />
      <KeyboardAvoidingView behavior={isIOS ? 'padding' : 'height'} style={{ flex: 1 }}>
        <View style={styles.centerWrapper}>
          <Typography f27 bold marginTop={24} marginBottom={8}>
            Работа
          </Typography>
          <View style={styles.inputWrapper}>
            <TextInput value={company} placeholder='' label='Название компании' onChange={text => setCompany(text)} />
          </View>
          <View style={styles.inputWrapper}>
            <TextInput placeholder='' value={position} label='Ваша должность' onChange={text => setPosition(text)} />
          </View>
          <CustomMaps placeholder='Местоположение' isVisible location={location} setLocation={setLocation} types='' />
        </View>
        <SubmitButton
          isEditing={edit}
          buttonLabelCondition={!!company || !!position}
          onPress={() => navigation.navigate('Financial')}
        />
      </KeyboardAvoidingView>
    </View>
  )
}

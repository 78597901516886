import {
  GET_CHANNEL_BY_USER_ID_REQUEST,
  GET_CHANNEL_BY_USER_ID_SUCCESS,
  GetChannelByUserIdRequestAction,
  GetChannelByUserIdSuccessAction
} from '@redux/types'
import { Channel } from '@types'

export const getChannelByUserIdRequest = (chatUserId: number): GetChannelByUserIdRequestAction => ({
  type: GET_CHANNEL_BY_USER_ID_REQUEST,
  payload: { chatUserId }
})

export const getChannelByUserIdSuccess = (channel: Channel): GetChannelByUserIdSuccessAction => ({
  type: GET_CHANNEL_BY_USER_ID_SUCCESS,
  payload: channel
})

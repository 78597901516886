import { PropsWithChildren } from 'react'
import { ScrollView, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'

import { ArrowRightBlackSvg } from '@assets/icons'
import { Typography } from '@components'
import { isWeb } from '@components/utils'
import { RootStackParamList, useNavigator } from '@navigation'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    borderBottomColor: '#F3F2F8',
    borderBottomWidth: 1,
    justifyContent: 'space-between',
    paddingVertical: 6
  },
  pressablePart: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 44,
    width: '100%'
  },
  previewContainer: {
    flexDirection: 'row',
    overflow: 'scroll',
    width: '100%'
  },
  webContainer: {
    overflowX: 'auto',
    paddingBottom: 2,
    width: '100%'
  }
})

interface MenuItemWithPreviewProps extends PropsWithChildren {
  title: string
  page: keyof RootStackParamList
  style?: ViewStyle
  rowGap?: number
}

export const MenuItemWithPreview = ({ title, page, style, rowGap = 10, children }: MenuItemWithPreviewProps) => {
  const { navigation } = useNavigator<'MainProfile'>()
  const moveTo = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate(page, { edit: true })
  }

  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity onPress={moveTo} style={styles.pressablePart}>
        <Typography f14 normal>
          {title}
        </Typography>
        <ArrowRightBlackSvg />
      </TouchableOpacity>
      <ScrollView
        contentContainerStyle={[isWeb && styles.webContainer, { gap: rowGap }]}
        horizontal
        style={styles.previewContainer}
        showsHorizontalScrollIndicator={false}
      >
        {children}
      </ScrollView>
    </View>
  )
}

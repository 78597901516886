import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'

import { CloseSvg, FollowSvg } from '@assets/icons'
import { SButton, SFlex } from '@components'
import { Colors } from '@components/utils'
import { BottomSheetFooter, BottomSheetFooterProps } from '@gorhom/bottom-sheet'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserId } from '@redux/selectors'

interface CustomFooterProps extends BottomSheetFooterProps {
  close?: () => void
  id: string
  openConnection: () => void
}

const styles = StyleSheet.create({
  connect: {
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 30,
    paddingVertical: 21
  },
  footer: {
    backgroundColor: Colors.BackgroundModal,
    paddingTop: 8
  },
  reject: {
    paddingHorizontal: 30,
    paddingVertical: 21
  }
})

export const ModelBottomSheetFooterSendRequest = ({
  animatedFooterPosition,
  openConnection,
  id
}: CustomFooterProps) => {
  const insets = useSafeAreaInsets()

  const { sendLike, clearAllUsers } = useDataLayer()
  const currentUserId = useSelector(selectCurrentUserId)
  const { navigation } = useNavigator<'Tabs'>()
  const [redirect, setRedirect] = useState(false)

  const reject = async () => {
    setRedirect(true)
    sendLike({
      type: 'rejected',
      sender: currentUserId,
      liked: id,
      message: null
    })
    clearAllUsers()
  }

  useEffect(() => {
    if (!redirect) {
      return
    }

    setRedirect(false)
    navigation.navigate('Tabs')
  }, [redirect])

  return (
    <BottomSheetFooter animatedFooterPosition={animatedFooterPosition}>
      <View
        style={[
          styles.footer,
          {
            paddingBottom: insets.bottom + 25
          }
        ]}
      >
        <SFlex gap={9} paddingLeft={26} paddingRight={26}>
          <SButton onPress={reject} styleBtn={styles.reject} baseColor={Colors.LightGray} borderRadius={40}>
            <CloseSvg />
          </SButton>
          <SButton onPress={openConnection} styleBtn={styles.connect} baseColor={Colors.DarkGray} borderRadius={40}>
            <FollowSvg />
          </SButton>
        </SFlex>
      </View>
    </BottomSheetFooter>
  )
}

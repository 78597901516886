import { DeviceEventEmitter as Event } from 'react-native'
// eslint-disable-next-line import/no-named-as-default
import io from 'socket.io-client'

import {
  CHAT_CONNECT_REQUEST,
  DELETE_MESSAGES_REQUEST,
  DELETE_MESSAGES_SUCCESS,
  GET_MESSAGES_FOR_CHAT_REQUEST,
  NOTIFICATION_SUCCESS,
  RECEIVE_DISCUSSION,
  RECEIVE_MESSAGE,
  RECEIVE_MESSAGES_FOR_CHAT,
  RECEIVE_READ_MESSAGE,
  RECEIVE_READ_MESSAGES,
  SEND_DISCUSSION_MESSAGE_REQUEST,
  SEND_MESSAGE_REQUEST,
  SEND_READ_MESSAGE_REQUEST,
  SEND_READ_MESSAGES_REQUEST
} from '@redux/types'
import { DOMAIN } from '@services/apiService'
import {
  DiscussionResponseAttributes,
  MessagesAttributes,
  ReadMessageAttributes,
  ReadMessagesAttributes,
  UserProfile
} from '@types'
import { getFromLS } from '@utils'

export const socket = io(`https://ws-chat.${DOMAIN}`)

Event.addListener(CHAT_CONNECT_REQUEST, payload => {
  socket.emit('connect_id', payload)
})

Event.addListener(GET_MESSAGES_FOR_CHAT_REQUEST, payload => {
  socket.emit('getMessagesForChat', payload)
})

Event.addListener(SEND_MESSAGE_REQUEST, payload => {
  socket.emit('createMessage', payload)
})

Event.addListener(SEND_DISCUSSION_MESSAGE_REQUEST, payload => {
  socket.emit('createDiscussionMessage', payload)
})

Event.addListener(SEND_READ_MESSAGE_REQUEST, payload => {
  socket.emit('markMessageAsRead', payload)
})

Event.addListener(SEND_READ_MESSAGES_REQUEST, payload => {
  socket.emit('markMessagesAsRead', payload)
})

Event.addListener(DELETE_MESSAGES_REQUEST, payload => {
  socket.emit('deleteMessages', payload)
})

socket.on('connect', async () => {
  try {
    const user: UserProfile = JSON.parse((await getFromLS('@user')) as string)
    socket.emit('connect_id', user.userChatId)
  } catch (err) {
    console.log('Cant connect chat service')
  }

  socket.on('messagesForChat', (payload: MessagesAttributes[]) => {
    Event.emit(RECEIVE_MESSAGES_FOR_CHAT, payload)
  })

  socket.on('message', (payload: MessagesAttributes) => {
    Event.emit(RECEIVE_MESSAGE, payload)
  })

  socket.on('comment', (payload: DiscussionResponseAttributes) => {
    Event.emit(RECEIVE_DISCUSSION, payload)
  })

  socket.on('notification', (payload: Notification) => {
    Event.emit(NOTIFICATION_SUCCESS, payload)
  })

  socket.on('read_message', (payload: ReadMessageAttributes) => {
    Event.emit(RECEIVE_READ_MESSAGE, payload)
  })

  socket.on('read_messages', (payload: ReadMessagesAttributes) => {
    Event.emit(RECEIVE_READ_MESSAGES, payload)
  })

  socket.on('deleteMessages', payload => {
    Event.emit(DELETE_MESSAGES_SUCCESS, payload)
  })
})

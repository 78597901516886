import { StyleSheet } from 'react-native'

import { Button, ModalWrapper, Typography } from '@components'

const styles = StyleSheet.create({
  buttonWrapper: {
    marginTop: 40,
    width: '100%'
  },
  modalContent: {
    paddingTop: 54
  }
})

interface ModalTeenagerNotificationProps {
  visible: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ModalTeenagerNotification = ({ visible, onClose, onSubmit }: ModalTeenagerNotificationProps) => (
  <ModalWrapper isVisible={visible} close={onClose} contentStyle={styles.modalContent}>
    <Typography f18 normal textAlign='center'>
      Для подростков в возрасте от 12 до 17 лет доступ к регистрации предоставляется исключительно с целью нахождения
      новых друзей.
    </Typography>
    <Button style={styles.buttonWrapper} text='Понятно' onPress={onSubmit} />
  </ModalWrapper>
)

import { useMemo, useState } from 'react'
import { Image, Pressable, StyleSheet, View } from 'react-native'
import ImageView from 'react-native-image-viewing'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { AttachmentSvg, CommentSvg } from '@assets/icons'
import { Typography } from '@components'
import { isWeb } from '@components/utils'
import { SERVER_URL } from '@services/apiService'
import { ChannelMessage, UserProfile } from '@types'

import { FeedPostHeader } from './FeedPostHeader'

const styles = StyleSheet.create({
  attachmentsIcon: {
    backgroundColor: 'rgba(255,255,255, 0.5)',
    borderRadius: 8,
    height: 40,
    opacity: 0.5,
    padding: 5,
    position: 'absolute',
    right: 15,
    top: 15,
    width: 40,
    zIndex: 1
  },
  commentsIcon: {
    backgroundColor: 'rgba(0,0,0, 0.12)',
    borderRadius: 12,
    padding: 5
  },
  commentsWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 4,
    marginTop: 12
  },
  container: {
    gap: 8,
    marginTop: 12,
    paddingHorizontal: 18,
    paddingVertical: 12
  },
  image: {
    height: '100%',
    width: '100%'
  },
  imageWrapper: {
    borderRadius: 20,
    height: 258,
    marginTop: 12,
    maxHeight: 300,
    overflow: 'hidden'
  }
})

interface FeedPostItemProps {
  post: ChannelMessage | null
  user: UserProfile | null
  isCommentsCountAsString?: boolean
  backgroundColor?: string
  onPress?: () => void
  onLongPress?: () => void
}

export const FeedPostItem = ({
  post,
  user,
  isCommentsCountAsString,
  onPress,
  onLongPress,
  backgroundColor = '#E8E7ED'
}: FeedPostItemProps) => {
  const [isContainerPressed, setIsContainerPressed] = useState(false)
  const [isImagePressed, setIsImagePressed] = useState(false)
  const [isAttachmentFullScreen, setIsAttachmentFullScreen] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  const insets = useSafeAreaInsets()

  const images = useMemo(
    () =>
      post?.attachments?.map(a => {
        return { uri: `${SERVER_URL}/${a.attachment_url}` }
      }) || [],
    [post?.attachments?.length]
  )

  if (!post || !user) {
    return null
  }

  const commentsCount = post.discussionChat?.messages.length || 0

  const FooterComponent = () => (
    <View style={{ flex: 1, alignItems: 'center', bottom: insets.bottom }}>
      <Typography color='#fff' f18>{`${imageIndex + 1} / ${images.length}`}</Typography>
    </View>
  )

  return (
    <View style={[styles.container, { backgroundColor }]}>
      <Pressable
        onPress={onPress}
        onLongPress={onLongPress}
        onPressIn={() => setIsContainerPressed(true)}
        onPressOut={() => setIsContainerPressed(false)}
        style={isContainerPressed && { opacity: 0.5 }}
      >
        <FeedPostHeader userName={user.name} imageUri={user.profileImage[0].name} timestamp={post.timestamp} />
        {post.content && (
          <Typography f15 marginTop={12}>
            {post.content}
          </Typography>
        )}
        {!!post.attachments?.length && (
          <Pressable
            onLongPress={onLongPress}
            onPressIn={() => setIsImagePressed(true)}
            onPressOut={() => setIsImagePressed(false)}
            onPress={() => setIsAttachmentFullScreen(true)}
            style={[styles.imageWrapper, isImagePressed && { opacity: 0.5 }]}
          >
            <Image style={styles.image} source={{ uri: images[0].uri }} resizeMode='cover' />
            {images.length > 1 && (
              <View style={styles.attachmentsIcon}>
                <AttachmentSvg fill='#000' />
              </View>
            )}
          </Pressable>
        )}
        <View style={styles.commentsWrapper}>
          {isCommentsCountAsString ? (
            <Typography color='#999' f15>
              {commentsCount} комментариев
            </Typography>
          ) : (
            <>
              <View style={styles.commentsIcon}>
                <CommentSvg width={14} height={14} />
              </View>
              <Typography f15>{commentsCount}</Typography>
            </>
          )}
        </View>
      </Pressable>
      {images.length > 0 && (
        <ImageView
          images={images}
          imageIndex={0}
          visible={isAttachmentFullScreen}
          doubleTapToZoomEnabled
          onImageIndexChange={index => setImageIndex(index)}
          onRequestClose={() => {
            setIsAttachmentFullScreen(false)
            setImageIndex(0)
          }}
          FooterComponent={!isWeb && images.length > 1 ? FooterComponent : undefined}
        />
      )}
    </View>
  )
}

import { StyleSheet, TouchableOpacity } from 'react-native'
import * as ImageManipulator from 'expo-image-manipulator'
import * as ImagePicker from 'expo-image-picker'

import { ModalWrapper, Typography } from '@components'

const styles = StyleSheet.create({
  option: {
    alignItems: 'center',
    borderTopColor: '#fff',
    borderTopWidth: 1,
    flexGrow: 1,
    justifyContent: 'center',
    padding: 18,
    width: '100%'
  }
})

interface ModalAttachFileProps {
  visible: boolean
  onClose: () => void
  onFilesAttach: (files: any[]) => void
}

interface ImageData {
  width: number
  height: number
  uri: string
}

const IMAGE_MAX_DIMENSION_SIZE = 1500

export const ModalAttachFiles = ({ visible, onClose, onFilesAttach }: ModalAttachFileProps) => {
  const resizeImage = async ({ width, height, uri }: ImageData) => {
    const largestDimension = width > height ? 'width' : 'height'

    const resizedImage = await ImageManipulator.manipulateAsync(
      uri,
      [
        {
          resize: { [largestDimension]: IMAGE_MAX_DIMENSION_SIZE }
        }
      ],
      { base64: true, format: ImageManipulator.SaveFormat.PNG }
    )

    return resizedImage
  }

  const sendAttachments = async (result: ImagePicker.ImagePickerResult) => {
    try {
      if (result.canceled) {
        return
      }

      const resizedPhotosPromises = []
      for (const photo of result.assets) {
        resizedPhotosPromises.push(resizeImage(photo))
      }
      const resizedPhotos = await Promise.all(resizedPhotosPromises)

      resizedPhotos.forEach((photo: any) => {
        photo.base64 = `data:image/png;base64,${photo.base64}`
      })

      onFilesAttach(resizedPhotos)
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All
    })

    await sendAttachments(result)
  }

  const takePhoto = async () => {
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync()
    if (!permissionResult.granted) {
      return
    }

    const result = await ImagePicker.launchCameraAsync()
    await sendAttachments(result)
  }

  return (
    <ModalWrapper isVisible={visible} close={onClose}>
      <TouchableOpacity style={[styles.option, { borderTopWidth: 0 }]} onPress={pickImage}>
        <Typography f16 style={{ fontWeight: '700' }}>
          Выбрать из галереи
        </Typography>
      </TouchableOpacity>
      <TouchableOpacity style={styles.option} onPress={takePhoto}>
        <Typography f16 style={{ fontWeight: '700' }}>
          Сделать снимок
        </Typography>
      </TouchableOpacity>
      <TouchableOpacity style={styles.option} onPress={onClose}>
        <Typography f16 style={{ opacity: 0.5 }}>
          Отмена
        </Typography>
      </TouchableOpacity>
    </ModalWrapper>
  )
}

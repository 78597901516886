import { ProfileOptions } from '@utils'

export const RELIGION: ProfileOptions = [
  { id: 0, slug: 'agnosticism', label: 'Агностицизм' },
  { id: 1, slug: 'atheism', label: 'Атеизм' },
  { id: 2, slug: 'buddhism', label: 'Буддизм' },
  { id: 3, slug: 'hinduism', label: 'Индуизм' },
  { id: 4, slug: 'islam', label: 'Ислам' },
  { id: 5, slug: 'judaism', label: 'Иудаизм' },
  { id: 6, slug: 'christianity', label: 'Христианство' },
  { id: 7, slug: 'bábism', label: 'Бабизм' },
  { id: 8, slug: 'mysticism', label: 'Мистицизм' },
  { id: 9, slug: 'occultism', label: 'Оккультизм' },
  { id: 10, slug: 'agniYoga', label: 'Агни-йога' },
  { id: 11, slug: 'ayyavari', label: 'Айявари' },
  { id: 12, slug: 'vaishnavism', label: 'Вишнуизм' },
  { id: 13, slug: 'taoism', label: 'Даосизм' },
  { id: 14, slug: 'jainism', label: 'Джайнизм' },
  { id: 15, slug: 'jediism', label: 'Джедаизм' },
  { id: 16, slug: 'dudeism', label: 'Дудеизм' },
  { id: 17, slug: 'zoroastrianism', label: 'Зороастризм' },
  { id: 18, slug: 'judeoChristianity', label: 'Иудеохристианство' },
  { id: 19, slug: 'catholicism', label: 'Католицизм' },
  { id: 20, slug: 'confucianism', label: 'Конфуцианство' },
  { id: 21, slug: 'modenkgei', label: 'Модекнгеи' },
  { id: 22, slug: 'neoshamanism', label: 'Неошаманизм' },
  { id: 23, slug: 'oshoMovement', label: 'Ошо-движение' },
  { id: 24, slug: 'orthodoxy', label: 'Православие' },
  { id: 25, slug: 'protestantism', label: 'Протестантизм' },
  { id: 26, slug: 'rastafari', label: 'Растафарианство' },
  { id: 27, slug: 'reiki', label: 'Рэйки' },
  { id: 28, slug: 'satanism', label: 'Сатанизм' },
  { id: 29, slug: 'sikhism', label: 'Сикхизм' },
  { id: 30, slug: 'shamanism', label: 'Шаманизм' },
  { id: 31, slug: 'shaivism', label: 'Шиваизм' },
  { id: 32, slug: 'esotericism', label: 'Эзотеризм' },
  { id: 33, slug: 'other', label: 'Другое' }
]

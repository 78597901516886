import { combineReducers } from 'redux'

import {
  allDeletedUsersReducer,
  allUsersReducer,
  authReducer,
  channelReducer,
  chatsReducer,
  checkCodeReducer,
  connectRequestsReducer,
  currentUserReducer,
  fullChatUserReducer,
  notificationHistoryReducer,
  notificationReducer,
  receiveMessagesForChatReducer,
  usersReducer
} from '@redux/reducers'
import {
  AllDeletedUsersState,
  AllUsersState,
  AuthState,
  ChannelState,
  CurrentUserState,
  DiscussionState,
  FullChatUserState,
  GetMyChatsState,
  GetMyLikesState,
  NotificationState,
  ReceiveMessagesForChatState,
  RESET_STORE,
  UserChatsState,
  UsersState
} from '@redux/types'

const initialState: RootState = {
  auth: {
    phone: '',
    status: '',
    googleAuth: null,
    facebookAuth: null,
    instagramAuth: null
  },
  users: [],
  currentUser: null,
  connectRequests: [],
  notificationHistory: { data: [], counter: 0 },
  chats: [],
  channel: null,
  discussion: null,
  allUsers: [],
  allDeletedUsers: [],
  chatMessages: { messages: [] },
  code: '',
  notification: null,
  fullChatUser: null
}

export interface RootState {
  auth: AuthState
  users: UsersState
  currentUser: CurrentUserState
  connectRequests: GetMyLikesState
  notificationHistory: GetMyChatsState
  chats: UserChatsState
  channel: ChannelState
  discussion: DiscussionState
  allUsers: AllUsersState
  allDeletedUsers: AllDeletedUsersState
  chatMessages: ReceiveMessagesForChatState
  notification: NotificationState
  code: string
  fullChatUser: FullChatUserState
}

const combinedReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  currentUser: currentUserReducer,
  connectRequests: connectRequestsReducer,
  notificationHistory: notificationHistoryReducer,
  chats: chatsReducer,
  channel: channelReducer,
  allUsers: allUsersReducer,
  allDeletedUsers: allDeletedUsersReducer,
  chatMessages: receiveMessagesForChatReducer,
  notification: notificationReducer,
  code: checkCodeReducer,
  fullChatUser: fullChatUserReducer
})

export const rootReducer = (state: any, action: any) => {
  if (action.type === RESET_STORE) {
    return initialState
  }

  return combinedReducer(state, action)
}

import { ProfileOptions } from '@utils'

export { GENRES, GenreTypes } from './ArtGenres'
export { HOBBIES } from './Hobbies'
export { RELIGION } from './Religion'
export * from './Status'
export * from './types'
export { VALUABLES } from './Valuables'

export const LANGUAGES: ProfileOptions = [
  { id: 0, slug: 'english', label: 'Английский' },
  { id: 1, slug: 'russian', label: 'Русский' },
  { id: 2, slug: 'ukrainian', label: 'Украинский' },
  { id: 3, slug: 'spanish', label: 'Испанский' },
  { id: 4, slug: 'chinese', label: 'Китайский' },
  { id: 5, slug: 'arabic', label: 'Арабский' },
  // Убедитесь, что нет дублирования идентификаторов
  { id: 6, slug: 'hindi', label: 'Хинди' },
  { id: 7, slug: 'bengali', label: 'Бенгальский' },
  { id: 8, slug: 'portuguese', label: 'Португальский' },
  { id: 9, slug: 'french', label: 'Французский' },
  { id: 10, slug: 'german', label: 'Немецкий' },
  { id: 11, slug: 'japanese', label: 'Японский' },
  { id: 12, slug: 'korean', label: 'Корейский' },
  { id: 13, slug: 'turkish', label: 'Турецкий' },
  { id: 14, slug: 'italian', label: 'Итальянский' },
  { id: 15, slug: 'polish', label: 'Польский' },
  { id: 16, slug: 'dutch', label: 'Голландский' },
  { id: 17, slug: 'thai', label: 'Тайский' },
  { id: 18, slug: 'vietnamese', label: 'Вьетнамский' },
  { id: 19, slug: 'persian', label: 'Персидский' },
  { id: 20, slug: 'romanian', label: 'Румынский' },
  { id: 21, slug: 'malay', label: 'Малайский' },
  { id: 22, slug: 'indonesian', label: 'Индонезийский' },
  { id: 23, slug: 'czech', label: 'Чешский' },
  { id: 24, slug: 'greek', label: 'Греческий' },
  { id: 25, slug: 'danish', label: 'Датский' },
  { id: 26, slug: 'swedish', label: 'Шведский' },
  { id: 27, slug: 'norwegian', label: 'Норвежский' },
  { id: 28, slug: 'hungarian', label: 'Венгерский' },
  { id: 29, slug: 'finnish', label: 'Финский' },
  { id: 30, slug: 'slovak', label: 'Словацкий' },
  { id: 31, slug: 'hebrew', label: 'Иврит' },
  { id: 32, slug: 'filipino', label: 'Филиппинский' },
  { id: 33, slug: 'serbian', label: 'Сербский' },
  { id: 34, slug: 'croatian', label: 'Хорватский' },
  { id: 35, slug: 'bulgarian', label: 'Болгарский' },
  { id: 36, slug: 'lithuanian', label: 'Литовский' },
  { id: 37, slug: 'slovenian', label: 'Словенский' },
  { id: 38, slug: 'latvian', label: 'Латышский' },
  { id: 39, slug: 'estonian', label: 'Эстонский' },
  { id: 40, slug: 'swahili', label: 'Суахили' },
  { id: 41, slug: 'urdu', label: 'Урду' },
  { id: 42, slug: 'punjabi', label: 'Пенджаби' },
  { id: 43, slug: 'amharic', label: 'Амхарский' },
  { id: 44, slug: 'nepali', label: 'Непальский' },
  { id: 45, slug: 'marathi', label: 'Маратхи' },
  { id: 46, slug: 'telugu', label: 'Телугу' },
  { id: 47, slug: 'tamil', label: 'Тамильский' },
  { id: 48, slug: 'gujarati', label: 'Гуджарати' },
  { id: 49, slug: 'kannada', label: 'Каннада' },
  { id: 50, slug: 'malayalam', label: 'Малаялам' }
]

export const EDUCATION: ProfileOptions = [
  { id: 0, slug: 'doctoral', label: 'Докторская' },
  { id: 1, slug: 'higher', label: 'Высшее' },
  { id: 2, slug: 'incompleteHigher', label: 'Не оконченное высшее' },
  { id: 3, slug: 'secondary', label: 'Среднее' },
  { id: 4, slug: 'noEducation', label: 'Без образования' }
]

export const FINANCIAL: ProfileOptions = [
  { id: 0, slug: 'bad', label: 'Все не очень' },
  { id: 1, slug: 'enoughForLiving', label: 'На жизнь хватает' },
  { id: 2, slug: 'moreAV', label: 'Выше среднего' },
  { id: 3, slug: 'good', label: 'Хорошо зарабатываю' },
  { id: 4, slug: 'rich', label: 'Богат(а)' }
]

export const ORIENTATION: ProfileOptions = [
  { id: 1, slug: 'hetero', label: 'Гетеро' },
  { id: 2, slug: 'bi', label: 'Би' },
  { id: 3, slug: 'homo', label: 'Гомо' },
  { id: 4, slug: 'otherOrientation', label: 'Другое' }
]
export const LGBT_TOLERANT: ProfileOptions = [
  { id: 1, slug: 'iSupportLGBT', label: 'Поддерживаю' },
  { id: 2, slug: 'lGBTIsNeutral', label: 'Нейтральное' },
  { id: 3, slug: 'againstLGBT', label: 'Негативное' }
]

export const GOALS: ProfileOptions = [
  { id: 1, slug: 'meetFriends', label: 'Дружба, друзья по интересам' },
  { id: 2, slug: 'meetLove', label: 'Любовь-брак' },
  {
    id: 3,
    slug: 'commercial',
    label: 'Отношения по расчету',
    children: [
      { id: 4, slug: 'beSponsor', label: 'Я спонсор' },
      { id: 5, slug: 'findSponsor', label: 'Ищу спонсора' }
    ]
  },
  { id: 6, slug: 'findBusinessPartners', label: 'Бизнес партнерство' },
  { id: 7, slug: 'sexyAdventures', label: 'Свободные отношения' }
]

export const RELATIONSHIP: ProfileOptions = [
  { id: 0, slug: 'married/Married', label: 'Замужем/Женат' },
  { id: 1, slug: 'inARelationship', label: 'В отношениях' },
  { id: 2, slug: 'single', label: 'Свободен' }
]

export const CHILDREN: ProfileOptions = [
  { id: 0, slug: 'haveChildren', label: 'Есть' },
  { id: 1, slug: 'NoChildrenButWant', label: 'Нет, но хочу' },
  { id: 2, slug: 'NoChildrenAndDoNotWantTo', label: 'Нет и не хочу' }
]

export const SEX: ProfileOptions = [
  { id: 'male', slug: 'man', label: 'Мужчина' },
  { id: 'female', slug: 'woman', label: 'Женщина' },
  { id: 'other', slug: 'otherGender', label: 'Другое' }
]

export const PETS: ProfileOptions = [
  { id: 0, slug: 'iLoveAnimalsIHave', label: 'Люблю животных, есть' },
  { id: 1, slug: 'ILoveButIDontHave', label: 'Люблю, но нету' },
  { id: 2, slug: 'IAmNeutral', label: 'Нейтрально отношусь' },
  { id: 3, slug: 'IDontLike', label: 'Не люблю' }
]

import { GET_USERS_REQUEST, GET_USERS_SUCCESS, GetUsersRequestAction, GetUsersSuccessAction } from '@redux/types'
import { UserProfile } from '@types'

export const getUsersRequest = (filter?: any): GetUsersRequestAction => {
  return {
    type: GET_USERS_REQUEST,
    payload: { filter }
  }
}

export const getUsersSuccess = (users: UserProfile[]): GetUsersSuccessAction => {
  return {
    type: GET_USERS_SUCCESS,
    payload: users
  }
}

import { Image, StyleSheet, View } from 'react-native'

import { Typography } from '@components'
import { SERVER_URL } from '@services/apiService'

const styles = StyleSheet.create({
  image: {
    borderRadius: 25,
    height: 50,
    width: 50
  },
  notifactionWrapper: {
    alignItems: 'center',
    backgroundColor: '#0000000D',
    borderRadius: 20,
    flexDirection: 'row',
    marginBottom: 14,
    maxHeight: 70,
    paddingBottom: 10,
    paddingHorizontal: 14,
    paddingVertical: 14
  }
})

interface CustomNotificationProps {
  photoNotifaction: string
  textNotifaction: string
}

export const CustomNotification = ({ photoNotifaction, textNotifaction }: CustomNotificationProps) => (
  <View style={styles.notifactionWrapper}>
    <Image source={{ uri: `${SERVER_URL}/${photoNotifaction}` }} style={styles.image} />
    <Typography style={{ flex: 1 }} f14 semibold marginLeft={12}>
      {textNotifaction}
    </Typography>
  </View>
)

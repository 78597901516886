import { Fragment, useEffect, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { IconCloseSvg } from '@assets/icons'
import { Autocomplete, Chip, HeaderInfo, SelectFilterModal, TextInput, Typography } from '@components'
import { isIOS, isWeb } from '@components/utils'
import { useCurrentUserAge } from '@hooks'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'
import { GENRES, GenreTypes, OptionId, ProfileOption, ProfileOptions } from '@utils'

const styles = StyleSheet.create({
  chipsWrapper: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    marginBottom: 16,
    marginTop: 10
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

export const Preferences = () => {
  const { route } = useNavigator<'Preferences'>()
  const edit = route.params?.edit
  const { userUpdate } = useDataLayer()
  const currentUser = useSelector(selectCurrentUserData)
  const { isAdult } = useCurrentUserAge()

  const [modalOpen, setModalOpen] = useState(false)
  const [selectedGenre, setSelectedGenre] = useState<GenreTypes | null>(null)
  const [selectedOptionsList, setSelectedOptionsList] = useState<ProfileOptions>([])
  const [selectedMovieIds, setSelectedMovieIds] = useState<OptionId[]>([])
  const [selectedBookIds, setSelectedBookIds] = useState<OptionId[]>([])
  const [selectedMusicIds, setSelectedMusicIds] = useState<OptionId[]>([])

  useEffect(() => {
    if (!currentUser) {
      return
    }

    setSelectedMovieIds(currentUser.profile.movieGenre)
    setSelectedBookIds(currentUser.profile.bookGenre)
    setSelectedMusicIds(currentUser.profile.musicGenre)
  }, [currentUser])

  const getNextPage = () => {
    if (edit) {
      return 'InformationYourself'
    }

    return isAdult ? 'Quality' : 'TellYourself'
  }

  const submit = () => {
    if (!currentUser) {
      return
    }

    userUpdate(
      {
        profile: {
          ...currentUser.profile,
          movieGenre: selectedMovieIds,
          bookGenre: selectedBookIds,
          musicGenre: selectedMusicIds
        }
      },
      { page: getNextPage() }
    )
  }

  const getGenreData = (genre: GenreTypes) => {
    if (genre === GenreTypes.Cinema) {
      return { label: 'Фильмы', value: selectedMovieIds, setValue: setSelectedMovieIds }
    }

    if (genre === GenreTypes.Music) {
      return { label: 'Музыка', value: selectedMusicIds, setValue: setSelectedMusicIds }
    }

    if (genre === GenreTypes.Literature) {
      return { label: 'Книги', value: selectedBookIds, setValue: setSelectedBookIds }
    }
  }

  const removeValueFromState = (id: OptionId, genre: GenreTypes) => {
    let action
    switch (genre) {
      case GenreTypes.Cinema:
        action = setSelectedMovieIds
        break
      case GenreTypes.Music:
        action = setSelectedMusicIds
        break
      case GenreTypes.Literature:
        action = setSelectedBookIds
        break
    }

    action(prev => prev.filter(_id => _id !== id))
  }

  const onModalOpen = (genre: GenreTypes, items: ProfileOptions) => {
    setSelectedGenre(genre)
    setSelectedOptionsList(items)
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
  }

  const renderPreferences = () =>
    Object.entries(GENRES).map(([genre, items]) => {
      const genreData = getGenreData(genre as GenreTypes)
      if (!genreData) {
        return null
      }

      const { label, value, setValue } = genreData
      return (
        <Fragment key={genre}>
          {isWeb ? (
            <Pressable onPress={() => onModalOpen(genre as GenreTypes, items)}>
              <TextInput autoCapitalize='none' pointerEvents='none' value={label} />
            </Pressable>
          ) : (
            <Autocomplete
              label={label}
              options={items}
              renderOption={option => option.label}
              onChange={option =>
                setValue((prev: OptionId[]) => (prev.includes(option.id) ? prev : [...prev, option.id]))
              }
            />
          )}
          <View style={styles.chipsWrapper}>
            {value.map((id: OptionId) => (
              <Chip
                key={id}
                label={items[id as number].label}
                rightIcon={
                  <Pressable onPress={() => removeValueFromState(id, genre as GenreTypes)}>
                    <IconCloseSvg width={18} height={18} />
                  </Pressable>
                }
              />
            ))}
          </View>
        </Fragment>
      )
    })

  return (
    <View style={styles.mainWrapper}>
      <HeaderInfo showProgressBar={!edit} step={13} />
      <Typography f27 bold marginTop={24} marginBottom={8}>
        Предпочтения
      </Typography>
      <View style={{ flex: 1 }}>{renderPreferences()}</View>
      <SubmitButton
        isEditing={edit}
        onPress={submit}
        buttonLabelCondition={!!selectedMovieIds.length || !!selectedMusicIds.length || !!selectedBookIds.length}
      />
      <SelectFilterModal
        style={{ maxHeight: 500 }}
        isModalOpen={modalOpen}
        options={selectedOptionsList}
        onClose={onModalClose}
        onSelect={(option: ProfileOption) => {
          const genreData = getGenreData(selectedGenre as GenreTypes)
          if (!genreData) {
            return
          }

          genreData.setValue((prev: OptionId[]) => (prev.includes(option.id) ? prev : [...prev, option.id]))
          onModalClose()
        }}
      />
    </View>
  )
}

import Svg, { Path } from 'react-native-svg'

export const BellSvg = ({ fill = 'black' }: { fill: string }) => (
  <Svg width={19} height={19} viewBox='0 0 24 24'>
    <Path
      d='M122.338 101.194C121.114 99.4262 119.624 97.4734 117.977 95.3208C108.201 84.0276 101.348 70.5141 98.0183 55.9638C94.0476 34.0983 79.5259 20.1539 61.0339 20.4394C50.2519 20.654 40.137 25.6936 33.4844 34.1654C26.0946 43.4029 23.2547 55.4659 25.7487 67.0217C28.3109 82.4324 26.7892 98.2437 21.3343 112.886C20.5706 115.44 19.8741 117.766 19.3564 119.82C17.8612 125.754 21.4686 131.775 27.4143 133.268C28.965 133.657 30.5814 133.707 32.1534 133.415L115.157 118.398C121.192 117.325 125.213 111.571 124.138 105.546C123.859 103.984 123.247 102.499 122.343 101.193L122.338 101.194Z'
      fill={fill}
    />
  </Svg>
)

/* eslint-disable func-style */
import { call, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects'

import { CREATE_NEW_CHAT_USER_REQUEST, CreateNewChatUserRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect

function* createChatUser(action: CreateNewChatUserRequestAction): Generator<SagaEffects, void, any> {
  try {
    yield call([apiService, apiService.createNewChatUser], action.payload)
  } catch (error: any) {
    console.log('createChatUser', error)
  }
}

export function* createChatUserSaga() {
  yield takeLatest<CreateNewChatUserRequestAction>(CREATE_NEW_CHAT_USER_REQUEST, createChatUser)
}

import { useEffect } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import * as Updates from 'expo-updates'

import { ModalWrapper, Typography } from '@components'
import { useNavigator } from '@navigation'
import { selectCurrentUserData } from '@redux/selectors'

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderColor: 'rgba(255, 255, 255, 0.8)',
    borderWidth: 1,
    flex: 1,
    justifyContent: 'center',
    paddingVertical: 18
  },
  buttonsWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  textWrapper: {
    paddingHorizontal: 20
  }
})

interface ProfileActionModalProps {
  visible: boolean
  title: string
  description: string
  submitLabel: string
  onSubmit: () => void
  onClose: () => void
}

export const ProfileActionModal = ({
  visible,
  title,
  description,
  submitLabel,
  onSubmit,
  onClose
}: ProfileActionModalProps) => {
  const currentUser = useSelector(selectCurrentUserData)
  const { navigation } = useNavigator<'FirstScreen'>()

  useEffect(() => {
    if (currentUser) {
      return
    }

    navigation.navigate('FirstScreen')
    Updates.reloadAsync()
  }, [currentUser])

  return (
    <ModalWrapper isVisible={visible} close={onClose}>
      <View style={styles.textWrapper}>
        <Typography f17 semibold textAlign='center' marginBottom={8}>
          {title}
        </Typography>
        <Typography f13 normal textAlign='center' marginBottom={32}>
          {description}
        </Typography>
      </View>
      <View style={styles.buttonsWrapper}>
        <TouchableOpacity style={styles.button} onPress={onClose}>
          <Typography f16 normal>
            Отмена
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={onSubmit}>
          <Typography f16 bold color='#F64A3F'>
            {submitLabel}
          </Typography>
        </TouchableOpacity>
      </View>
    </ModalWrapper>
  )
}

/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getCurrentUserSuccess, notificationAction } from '@redux/actions'
import { DELETE_IMAGE_REQUEST, DeleteImageRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { UserProfile } from '@types'

type SagaEffects = CallEffect | PutEffect

function* deleteImage(action: DeleteImageRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.deleteImage], action.payload.id)
    const user: UserProfile = response.data
    yield put(getCurrentUserSuccess(user))
  } catch (error: any) {
    yield put(
      notificationAction({
        type: 'error',
        text: error.response.data.message,
        image: ''
      })
    )
  }
}

export function* deleteImageSaga() {
  yield takeLatest<DeleteImageRequestAction>(DELETE_IMAGE_REQUEST, deleteImage)
}

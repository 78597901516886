import { StyleSheet, Text, View } from 'react-native'
import * as Progress from 'react-native-progress'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    paddingLeft: 34
  },
  progressText: {
    fontSize: 17,
    fontWeight: '600',
    paddingBottom: 9
  },
  wrapper: {
    justifyContent: 'center'
  }
})

interface ProgressBarProps {
  step: number
}

export const ProgressBar = ({ step }: ProgressBarProps) => (
  <View style={styles.container}>
    <Text style={styles.progressText}>Шаг {step}/15</Text>
    <View style={styles.wrapper}>
      <Progress.Bar
        width={274}
        height={3}
        color='#2F2F2F'
        unfilledColor='#E8E7ED'
        borderColor='#E8E7ED'
        progress={step / 15}
      />
    </View>
  </View>
)

import { ReactNode, useEffect } from 'react'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { Provider } from 'react-redux'
import { Asset } from 'expo-asset'
import Constants from 'expo-constants'
import { useFonts } from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'

import { Toast, ToastProvider, WebAppContainer } from '@components'
import { isWeb } from '@components/utils'
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { AppNavigator } from '@navigation'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { store } from '@redux/configureStore'

import '@services/socketService'
import '@services/chatService'

const loadAssetsAsync = async () => {
  const imageAssets = [
    require('../assets/images/BackgroundFirsScreen.png'),
    require('../assets/images/CompatBackLayer.png'),
    require('../assets/images/send-icon.png'),
    require('../assets/images/Plus.png'),
    require('../assets/images/close.png')
  ]

  await Promise.all(imageAssets.map(image => Asset.fromModule(image).downloadAsync()))
}

SplashScreen.preventAutoHideAsync()

export const App = () => {
  const [fontsLoaded] = useFonts({
    ManropeSemiBold: require('../assets/fonts/Manrope-SemiBold.ttf'),
    ManropeLight: require('../assets/fonts/Manrope-Light.ttf')
  })

  useEffect(() => {
    loadAssetsAsync()
  }, [])

  if (!fontsLoaded) {
    return null
  }

  // Temporary fix while there is no google sign in for mobile
  const Container = ({ children }: { children: ReactNode }) => {
    if (isWeb) {
      return (
        <GoogleOAuthProvider clientId={Constants.expoConfig?.extra?.env.EXPO_PUBLIC_GOOGLE_CLIENT_ID}>
          {children}
        </GoogleOAuthProvider>
      )
    }

    return children
  }

  return (
    <Container>
      <Provider store={store}>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <BottomSheetModalProvider>
            <WebAppContainer>
              <ToastProvider config={{ timeout: 2000 }}>
                <AppNavigator />
                <Toast />
              </ToastProvider>
            </WebAppContainer>
          </BottomSheetModalProvider>
        </GestureHandlerRootView>
      </Provider>
    </Container>
  )
}

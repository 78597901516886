import { useEffect, useState } from 'react'
import { View } from 'react-native'
import Carousel from 'react-native-snap-carousel'
import Swipeable from 'react-native-swipeable'
import { useSelector } from 'react-redux'

import { ModelCard } from '@components'
import { baseStyle, isWeb, screenHeight, screenWidth } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserId, selectUsers } from '@redux/selectors'
import { UserProfile } from '@types'

const itemHeight = screenHeight * 0.68

const uniqueArray = (array: UserProfile[]) => {
  const uniqueItems: UserProfile[] = []
  const seenIds: any = {}

  for (const item of array) {
    if (!seenIds[item._id]) {
      uniqueItems.push(item)
      seenIds[item._id] = true
    }
  }

  return uniqueItems
}

export const MainScreen = () => {
  const { navigation } = useNavigator<'ModelScreen'>()

  const { getUsers, sendLike } = useDataLayer()
  const usersStore = useSelector(selectUsers)
  const [users, setUsers] = useState<UserProfile[]>(usersStore)
  const [endOfUsers, setEndOfUsers] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const currentUserId = useSelector(selectCurrentUserId)

  useEffect(() => {
    if ((users.length <= 2 || users.length - activeIndex <= 3) && !endOfUsers) {
      getUsers()
    }
  }, [users, activeIndex])

  useEffect(() => {
    setEndOfUsers(usersStore.length <= 2)

    if (users.length <= 2 || users.length - activeIndex <= 3) {
      setUsers(uniqueArray([...users, ...usersStore]))
    }
  }, [usersStore])

  useEffect(() => {
    const getUsersInterval = setInterval(() => {
      if (endOfUsers) {
        getUsers()
      }
    }, 30000)

    return () => {
      clearInterval(getUsersInterval)
    }
  }, [])

  const onReject = async (id: string) => {
    sendLike({
      type: 'rejected',
      sender: currentUserId,
      liked: id,
      message: null
    })
    setUsers(users.filter(item => item._id !== id))
  }

  const removeUser = (id: string) => {
    setUsers(users.filter(item => item._id !== id))
  }

  const onLike = (user: UserProfile) => {
    navigation.navigate('ModelScreen', { user, showModal: true, removeUser })
  }

  return (
    <View style={baseStyle.screenView}>
      <Carousel
        layout='default'
        data={users}
        renderItem={({ item }) => (
          <Swipeable
            leftContent={<></>}
            rightContent={<></>}
            leftActionActivationDistance={90}
            rightActionActivationDistance={90}
            onLeftActionRelease={() => onLike(item)}
            onRightActionRelease={() => onReject(item._id)}
          >
            <ModelCard item={item} goToModelScreen={() => {}} />
          </Swipeable>
        )}
        sliderWidth={screenWidth}
        itemWidth={screenWidth}
        itemHeight={itemHeight}
        swipeThreshold={40}
        inactiveSlideOpacity={1}
        inactiveSlideScale={0.9}
        sliderHeight={screenHeight * 0.76}
        vertical
        onSnapToItem={index => setActiveIndex(index)}
        onScroll={e => {
          if (!isWeb) {
            return
          }
          // hack around onSnapToItem which is not working on web:
          setActiveIndex(Math.round(e.nativeEvent.contentOffset.y / itemHeight))
        }}
      />
    </View>
  )
}

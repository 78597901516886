import { StyleSheet, View } from 'react-native'

import { FacebookAuthButton, GoogleAuthButton, InstagramAuthButton, Typography } from '@components'

const styles = StyleSheet.create({
  loginButtons: {
    flexDirection: 'row',
    gap: 10,
    justifyContent: 'space-between',
    marginTop: 16
  },
  title: {
    alignItems: 'center',
    paddingTop: 16
  }
})

export const SocialAuthButtonList = () => (
  <View style={styles.title}>
    <Typography textAlign='center' f15 bold>
      Или войдите через соцсети:
    </Typography>
    <View style={styles.loginButtons}>
      <GoogleAuthButton />
      <FacebookAuthButton />
      <InstagramAuthButton />
    </View>
  </View>
)

import { useState } from 'react'
import { Image, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { BackSvg, EllipsisSvg } from '@assets/icons'
import { UserDeleted } from '@assets/images'
import { ModalUserActions, Typography } from '@components'
import { formatUserName, isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { fullChatUserData } from '@redux/selectors'
import { SERVER_URL } from '@services/apiService'
import { ShortUser } from '@types'

interface IProps {
  user: ShortUser
  isUserDeleted: boolean
}

const styles = StyleSheet.create({
  icon: {
    alignItems: 'center',
    backgroundColor: 'rgba(131, 131, 131, 0.12)',
    borderRadius: 20,
    height: 40,
    justifyContent: 'center',
    width: 40
  },
  image: {
    borderRadius: 25,
    height: 40,
    marginHorizontal: 16,
    width: 40
  },
  mainWrapper: {
    alignItems: 'center',
    backgroundColor: '#E8E7ED',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: isIOS ? 55 : 15
  },
  user: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  }
})

export const HeaderMessage = ({ user, isUserDeleted }: IProps) => {
  const { navigation } = useNavigator<'Message'>()
  const fullUser = useSelector(fullChatUserData)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const goToHome = () => navigation.navigate('Message')

  const openUserProfile = () => {
    if (!fullUser || isUserDeleted) {
      return
    }

    navigation.navigate('ModelScreen', {
      user: fullUser,
      showModal: false,
      hideButtons: true
    })
  }

  const imageSource = isUserDeleted ? UserDeleted : { uri: `${SERVER_URL}/${user.profile_photo[0]}` }
  const userName = isUserDeleted ? 'Пользователь удалён' : formatUserName(user.name)

  return (
    <>
      <StatusBar backgroundColor='#E8E7ED' barStyle='dark-content' />
      <View style={styles.mainWrapper}>
        <TouchableOpacity style={styles.icon} onPress={goToHome}>
          <BackSvg />
        </TouchableOpacity>
        <TouchableOpacity disabled={isUserDeleted} onPress={openUserProfile} style={styles.user}>
          <Image source={imageSource} style={styles.image} />
          <View style={{ justifyContent: 'center' }}>
            <Typography f17 semibold>
              {userName}
            </Typography>
            {!isUserDeleted && (
              <Typography f11 light>
                {user.username}
              </Typography>
            )}
          </View>
        </TouchableOpacity>
        {fullUser && (
          <TouchableOpacity style={styles.icon} onPress={() => setIsModalVisible(true)}>
            <EllipsisSvg />
          </TouchableOpacity>
        )}
      </View>
      {fullUser && (
        <ModalUserActions isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} targetId={fullUser._id} />
      )}
    </>
  )
}

import { RECEIVE_MESSAGES_FOR_CHAT, ReceiveMessagesForChatAction, ReceiveMessagesForChatState } from '@redux/types'

const initialState: ReceiveMessagesForChatState = {
  messages: []
}

export const receiveMessagesForChatReducer = (
  state = initialState,
  action: ReceiveMessagesForChatAction
): ReceiveMessagesForChatState => {
  switch (action.type) {
    case RECEIVE_MESSAGES_FOR_CHAT:
      return { ...state, messages: action.payload }
    default:
      return state
  }
}

/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getUsersSuccess } from '@redux/actions'
import { GET_USERS_REQUEST, GetUsersRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { UserProfile } from '@types'

type SagaEffects = CallEffect | PutEffect

function* getUsers(action: GetUsersRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.getUsers], action.payload?.filter)
    const users: UserProfile[] = response.data ? response.data : []
    // TODO: Remove it after Vlad complete algorithm impovements
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield put(getUsersSuccess(users.filter(el => el.best !== 'none')))
  } catch (error: any) {
    console.log('error getUserSaga', error)
  }
}

export function* getUsersSaga() {
  yield takeLatest(GET_USERS_REQUEST, getUsers)
}

import { useEffect, useMemo, useRef, useState } from 'react'
import { Keyboard, StyleSheet, TouchableOpacity, View } from 'react-native'

import { ArrowDownSvg } from '@assets/icons'
import { TextInput, Typography } from '@components'
import { isWeb, screenWidth } from '@components/utils'
import { BottomSheetModal, BottomSheetScrollView } from '@gorhom/bottom-sheet'
import { ProfileOption, ProfileOptions } from '@utils'

const styles = StyleSheet.create({
  arrowDownContainer: {
    alignItems: 'center',
    paddingVertical: 10
  },
  input: {
    backgroundColor: '#F3F2F9',
    fontSize: 16,
    marginBottom: 6,
    marginRight: 8
  },
  option: {
    alignItems: 'center',
    borderBottomColor: '#FFFFFF',
    borderBottomWidth: 1,
    flexDirection: 'row',
    gap: 8,
    height: 48,
    justifyContent: 'center',
    maxHeight: 48
  },
  sheet: {
    marginHorizontal: 'auto',
    maxWidth: screenWidth,
    paddingHorizontal: 26
  }
})

const useBottomSheetKeyboard = () => {
  const bottomSheetRef = useRef<BottomSheetModal>(null)

  const handleKeyboardDidShow = () => bottomSheetRef.current?.snapToIndex(1)
  const handleKeyboardDidHide = () => bottomSheetRef.current?.snapToIndex(0)

  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', handleKeyboardDidShow)
    Keyboard.addListener('keyboardDidHide', handleKeyboardDidHide)

    return () => {
      Keyboard.removeAllListeners('keyboardDidShow')
      Keyboard.removeAllListeners('keyboardDidHide')
    }
  }, [])

  return bottomSheetRef
}

interface AutocompleteProps {
  options: ProfileOptions
  label: string
  value?: ProfileOption['id']
  placeholder?: string
  onChange: (option: ProfileOption) => void
  renderOption: (option: ProfileOption) => string
}

export const Autocomplete = ({ options, label, value, placeholder, onChange, renderOption }: AutocompleteProps) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [filterValue, setFilterValue] = useState<string>('')
  const bottomSheetRef = useBottomSheetKeyboard()

  useEffect(() => {
    const forInput = options.find(option => option.id === value)?.label || ''
    setInputValue(forInput)
  }, [value])

  const handleFilterInputChange = (text: string) => setFilterValue(text)

  const open = () => bottomSheetRef.current?.present()

  const close = () => {
    setFilterValue('')
    bottomSheetRef.current?.close()
  }

  const handleChange = (newValue: ProfileOption) => {
    close()
    onChange(newValue)
  }

  const optionsList = useMemo(
    () =>
      options
        .filter(item => new RegExp(filterValue, 'i').test(item.label))
        .map(item => (
          <TouchableOpacity key={item.id} style={styles.option} onPress={() => handleChange(item)}>
            <Typography f16 normal textAlign='center' color='#2c2c2c'>
              {renderOption(item)}
            </Typography>
          </TouchableOpacity>
        )),
    [options, filterValue]
  )

  const handleComponent = () => (
    <View style={styles.arrowDownContainer}>
      <ArrowDownSvg />
    </View>
  )

  if (isWeb) {
    return null
  }

  return (
    <>
      <TouchableOpacity onPress={open} style={{ zIndex: 2 }}>
        <View pointerEvents='none'>
          <TextInput value={inputValue} label={label} />
        </View>
      </TouchableOpacity>
      <BottomSheetModal
        name='AutocompleteModal'
        ref={bottomSheetRef}
        index={0}
        snapPoints={['50%, 90%']}
        backgroundStyle={{ backgroundColor: '#F3F2F9' }}
        handleComponent={handleComponent}
        enablePanDownToClose
        enableHandlePanningGesture
        style={styles.sheet}
      >
        <TextInput
          style={styles.input}
          label={label}
          placeholder={placeholder}
          value={filterValue}
          onChange={handleFilterInputChange}
        />
        <BottomSheetScrollView contentContainerStyle={{ minHeight: 500 }} showsVerticalScrollIndicator={false}>
          {optionsList.length ? (
            optionsList
          ) : (
            <Typography marginTop={10} f16 normal textAlign='center' color='#2c2c2c'>
              Ничего не найдено
            </Typography>
          )}
        </BottomSheetScrollView>
      </BottomSheetModal>
    </>
  )
}

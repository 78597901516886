import { ReactNode } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { ModalWrapper, Typography } from '@components'

const styles = StyleSheet.create({
  touchableContent: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8,
    height: 58,
    justifyContent: 'center',
    maxHeight: 58
  },
  touchableWithBorder: {
    borderBottomColor: '#FFFFFF',
    borderBottomWidth: 1
  }
})

interface BaseOption<IdType> {
  id: IdType
  label: string
}

interface SelectModalProps<Option extends BaseOption<IdType>, IdType> {
  selected?: IdType
  options: Option[]
  onClose: () => void
  onSelect: (id: Option) => void
  renderOption?: (item: Option, selected: boolean) => ReactNode
}

export const SelectModal = <Option extends BaseOption<IdType>, IdType>({
  onClose,
  options,
  onSelect,
  renderOption,
  selected
}: SelectModalProps<Option, IdType>) => {
  const getOption = (option: Option, isSelected: boolean) =>
    renderOption ? (
      renderOption(option, isSelected)
    ) : (
      <Typography f16 medium textAlign='center' color={isSelected ? '#8c8c8c' : '#2c2c2c'}>
        {option.label}
      </Typography>
    )

  const renderOptions = () =>
    options.map(option => (
      <TouchableOpacity
        key={String(option.id)}
        style={[styles.touchableContent, styles.touchableWithBorder]}
        onPress={() => onSelect(option)}
        disabled={selected === option.id}
      >
        {getOption(option, selected === option.id)}
      </TouchableOpacity>
    ))

  return (
    <ModalWrapper isVisible close={onClose}>
      {renderOptions()}
      <TouchableOpacity style={styles.touchableContent} onPress={onClose}>
        <Typography f16 normal textAlign='center' color='#8c8c8c'>
          Отмена
        </Typography>
      </TouchableOpacity>
    </ModalWrapper>
  )
}

import { useEffect, useState } from 'react'
import { Image, Pressable, ScrollView, StyleSheet, TextInput, TouchableOpacity, View, ViewStyle } from 'react-native'

import { AttachSvg, IconCloseSvg, SendMessageSvg } from '@assets/icons'
import { ModalAttachFiles, Typography } from '@components'
import { isWeb } from '@components/utils'
import { useDataLayer } from '@redux/dataLayer'
import { Base64File } from '@types'

const styles = StyleSheet.create({
  attachmentContainer: {
    height: 100,
    position: 'relative',
    width: 100
  },
  blocked: {
    alignItems: 'center',
    backgroundColor: '#e8e7ed',
    justifyContent: 'center',
    padding: 16,
    textAlign: 'center',
    width: '100%'
  },
  close: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 20,
    position: 'absolute',
    right: 5,
    top: 5,
    zIndex: 1
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#e8e7ed',
    justifyContent: 'center',
    padding: 10,
    width: '100%'
  },
  controls: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8
  },
  inputLine: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'space-between',
    width: '100%'
  },
  textInput: {
    backgroundColor: '#dcdbe1',
    borderRadius: 18,
    flexGrow: 1,
    maxHeight: 140,
    paddingBottom: 10,
    paddingHorizontal: 10,
    paddingTop: 10
  }
})

interface InputMessageProps {
  chatId: number
  userChatId: number
  selectedMessageId?: number
  isDeleted?: boolean
  isBlocked?: boolean
  placeholder?: string
  style?: ViewStyle
}

export const InputMessage = ({
  chatId,
  userChatId,
  selectedMessageId,
  isDeleted,
  isBlocked,
  placeholder = 'Сообщение...',
  style
}: InputMessageProps) => {
  const [textInputValue, setTextInputValue] = useState('')
  const [attachments, setAttachments] = useState<Base64File[]>([])
  const [height, setHeight] = useState(40)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { sendAttachmentsToChat, sendMessage, sendDiscussionMessage } = useDataLayer()

  useEffect(() => {
    setTextInputValue('')
    setAttachments([])
  }, [selectedMessageId])

  if (isBlocked || isDeleted) {
    const string = (isBlocked && 'заблокирован') || (isDeleted && 'удалён')

    return (
      <View style={styles.blocked}>
        <Typography f13>Пользователь {string}</Typography>
      </View>
    )
  }

  const onFilesAttach = (files: Base64File[]) => {
    const filteredFiles = files.filter(file => !attachments.some(attachment => attachment.base64 === file.base64))
    setAttachments(prev => [...prev, ...filteredFiles])
    setIsModalVisible(false)
  }

  const sendMessageHandler = () => {
    const formatted = textInputValue.trim()
    if (!formatted && !attachments.length) {
      return
    }

    setHeight(40)
    setTextInputValue('')
    setAttachments([])

    if (attachments.length && !selectedMessageId) {
      sendAttachmentsToChat(
        {
          content: formatted,
          user_id: userChatId,
          chat_id: chatId,
          type: 'attachment'
        },
        attachments
      )
      return
    }

    if (selectedMessageId) {
      sendDiscussionMessage({
        content: formatted,
        user_id: userChatId,
        message_id: selectedMessageId,
        message_type: 'text'
      })
      return
    }

    sendMessage({
      content: formatted,
      user_id: userChatId,
      chat_id: chatId,
      message_type: 'text'
    })
  }

  const removeFromAttachments = (base64: string) => {
    setAttachments(prev => prev.filter(file => file.base64 !== base64))
  }

  const renderAttachmentImages = () =>
    attachments.map(file => (
      <View style={styles.attachmentContainer} key={file.base64}>
        <Image source={{ uri: file.uri }} resizeMode='contain' style={{ flex: 1 }} />
        <Pressable style={styles.close} onPress={() => removeFromAttachments(file.base64)}>
          <IconCloseSvg fill='black' />
        </Pressable>
      </View>
    ))

  return (
    <View style={[styles.container, style]}>
      {attachments.length > 0 && (
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={isWeb}
          contentContainerStyle={{ flexDirection: 'row', flexWrap: 'wrap', gap: 8 }}
          style={{ marginBottom: 10 }}
        >
          {renderAttachmentImages()}
        </ScrollView>
      )}
      <View style={styles.inputLine}>
        <TextInput
          multiline
          style={[styles.textInput, isWeb && { height }]}
          placeholder={placeholder}
          value={textInputValue}
          onChangeText={setTextInputValue}
          onContentSizeChange={event => setHeight(event.nativeEvent.contentSize.height)}
        />
        <View style={styles.controls}>
          {!selectedMessageId && (
            <TouchableOpacity onPress={() => setIsModalVisible(true)} disabled={isBlocked || isDeleted}>
              <AttachSvg />
            </TouchableOpacity>
          )}
          {(textInputValue !== '' || !!attachments.length) && (
            <TouchableOpacity onPress={sendMessageHandler} disabled={isBlocked || isDeleted}>
              <SendMessageSvg />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <ModalAttachFiles
        visible={isModalVisible && !selectedMessageId}
        onClose={() => setIsModalVisible(false)}
        onFilesAttach={onFilesAttach}
      />
    </View>
  )
}

/* eslint-disable func-style */
import { call, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects'

import { SEND_ATTACHMENTS_TO_CHAT_REQUEST, SendAttachmentsToChatRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect

function* sendAttachmentsToChat(action: SendAttachmentsToChatRequestAction): Generator<SagaEffects, void, any> {
  try {
    yield call([apiService, apiService.sendAttachmentsToChat], action.payload.data, action.payload.files)
  } catch (error: any) {
    console.log('sendAttachmentsToChat', error)
  }
}

export function* sendAttachmentsToChatSaga() {
  yield takeLatest<SendAttachmentsToChatRequestAction>(SEND_ATTACHMENTS_TO_CHAT_REQUEST, sendAttachmentsToChat)
}

import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { BigLikeSvg, ConnectTextLogoSvg } from '@assets/icons'
import { Button, Typography } from '@components'
import { useNavigator } from '@navigation'

const styles = StyleSheet.create({
  LogoWrapper: {
    marginBottom: 80
  },

  bottomButton: {
    width: '100%'
  },
  bottomButtonContainer: {
    alignItems: 'center'
  },
  centerWrapper: {
    alignItems: 'center',
    flex: 1
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 70
  }
})

export const Congratulations = () => {
  const { navigation } = useNavigator<'NotificationScreen'>()

  const goToNotificationScreen = () => {
    navigation.navigate('NotificationScreen')
  }

  return (
    <View style={styles.mainWrapper}>
      <View style={styles.centerWrapper}>
        <View style={styles.LogoWrapper}>
          <ConnectTextLogoSvg />
        </View>
        <BigLikeSvg />
        <Typography f29 extraBold marginTop={116} marginBottom={12}>
          Поздравляем!
        </Typography>
        <Typography f15 normal textAlign='center'>
          Вы успешно прошли регистрацию, сейчас ваш профиль находится на верификации, некоторые функции будут ограничены
        </Typography>
      </View>
      <View style={styles.bottomButtonContainer}>
        <TouchableOpacity style={styles.bottomButton}>
          <Button text='Начать!' onPress={goToNotificationScreen} />
        </TouchableOpacity>
      </View>
    </View>
  )
}

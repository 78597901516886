import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { ConnectTextLogoSvg, LogoSvg } from '@assets/icons'
import { Typography } from '@components'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { SubmitButton } from '@screens'
import { saveToLS } from '@utils'

const styles = StyleSheet.create({
  centralWrapper: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 50
  },
  topWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12
  }
})

export const EndSign = () => {
  const { navigation } = useNavigator<'GeneralInformation'>()
  const { resetStore } = useDataLayer()

  const resetLogin = async () => {
    await saveToLS('@token', '')
    await saveToLS('@user', '')
    resetStore()
    navigation.navigate('FirstScreen')
  }

  return (
    <View style={styles.mainWrapper}>
      <View style={styles.topWrapper}>
        <ConnectTextLogoSvg />
      </View>
      <View style={styles.centralWrapper}>
        <TouchableOpacity onPress={resetLogin}>
          <LogoSvg width={217} height={144} />
        </TouchableOpacity>
        <Typography f29 extraBold marginTop={104} marginBottom={8}>
          Добро пожаловать
        </Typography>
        <Typography f15 normal>
          Connect - больше чем социальная сеть!
        </Typography>
      </View>
      <SubmitButton isEditing={false} label='Продолжить' onPress={() => navigation.navigate('GeneralInformation')}>
        <Typography f15 normal textAlign='center' marginBottom={16}>
          Давайте начнем и заполним данные о себе
        </Typography>
      </SubmitButton>
    </View>
  )
}

import { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { HeaderInfo, ProfileOptionItem, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'
import { OptionId, VALUABLES } from '@utils'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1
  },

  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

export const Quality = () => {
  const { route } = useNavigator<'Quality'>()
  const edit = route.params?.edit
  const { userUpdate } = useDataLayer()
  const [selectedQuality, setSelectedQuality] = useState<OptionId[]>([])

  const currentUser = useSelector(selectCurrentUserData)

  const handlePress = (quality: OptionId) => {
    setSelectedQuality(prevQuality =>
      selectedQuality.includes(quality) ? prevQuality.filter(item => item !== quality) : [...prevQuality, quality]
    )
  }

  useEffect(() => {
    if (currentUser) {
      setSelectedQuality(currentUser.profile.valuables)
    }
  }, [currentUser])

  const goToFuturePlaces = () => {
    if (!currentUser) {
      return
    }

    userUpdate(
      {
        profile: {
          ...currentUser.profile,
          valuables: selectedQuality
        }
      },
      { page: edit ? 'InformationYourself' : 'TellYourself' }
    )
  }

  const renderValuables = () =>
    VALUABLES.map(quality => (
      <ProfileOptionItem key={quality.id} data={quality} selected={selectedQuality} onSelect={handlePress} />
    ))

  return (
    <View style={styles.mainWrapper}>
      <HeaderInfo showProgressBar={!edit} step={14} />
      <View style={styles.centerWrapper}>
        <Typography f27 bold marginTop={28} marginBottom={20}>
          Ценности
        </Typography>
        <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ gap: 16 }}>
          {renderValuables()}
        </ScrollView>
      </View>
      <SubmitButton isEditing={edit} onPress={goToFuturePlaces} buttonLabelCondition={!!selectedQuality.length} />
    </View>
  )
}

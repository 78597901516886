import {
  CurrentUserState,
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GetCurrentUserActionTypes
} from '@redux/types'

const initialState: CurrentUserState = null

export const currentUserReducer = (state = initialState, action: GetCurrentUserActionTypes) => {
  switch (action.type) {
    case GET_CURRENT_USER_REQUEST:
      return state
    case GET_CURRENT_USER_SUCCESS:
      return action.payload
    default:
      return state
  }
}

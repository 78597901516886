import { useEffect, useState } from 'react'
import { KeyboardAvoidingView, Pressable, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { IconCloseSvg } from '@assets/icons'
import { Chip, CustomMaps, HeaderInfo, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1
  },
  customChip: {
    marginRight: 15
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

interface Location {
  name: string
  placeId: string
  position: {
    lat: number
    lng: number
  }
}

export const VisitedPlaces = () => {
  const { route } = useNavigator<'VisitedPlaces'>()
  const edit = route.params?.edit

  const { userUpdate } = useDataLayer()
  const currentUser = useSelector(selectCurrentUserData)
  const [locations, setLocations] = useState<Location[]>([])
  const [location, setLocation] = useState<Location>({
    name: '',
    placeId: '',
    position: {
      lat: 39.78825,
      lng: -122.4324
    }
  })

  useEffect(() => {
    if (currentUser) {
      setLocations(currentUser.profile.places)
    }
  }, [currentUser])

  const goToReferences = () => {
    if (!currentUser) {
      return
    }

    userUpdate(
      {
        profile: { ...currentUser.profile, places: locations }
      },
      { page: edit ? 'InformationYourself' : 'Preferences' }
    )
  }

  useEffect(() => {
    if (!location.placeId) {
      return
    }

    if (locations.some(l => l.placeId === location.placeId)) {
      return
    }

    setLocations([...locations, location])
  }, [location])

  const deletePlace = (id: string) => {
    setLocations(locations.filter(_location => _location.placeId !== id))
  }

  const renderLocations = () =>
    locations.map(_location => (
      <Chip
        key={_location.placeId}
        label={_location.name}
        rightIcon={
          <Pressable onPress={() => deletePlace(_location.placeId)}>
            <IconCloseSvg width={18} height={18} />
          </Pressable>
        }
        style={styles.customChip}
      />
    ))

  return (
    <View style={styles.mainWrapper}>
      <HeaderInfo showProgressBar={!edit} step={12} />
      <KeyboardAvoidingView behavior={isIOS ? 'padding' : undefined} style={{ flex: 1 }}>
        <View style={styles.centerWrapper}>
          <Typography f27 bold marginTop={24} marginBottom={8}>
            Посещаемые места
          </Typography>
          <Typography f15 normal marginBottom={20}>
            Отметь локации в которых часто бываешь, чтобы получать уведомления о тех, кто с тобой рядом
          </Typography>
          <CustomMaps
            placeholder='Местоположение'
            isVisible
            location={location}
            setLocation={setLocation}
            types=''
            clearInputOnSelect
            selectedNodes={locations?.length ? renderLocations() : null}
          />
        </View>
        <SubmitButton isEditing={edit} onPress={goToReferences} buttonLabelCondition={!!locations.length} />
      </KeyboardAvoidingView>
    </View>
  )
}

/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getCurrentUserSuccess, notificationAction } from '@redux/actions'
import { LOGIN_REQUEST, LoginRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect

function* login(action: LoginRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.login], action.payload)
    const user = response.data.user
    yield put(getCurrentUserSuccess(user))
  } catch (error: any) {
    yield put(
      notificationAction({
        type: 'error',
        text: error.response.data.message,
        image: ''
      })
    )
  }
}

export function* loginSaga() {
  yield takeLatest<LoginRequestAction>(LOGIN_REQUEST, login)
}

import { useEffect, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Autocomplete, Checkbox, HeaderInfo, Select, SelectFilterModal, TextInput, Typography } from '@components'
import { isIOS, isWeb } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'
import { CHILDREN, ProfileOption, RELATIONSHIP, RELIGION } from '@utils'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1
  },

  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

export const ReligionChildren = () => {
  const { route, navigation } = useNavigator<'ReligionChildren'>()
  const edit = route.params?.edit
  const { userUpdate } = useDataLayer()
  const currentUser = useSelector(selectCurrentUserData)

  const [selectedRelationship, setSelectedRelationship] = useState(-1)
  const [selectedChildrenValue, setSelectedChildrenValue] = useState(-1)
  const [selectedReligionValue, setSelectedReligionValue] = useState<number | string>(-1)

  const [modalOpen, setModalOpen] = useState(false)
  const [hideInfoOption, setHideInfoOption] = useState(false)
  const [showOnlyOrientationOption, setShowOnlyOrientationOption] = useState(false)

  useEffect(() => {
    if (!currentUser) {
      return
    }

    setSelectedReligionValue(currentUser.profile.religion)
    setSelectedRelationship(currentUser.profile.relationshipStatus)
    setSelectedChildrenValue(currentUser.profile.hasChildren)
    setHideInfoOption(!currentUser.profileVisability.hasChildren)
    setShowOnlyOrientationOption(currentUser.profile.algorithmReligion)
  }, [currentUser])

  const goToWorks = () => {
    if ((selectedRelationship === -1 || selectedChildrenValue === -1 || selectedReligionValue === -1) && !edit) {
      navigation.navigate('Works')
      return
    }

    if (!currentUser) {
      return
    }

    userUpdate(
      {
        profileVisability: {
          ...currentUser.profileVisability,
          hasChildren: !hideInfoOption
        },
        profile: {
          ...currentUser.profile,
          religion: selectedReligionValue,
          relationshipStatus: selectedRelationship,
          hasChildren: selectedChildrenValue,
          algorithmReligion: showOnlyOrientationOption
        }
      },
      { page: edit ? 'InformationYourself' : 'Works' }
    )
  }

  const onModalOpen = () => {
    setModalOpen(true)
  }

  return (
    <View style={styles.mainWrapper}>
      <HeaderInfo showProgressBar={!edit} step={7} />
      <View style={styles.centerWrapper}>
        <Typography f27 bold marginTop={24} marginBottom={12}>
          Религия
        </Typography>
        {isWeb ? (
          <Pressable onPress={() => onModalOpen()}>
            <TextInput
              autoCapitalize='none'
              pointerEvents='none'
              value={RELIGION.find(r => r.id === selectedReligionValue)?.label}
            />
          </Pressable>
        ) : (
          <Autocomplete
            placeholder='Не выбрано'
            label='Выбрать'
            value={selectedReligionValue}
            options={RELIGION}
            renderOption={option => option.label}
            onChange={option => setSelectedReligionValue(option.id)}
          />
        )}

        <Typography f27 bold marginTop={40} marginBottom={12}>
          Семейное положение
        </Typography>

        <Select
          options={RELATIONSHIP}
          onChange={option => setSelectedRelationship(option.id as number)}
          value={selectedRelationship}
          style={{ zIndex: 2 }}
        />

        <Typography f27 bold marginTop={40} marginBottom={12}>
          Дети
        </Typography>

        <Select
          options={CHILDREN}
          onChange={option => setSelectedChildrenValue(option.id as number)}
          value={selectedChildrenValue}
          style={{ zIndex: 1 }}
        />
      </View>
      <SubmitButton
        isEditing={edit}
        buttonLabelCondition={
          selectedRelationship !== -1 || selectedChildrenValue !== -1 || selectedReligionValue !== -1
        }
        onPress={goToWorks}
        marginTop={20}
      >
        <Checkbox
          label='Скрыть информацию “Дети” в профиле'
          checked={hideInfoOption}
          onPress={() => setHideInfoOption(prev => !prev)}
        />
        <View style={{ marginTop: 18 }}>
          <Checkbox
            label='Учитывать только одинаковое вероисповедание в алгоритме'
            checked={showOnlyOrientationOption}
            onPress={() => setShowOnlyOrientationOption(prev => !prev)}
          />
        </View>
      </SubmitButton>
      <SelectFilterModal
        style={{ maxHeight: 500 }}
        isModalOpen={modalOpen}
        options={RELIGION}
        onClose={() => setModalOpen(false)}
        onSelect={(option: ProfileOption) => {
          setSelectedReligionValue(option.id)
          setModalOpen(false)
        }}
      />
    </View>
  )
}

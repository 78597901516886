import {
  GET_USER_CHATS_REQUEST,
  GET_USER_CHATS_SUCCESS,
  GetUserChatsRequestAction,
  GetUserChatsSuccessAction
} from '@redux/types'
import { UserChat } from '@types'

export const getUserChatsRequest = (id: number): GetUserChatsRequestAction => ({
  type: GET_USER_CHATS_REQUEST,
  payload: id
})

export const getUserChatsSuccess = (chats: UserChat[]): GetUserChatsSuccessAction => ({
  type: GET_USER_CHATS_SUCCESS,
  payload: chats
})

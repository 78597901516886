import { useState } from 'react'
import { Keyboard, StyleSheet, TouchableOpacity, View } from 'react-native'
import moment from 'moment'

import { Button, DatePicker, TextInput } from '@components'
import { useDataLayer } from '@redux/dataLayer'

const styles = StyleSheet.create({
  button: {
    marginBottom: 0,
    marginTop: 16,
    width: '100%'
  },
  inputContainer: {
    width: '100%'
  },
  mainWrapper: {
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: 15,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 16
  },
  textInput: {
    backgroundColor: 'white'
  }
})

export const DateCompatibility = () => {
  const { dateCompatibility } = useDataLayer()
  const [showModal, setShowModal] = useState(false)
  const [date, setDate] = useState('')

  const handleDatePickerResult = (dateValue?: Date) => {
    if (!(dateValue instanceof Date)) {
      return
    }

    setShowModal(false)
    setDate(moment(dateValue).format('DD.MM.YYYY'))
  }

  const openDatePickerModal = () => {
    Keyboard.dismiss()
    setShowModal(true)
  }

  const calculateCompatibility = () => {
    dateCompatibility(date, { page: 'DateCompResult', params: { date } })
    setDate('')
  }

  return (
    <View style={styles.mainWrapper}>
      <TouchableOpacity onPress={openDatePickerModal} style={styles.inputContainer}>
        <View pointerEvents='none'>
          <TextInput value={date} placeholder='Дата рождения' style={styles.textInput} />
        </View>
      </TouchableOpacity>
      <DatePicker isVisible={showModal} handleResult={handleDatePickerResult} />
      <Button text='Продолжить' style={styles.button} onPress={calculateCompatibility} disabled={!date} />
    </View>
  )
}

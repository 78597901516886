/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { navigate } from '@navigation'
import { getCurrentUserSuccess, notificationAction } from '@redux/actions'
import { GENERAL_INFORMATION_REQUEST, GeneralInformationRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect

function* generalInformation(action: GeneralInformationRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call(
      [apiService, apiService.generalInformation],
      action.payload.name,
      action.payload.dateOfBirth,
      action.payload.sex,
      action.payload.log
    )
    const user = response.data
    navigate(action.payload.next)
    yield put(getCurrentUserSuccess(user))
  } catch (error: any) {
    yield put(
      notificationAction({
        type: 'error',
        text: error.response.data.message,
        image: ''
      })
    )
  }
}

export function* generalInformationSaga() {
  yield takeLatest<GeneralInformationRequestAction>(GENERAL_INFORMATION_REQUEST, generalInformation)
}

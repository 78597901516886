import { useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { ScoreAttractionSvg, ScoreBizSvg, ScoreFriendSvg, ScoreLoveSvg, ScoreSexSvg } from '@assets/icons'
import { CompatibilityBarItem, SelectModal, Typography } from '@components'
import { useCurrentUserAge } from '@hooks'
import { CompatibilityTypes, UserProfile } from '@types'

interface CompInfo {
  iconTitle: string
  label: string
  icon: typeof ScoreBizSvg
}

export interface CompInfoWithType extends CompInfo {
  id: CompatibilityTypes
}

type CompMap = {
  [K in CompatibilityTypes]: CompInfo
}

const compMap: CompMap = {
  BusinessPartners: { iconTitle: 'В бизнес', icon: ScoreBizSvg, label: 'Бизнес партнерство' },
  Friendship: { iconTitle: 'В дружбе', icon: ScoreFriendSvg, label: 'Дружба, друзья по интересам' },
  LoveMarriage: { iconTitle: 'В любви', icon: ScoreLoveSvg, label: 'Любовь-брак' },
  SexyAdventures: { iconTitle: 'В отношениях', icon: ScoreSexSvg, label: 'Свободные отношения' },
  Commercial: { iconTitle: 'В отношениях', icon: ScoreSexSvg, label: 'Отношения по расчету' }
}

const styles = StyleSheet.create({
  buttonWrapper: {
    backgroundColor: '#fff',
    borderRadius: 55,
    height: '100%',
    justifyContent: 'center',
    marginBottom: 40,
    maxHeight: 50,
    width: '100%'
  },
  container: {
    paddingHorizontal: 26,
    paddingTop: 8
  },
  noComp: {
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  },
  scoreWrapper: {
    alignItems: 'center',
    borderColor: '#999',
    borderRadius: 23,
    borderStyle: 'solid',
    borderWidth: 2,
    height: 133,
    justifyContent: 'center',
    marginBottom: 20,
    width: 108
  },
  singleScore: {
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'center'
  }
})

interface CompatibilityTabProps {
  user: UserProfile
}

export const CompatibilityTab = ({ user }: CompatibilityTabProps) => {
  const insets = useSafeAreaInsets()
  const [currentCompType, setCurrentCompType] = useState<CompatibilityTypes>(user.best)
  const [isModalVisible, setModalVisible] = useState(false)

  const { isAdult } = useCurrentUserAge()

  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)

  const handleTypeChange = (newType: CompatibilityTypes) => {
    setCurrentCompType(newType)
    hideModal()
  }

  const personDescription = useMemo(() => {
    const texts = user.description

    if (currentCompType === 'BusinessPartners') {
      return { personText: texts.business, title: 'в бизнесе', compText: '' }
    }

    if (['LoveMarriage', 'SexyAdventures', 'Commercial'].includes(currentCompType)) {
      return { personText: texts.love, title: 'в любви', compText: user.planets_love }
    }

    return { personText: texts.friend, title: 'в дружбе', compText: user.planets_friendship }
  }, [currentCompType])

  const availableCompOptions = (Object.keys(compMap) as CompatibilityTypes[])
    .filter(type => !!user.results[type])
    .map(id => ({ ...compMap[id], id }))

  const comp = user.results[currentCompType]

  if (!comp) {
    return (
      <View style={styles.noComp}>
        <Typography f16 semibold marginBottom={12} color='#7b7b7d' marginTop={32} maxWidth='75%' textAlign='center'>
          Нет доступных совместимостей для отображения
        </Typography>
      </View>
    )
  }

  return (
    <View style={[styles.container, { paddingBottom: insets.bottom + 90 }]}>
      <Typography f13 color='#8c8c8c' marginBottom={8} textAlign='right'>
        {compMap[currentCompType]?.label}
      </Typography>
      <View style={styles.singleScore}>
        {!!comp?.attraction && (
          <View style={styles.scoreWrapper}>
            <CompatibilityBarItem value={comp.attraction} Icon={ScoreAttractionSvg} fontSize={14} textColor='black' />
            <Typography f14 semibold marginTop={8} color='#2C2C2C'>
              Притяжение
            </Typography>
          </View>
        )}
        {!!comp?.sex_points && isAdult && (
          <View style={styles.scoreWrapper}>
            <CompatibilityBarItem value={comp.sex_points} Icon={ScoreSexSvg} fontSize={14} textColor='black' />
            <Typography f14 semibold marginTop={8} color='#2C2C2C'>
              В сексе
            </Typography>
          </View>
        )}
        {!!comp?.points && (
          <View style={styles.scoreWrapper}>
            <CompatibilityBarItem value={comp.points} Icon={compMap[comp.type]?.icon} fontSize={14} textColor='black' />
            <Typography f14 semibold marginTop={8} color='#2C2C2C'>
              {compMap[comp.type]?.iconTitle}
            </Typography>
          </View>
        )}
      </View>

      <View style={styles.buttonWrapper} onTouchEnd={showModal}>
        <Typography f14 medium textAlign='center' color='#18181C'>
          Другие совместимости
        </Typography>
      </View>

      {personDescription.personText && (
        <>
          <Typography f16 semibold marginBottom={12} color='#7b7b7d'>
            Как проявляется {personDescription.title}
          </Typography>
          <Typography f15 normal marginBottom={40} color='#525253'>
            {personDescription.personText}
          </Typography>
        </>
      )}

      {personDescription.compText && (
        <>
          <Typography f16 semibold marginBottom={12} color='#7b7b7d'>
            Сценарий взаимодействия
          </Typography>
          <Typography f15 normal marginBottom={40} color='#525253'>
            {personDescription.compText}
          </Typography>
        </>
      )}

      {isModalVisible && (
        <SelectModal<CompInfoWithType, CompatibilityTypes>
          selected={currentCompType}
          options={availableCompOptions}
          renderOption={(item, isSelected) => (
            <>
              <item.icon fill={isSelected ? '#8c8c8c' : '#2c2c2c'} />
              <Typography f16 medium textAlign='center' color={isSelected ? '#8c8c8c' : '#2c2c2c'}>
                {item.label}
              </Typography>
            </>
          )}
          onClose={hideModal}
          onSelect={option => handleTypeChange(option.id)}
        />
      )}
    </View>
  )
}

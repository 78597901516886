import { NOTIFICATION_SUCCESS, NotificationActionTypes, NotificationState } from '@redux/types'

const initialState: NotificationState = null

export const notificationReducer = (state = initialState, action: NotificationActionTypes) => {
  switch (action.type) {
    case NOTIFICATION_SUCCESS:
      return action.payload
    default:
      return state
  }
}

import { Fragment, useEffect, useMemo, useState } from 'react'
import { KeyboardAvoidingView, ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { ArrowDownBlackSvg, ArrowDownWhiteSvg, ArrowUpWhiteSvg } from '@assets/icons'
import { Chip, HeaderInfo, TextInput, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'
import { HOBBIES, OptionId, ProfileOption, ProfileOptions } from '@utils'

const styles = StyleSheet.create({
  categoriesWrapper: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    marginTop: 24,
    paddingVertical: 10
  },
  centerWrapper: {
    flex: 1
  },
  hobbiesDivider: {
    backgroundColor: '#A2A0AC',
    height: 1,
    marginVertical: 8,
    minWidth: '100%',
    opacity: 0.1
  },
  hobbiesWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    width: '100%'
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

export const Hobbies = () => {
  const { route } = useNavigator<'Hobbies'>()
  const edit = route.params?.edit
  const { userUpdate } = useDataLayer()
  const currentUser = useSelector(selectCurrentUserData)

  const [allHobbies, setAllHobbies] = useState<ProfileOption[]>([])
  const [activeCategoryId, setActiveCategoryId] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [selectedHobbyIds, setSelectedHobbyIds] = useState<OptionId[]>([])
  const [selectedHobbiesCountByCategory, setSelectedHobbiesCountByCategory] = useState<Record<string, number>>({})

  useEffect(() => {
    const hobbies: ProfileOption[] = []
    HOBBIES.forEach(hobby => hobbies.push(...(hobby.children ?? [])))
    setAllHobbies(hobbies)
  }, [])

  useEffect(() => {
    if (!currentUser) {
      return
    }

    const hobbieIds = currentUser.profile.hobbies
    const hobbiesCount: Record<string, number> = {}

    hobbieIds.forEach(id => {
      const categoryId = +id.split('_')[0]
      hobbiesCount[categoryId] = ~~hobbiesCount[categoryId] + 1
    })

    setSelectedHobbyIds(hobbieIds)
    setSelectedHobbiesCountByCategory(hobbiesCount)
  }, [currentUser])

  const submit = () => {
    if (!currentUser) {
      return
    }

    userUpdate(
      { profile: { ...currentUser.profile, hobbies: selectedHobbyIds } },
      { page: edit ? 'InformationYourself' : 'VisitedPlaces' }
    )
  }

  const selectCategory = (_categoryId: string) =>
    setActiveCategoryId(activeCategoryId === _categoryId ? '' : _categoryId)

  const toggleHobby = (hobbyId: OptionId, categoryId: OptionId) => {
    const isHobbyIncluded = selectedHobbyIds.includes(hobbyId.toString())

    if (isHobbyIncluded) {
      setSelectedHobbyIds(selectedHobbyIds.filter(id => id !== hobbyId))
      setSelectedHobbiesCountByCategory({
        ...selectedHobbiesCountByCategory,
        [categoryId]: selectedHobbiesCountByCategory[categoryId] - 1
      })
    } else {
      setSelectedHobbyIds([...selectedHobbyIds, hobbyId])
      setSelectedHobbiesCountByCategory({
        ...selectedHobbiesCountByCategory,
        [categoryId]: (selectedHobbiesCountByCategory[categoryId] || 0) + 1
      })
    }
  }

  const isHobbySelected = (hobbyId: OptionId) => selectedHobbyIds.includes(hobbyId.toString())

  const categories = useMemo(() => {
    const allFilter = allHobbies.filter(el => el.label.toLocaleLowerCase().includes(filterValue))
    const setOfCategories = new Set(allFilter.map(el => +(el.id as string).split('_')[0]))
    const categoriesArr: ProfileOptions = []
    setOfCategories.forEach((idx: number) => {
      categoriesArr.push({
        ...HOBBIES[idx],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        children: HOBBIES[idx].children!.filter(el => el.label.toLocaleLowerCase().includes(filterValue))
      })
    })
    return categoriesArr
  }, [filterValue, allHobbies])

  const onChangeText = (text: string) => setFilterValue(text.toLocaleLowerCase())

  const CategoryArrow = ({ categoryId }: { categoryId: OptionId }) => {
    const ArrowDown = selectedHobbiesCountByCategory[categoryId] > 0 ? ArrowDownWhiteSvg : ArrowDownBlackSvg
    const ActiveArrow = activeCategoryId === categoryId ? ArrowUpWhiteSvg : ArrowDown
    return <ActiveArrow width={18} height={18} />
  }

  const renderHobbies = (category: ProfileOption) =>
    category.children?.map(hobby => (
      <Chip
        key={hobby.id}
        label={hobby.label}
        onPress={() => toggleHobby(hobby.id, category.id)}
        bgColor={isHobbySelected(hobby.id) ? '#2F2F2F' : undefined}
        textColor={isHobbySelected(hobby.id) ? '#FFFFFF' : undefined}
      />
    ))

  const renderCategories = () =>
    categories.map(category => {
      const isCategoryActive = activeCategoryId === category.id || selectedHobbiesCountByCategory[category.id] > 0
      return (
        <Fragment key={category.id}>
          <Chip
            onPress={() => selectCategory(category.id as string)}
            bgColor={isCategoryActive ? '#2F2F2F' : undefined}
            textColor={isCategoryActive ? '#FFFFFF' : undefined}
            label={category.label}
            leftIcon={<CategoryArrow categoryId={category.id} />}
          />
          {activeCategoryId === category.id && category.children && (
            <>
              <View style={styles.hobbiesWrapper}>{renderHobbies(category)}</View>
              <View style={styles.hobbiesDivider} />
            </>
          )}
        </Fragment>
      )
    })

  return (
    <View style={styles.mainWrapper}>
      <HeaderInfo showProgressBar={!edit} step={11} />
      <KeyboardAvoidingView behavior={isIOS ? 'padding' : 'height'} style={{ flex: 1 }}>
        <View style={styles.centerWrapper}>
          <Typography f27 bold marginTop={28} marginBottom={8}>
            Увлечения
          </Typography>
          <TextInput placeholder='Поиск по хобби' onChange={onChangeText} />
          {categories.length ? (
            <ScrollView showsVerticalScrollIndicator={false}>
              <View style={styles.categoriesWrapper}>{renderCategories()}</View>
            </ScrollView>
          ) : (
            <Typography marginTop={16} f22 textAlign='center'>
              Увлечения не найдены
            </Typography>
          )}
        </View>
      </KeyboardAvoidingView>
      <SubmitButton isEditing={edit} onPress={submit} buttonLabelCondition={!!selectedHobbyIds.length} />
    </View>
  )
}

import { useState } from 'react'
import { ActivityIndicator, StyleSheet, ViewStyle } from 'react-native'
import { IResolveParams, LoginSocialFacebook } from 'reactjs-social-login'

import { Button } from '@components'
import { isWeb } from '@components/utils'
import { AntDesign } from '@expo/vector-icons'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { SocialAuth, SocialProvider } from '@types'

const FACEBOOK_APP_ID = '1155855639086162'

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#fff',
    width: 60
  }
})

interface FacebookAuthButtonProps {
  mode?: 'login' | 'connect'
  isDisabled?: boolean
  buttonStyles?: ViewStyle
}

export const FacebookAuthButton = ({ mode = 'login', isDisabled, buttonStyles }: FacebookAuthButtonProps) => {
  const [isAuthInProgress, setIsAuthInProgress] = useState(false)
  const { registration, connectSocial } = useDataLayer()
  const { navigation } = useNavigator()

  if (!isWeb) {
    return null
  }

  const onLoginStart = () => {
    setIsAuthInProgress(true)
  }

  const onResolve = ({ data }: IResolveParams) => {
    setIsAuthInProgress(false)

    if (!data || data.error) {
      return
    }

    const params: SocialAuth = {
      provider: SocialProvider.FACEBOOK,
      token: data.accessToken
    }

    if (mode === 'connect') {
      connectSocial(params)
      return
    }

    registration({ socialAuth: params }, () => navigation.navigate('Tabs'))
  }

  const onReject = (err: any) => {
    setIsAuthInProgress(false)
    console.warn(err)
  }

  const Icon = <AntDesign name='facebook-square' size={30} color='black' />

  if (isDisabled) {
    return <Button isView style={[styles.button, buttonStyles]} iconLeft={Icon} />
  }

  return (
    <LoginSocialFacebook
      isOnlyGetToken
      onLoginStart={onLoginStart}
      onResolve={onResolve}
      onReject={onReject}
      appId={FACEBOOK_APP_ID}
      version='v19.0'
    >
      <Button isView style={[styles.button, buttonStyles]} iconLeft={isAuthInProgress ? <ActivityIndicator /> : Icon} />
    </LoginSocialFacebook>
  )
}

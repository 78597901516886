import { Image, Pressable, PressableProps, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import { selectCurrentUserData } from '@redux/selectors'
import { SERVER_URL } from '@services/apiService'

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden'
  },
  image: {
    flex: 1
  }
})

interface AvatarProps extends PressableProps {
  size: number
  uri?: string
}

export const Avatar = ({ onPress, size, uri }: AvatarProps) => {
  const currentUser = useSelector(selectCurrentUserData)
  const avatarSrc = currentUser?.profileImage[0]?.name

  return (
    <Pressable
      disabled={!onPress}
      style={({ pressed }) => [
        {
          opacity: pressed ? 0.5 : 1,
          width: size,
          height: size,
          borderRadius: size / 2
        },
        styles.container
      ]}
      onPress={onPress}
    >
      <Image style={styles.image} source={{ uri: `${SERVER_URL}/${uri || avatarSrc}` }} />
    </Pressable>
  )
}

import {
  adventure,
  angry,
  anticipation,
  attention,
  badMood,
  blanket,
  brokenHeart,
  coffee,
  company,
  dance,
  dream,
  drink,
  exited,
  hand,
  happy,
  house,
  hugs,
  inLove,
  lonely,
  love,
  noPower,
  noStatus,
  party,
  partyToday,
  playMood,
  pleasantLife,
  prey,
  reading,
  readyTalk,
  sad,
  smile,
  sport,
  stressed,
  time,
  tired,
  vacation,
  waiting,
  work
} from '@assets/icons'

export type StatusItem = {
  id: number
  text: { ru: string }
  icon: any
}

export const STATUSES: StatusItem[] = [
  { id: 0, text: { ru: 'нет статуса' }, icon: noStatus },
  { id: 1, text: { ru: 'влюблена' }, icon: love },
  { id: 2, text: { ru: 'счастлива' }, icon: happy },
  { id: 3, text: { ru: 'нахожусь в отпуске' }, icon: vacation },
  { id: 4, text: { ru: 'читаю' }, icon: reading },
  { id: 5, text: { ru: 'Наслаждаюсь жизнью' }, icon: pleasantLife },
  { id: 6, text: { ru: 'Пригласи меня на кофе' }, icon: coffee },
  { id: 7, text: { ru: 'Я люблю свою работу, я приду сюда в субботу' }, icon: work },
  { id: 8, text: { ru: 'Улыбаюсь, улыбнись и ты' }, icon: smile },
  { id: 9, text: { ru: 'Жду предложений' }, icon: waiting },
  { id: 10, text: { ru: 'Хочется грустить' }, icon: sad },
  { id: 11, text: { ru: 'Вообще не в духе' }, icon: badMood },
  { id: 12, text: { ru: 'Очень устала' }, icon: tired },
  { id: 13, text: { ru: 'Игривое настроение' }, icon: playMood },
  { id: 14, text: { ru: 'Мне одиноко' }, icon: lonely },
  { id: 15, text: { ru: 'Ищу компанию на вечер' }, icon: company },
  { id: 16, text: { ru: 'Готов к вечеринке' }, icon: party },
  { id: 17, text: { ru: 'Злюсь' }, icon: angry },
  { id: 18, text: { ru: 'Хочу обнимашек' }, icon: hugs },
  { id: 19, text: { ru: 'Хочу побыть один' }, icon: prey },
  { id: 20, text: { ru: 'Хочется выпить' }, icon: drink },
  { id: 21, text: { ru: 'Хочется потанцевать' }, icon: dance },
  { id: 22, text: { ru: 'Ищу приключений' }, icon: adventure },
  { id: 23, text: { ru: 'Готов пообщаться' }, icon: readyTalk },
  { id: 24, text: { ru: 'Хочется внимания и цветочков' }, icon: attention },
  { id: 25, text: { ru: 'Настроение для тренировки' }, icon: sport },
  { id: 26, text: { ru: 'Я за любой движ, кроме голодовки' }, icon: hand },
  { id: 27, text: { ru: 'У меня сегодня праздник' }, icon: partyToday },
  { id: 28, text: { ru: 'Волнуюсь, переживаю' }, icon: stressed },
  { id: 29, text: { ru: 'Без сил' }, icon: noPower },
  { id: 30, text: { ru: 'Я в домике' }, icon: house },
  { id: 31, text: { ru: 'Я в восторге' }, icon: exited },
  { id: 32, text: { ru: 'В предвкушении…' }, icon: anticipation },
  { id: 33, text: { ru: 'Жду' }, icon: time },
  { id: 34, text: { ru: 'Влюблен' }, icon: inLove },
  { id: 35, text: { ru: 'Мое сердце разбито' }, icon: brokenHeart },
  { id: 36, text: { ru: 'Лежу под одеялком' }, icon: blanket },
  { id: 37, text: { ru: 'Сегодня я ленивец' }, icon: dream }
]

export const getStatusById = (id: number | undefined) =>
  id ? STATUSES.find(item => item.id === id) || STATUSES[0] : STATUSES[0]

import { CHECK_CODE_REQUEST, CHECK_CODE_SUCCESS, CheckCodeRequestAction, CheckCodeSuccessAction } from '@redux/types'

export const checkCodeRequest = (phone: string, code: string): CheckCodeRequestAction => ({
  type: CHECK_CODE_REQUEST,
  payload: { phone, code }
})

export const checkCodeSuccess = (data: string): CheckCodeSuccessAction => ({
  type: CHECK_CODE_SUCCESS,
  payload: data
})

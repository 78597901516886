import { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'

import { FeedPostItem, FeedPostWithComments, InputMessage, ModalWrapper, SButton, Typography } from '@components'
import { useDataLayer } from '@redux/dataLayer'
import { selectChannel, selectCurrentUserId } from '@redux/selectors'
import { ChannelMessage, UserProfile } from '@types'

export const POST_PADDING = 18

const styles = StyleSheet.create({
  input: {
    bottom: 0,
    position: 'absolute'
  },
  option: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
    padding: 18,
    width: '100%'
  }
})
interface FeedTabProps {
  user: UserProfile | null
}

export const FeedTab = ({ user }: FeedTabProps) => {
  const { getChannelByUserId, deleteMessages } = useDataLayer()
  const [selectedPostId, setSelectedPostId] = useState<number | undefined>()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isPostCommentsVisible, setIsPostCommentsVisible] = useState(false)
  const channel = useSelector(selectChannel)
  const insets = useSafeAreaInsets()
  const currentUserId = useSelector(selectCurrentUserId)
  const showInput = user?._id === currentUserId

  useEffect(() => {
    if (user) {
      getChannelByUserId(user.userChatId)
    }
  }, [])

  if (!user || !channel) {
    return null
  }

  const deletePost = () => {
    setSelectedPostId(undefined)
    setIsModalVisible(false)

    if (selectedPostId) {
      deleteMessages({ chat_id: channel.id, messages: [selectedPostId] })
    }
  }

  const onPostPress = (post: ChannelMessage) => {
    setIsPostCommentsVisible(true)
    setSelectedPostId(post.message_id)
  }

  const onPostLongPress = (post: ChannelMessage) => {
    setIsModalVisible(true)
    setSelectedPostId(post.message_id)
  }

  const FeedContent = () => {
    if (!channel.history.length) {
      return (
        <View style={{ margin: 40, marginTop: 60 }}>
          <Typography f16 textAlign='center' color='#b6b6bb'>
            Пользователь еще не делал записей
          </Typography>
        </View>
      )
    }

    if (isPostCommentsVisible && selectedPostId) {
      return (
        <FeedPostWithComments
          onPress={() => {
            setIsPostCommentsVisible(false)
            setSelectedPostId(undefined)
          }}
          postId={selectedPostId}
          user={user}
        />
      )
    }

    return channel.history.map(post => (
      <FeedPostItem
        onPress={() => onPostPress(post)}
        onLongPress={() => onPostLongPress(post)}
        key={post.message_id}
        user={user}
        post={post}
      />
    ))
  }

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={showInput && { paddingBottom: insets.bottom + 40 }}
      >
        <FeedContent />
      </ScrollView>
      {showInput && (
        <InputMessage
          style={{ ...styles.input, paddingBottom: showInput && insets.bottom }}
          chatId={channel.id}
          userChatId={user.userChatId}
          selectedMessageId={selectedPostId}
          placeholder={selectedPostId ? 'Оставить комментарий...' : 'Новый пост...'}
        />
      )}
      <ModalWrapper isVisible={isModalVisible} close={() => setIsModalVisible(false)}>
        <SButton styleBtn={styles.option} onPress={deletePost}>
          <Typography f16 style={{ fontWeight: '700', color: '#F64A3F' }}>
            Удалить
          </Typography>
        </SButton>
      </ModalWrapper>
    </>
  )
}

export type StackParamList = {
  Main: undefined
  Model: undefined
}

export enum ScreenEnum {
  Main = 'Main',
  Model = 'Model'
}

export enum ModalUserSelectedOptions {
  MAIN = '',
  CHAT = 'chat',
  COMPLAINT = 'complaint',
  BLOCK = 'block'
}

/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { navigate } from '@navigation'
import { getCurrentUserSuccess } from '@redux/actions'
import { RESTORE_REQUEST, RestoreRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect

function* restore(action: RestoreRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.restore], action.payload.phone)
    const user = response.data
    yield put(getCurrentUserSuccess(user))
    navigate(action.payload.next)
  } catch (error: any) {
    console.log('restoreSaga', error)
  }
}

export function* restoreSaga() {
  yield takeLatest<RestoreRequestAction>(RESTORE_REQUEST, restore)
}

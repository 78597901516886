/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getUserChatsSuccess } from '@redux/actions'
import { GET_USER_CHATS_REQUEST, GetUserChatsRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { UserChat } from '@types'

type SagaEffects = CallEffect | PutEffect

function* getUserChats(action: GetUserChatsRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.getUserChats], action.payload)
    const chats: UserChat[] = response.data
    yield put(getUserChatsSuccess(chats))
  } catch (error: any) {
    console.log('getUserChatsSaga', error)
  }
}

export function* getUserChatsSaga() {
  yield takeLatest<GetUserChatsRequestAction>(GET_USER_CHATS_REQUEST, getUserChats)
}

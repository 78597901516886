export { default as adventure } from './adventure.svg'
export { default as angry } from './angry.svg'
export { default as anticipation } from './anticipation.svg'
export { default as attention } from './attention.svg'
export { default as badMood } from './bad-mood.svg'
export { default as blanket } from './blanket.svg'
export { default as brokenHeart } from './broken-heart.svg'
export { default as coffee } from './coffee.svg'
export { default as company } from './company.svg'
export { default as dance } from './dance.svg'
export { default as dream } from './dream.svg'
export { default as drink } from './drink.svg'
export { default as exited } from './exited.svg'
export { default as hand } from './hand.svg'
export { default as happy } from './happy.svg'
export { default as house } from './house.svg'
export { default as hugs } from './hugs.svg'
export { default as inLove } from './in-love.svg'
export { default as lonely } from './lonely.svg'
export { default as love } from './love.svg'
export { default as noPower } from './no-power.svg'
export { default as noStatus } from './noStatus.svg'
export { default as party } from './party.svg'
export { default as partyToday } from './party-today.svg'
export { default as playMood } from './play-mood.svg'
export { default as pleasantLife } from './pleasant-life.svg'
export { default as prey } from './prey.svg'
export { default as reading } from './reading.svg'
export { default as readyTalk } from './ready-talk.svg'
export { default as sad } from './sad.svg'
export { default as smile } from './smile.svg'
export { default as sport } from './sport.svg'
export { default as stressed } from './stressed.svg'
export { default as time } from './time.svg'
export { default as tired } from './tired.svg'
export { default as vacation } from './vacation.svg'
export { default as waiting } from './waiting.svg'
export { default as work } from './work.svg'

import { useEffect, useMemo, useState } from 'react'
import { KeyboardAvoidingView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { ArrowDownBlackSvg } from '@assets/icons'
import { Button, HeaderInfo, TextInput, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectAuthUser } from '@redux/selectors'
import { LIMITED_CODE_DICTS } from '@utils'

const styles = StyleSheet.create({
  chooseNumber: {
    alignItems: 'center',
    borderColor: '#a2a0ac',
    borderRadius: 40,
    borderWidth: 1,
    flexDirection: 'row',
    height: 52,
    justifyContent: 'center',
    marginRight: 8,
    width: 82
  },
  enterNumberWrapper: {
    flexDirection: 'row',
    marginTop: 8
  },
  error: {
    backgroundColor: '#f8d7da',
    borderRadius: 16,
    marginTop: 32,
    padding: 16
  },
  inputContainer: {
    flex: 1,
    height: 52
  },
  mainContainer: {
    flex: 1,
    height: '100%',
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: 50
  }
})

export const EnterPhone = () => {
  const { route, navigation } = useNavigator<'EnterPhone'>()
  const { registration } = useDataLayer()
  const authUser = useSelector(selectAuthUser)

  const dialCode = route.params?.dialCode ?? '+380'
  const phoneLength = route.params?.len ?? 9
  const [number, setNumber] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const isCodeLimited = useMemo(() => LIMITED_CODE_DICTS.some(dict => dict.dialCode === dialCode), [dialCode])

  const handleChangeNumber = (newNumber: string) => {
    setNumber(newNumber)
    setSubmitted(false)
  }

  const handleSubmit = () => {
    setSubmitted(true)
  }

  const normalizePhoneNumber = (phone: string) => phone.replace(/^\+/, '')

  const goToNewPage = () => {
    if (number.length !== phoneLength) {
      handleSubmit()
      return
    }

    registration({ phone: normalizePhoneNumber(dialCode + number) })
    setRedirect(true)
  }

  useEffect(() => {
    if (!redirect || !authUser.status) {
      return
    }

    let currentRoute: 'PasswordLogin' | 'EnterCode' | 'RestoreAccount' | undefined
    switch (authUser.status) {
      case 'done':
      case 'creating':
        currentRoute = 'PasswordLogin'
        break
      case 'registration':
        currentRoute = 'EnterCode'
        break
    }

    if (authUser.deleted) {
      currentRoute = 'RestoreAccount'
    }

    if (!currentRoute) {
      return
    }

    navigation.navigate(currentRoute, { phone: normalizePhoneNumber(dialCode + number) })
  }, [authUser, redirect])

  const goToChoseCountry = () => {
    navigation.navigate('ChoseCountry')
  }

  return (
    <View style={styles.mainContainer}>
      <HeaderInfo />
      <KeyboardAvoidingView behavior={isIOS ? 'padding' : undefined} style={{ flex: 1 }}>
        <View>
          <Typography f27 bold marginBottom={8} marginTop={52}>
            Укажите свой номер
          </Typography>
          <Typography f15 normal color='#535354' marginBottom={24}>
            Мы отправим код на введенный номер
          </Typography>
          <View style={styles.enterNumberWrapper}>
            <TouchableOpacity onPress={goToChoseCountry}>
              <View style={styles.chooseNumber}>
                <Typography f17 normal>
                  {' '}
                  {dialCode}
                </Typography>
                <ArrowDownBlackSvg />
              </View>
            </TouchableOpacity>
            <View style={styles.inputContainer}>
              <TextInput
                onChange={handleChangeNumber}
                error={submitted && number.length !== phoneLength}
                placeholder='Номер телефона'
                errorMessage='Неправильный номер телефона'
                maxLength={phoneLength}
                keyboardType='numeric'
              />
            </View>
          </View>
        </View>
        {isCodeLimited && (
          <View style={styles.error}>
            <Typography f18>
              К сожалению, на данный момент регистрация по номеру из указанной страны невозможна. Пожалуйста, укажите
              другой номер телефона либо cмените способ регистрации.
            </Typography>
          </View>
        )}
        <View style={{ flexGrow: 1 }} />
        <Button text='Продолжить' disabled={number.length !== phoneLength || isCodeLimited} onPress={goToNewPage} />
      </KeyboardAvoidingView>
    </View>
  )
}

import { PageAttributes } from '@navigation'
import { GENERAL_INFORMATION_REQUEST, GeneralInformationRequestAction } from '@redux/types'

export const generalInformationRequest = (
  name: string,
  dateOfBirth: string,
  sex: string,
  log: string,
  next: PageAttributes
): GeneralInformationRequestAction => ({
  type: GENERAL_INFORMATION_REQUEST,
  payload: {
    name,
    dateOfBirth,
    sex,
    log,
    next
  }
})

import {
  AllUsersState,
  CLEAR_USERS_SUCCESS,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GetAllUsersSuccessAction
} from '@redux/types'

const initialState: AllUsersState = []

export const allUsersReducer = (state = initialState, action: GetAllUsersSuccessAction) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return state
    case CLEAR_USERS_SUCCESS:
      return initialState
    case GET_ALL_USERS_SUCCESS:
      return action.payload
    default:
      return state
  }
}

import { useEffect, useState } from 'react'
import { StatusBar, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Checkbox, HeaderInfo, Select, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'
import { LGBT_TOLERANT, ORIENTATION } from '@utils'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

export const SexualOrientation = () => {
  const { route, navigation } = useNavigator<'SexualOrientation'>()
  const edit = route.params?.edit
  const { userUpdate } = useDataLayer()
  const currentUser = useSelector(selectCurrentUserData)

  const [hideInfoOption, setHideInfoOption] = useState(false)
  const [showOnlyOrientationOption, setShowOnlyOrientationOption] = useState(false)
  const [selectedGenderValue, setSelectedGenderValue] = useState(-1)
  const [selectedLGBTValue, setSelectedLGBTValue] = useState(-1)

  useEffect(() => {
    if (!currentUser) {
      return
    }

    setSelectedGenderValue(currentUser.profile.sexuality)
    setSelectedLGBTValue(currentUser.profile.lgbtTolerant)
    setHideInfoOption(!currentUser.profileVisability.sexuality)
    setShowOnlyOrientationOption(currentUser.profile.showOnlyYourSexuality)
  }, [currentUser])

  const goToReligionChildren = () => {
    if ((selectedGenderValue === -1 || selectedLGBTValue === -1) && !edit) {
      return navigation.navigate('ReligionChildren')
    }

    if (!currentUser) {
      return
    }

    userUpdate(
      {
        profileVisability: {
          ...currentUser.profileVisability,
          sexuality: !hideInfoOption,
          lgbtTolerant: !hideInfoOption
        },
        profile: {
          ...currentUser.profile,
          lgbtTolerant: selectedLGBTValue,
          sexuality: selectedGenderValue,
          showOnlyYourSexuality: showOnlyOrientationOption
        }
      },
      { page: edit ? 'InformationYourself' : 'ReligionChildren' }
    )
  }

  return (
    <View style={styles.mainWrapper}>
      <StatusBar backgroundColor='#F3F2F9' barStyle='dark-content' />

      <HeaderInfo showProgressBar={!edit} step={6} />
      <View style={styles.centerWrapper}>
        <Typography f27 bold marginTop={24} marginBottom={12}>
          Сексуальная{'\n'}ориентация
        </Typography>
        <Select
          options={ORIENTATION}
          onChange={option => setSelectedGenderValue(option.id as number)}
          value={selectedGenderValue}
          style={{ zIndex: 2 }}
        />
        <Typography f27 bold marginTop={40} marginBottom={12}>
          Отношение к ЛГБТ
        </Typography>

        <Select
          options={LGBT_TOLERANT}
          onChange={option => setSelectedLGBTValue(option.id as number)}
          value={selectedLGBTValue}
          style={{ zIndex: 1 }}
        />
      </View>
      <SubmitButton
        isEditing={edit}
        buttonLabelCondition={selectedGenderValue !== -1 || selectedLGBTValue !== -1}
        marginTop={21}
        onPress={goToReligionChildren}
        center
      >
        <View style={{ alignItems: 'center', marginBottom: 17 }}>
          <Checkbox
            label='Скрыть информацию в профиле'
            checked={hideInfoOption}
            onPress={() => setHideInfoOption(prev => !prev)}
          />
        </View>
        <Checkbox
          label='Показывать людей только своей ориентации'
          checked={showOnlyOrientationOption}
          onPress={() => setShowOnlyOrientationOption(prev => !prev)}
        />
      </SubmitButton>
    </View>
  )
}

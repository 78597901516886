import { useSelector } from 'react-redux'

import { selectCurrentUserData } from '@redux/selectors'
import { calculateAgeByDateOfBirth } from '@utils'

export const useCurrentUserAge = () => {
  const currentUser = useSelector(selectCurrentUserData)
  if (!currentUser) {
    return {}
  }

  const age = calculateAgeByDateOfBirth(currentUser.dof)
  const isAdult = age >= 18

  return {
    age,
    isAdult
  }
}

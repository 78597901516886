/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getAllDeletedUsersSuccess } from '@redux/actions'
import { GET_ALL_DELETED_USERS_REQUEST } from '@redux/types'
import { apiService } from '@services/apiService'
import { UserProfile } from '@types'

type SagaEffects = CallEffect | PutEffect

function* getAllDeletedUsers(): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.getAllDeletedUsers])
    const users: UserProfile[] = response.data
    yield put(getAllDeletedUsersSuccess(users || []))
  } catch (error: any) {
    console.log('getAllDeletedUsersSaga', error)
  }
}

export function* getAllDeletedUsersSaga() {
  yield takeLatest(GET_ALL_DELETED_USERS_REQUEST, getAllDeletedUsers)
}

import { FC, useMemo, useState } from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { BlurView } from 'expo-blur'

import { ScoreAttractionSvg, ScoreFriendSvg, ScoreLoveSvg, ScoreSexSvg } from '@assets/icons'
import { Button, CompatibilityBarItem, HeaderInfoProfile, SelectModal, ShareModal, Typography } from '@components'
import { useCurrentUserAge } from '@hooks'
import { useNavigator } from '@navigation'
import { selectCurrentUserData } from '@redux/selectors'

const styles = StyleSheet.create({
  blur: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
  blurContainer: {
    padding: 6
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 40,
    marginTop: 28,
    paddingHorizontal: 20,
    width: '100%'
  },
  buttonWrapper: {
    backgroundColor: '#fff',
    borderRadius: 55,
    borderWidth: 1,
    height: '100%',
    justifyContent: 'center',
    marginBottom: 40,
    maxHeight: 50,
    width: '100%'
  },
  input: {
    borderColor: 'black',
    borderRadius: 40,
    borderWidth: 1,
    height: 55,
    justifyContent: 'center',
    marginBottom: 20,
    paddingLeft: 15
  },
  mainWrapper: {
    backgroundColor: 'white',
    borderRadius: 7,
    marginBottom: 20,
    marginHorizontal: 10,
    marginTop: 10,
    maxWidth: '100%',
    padding: 15
  },
  scoreWrapper: {
    alignItems: 'center',
    borderRadius: 23,
    height: 133,
    justifyContent: 'center',
    marginBottom: 20,
    width: 108
  },
  singleScore: {
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'center'
  }
})

interface CompInfo {
  iconTitle: string
  label: string
  icon: typeof ScoreAttractionSvg
}

interface CompatibilityInfoItemProps {
  value: number | undefined
  text: string
  icon: FC
}

type CompMap = {
  [K in CompatibilityTypes]: CompInfo
}

const compMap: CompMap = {
  Friendship: {
    iconTitle: 'В дружбе',
    icon: ScoreFriendSvg,
    label: 'Дружба, друзья по интересам'
  },
  LoveMarriage: { iconTitle: 'В любви', icon: ScoreLoveSvg, label: 'Любовь-брак' }
}

type CompatibilityTypes = 'Friendship' | 'LoveMarriage'

export const DateCompResult = () => {
  const user = useSelector(selectCurrentUserData)
  const { isAdult } = useCurrentUserAge()

  const { route } = useNavigator<'DateCompResult'>()
  const { comp, date } = route.params
  const [currentCompType, setCurrentCompType] = useState<CompatibilityTypes>('LoveMarriage')
  const isFriendship = currentCompType === 'Friendship'
  const isLoveMarriage = currentCompType === 'LoveMarriage'

  const [isModalVisible, setModalVisible] = useState(false)
  const [isShareVisible, setShareVisible] = useState(false)

  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)
  const closeShareModal = () => setShareVisible(false)
  const openShareModal = () => setShareVisible(true)

  const handleTypeChange = (newType: CompatibilityTypes) => {
    setCurrentCompType(newType)
    hideModal()
  }

  const personDescription = useMemo(() => {
    return {
      title: isFriendship ? 'в дружбе' : 'в любви',
      mainText: isFriendship ? comp.metadata.partner_text_friendship : comp.metadata.partner_text_love,
      personText: isFriendship ? comp.metadata.planets_friendship : comp.metadata.planets_love
    }
  }, [currentCompType])

  const availableCompOptions = (Object.keys(compMap) as CompatibilityTypes[]).map(id => ({ ...compMap[id], id }))

  const CompatibilityInfoItem = ({ value, icon, text }: CompatibilityInfoItemProps) =>
    value ? (
      <View style={styles.scoreWrapper}>
        <CompatibilityBarItem value={value} Icon={icon} fontSize={14} textColor='black' />
        <Typography f14 semibold marginTop={8} color='#2C2C2C'>
          {text}
        </Typography>
      </View>
    ) : null

  return (
    <>
      <HeaderInfoProfile username={user?.login} />
      <ScrollView
        style={styles.mainWrapper}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 20 }}
      >
        <View style={styles.input}>
          <Text style={{ fontSize: 16 }}>{date}</Text>
        </View>
        <View style={styles.singleScore}>
          <CompatibilityInfoItem value={comp.attraction} icon={ScoreAttractionSvg} text='Притяжение' />
          {isFriendship && <CompatibilityInfoItem value={comp.friendship} icon={ScoreFriendSvg} text='В дружбе' />}
          {isLoveMarriage && isAdult && <CompatibilityInfoItem value={comp.sexual} icon={ScoreSexSvg} text='В сексе' />}
          {isLoveMarriage && <CompatibilityInfoItem value={comp.love} icon={ScoreLoveSvg} text='В любви' />}
        </View>
        <TouchableOpacity style={styles.buttonWrapper} onPress={showModal}>
          <Typography f14 medium textAlign='center' color='#18181C'>
            Другие совместимости
          </Typography>
        </TouchableOpacity>
        {personDescription.mainText && (
          <>
            <Typography f16 semibold marginBottom={12} color='#7b7b7d'>
              Описание личности
            </Typography>
            <Typography f15 normal marginBottom={40} color='#525253'>
              {personDescription.mainText}
            </Typography>
          </>
        )}
        {personDescription.personText && (
          <>
            <Typography f16 semibold marginBottom={12} color='#7b7b7d'>
              Описание {personDescription.title}
            </Typography>
            <View style={styles.blurContainer}>
              <Typography f15 normal marginBottom={40} color='#525253'>
                {personDescription.personText}
              </Typography>
              <BlurView intensity={12} style={styles.blur} />
            </View>
          </>
        )}
        <Typography f18 fontWeight='500' color='#000'>
          Пригласи друга в connect чтобы узнать больше
        </Typography>
        <Typography f15 fontWeight='400' lineHeight={1.5} marginTop={10} opacity={0.8}>
          Процент совместимости может измениться после заполнения анкеты
        </Typography>
        {isModalVisible && (
          <SelectModal<any, CompatibilityTypes>
            selected={currentCompType}
            options={availableCompOptions}
            renderOption={(item, isSelected) => (
              <>
                <item.icon fill={isSelected ? '#8c8c8c' : '#2c2c2c'} />
                <Typography f16 medium textAlign='center' color={isSelected ? '#8c8c8c' : '#2c2c2c'}>
                  {item.label}
                </Typography>
              </>
            )}
            onClose={hideModal}
            onSelect={option => handleTypeChange(option.id)}
          />
        )}
        <ShareModal isVisible={isShareVisible} close={closeShareModal} />
        <Button style={styles.button} text='Пригласить в Connect' onPress={openShareModal} />
      </ScrollView>
    </>
  )
}

import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useToast } from '@components'
import { useDataLayer } from '@redux/dataLayer'
import { selectNotificationState } from '@redux/selectors'

export const useAppToast = () => {
  const { clearNotification } = useDataLayer()
  const notificationState = useSelector(selectNotificationState)
  const { showToast } = useToast()

  useEffect(() => {
    if (!notificationState) {
      return
    }

    if (
      notificationState.type === 'like' ||
      // TODO: PROVIDE LOGIC: NOT SHOW IN CURRENT CHAT if (notificationState.data.chatId !== navigation.params.chatId)
      notificationState.type === 'message' ||
      notificationState.type === 'error'
    ) {
      showToast(notificationState.type, notificationState.text, notificationState.image)
    }

    clearNotification()
  }, [notificationState])
}

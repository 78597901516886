import { useEffect, useState } from 'react'
import { Pressable, ScrollView, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'

import { CloseModalSvg } from '@assets/icons'
import { ModalWrapper, TextInput, Typography } from '@components'

const styles = StyleSheet.create({
  input: {
    flex: 1
  },
  inputWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    flexGrow: 1,
    gap: 8,
    width: '100%'
  },
  option: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8,
    height: 58,
    justifyContent: 'center'
  }
})

interface Option {
  id: string | number
  slug: string
  label: string
}

interface SelectFilterModalProps {
  isModalOpen: boolean
  options: Option[]
  onClose: () => void
  onSelect: (option: Option) => void
  style?: ViewStyle
}

export const SelectFilterModal = ({ isModalOpen, options, onClose, onSelect, style }: SelectFilterModalProps) => {
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options)
  const [filterString, setFilterString] = useState('')

  const close = () => {
    onClose()
    setFilterString('')
  }

  useEffect(() => {
    const filtered = options.filter(option => option.label.toLowerCase().includes(filterString.toLowerCase()))
    setFilteredOptions(filtered)
  }, [filterString, options])

  const renderFilteredOptions = () => {
    return filteredOptions.map(option => (
      <TouchableOpacity key={option.id} style={styles.option} onPress={() => onSelect(option)}>
        <Typography f18>{option.label}</Typography>
      </TouchableOpacity>
    ))
  }

  return (
    <ModalWrapper contentStyle={style} isVisible={isModalOpen} close={close} hideCloseButton>
      <View style={styles.inputWrapper}>
        <TextInput
          style={styles.input}
          autoCapitalize='none'
          placeholder='Искать...'
          onChange={text => setFilterString(text)}
        />
        <Pressable onPress={close}>
          <CloseModalSvg />
        </Pressable>
      </View>
      {filteredOptions.length ? (
        <ScrollView showsVerticalScrollIndicator={false}>{renderFilteredOptions()}</ScrollView>
      ) : (
        <Typography marginTop={16} f22 normal textAlign='center'>
          Ничего не найдено
        </Typography>
      )}
    </ModalWrapper>
  )
}

import { TouchableOpacity, View } from 'react-native'

import { BackSvg } from '@assets/icons'
import { ProgressBar } from '@components'
import { useNavigator } from '@navigation'

interface HeaderInfoProps {
  showProgressBar?: boolean
  step?: number
}

export const HeaderInfo = ({ showProgressBar, step }: HeaderInfoProps) => {
  const { navigation } = useNavigator()

  const goToHome = () => {
    navigation.goBack()
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      <TouchableOpacity onPress={goToHome}>
        <BackSvg />
      </TouchableOpacity>
      {showProgressBar && step && <ProgressBar step={step} />}
    </View>
  )
}

import { useEffect, useState } from 'react'
import { KeyboardAvoidingView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { HeaderInfo, TextArea, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectUserProfile } from '@redux/selectors'
import { SubmitButton } from '@screens'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1
  },
  container: {
    flex: 1,
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

export const ChangeTellYourself = () => {
  const { route } = useNavigator<'Location'>()
  const { userUpdate } = useDataLayer()
  const edit = route.params?.edit
  const userProfile = useSelector(selectUserProfile)
  const [text, setText] = useState('')

  useEffect(() => {
    if (userProfile) {
      setText(userProfile.about)
    }
  }, [userProfile])

  const goToCongratulations = () => {
    userUpdate(
      {
        profile: {
          ...userProfile,
          about: text
        }
      },
      { page: 'InformationYourself' }
    )
  }

  return (
    <View style={styles.container}>
      <HeaderInfo showProgressBar={!edit} step={15} />
      <KeyboardAvoidingView behavior={isIOS ? 'padding' : undefined} style={{ flex: 1 }}>
        <View style={styles.centerWrapper}>
          <Typography f27 bold marginTop={24} marginBottom={7}>
            Расскажите о себе
          </Typography>
          <Typography f15 normal marginBottom={20}>
            Напишите короткую информацию о себе, до 250 символов
          </Typography>
          <TextArea text={text} setText={t => setText(t)} />
        </View>
        <SubmitButton isEditing={edit} label='Готово' onPress={goToCongratulations} />
      </KeyboardAvoidingView>
    </View>
  )
}

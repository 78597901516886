import { StyleSheet, View } from 'react-native'

import { BeautySvg, BusinessSvg, CompLoveSvg, FriendSvg, SexSvg } from '@assets/icons'
import { CompatibilityBarItem } from '@components/Model'
import { CompatibilityTypes } from '@types'

const styles = StyleSheet.create({
  main: {
    backgroundColor: '#B9B9B980',
    borderRadius: 100,
    flexDirection: 'row',
    gap: 8,
    padding: 5
  }
})

interface CompatibilityBarProps {
  type?: CompatibilityTypes | ''
  compPoints?: number
  attraction?: number
  sex?: number
}

// TODO: Describe type && improve logic for reuse it in CompatibilityTab component
const iconsMap: any = {
  BusinessPartners: BusinessSvg,
  Friendship: FriendSvg,
  LoveMarriage: CompLoveSvg,
  SexyAdventures: SexSvg,
  Commercial: SexSvg
}

export const CompatibilityBar = ({ type = '', compPoints, sex, attraction }: CompatibilityBarProps) => (
  <View style={styles.main}>
    {!!attraction && <CompatibilityBarItem value={attraction} Icon={BeautySvg} />}
    {!!sex && <CompatibilityBarItem value={sex} Icon={SexSvg} />}
    {!!compPoints && <CompatibilityBarItem value={compPoints} Icon={iconsMap[type]} />}
  </View>
)

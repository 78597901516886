import { StyleSheet, View } from 'react-native'

import { MailSvg } from '@assets/icons'
import { Typography } from '@components'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 26
  }
})

interface EmptyMessageProps {
  mainTitle: string
  subTitle: string
}

export const EmptyMessage = ({ mainTitle, subTitle }: EmptyMessageProps) => (
  <View style={styles.container}>
    <MailSvg width={175} height={175} />
    <Typography f17 semibold>
      {mainTitle}
    </Typography>
    <Typography f14 normal textAlign='center' color='#707072' marginTop={12}>
      {subTitle}
    </Typography>
  </View>
)

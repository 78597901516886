/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getCurrentUserSuccess } from '@redux/actions'
import { UNBLOCK_USER_REQUEST, UnblockUserRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { UserProfile } from '@types'

type SagaEffects = CallEffect | PutEffect

function* unblockUser(action: UnblockUserRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.unblockUser], action.payload.userId)
    const currentUser: UserProfile = response.data
    yield put(getCurrentUserSuccess(currentUser))
  } catch (error: any) {
    console.log('unblockUserSaga', error)
  }
}

export function* unblockUserSaga() {
  yield takeLatest<UnblockUserRequestAction>(UNBLOCK_USER_REQUEST, unblockUser)
}

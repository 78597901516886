import { useEffect } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { ChatList, ConnectRequests, EmptyMessage } from '@components'
import { useChatList } from '@hooks'
import { useDataLayer } from '@redux/dataLayer'
import { selectMyLikes } from '@redux/selectors'

export const MyTelegram = () => {
  const { getMyLikes, getAllDeletedUsers } = useDataLayer()
  const requests = useSelector(selectMyLikes)
  const { chats } = useChatList()

  useEffect(() => {
    getMyLikes()
    getAllDeletedUsers()
  }, [])

  if (!requests.length && !chats.length) {
    return (
      <EmptyMessage
        mainTitle='Начните общение в Connect!'
        subTitle='Отправьте запрос пользователю и общайтесь в Connect'
      />
    )
  }

  return (
    <View style={{ padding: 24, flex: 1 }}>
      <ConnectRequests requests={requests} />
      <ChatList chats={chats} />
    </View>
  )
}

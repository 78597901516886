import { ReactNode } from 'react'
import { Pressable, StyleSheet, TouchableOpacityProps, View } from 'react-native'

import { Typography } from '@components'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: '#2f2f2f',
    borderRadius: 55,
    cursor: 'pointer',
    flexDirection: 'row',
    gap: 10,
    height: 60,
    justifyContent: 'center',
    marginBottom: 24,
    opacity: 1,
    paddingHorizontal: 24,
    width: '100%'
  },
  text: {
    fontWeight: '700'
  }
})

interface ButtonProps extends TouchableOpacityProps {
  text?: string | false
  iconLeft?: ReactNode
  isView?: boolean
}

export const Button = ({ disabled, onPress, text, style, iconLeft, isView }: ButtonProps) => {
  const ButtonComponent = isView ? View : Pressable

  const stylesArray = [styles.container, style, disabled && { backgroundColor: '#e8e7ed' }]

  const customStyles = isView
    ? stylesArray
    : ({ pressed }: { pressed: boolean }) => [...stylesArray, pressed && { opacity: 0.5 }]

  return (
    <ButtonComponent disabled={disabled} onPress={onPress} style={customStyles}>
      {iconLeft}
      {text && (
        <Typography f16 style={[styles.text, { color: disabled ? '#a9a8ac' : '#fff' }]} disabled={disabled}>
          {text}
        </Typography>
      )}
    </ButtonComponent>
  )
}

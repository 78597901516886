import {
  CLEAR_USERS_SUCCESS,
  ClearUsersAction,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GetAllUsersRequestAction,
  GetAllUsersSuccessAction
} from '@redux/types'
import { UserProfile } from '@types'

export const getAllUsersRequest = (login: string, filter: any): GetAllUsersRequestAction => {
  return {
    type: GET_ALL_USERS_REQUEST,
    payload: {
      login,
      filter
    }
  }
}

export const getAllUsersSuccess = (users: UserProfile[]): GetAllUsersSuccessAction => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: users
  }
}

export const clearAllUsersRequest = (): ClearUsersAction => {
  return {
    type: CLEAR_USERS_SUCCESS
  }
}

import { GET_USER_CHATS_REQUEST, GET_USER_CHATS_SUCCESS, UserChatActionTypes, UserChatsState } from '@redux/types'

const initialState: UserChatsState = []

export const chatsReducer = (state = initialState, action: UserChatActionTypes): UserChatsState => {
  switch (action.type) {
    case GET_USER_CHATS_REQUEST:
      return state
    case GET_USER_CHATS_SUCCESS:
      return action.payload
    default:
      return state
  }
}

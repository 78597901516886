import moment from 'moment'

export const formatDateForChat = (timestamp: string) => {
  if (!timestamp) {
    return ''
  }

  const messageDate = new Date(timestamp)
  const currentDate = new Date()

  const oneDay = 24 * 60 * 60 * 1000

  const daysBetween = Math.round((currentDate.getTime() - messageDate.getTime()) / oneDay)

  if (daysBetween < 1) {
    return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  } else if (daysBetween >= 1 && daysBetween < 7) {
    const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
    return days[messageDate.getDay()]
  } else {
    return moment.utc(messageDate).format('DD.MM.YYYY')
  }
}

export const calculateAgeByDateOfBirth = (dateOfBirth: string): number =>
  moment().diff(moment(dateOfBirth, 'DD.MM.YYYY'), 'years')

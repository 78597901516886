import { ReactNode } from 'react'
import { KeyboardAvoidingView, Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import Modal from 'react-native-modal'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { CloseModalSvg } from '@assets/icons'
import { isIOS, screenHeight, screenWidth } from '@components/utils'

interface ModalWrapperProps {
  children: ReactNode
  isVisible: boolean
  close: () => void
  contentStyle?: ViewStyle
  hideCloseButton?: boolean
  middle?: boolean
}

const styles = StyleSheet.create({
  close: {
    position: 'absolute',
    right: 14,
    top: 14,
    zIndex: 1
  },
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    marginHorizontal: 'auto',
    width: screenWidth
  },
  content: {
    alignItems: 'center',
    backgroundColor: '#F3F2F8',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    justifyContent: 'flex-end',
    margin: 0,
    paddingHorizontal: 26,
    paddingTop: 20,
    width: '100%'
  }
})

export const ModalWrapper = ({
  children,
  isVisible,
  close,
  contentStyle,
  hideCloseButton,
  middle
}: ModalWrapperProps) => {
  const insets = useSafeAreaInsets()

  return (
    <Modal
      isVisible={isVisible}
      backdropColor='#000'
      backdropOpacity={0.7}
      style={{ margin: 0 }}
      onSwipeComplete={close}
      onBackdropPress={close}
      useNativeDriverForBackdrop
      swipeDirection={['down']}
      propagateSwipe
      avoidKeyboard
      deviceHeight={screenHeight}
    >
      <KeyboardAvoidingView
        behavior={isIOS ? 'padding' : undefined}
        style={[styles.container, middle && { justifyContent: 'center' }]}
      >
        <View style={[styles.content, { paddingBottom: insets.bottom }, middle && { borderRadius: 24 }, contentStyle]}>
          {!hideCloseButton && (
            <Pressable style={styles.close} onPress={close}>
              <CloseModalSvg />
            </Pressable>
          )}
          {children}
        </View>
      </KeyboardAvoidingView>
    </Modal>
  )
}

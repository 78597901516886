import { ProfileOptions } from '@utils'

export const HOBBIES: ProfileOptions = [
  {
    id: '0',
    slug: 'handmade',
    label: 'Handmade',
    children: [
      { id: '1_0', slug: 'handmade', label: 'Handmade' },
      { id: '1_1', slug: 'diamondMosaic', label: 'Алмазная мозайка' },
      { id: '1_2', slug: 'batik', label: 'Батик' },
      { id: '1_3', slug: 'beadCraft', label: 'Бисероплетение' },
      { id: '1_4', slug: 'brooches', label: 'Броши' },
      { id: '1_5', slug: 'pyrography', label: 'Выжигание' },
      { id: '1_6', slug: 'crystalGrowing', label: 'Выращивание кристаллов' },
      { id: '1_7', slug: 'knitting', label: 'Вязание' },
      { id: '1_8', slug: 'pottery', label: 'Гончарное дело' },
      { id: '1_9', slug: 'decorativeCanning', label: 'Декоративное консервирование' },
      { id: '1_10', slug: 'decoupage', label: 'Декупаж' },
      { id: '1_11', slug: 'candleMaking', label: 'Изготовление свечей' },
      { id: '1_12', slug: 'jewelryMaking', label: 'Изготовление украшений' },
      { id: '1_13', slug: 'isography', label: 'Изографика' },
      { id: '1_14', slug: 'isonit', label: 'Изонить' },
      { id: '1_15', slug: 'ikebana', label: 'Икебана' },
      { id: '1_16', slug: 'kanzashi', label: 'Канзаши' },
      { id: '1_17', slug: 'carving', label: 'Карвинг - резьба' },
      { id: '1_18', slug: 'customizing', label: 'Кастомайзинг' },
      { id: '1_19', slug: 'quilling', label: 'Квиллинг' },
      { id: '1_20', slug: 'rugWeaving', label: 'Ковроплетение' },
      { id: '1_21', slug: 'lace', label: 'Кружево' },
      { id: '1_22', slug: 'blacksmithing', label: 'Кузнечное дело' },
      { id: '1_23', slug: 'dolls', label: 'Куклы' },
      { id: '1_24', slug: 'wickerwork', label: 'Лозоплетение' },
      { id: '1_25', slug: 'macrame', label: 'Макраме' },
      { id: '1_26', slug: 'soapMaking', label: 'Мыловарение' },
      { id: '1_27', slug: 'origami', label: 'Оригами' },
      { id: '1_28', slug: 'patchwork', label: 'Пэчворк' },
      { id: '1_29', slug: 'gingerbreadPainting', label: 'Роспись пряников' },
      { id: '1_30', slug: 'sweetDesign', label: 'Свит-дизайн' },
      { id: '1_31', slug: 'scrapbooking', label: 'Скрапбукинг' },
      { id: '1_32', slug: 'buildingConstructionSets', label: 'Собирать конструктор' },
      { id: '1_33', slug: 'creatingMetalProducts', label: 'Создание изделий из металла' },
      { id: '1_34', slug: 'creatingMiniatureCarModels', label: 'Создание мини-копий машин' },
      { id: '1_35', slug: 'stringArt', label: 'Стринг Арт' },
      { id: '1_36', slug: 'taxidermy', label: 'Таксидермия' },
      { id: '1_37', slug: 'twisting', label: 'Твистинг' },
      { id: '1_38', slug: 'topiary', label: 'Топиарии' },
      { id: '1_39', slug: 'felting', label: 'Фелтинг' },
      { id: '1_40', slug: 'sewing', label: 'Шитье' }
    ]
  },
  {
    id: '1',
    slug: 'painting',
    label: 'Рисование',
    children: [
      { id: '2_0', slug: 'painting', label: 'Рисование' },
      { id: '2_1', slug: 'bodyArt', label: 'Бодиарт' },
      { id: '2_2', slug: 'bodyPainting', label: 'Бодипейнтинг' },
      { id: '2_3', slug: 'stainedGlassPainting', label: 'Витражная живопись' },
      { id: '2_4', slug: 'graffiti', label: 'Графитти' },
      { id: '2_5', slug: 'doodlingAndZentangle', label: 'Дудлинг и зентангл' },
      { id: '2_6', slug: 'painting', label: 'Живопись' },
      { id: '2_7', slug: 'iconography', label: 'Иконография' },
      { id: '2_8', slug: 'iconPainting', label: 'Иконопись' },
      { id: '2_9', slug: 'calligraphy', label: 'Каллиграфия' },
      { id: '2_10', slug: 'blotPainting', label: 'Кляксография' },
      { id: '2_11', slug: 'marbling', label: 'Марблинг' },
      { id: '2_12', slug: 'mehndi', label: 'Мехенди' },
      { id: '2_13', slug: 'mosaic', label: 'Мозаика' },
      { id: '2_14', slug: 'monotype', label: 'Монотипия' },
      { id: '2_15', slug: 'threadGraphics', label: 'Ниткография' },
      { id: '2_16', slug: 'rightHemisphereDrawing', label: 'Правополушарное рисование' },
      { id: '2_17', slug: 'sandDrawing', label: 'Рисование песком' },
      { id: '2_18', slug: 'glassPainting', label: 'Роспись стекла' },
      { id: '2_19', slug: 'sketching', label: 'Скетчинг' },
      { id: '2_20', slug: 'temariArt', label: 'Темари арт' },
      { id: '2_21', slug: 'facePainting', label: 'Фейспеинтинг' },
      { id: '2_22', slug: 'freezlight', label: 'Фризлайт' },
      { id: '2_23', slug: 'ebru', label: 'Эбру' },
      { id: '2_24', slug: 'encaustic', label: 'Энкаустика' }
    ]
  },
  {
    id: '2',
    slug: 'poemsAndHandWritting',
    label: 'Поэзия и писательство',
    children: [{ id: '2_0', slug: 'poemsAndHandWritting', label: 'Поэзия и писательство' }]
  },
  {
    id: '3',
    slug: 'postScrolling',
    label: 'Посткроссинг',
    children: [{ id: '3', slug: 'postScrolling', label: 'Посткроссинг' }]
  },
  {
    id: '4',
    slug: 'artsAndCulture',
    label: 'Искусство и Культура',
    children: [
      { id: '4_0', slug: 'artsAndCulture', label: 'Искусство и Культура' },
      { id: '4_1', slug: 'ballet', label: 'Балет' },
      { id: '4_2', slug: 'exhibitions', label: 'Выставки' },
      { id: '4_3', slug: 'cinema', label: 'Кино' },
      { id: '4_4', slug: 'concertsShows', label: 'Концерт, шоу' },
      { id: '4_5', slug: 'musical', label: 'Мюзикл' },
      { id: '4_6', slug: 'writing', label: 'Писательство' },
      { id: '4_7', slug: 'artWorks', label: 'Произведения искусства' },
      { id: '4_8', slug: 'sculptures', label: 'Скульптуры' },
      { id: '4_9', slug: 'theatre', label: 'Театр' },
      { id: '4_10', slug: 'festivals', label: 'Фестивали' },
      { id: '4_11', slug: 'circus', label: 'Цирк' },
      { id: '4_12', slug: 'tours', label: 'Экскурссии' }
    ]
  },
  {
    id: '5',
    slug: 'tvShows',
    label: 'Телешоу',
    children: [
      { id: '5_0', slug: 'tvShows', label: 'Телешоу' },
      { id: '5_1', slug: 'hellKitchen', label: 'Адская Кухня' },
      { id: '5_2', slug: 'pregnantAt16', label: 'Беременна в 16' },
      { id: '5_3', slug: 'battleOfTheClairvoyants', label: 'Битва экстрасенсов' },
      { id: '5_4', slug: 'returnToMeBeauty', label: 'Верните мне красоту' },
      { id: '5_5', slug: 'theVoice', label: 'Голос' },
      { id: '5_6', slug: 'whoWantsToBeAMillionaire', label: 'Кто хочет стать миллионером' },
      { id: '5_7', slug: 'betterThanEveryone', label: 'Лучше всех' },
      { id: '5_8', slug: 'masterChef', label: 'Мастер Шеф' },
      { id: '5_9', slug: 'eagleAndTails', label: 'Орел и решка' },
      { id: '5_10', slug: 'dances', label: 'Танцы' },
      { id: '5_11', slug: 'whatWhereWhen', label: 'Что, где, когда' },
      { id: '5_12', slug: 'talentShows', label: 'Шоу талантов' },
      { id: '5_13', slug: 'bodyShaming', label: 'Я стесняюсь своего тела' }
    ]
  },
  {
    id: '6',
    slug: 'humor',
    label: 'Юмор',
    children: [
      { id: '6_0', slug: 'humor', label: 'Юмор' },
      { id: '6_1', slug: 'irony', label: 'Ирония' },
      { id: '6_2', slug: 'parody', label: 'Пародия' },
      { id: '6_3', slug: 'sarcasm', label: 'Сарказм' },
      { id: '6_4', slug: 'standUpComedy', label: 'Стендап' },
      { id: '6_5', slug: 'darkHumor', label: 'Черный юмор' }
    ]
  },
  {
    id: '7',
    slug: 'music',
    label: 'Музыка',
    children: [
      { id: '7_0', slug: 'music', label: 'Музыка' },
      { id: '7_1', slug: 'sampling', label: 'Cэмплирование' },
      { id: '7_2', slug: 'originalSongs', label: 'Авторские песни' },
      { id: '7_3', slug: 'virtualDJing', label: 'Виртуальный диджеинг' },
      { id: '7_4', slug: 'soundRecording', label: 'Звукозапись' },
      { id: '7_5', slug: 'folkSinging', label: 'Народное пение' },
      { id: '7_6', slug: 'singing', label: 'Пение' },
      { id: '7_7', slug: 'musicAndRhythmCreation', label: 'Создание музыки и ритмов' },
      { id: '7_8', slug: 'songLyricsAndPoetry', label: 'Тексты и стихи для песен' }
    ]
  },
  {
    id: '8',
    slug: 'musicalInstruments',
    label: 'Игра на музыкальных инструментах',
    children: [
      { id: '8_0', slug: 'musicalInstruments', label: 'Игра на музыкальных инструментах' },
      { id: '8_1', slug: 'windInstruments', label: 'Духовые' },
      { id: '8_2', slug: 'bassClarinet', label: 'Бас-кларнет' },
      { id: '8_3', slug: 'recorder', label: 'Блокфлейта' },
      { id: '8_4', slug: 'frenchHorn', label: 'Валторна' },
      { id: '8_5', slug: 'whistle', label: 'Вистл' },
      { id: '8_6', slug: 'helicon', label: 'Геликон' },
      { id: '8_7', slug: 'oboe', label: 'Гобой' },
      { id: '8_8', slug: 'whistle', label: 'Дудка' },
      { id: '8_9', slug: 'duduk', label: 'Дудука' },
      { id: '8_10', slug: 'zurna', label: 'Зурна' },
      { id: '8_11', slug: 'clarinet', label: 'Кларнет' },
      { id: '8_12', slug: 'clarinetPiccolo', label: 'Кларнет-пикколо' },
      { id: '8_13', slug: 'cornet', label: 'Корнет' },
      { id: '8_14', slug: 'recorder', label: 'Рекордер' },
      { id: '8_15', slug: 'saxophone', label: 'Саксофон' },
      { id: '8_16', slug: 'sousaphone', label: 'Сузафон' },
      { id: '8_17', slug: 'trombone', label: 'Тромбон' },
      { id: '8_18', slug: 'trumpet', label: 'Труба' },
      { id: '8_19', slug: 'tuba', label: 'Туба' },
      { id: '8_20', slug: 'bassoon', label: 'Фагот' },
      { id: '8_21', slug: 'flute', label: 'Флейта' },
      { id: '8_22', slug: 'piccoloFlute', label: 'Флейта-пикколо' },
      { id: '8_23', slug: 'flugelhorn', label: 'Флюгельгорн' },
      { id: '8_24', slug: 'shawm', label: 'Шалмей' },
      { id: '8_25', slug: 'keyboardInstruments', label: 'Клавишные' },
      { id: '8_26', slug: 'midiKeyboard', label: 'Midi-клавиатура' },
      { id: '8_27', slug: 'accordion', label: 'Аккордеон' },
      { id: '8_28', slug: 'uprightPiano', label: 'Акустическое пианино' },
      { id: '8_29', slug: 'bayan', label: 'Баян' },
      { id: '8_30', slug: 'vocoder', label: 'Вокодер' },
      { id: '8_31', slug: 'harmonica', label: 'Гармонь' },
      { id: '8_32', slug: 'harpsichord', label: 'Клавесин' },
      { id: '8_33', slug: 'clavichord', label: 'Клавикорд' },
      { id: '8_34', slug: 'clavinet', label: 'Клавинет' },
      { id: '8_35', slug: 'melodica', label: 'Мелодика' },
      { id: '8_36', slug: 'organ', label: 'Орган' },
      { id: '8_37', slug: 'piano', label: 'Пианино' },
      { id: '8_38', slug: 'grandPiano', label: 'Рояль' },
      { id: '8_39', slug: 'synthesizer', label: 'Синтезатор' },
      { id: '8_40', slug: 'pianoFort', label: 'Фортепьяно' },
      { id: '8_41', slug: 'digitalPiano', label: 'Цифровое пианино' },
      { id: '8_42', slug: 'celesta', label: 'Челеста' },
      { id: '8_43', slug: 'electricPiano', label: 'Электропиано' },
      { id: '8_44', slug: 'mechanicalInstruments', label: 'Механические' },
      { id: '8_45', slug: 'veltMinion', label: 'Вельте-миньон' },
      { id: '8_46', slug: 'orchestrion', label: 'Оркестрион' },
      { id: '8_47', slug: 'pianola', label: 'Пианола' },
      { id: '8_48', slug: 'phonola', label: 'Фонола' },
      { id: '8_49', slug: 'barrelOrgan', label: 'Шарманка' },
      { id: '8_50', slug: 'stringInstruments', label: 'Струнные' },
      { id: '8_51', slug: 'viola', label: 'Альт' },
      { id: '8_52', slug: 'harp', label: 'Арфа' },
      { id: '8_53', slug: 'balalaika', label: 'Балалайка' },
      { id: '8_54', slug: 'bandura', label: 'Бандура' },
      { id: '8_55', slug: 'banjo', label: 'Банжо' },
      { id: '8_56', slug: 'cello', label: 'Виолончель' },
      { id: '8_57', slug: 'gadulka', label: 'Гадулука' },
      { id: '8_58', slug: 'guitar', label: 'Гитара' },
      { id: '8_59', slug: 'woodenFlute', label: 'Гудок' },
      { id: '8_60', slug: 'gusle', label: 'Гусле' },
      { id: '8_61', slug: 'gusli', label: 'Гусли' },
      { id: '8_62', slug: 'dombra', label: 'Домбра' },
      { id: '8_63', slug: 'domra', label: 'Домра' },
      { id: '8_64', slug: 'kemanche', label: 'Кеманча' },
      { id: '8_65', slug: 'kobza', label: 'Кобза' },
      { id: '8_66', slug: 'kobyz', label: 'Кобыз' },
      { id: '8_67', slug: 'komuz', label: 'Комуз' },
      { id: '8_68', slug: 'doubleBass', label: 'Контрабас' },
      { id: '8_69', slug: 'koto', label: 'Кото' },
      { id: '8_70', slug: 'kylak', label: 'Кыяк' },
      { id: '8_71', slug: 'lute', label: 'Лютня' },
      { id: '8_72', slug: 'morinKhuur', label: 'Морин хуур' },
      { id: '8_73', slug: 'nickelHarp', label: 'Никельхарпа' },
      { id: '8_74', slug: 'rebec', label: 'Ребаб' },
      { id: '8_75', slug: 'saz', label: 'Саз' },
      { id: '8_76', slug: 'sarangi', label: 'Саранджи' },
      { id: '8_77', slug: 'violin', label: 'Скрипка' },
      { id: '8_78', slug: 'tar', label: 'Тар' },
      { id: '8_79', slug: 'fiddle', label: 'Фидель' },
      { id: '8_80', slug: 'hardingfele', label: 'Хардингфеле' },
      { id: '8_81', slug: 'khomus', label: 'Хомус' },
      { id: '8_82', slug: 'hucin', label: 'Хуцинь' },
      { id: '8_83', slug: 'zither', label: 'Цитра' },
      { id: '8_84', slug: 'charanga', label: 'Чаранга' },
      { id: '8_85', slug: 'chatkhan', label: 'Чатхан' },
      { id: '8_86', slug: 'percussionInstruments', label: 'Ударные' },
      { id: '8_87', slug: 'agogo', label: 'Агого' },
      { id: '8_88', slug: 'angklung', label: 'Ангклунг' },
      { id: '8_89', slug: 'asalato', label: 'Асатаяк' },
      { id: '8_90', slug: 'ashiko', label: 'Ахоко' },
      { id: '8_91', slug: 'balafon', label: 'Балафон' },
      { id: '8_92', slug: 'bang', label: 'Баньгу' },
      { id: '8_93', slug: 'barChimes', label: 'Бар чаймс' },
      { id: '8_94', slug: 'drums', label: 'Барабаны' },
      { id: '8_95', slug: 'bata', label: 'Бата' },
      { id: '8_96', slug: 'bomboLeguero', label: 'Бомбо легуэро' },
      { id: '8_97', slug: 'bonang', label: 'Бонанг' },
      { id: '8_98', slug: 'bongo', label: 'Бонго' },
      { id: '8_99', slug: 'bouran', label: 'Боуран' },
      { id: '8_100', slug: 'buben', label: 'Бубен' },
      { id: '8_101', slug: 'gambang', label: 'Гамбанг' },
      { id: '8_102', slug: 'glukofon', label: 'Глюкофон' },
      { id: '8_103', slug: 'guiro', label: 'Гуиро' },
      { id: '8_104', slug: 'dabyl', label: 'Дабыл' },
      { id: '8_105', slug: 'dangyra', label: 'Дангыра' },
      { id: '8_106', slug: 'darbuka', label: 'Дарбука' },
      { id: '8_107', slug: 'jaltarang', label: 'Джалтаранг' },
      { id: '8_108', slug: 'djembe', label: 'Джембе' },
      { id: '8_109', slug: 'doyra', label: 'Дойра' },
      { id: '8_110', slug: 'dotaku', label: 'Дотаку' },
      { id: '8_111', slug: 'dudyga', label: 'Дудыга' },
      { id: '8_112', slug: 'dholak', label: 'Дхолак' },
      { id: '8_113', slug: 'cabasa', label: 'Кабаса' },
      { id: '8_114', slug: 'kanjira', label: 'Канджира' },
      { id: '8_115', slug: 'carillons', label: 'Карильоны' },
      { id: '8_116', slug: 'castanets', label: 'Кастаньеты' },
      { id: '8_117', slug: 'cajon', label: 'Кахон' },
      { id: '8_118', slug: 'kashishi', label: 'Кашиши' },
      { id: '8_119', slug: 'clave', label: 'Клаве' },
      { id: '8_120', slug: 'cowbell', label: 'Ковбелл' },
      { id: '8_121', slug: 'bells', label: 'Колокола' },
      { id: '8_122', slug: 'conga', label: 'Конга' },
      { id: '8_123', slug: 'kpanlogo', label: 'Кпанлого' },
      { id: '8_124', slug: 'xylophone', label: 'Ксилофон' },
      { id: '8_125', slug: 'laghuti', label: 'Лаггути' },
      { id: '8_126', slug: 'litavry', label: 'Литавры' },
      { id: '8_127', slug: 'maracas', label: 'Маракас' },
      { id: '8_128', slug: 'marimba', label: 'Маримба' },
      { id: '8_129', slug: 'mridangam', label: 'Мриданга' },
      { id: '8_130', slug: 'pandeiro', label: 'Пандейру' },
      { id: '8_131', slug: 'rainstick', label: 'Рейнстик' },
      { id: '8_132', slug: 'rubel', label: 'Рубель' },
      { id: '8_133', slug: 'sagats', label: 'Сагаты' },
      { id: '8_134', slug: 'scraper', label: 'Скрабалай' },
      { id: '8_135', slug: 'splash', label: 'Сплэш' },
      { id: '8_136', slug: 'tabla', label: 'Табла' },
      { id: '8_137', slug: 'tavil', label: 'Тавил' },
      { id: '8_138', slug: 'taiko', label: 'Тайко' },
      { id: '8_139', slug: 'tambourine', label: 'Тамбурин' },
      { id: '8_140', slug: 'timbale', label: 'Тимбал' },
      { id: '8_141', slug: 'triangle', label: 'Треугольник' },
      { id: '8_142', slug: 'ratchet', label: 'Трещотка' },
      { id: '8_143', slug: 'tulumbas', label: 'Тулумбас' },
      { id: '8_144', slug: 'udu', label: 'Уду' },
      { id: '8_145', slug: 'tsudzumi', label: 'Цудзуми' },
      { id: '8_146', slug: 'chinaCymbal', label: 'Чайна' },
      { id: '8_147', slug: 'celesta', label: 'Челеста' },
      { id: '8_148', slug: 'shaker', label: 'Шейкер' },
      { id: '8_149', slug: 'electronicInstruments', label: 'Электромузыкальные' },
      { id: '8_150', slug: 'blasterBeam', label: 'Blaster Beam' },
      { id: '8_151', slug: 'midiKeyboard', label: 'MIDI-клавиатура' },
      { id: '8_152', slug: 'gittlerGuitar', label: 'Гитара Гиттлера' },
      { id: '8_153', slug: 'groovebox', label: 'Грувбокс' },
      { id: '8_154', slug: 'domavindger', label: 'Домавинджер' },
      { id: '8_155', slug: 'drumMachine', label: 'Драм-машина' },
      { id: '8_156', slug: 'continuum', label: 'Континуум' },
      { id: '8_157', slug: 'laserHarp', label: 'Лазерная арфа' },
      { id: '8_158', slug: 'mellotron', label: 'Меллотрон' },
      { id: '8_159', slug: 'mudswinger', label: 'Мудсвинджер' },
      { id: '8_160', slug: 'reactable', label: 'Реактейбл' },
      { id: '8_161', slug: 'sampler', label: 'Семплер' },
      { id: '8_162', slug: 'synthesizer', label: 'Синтезатор' },
      { id: '8_163', slug: 'theremin', label: 'Терменвокс' },
      { id: '8_164', slug: 'trautonium', label: 'Траутониум' },
      { id: '8_165', slug: 'tenoriOn', label: 'Тэнори-он' },
      { id: '8_166', slug: 'electricGuitar', label: 'Электрогитара' },
      { id: '8_167', slug: 'electricOrgan', label: 'Электроорган' },
      { id: '8_168', slug: 'emiritone', label: 'Эмиритон' }
    ]
  },
  {
    id: '9',
    slug: 'dance',
    label: 'Танцы',
    children: [
      { id: '9_0', slug: 'dance', label: 'Танцы' },
      { id: '9_1', slug: 'acrobaticDance', label: 'Акробатические танцы' },
      { id: '9_2', slug: 'acrobaticRockNRoll', label: 'Акробатический рок-н-рол' },
      { id: '9_3', slug: 'boogieWoogie', label: 'Буги-вуги' },
      { id: '9_4', slug: 'aerialEquilibrium', label: 'Воздушный эквилибр' },
      { id: '9_5', slug: 'capoeiraDance', label: 'Капоэйра' },
      { id: '9_6', slug: 'poleDance', label: 'Танец на пилоне' },
      { id: '9_7', slug: 'cheerleading', label: 'Черлидинг' },
      { id: '9_8', slug: 'ballroomDance', label: 'Бальные танцы' },
      { id: '9_9', slug: 'waltz', label: 'Вальс' },
      { id: '9_10', slug: 'westCoastSwing', label: 'Вест Коуст' },
      { id: '9_11', slug: 'jive', label: 'Джайв' },
      { id: '9_12', slug: 'pasodoble', label: 'Пасодобль' },
      { id: '9_13', slug: 'foxtrot', label: 'Фокстрот' },
      { id: '9_14', slug: 'ballet', label: 'Балет' },
      { id: '9_15', slug: 'easternDanceNew', label: 'Восточные танцы New' },
      { id: '9_16', slug: 'alexandriaDance', label: 'Александрия' },
      { id: '9_17', slug: 'balediDance', label: 'Баледи' },
      { id: '9_18', slug: 'bandariDance', label: 'Бандари' },
      { id: '9_19', slug: 'iraqiDance', label: 'Ираки' },
      { id: '9_20', slug: 'nubianDance', label: 'Нубия' },
      { id: '9_21', slug: 'saidiDance', label: 'Саиди' },
      { id: '9_22', slug: 'bellyDance', label: 'Танец Живота' },
      { id: '9_23', slug: 'tribalDance', label: 'Трибал' },
      { id: '9_24', slug: 'falaahiDance', label: 'Фалляхи' },
      { id: '9_25', slug: 'khaleejiDance', label: 'Халиджи' },
      { id: '9_26', slug: 'shaabiDance', label: 'Шааби' },
      { id: '9_27', slug: 'folkEthnicDances', label: 'Народные(этнические) танцы' },
      { id: '9_28', slug: 'armenianDances', label: 'Армянские' },
      { id: '9_29', slug: 'georgianDances', label: 'Грузинские' },
      { id: '9_30', slug: 'jewishDances', label: 'Еврейские' },
      { id: '9_31', slug: 'irishDances', label: 'Ирландские' },
      { id: '9_32', slug: 'caucasianDances', label: 'Кавказкие' },
      { id: '9_33', slug: 'kazakhDances', label: 'Казахские' },
      { id: '9_34', slug: 'chineseDances', label: 'Китайские' },
      { id: '9_35', slug: 'cubanDances', label: 'Кубинские' },
      { id: '9_36', slug: 'mexicanDances', label: 'Мексиканские' },
      { id: '9_37', slug: 'polishDances', label: 'Польские' },
      { id: '9_38', slug: 'russianDances', label: 'Русские' },
      { id: '9_39', slug: 'turkishDances', label: 'Турецкие' },
      { id: '9_40', slug: 'ukrainianDances', label: 'Украинские' },
      { id: '9_41', slug: 'japaneseDances', label: 'Японские' },
      { id: '9_42', slug: 'modernDances', label: 'Современнные танцы' },
      { id: '9_43', slug: 'buto', label: 'Буто' },
      { id: '9_44', slug: 'vogue', label: 'Вог' },
      { id: '9_45', slug: 'jazzModern', label: 'Джаз Модерн' },
      { id: '9_46', slug: 'jazzFunk', label: 'Джаз Фанк' },
      { id: '9_47', slug: 'dancehall', label: 'Дэнсхолл' },
      { id: '9_48', slug: 'contemporaryDance', label: 'Контемп' },
      { id: '9_49', slug: 'modernDance', label: 'Модерн' },
      { id: '9_50', slug: 'reggaeton', label: 'Реггетон' },
      { id: '9_51', slug: 'stripPlastic', label: 'Стрип-пластика' },
      { id: '9_52', slug: 'striptease', label: 'Стриптиз' },
      { id: '9_53', slug: 'highHeels', label: 'Хай Хилс' },
      { id: '9_54', slug: 'poleDancing', label: 'Pole Dance' },
      { id: '9_55', slug: 'latinDancesNew', label: 'Латинские танцы New' },
      { id: '9_56', slug: 'bachata', label: 'Бачата' },
      { id: '9_57', slug: 'lambada', label: 'Ламбада' },
      { id: '9_58', slug: 'mambo', label: 'Мамбо' },
      { id: '9_59', slug: 'rumba', label: 'Румба' },
      { id: '9_60', slug: 'salsa', label: 'Сальса' },
      { id: '9_61', slug: 'samba', label: 'Самба' },
      { id: '9_62', slug: 'tango', label: 'Танго' },
      { id: '9_63', slug: 'chaChaCha', label: 'Ча-ча-ча' },
      { id: '9_64', slug: 'streetDances', label: 'Уличные танцы' },
      { id: '9_65', slug: 'krump', label: 'Krump' },
      { id: '9_66', slug: 'breakdance', label: 'Брейк-данс' },
      { id: '9_67', slug: 'locking', label: 'Локинг' },
      { id: '9_68', slug: 'popping', label: 'Поппинг' },
      { id: '9_69', slug: 'tectonic', label: 'Тектоник' },
      { id: '9_70', slug: 'freestyle', label: 'Фристайл' },
      { id: '9_71', slug: 'houseDance', label: 'Хаус' },
      { id: '9_72', slug: 'hipHop', label: 'Хип-хоп' },
      { id: '9_73', slug: 'rnb', label: 'R&B' },
      { id: '9_74', slug: 'twerk', label: 'Тверк' },
      { id: '9_75', slug: 'gogo', label: 'Go-go' }
    ]
  },
  {
    id: '10',
    slug: 'fashion',
    label: 'Мода',
    children: [
      { id: '10_0', slug: 'fashion', label: 'Мода' },
      { id: '10_1', slug: 'fashionHistory', label: 'История моды' },
      { id: '10_2', slug: 'brandCollections', label: 'Коллекции брендов' },
      { id: '10_3', slug: 'fashionShows', label: 'Модные показы' },
      { id: '10_4', slug: 'styleAndImage', label: 'Стиль и имидж' },
      { id: '10_5', slug: 'modelingSchools', label: 'Школы моделей' },
      { id: '10_6', slug: 'shoppingAndTrends', label: 'Шоппинг и тренды' },
      { id: '10_7', slug: 'ecoFashion', label: 'Экомода' }
    ]
  },
  {
    id: '11',
    slug: 'design',
    label: 'Дизайн',
    children: [
      { id: '11_0', slug: 'design', label: 'Дизайн' },
      { id: '11_1', slug: 'webDesign', label: 'Web-дизайн' },
      { id: '11_2', slug: 'animationDesign', label: 'Анимационный дизайн' },
      { id: '11_3', slug: 'architecturalDesign', label: 'Архитектурный дизайн' },
      { id: '11_4', slug: 'aeroDesign', label: 'Аэродизайн' },
      { id: '11_5', slug: 'graphicDesign', label: 'Графический дизайн' },
      { id: '11_6', slug: 'interiorDesign', label: 'Дизайн интерьера' },
      { id: '11_7', slug: 'eventDesign', label: 'Дизайн мероприятий' },
      { id: '11_8', slug: 'clothingDesign', label: 'Дизайн одежды' },
      { id: '11_9', slug: 'customization', label: 'Кастомайзинг' },
      { id: '11_10', slug: 'landscapeDesign', label: 'Ландшафтный дизайн' },
      { id: '11_11', slug: 'landArt', label: 'Ленд-арт' },
      { id: '11_12', slug: 'modding', label: 'Моддинг' },
      { id: '11_13', slug: 'lightingDesign', label: 'Световой дизайн' },
      { id: '11_14', slug: 'suitDesign', label: 'Свит-дизайн' },
      { id: '11_15', slug: 'floristics', label: 'Флористика' },
      { id: '11_16', slug: 'futureDesign', label: 'Футуродизайн' },
      { id: '11_17', slug: 'ecoDesign', label: 'Экодизайн' }
    ]
  },
  {
    id: '12',
    slug: 'beautyAndHealth',
    label: 'Красота и здоровье',
    children: [
      { id: '12_0', slug: 'beautyAndHealth', label: 'Красота и здоровье' },
      { id: '12_1', slug: 'flylady', label: 'Flylady' },
      { id: '12_2', slug: 'aromatherapy', label: 'Ароматерапия' },
      { id: '12_3', slug: 'aerobics', label: 'Аэробика' },
      { id: '12_4', slug: 'bathsAndSaunas', label: 'Бани и сауны' },
      { id: '12_5', slug: 'bodyflex', label: 'Бодифлекс' },
      { id: '12_6', slug: 'makeup', label: 'Визаж' },
      { id: '12_7', slug: 'yoga', label: 'Йога' },
      { id: '12_8', slug: 'nailArt', label: 'Нейл-арт' },
      { id: '12_9', slug: 'hairdressingArt', label: 'Парикмахерское искусство' },
      { id: '12_10', slug: 'dietAndWeightLoss', label: 'ПП и похудение' },
      { id: '12_11', slug: 'nordicWalking', label: 'Скандинавская ходьба' },
      { id: '12_12', slug: 'creatingCosmetics', label: 'Создание уходовой косметики' },
      { id: '12_13', slug: 'nailStanding', label: 'Стояние на гвоздях' },
      { id: '12_14', slug: 'massage', label: 'Массаж' },
      { id: '12_15', slug: 'faceFitness', label: 'Фейсфитнес' },
      { id: '12_16', slug: 'faceBuilding', label: 'Фэйсбилдинг' },
      { id: '12_17', slug: 'qigong', label: 'Цигун' }
    ]
  },
  {
    id: '13',
    slug: 'olympicGames',
    label: 'Олимпийские игры',
    children: [
      { id: '13_0', slug: 'olympicGames', label: 'Олимпийские игры' },
      { id: '13_1', slug: 'paralympicGames', label: 'Паролимпийские игры' }
    ]
  },
  {
    id: '14',
    slug: 'sports',
    label: 'Спорт',
    children: [
      { id: '13_0', slug: 'sports', label: 'Спорт' },
      { id: '13_1', slug: 'acrobatics', label: 'Акробатика' },
      { id: '13_2', slug: 'armwrestling', label: 'Армреслинг' },
      { id: '13_3', slug: 'running', label: 'Бег' },
      { id: '13_4', slug: 'biathlon', label: 'Биатлон' },
      { id: '13_5', slug: 'bodybuilding', label: 'Бодибилдинг' },
      { id: '13_6', slug: 'cycling', label: 'Велоспорт' },
      { id: '13_7', slug: 'geocaching', label: 'Геокэшинг' },
      { id: '13_8', slug: 'sportsGymnastics', label: 'Гимнастика спортивная' },
      { id: '13_9', slug: 'artisticGymnastics', label: 'Гимнастика художественная' },
      { id: '13_10', slug: 'kettlebellSport', label: 'Гиревой спорт' },
      { id: '13_11', slug: 'rowing', label: 'Гребля' },
      { id: '13_12', slug: 'zumba', label: 'Зумба' },
      { id: '13_13', slug: 'kabaddi', label: 'Кабадди' },
      { id: '13_14', slug: 'equestrianSport', label: 'Конный спорт' },
      { id: '13_15', slug: 'skating', label: 'Коньки' },
      { id: '13_16', slug: 'crossfit', label: 'Кроссфит' },
      { id: '13_17', slug: 'athletics', label: 'Легкая атлетика' },
      { id: '13_18', slug: 'skiing', label: 'Лыжи' },
      { id: '13_19', slug: 'tableTennis', label: 'Настольный теннис' },
      { id: '13_20', slug: 'sailing', label: 'Парусный спорт' },
      { id: '13_21', slug: 'powerlifting', label: 'Пауэрлифтинг' },
      { id: '13_22', slug: 'pilates', label: 'Пилатес' },
      { id: '13_23', slug: 'swimming', label: 'Плавание' },
      { id: '13_24', slug: 'trampolineJumping', label: 'Прыжки на батуте' },
      { id: '13_25', slug: 'rogaining', label: 'Рогейн' },
      { id: '13_26', slug: 'rollerSports', label: 'Роликобежный спорт' },
      { id: '13_27', slug: 'scooterSport', label: 'Самокатный спорт' },
      { id: '13_28', slug: 'sportsShooting', label: 'Спортивная стрельба' },
      { id: '13_29', slug: 'archery', label: 'Стрельба из лука' },
      { id: '13_30', slug: 'shooting', label: 'Стрельба пулевая' },
      { id: '13_31', slug: 'clayShooting', label: 'Стрельба стендовая' },
      { id: '13_32', slug: 'sportsDance', label: 'Танцы спортивные' },
      { id: '13_33', slug: 'gym', label: 'Тренажорный зал New' },
      { id: '13_34', slug: 'triathlon', label: 'Триатлон' },
      { id: '13_35', slug: 'weightlifting', label: 'Тяжелая атлетика' },
      { id: '13_36', slug: 'fencing', label: 'Фехтование' },
      { id: '13_37', slug: 'figureSkating', label: 'Фигурное катание' },
      { id: '13_38', slug: 'fitnessNew', label: 'Фитнес New' },
      { id: '13_39', slug: 'shortTrack', label: 'Шорт-трек' },
      { id: '13_40', slug: 'yachting', label: 'Яхтинг' }
    ]
  },
  {
    id: '15',
    slug: 'teamSports',
    label: 'Team Sports',
    children: [
      { id: '15_0', slug: 'teamGames', label: 'Командные Игры' },
      { id: '15_1', slug: 'americanFootball', label: 'Американский футбол' },
      { id: '15_2', slug: 'badminton', label: 'Бадминтон' },
      { id: '15_3', slug: 'basketball', label: 'Баскетбол' },
      { id: '15_4', slug: 'baseball', label: 'Бейсбол' },
      { id: '15_5', slug: 'tennis', label: 'Большой теннис' },
      { id: '15_6', slug: 'bridge', label: 'Бридж' },
      { id: '15_7', slug: 'waterPolo', label: 'Водное поло' },
      { id: '15_8', slug: 'volleyball', label: 'Волейбол' },
      { id: '15_9', slug: 'beachVolleyball', label: 'Волейбол пляжный' },
      { id: '15_10', slug: 'handball', label: 'Гандбол' },
      { id: '15_11', slug: 'darts', label: 'Дартс' },
      { id: '15_12', slug: 'curling', label: 'Керлинг' },
      { id: '15_13', slug: 'kinball', label: 'Кинбол' },
      { id: '15_14', slug: 'cricket', label: 'Крикет' },
      { id: '15_15', slug: 'lapta', label: 'Лапта' },
      { id: '15_16', slug: 'lacrosse', label: 'Лякросс' },
      { id: '15_17', slug: 'motoball', label: 'Мотобол' },
      { id: '15_18', slug: 'paintball', label: 'Пейнтбол' },
      { id: '15_19', slug: 'tugOfWar', label: 'Перетягивание каната' },
      { id: '15_20', slug: 'polo', label: 'Поло' },
      { id: '15_21', slug: 'rugby', label: 'Регби' },
      { id: '15_22', slug: 'sledgeHockey', label: 'Следж-хоккей' },
      { id: '15_23', slug: 'slamball', label: 'Слэмбол' },
      { id: '15_24', slug: 'softball', label: 'Софтбол' },
      { id: '15_25', slug: 'sportingCompetitions', label: 'Спортивные состязания' },
      { id: '15_26', slug: 'strikeball', label: 'Страйкбол' },
      { id: '15_27', slug: 'tableTennis', label: 'Теннис настольный' },
      { id: '15_28', slug: 'trackball', label: 'Трекбол' },
      { id: '15_29', slug: 'football', label: 'Футбол' },
      { id: '15_30', slug: 'footbagNetGame', label: 'Футбэг нет-гейм' },
      { id: '15_31', slug: 'fieldHockey', label: 'Хокей на траве' },
      { id: '15_32', slug: 'hockey', label: 'Хоккей' },
      { id: '15_33', slug: 'ballHockey', label: 'Хоккей с мячом' },
      { id: '15_34', slug: 'encounter', label: 'Энкаунтер' }
    ]
  },
  {
    id: '16',
    slug: 'extremeHobbies',
    label: 'Экстремальные увлечения',
    children: [
      { id: '16_0', slug: 'extremeHobbies', label: 'Экстремальные увлечения' },
      { id: '16_1', slug: 'mountaineering', label: 'Альпинизм' },
      { id: '16_2', slug: 'bungeeJumping', label: 'Банджи-джампинг' },
      { id: '16_3', slug: 'baseJumping', label: 'Бейсджампинг' },
      { id: '16_4', slug: 'baseClimbers', label: 'Бейскламберы' },
      { id: '16_5', slug: 'bouldering', label: 'Билдеринг' },
      { id: '16_6', slug: 'wakeboarding', label: 'Вейкбординг' },
      { id: '16_7', slug: 'windsurfing', label: 'Виндсерфинг' },
      { id: '16_8', slug: 'skiing', label: 'Горные лыжи' },
      { id: '16_9', slug: 'groupAcrobatics', label: 'Групповая акробатика' },
      { id: '16_10', slug: 'diving', label: 'Дайвинг' },
      { id: '16_11', slug: 'diggering', label: 'Диггерство' },
      { id: '16_12', slug: 'zorbing', label: 'Зорбинг' },
      { id: '16_13', slug: 'kitesurfing', label: 'Кайтсёрфинг' },
      { id: '16_14', slug: 'dogSledding', label: 'Катание на собачьих упряжках' },
      { id: '16_15', slug: 'kayaking', label: 'Каякинг' },
      { id: '16_16', slug: 'kickScootering', label: 'Кикскутеринг' },
      { id: '16_17', slug: 'cliffDiving', label: 'Клиффдайвинг' },
      { id: '16_18', slug: 'bullfighting', label: 'Коррида' },
      { id: '16_19', slug: 'domedAcrobatics', label: 'Купольная акробатика' },
      { id: '16_20', slug: 'iceClimbing', label: 'Ледолазание' },
      { id: '16_21', slug: 'mountainBiking', label: 'Маунтин-байкинг' },
      { id: '16_22', slug: 'parkour', label: 'Паркур' },
      { id: '16_23', slug: 'swimmingWithSharks', label: 'Плавание с акулами' },
      { id: '16_24', slug: 'hotAirBalloonFlights', label: 'Полёты на воздушных шарах' },
      { id: '16_25', slug: 'paragliding', label: 'Полеты на параплане' },
      { id: '16_26', slug: 'questPassing', label: 'Прохождение квестов' },
      { id: '16_27', slug: 'parachuting', label: 'Прыжки с парашютом' },
      { id: '16_28', slug: 'rafting', label: 'Рафтинг' },
      { id: '16_29', slug: 'rollerblading', label: 'Ролики' },
      { id: '16_30', slug: 'roofering', label: 'Руфинг' },
      { id: '16_31', slug: 'mushroomPicking', label: 'Сбор грибов' },
      { id: '16_32', slug: 'surfing', label: 'Серфинг' },
      { id: '16_33', slug: 'skydiving', label: 'Скайдайвинг' },
      { id: '16_34', slug: 'climbingWall', label: 'Скалодром' },
      { id: '16_35', slug: 'races', label: 'Скачки' },
      { id: '16_36', slug: 'skateboarding', label: 'Скейтборд' },
      { id: '16_37', slug: 'snowboarding', label: 'Сноуборд' },
      { id: '16_38', slug: 'caving', label: 'Спелеотуризм' },
      { id: '16_39', slug: 'sportsBetting', label: 'Ставки на спорт' },
      { id: '16_40', slug: 'transurfing', label: 'Трейнсерфинг' },
      { id: '16_41', slug: 'heliboarding', label: 'Хелибординг' },
      { id: '16_42', slug: 'walkingOnTheRope', label: 'Хождение по канату' },
      { id: '16_43', slug: 'blackDiggers', label: 'Черные копатели' },
      { id: '16_44', slug: 'encierro', label: 'Энсьерро' }
    ]
  },
  {
    id: '17',
    slug: 'martialArts',
    label: 'Боевые исскуства',
    children: [
      { id: '17_0', slug: 'martialArts', label: 'Боевые исскуства' },
      { id: '17_1', slug: 'aikido', label: 'Айкидо' },
      { id: '17_2', slug: 'arnis', label: 'Арнис' },
      { id: '17_3', slug: 'bartitsu', label: 'Бартицу' },
      { id: '17_4', slug: 'mixedMartialArts', label: 'Бои без правил' },
      { id: '17_5', slug: 'boxing', label: 'Бокс' },
      { id: '17_6', slug: 'wingChun', label: 'Винь-чуй' },
      { id: '17_7', slug: 'freestyleWrestling', label: 'Вольная борьба' },
      { id: '17_8', slug: 'grecoRomanWrestling', label: 'Греко-римская борьба' },
      { id: '17_9', slug: 'gulesh', label: 'Гюлеш' },
      { id: '17_10', slug: 'jiuJitsu', label: 'Джиу-джитсу' },
      { id: '17_11', slug: 'judo', label: 'Дзюдо' },
      { id: '17_12', slug: 'capoeira', label: 'Капоэйра' },
      { id: '17_13', slug: 'karate', label: 'Каратэ' },
      { id: '17_14', slug: 'kyokushin', label: 'Киокусинкай' },
      { id: '17_15', slug: 'kyokushinkai', label: 'Киокушин' },
      { id: '17_16', slug: 'kuresh', label: 'Куреш' },
      { id: '17_17', slug: 'kendo', label: 'Кэндо' },
      { id: '17_18', slug: 'letWei', label: 'Летхвей' },
      { id: '17_19', slug: 'mma', label: 'ММА' },
      { id: '17_20', slug: 'muayThai', label: 'Муай тай' },
      { id: '17_21', slug: 'handToHandCombat', label: 'Рукопашный бой' },
      { id: '17_22', slug: 'savate', label: 'Сават' },
      { id: '17_23', slug: 'sambo', label: 'Самбо' },
      { id: '17_24', slug: 'sumo', label: 'Сумо' },
      { id: '17_25', slug: 'muayBoran', label: 'Тайский бокс' },
      { id: '17_26', slug: 'taekwondo', label: 'Тхэквандо' },
      { id: '17_27', slug: 'wushu', label: 'Ушу' },
      { id: '17_28', slug: 'fencing', label: 'Фехтование' },
      { id: '17_29', slug: 'hapkido', label: 'Хапкидо' },
      { id: '17_30', slug: 'shotokan', label: 'Шотокан' }
    ]
  },
  {
    id: '18',
    slug: 'cookingAndDrinks',
    label: 'Кулинария и напитки',
    children: [
      { id: '18_0', slug: 'cookingAndDrinks', label: 'Кулинария и напитки' },
      { id: '18_1', slug: 'asianCuisine', label: 'Азиатская кухня' },
      { id: '18_2', slug: 'winemaking', label: 'Виноделие' },
      { id: '18_3', slug: 'bakingCakesAndDesserts', label: 'Выпечка тортов и десертов' },
      { id: '18_4', slug: 'foodDecoration', label: 'Декорирование блюд' },
      { id: '18_5', slug: 'homemadeCheesemaking', label: 'Домашнее сыроделие' },
      { id: '18_6', slug: 'spanishCuisine', label: 'Испанская кухня' },
      { id: '18_7', slug: 'italianCuisine', label: 'Итальянская кухня' },
      { id: '18_8', slug: 'caucasianCuisine', label: 'Кавказская кухня' },
      { id: '18_9', slug: 'carving', label: 'Карвинг' },
      { id: '18_10', slug: 'catering', label: 'Кейтеринг' },
      { id: '18_11', slug: 'canning', label: 'Консервирование' },
      { id: '18_12', slug: 'latteArt', label: 'Латте-арт' },
      { id: '18_13', slug: 'mexicanCuisine', label: 'Мексиканская кухня' },
      { id: '18_14', slug: 'mixology', label: 'Миксология' },
      { id: '18_15', slug: 'panAsianCuisine', label: 'Паназиатская кухня' },
      { id: '18_16', slug: 'brewing', label: 'Пивоварение' },
      { id: '18_17', slug: 'drinkMaking', label: 'Приготовление напитков' },
      { id: '18_18', slug: 'russianCuisine', label: 'Русская кухня' },
      { id: '18_19', slug: 'moonshineMaking', label: 'Самогоноварение' },
      { id: '18_20', slug: 'servingAndEtiquette', label: 'Сервировка и этикет' },
      { id: '18_21', slug: 'handmadeSweets', label: 'Сладости ручной работы' },
      { id: '18_22', slug: 'turkishCuisine', label: 'Турецкая кухня' },
      { id: '18_23', slug: 'frenchCuisine', label: 'Французская кухня' },
      { id: '18_24', slug: 'coldDishes', label: 'Холодные блюда' }
    ]
  },
  {
    id: '19',
    slug: 'nightEntertainment',
    label: 'Ночные развлечения',
    children: [
      { id: '19_0', slug: 'nightEntertainment', label: 'Ночные развлечения' },
      { id: '19_1', slug: 'nightClubs', label: 'Ночные клубы' },
      { id: '19_2', slug: 'hookahSmoking', label: 'Курение кальяна' },
      { id: '19_3', slug: 'nightFitnessClub', label: 'Ночной фитнес клуб' },
      { id: '19_4', slug: 'karaokeBars', label: 'Караоке-бары' },
      { id: '19_5', slug: 'casinos', label: 'Казино' },
      { id: '19_6', slug: 'billiards', label: 'Бильярд' },
      { id: '19_7', slug: 'bowling', label: 'Боулинг' },
      { id: '19_8', slug: 'stargazing', label: 'Наблюдение за звездами' }
    ]
  },
  {
    id: '20',
    slug: 'photo',
    label: 'Фото',
    children: [
      { id: '20_0', slug: 'photo', label: 'Фото' },
      { id: '20_1', slug: 'photoRestoration', label: 'Реставрация фото' },
      { id: '20_2', slug: 'quadcopterPhotography', label: 'Съемка на квадрокоптер' },
      { id: '20_3', slug: 'photoMontage', label: 'Фотомонтаж' },
      { id: '20_4', slug: 'photoShooting', label: 'Фотосьемка' }
    ]
  },
  {
    id: '21',
    slug: 'video',
    label: 'Видео',
    children: [
      { id: '21_0', slug: 'video', label: 'Видео' },
      { id: '21_1', slug: 'videoArt', label: 'Видео Арт' },
      { id: '21_2', slug: 'videoEditing', label: 'Видеомонтаж' },
      { id: '21_3', slug: 'videoShooting', label: 'Видеосъемка' },
      { id: '21_4', slug: 'viralVideos', label: 'Вирусные ролики' },
      { id: '21_5', slug: 'quadcopterShooting', label: 'Съемка на квадрокоптер' }
    ]
  },
  {
    id: '22',
    slug: 'virtualWorld',
    label: 'Виртуальный мир',
    children: [
      { id: '22_0', slug: 'virtualWorld', label: 'Виртуальный мир' },
      { id: '22_1', slug: 'mmoMmorpgGames', label: 'ММО и MMORPG игры' },
      { id: '22_2', slug: 'flightSimulators', label: 'Авиасимуляторы' },
      { id: '22_3', slug: 'gameConsoles', label: 'Игровые приставки' },
      { id: '22_4', slug: 'quests', label: 'Квесты' },
      { id: '22_5', slug: 'esports', label: 'Киберспорт' },
      { id: '22_6', slug: 'computerGames', label: 'Компьютерные игры' },
      { id: '22_7', slug: 'simulators', label: 'Симуляторы' },
      { id: '22_8', slug: 'animationCreation', label: 'Создание анимации' },
      { id: '22_9', slug: 'cyberpunk', label: 'Cyberpunk' }
    ]
  },
  {
    id: '23',
    slug: 'it',
    label: 'IT',
    children: [
      { id: '23_0', slug: 'it', label: 'IT' },
      { id: '23_1', slug: '3dModeling', label: '3D моделирование' },
      { id: '23_2', slug: '4and5dModeling', label: '4 и 5D моделирование' },
      { id: '23_3', slug: 'arVrTechnologies', label: 'AR/VR технологии' },
      { id: '23_4', slug: 'itTechnologiesAndInnovations', label: 'IT-технологии и инновации' },
      { id: '23_5', slug: 'webProgramming', label: 'Web-программирование' },
      { id: '23_6', slug: 'gameDesign', label: 'Геймдизайн' },
      { id: '23_7', slug: 'programming', label: 'Программирование' },
      { id: '23_8', slug: 'gameProgramming', label: 'Программирование игр' },
      { id: '23_9', slug: 'appDevelopment', label: 'Программирование приложений' },
      { id: '23_10', slug: 'development', label: 'Разработка' }
    ]
  },
  { id: '24', slug: 'blog', label: 'Блогинг', children: [{ id: '24_0', slug: 'blog', label: 'Блогинг' }] },
  {
    id: '25',
    slug: 'gambling',
    label: 'Азартные игры',
    children: [
      { id: '24_0', slug: 'gambling', label: 'Азартные игры' },
      { id: '24_1', slug: 'poker', label: 'Покер' },
      { id: '24_2', slug: 'roulette', label: 'Рулетка' },
      { id: '24_3', slug: 'bets', label: 'Ставки' },
      { id: '24_4', slug: 'blackjack', label: 'Блэкджек' },
      { id: '24_5', slug: 'baccarat', label: 'Баккара' },
      { id: '24_6', slug: 'craps', label: 'Крэпс (кости)' },
      { id: '24_7', slug: 'lottery', label: 'Лотерея' }
    ]
  },
  {
    id: '26',
    slug: 'boardGames',
    label: 'Настольные игры',
    children: [
      { id: '26_0', slug: 'boardGames', label: 'Настольные игры' },
      { id: '26_1', slug: 'bankerAndBroker', label: 'Банкир и брокер' },
      { id: '26_2', slug: 'bluff', label: 'Блеф' },
      { id: '26_3', slug: 'blackjack', label: 'Блэк-джек' },
      { id: '26_4', slug: 'ten', label: 'Десятка' },
      { id: '26_5', slug: 'jenga', label: 'Дженга' },
      { id: '26_6', slug: 'diskit', label: 'Дискит' },
      { id: '26_7', slug: 'domino', label: 'Домино' },
      { id: '26_8', slug: 'diceGames', label: 'Игральные кости' },
      { id: '26_9', slug: 'miniatureGames', label: 'Игры миниатюр' },
      { id: '26_10', slug: 'pencilAndPaperGames', label: 'Игры с карандашом и бумагой' },
      { id: '26_11', slug: 'imaginarium', label: 'Имаджинариум' },
      { id: '26_12', slug: 'playingCards', label: 'Карты игральные' },
      { id: '26_13', slug: 'ticTacToe', label: 'Крестики-нолики' },
      { id: '26_14', slug: 'crocodile', label: 'Крокодил' },
      { id: '26_15', slug: 'mahjong', label: 'Маджонг' },
      { id: '26_16', slug: 'munchkin', label: 'Манчкин' },
      { id: '26_17', slug: 'mafia', label: 'Мафия' },
      { id: '26_18', slug: 'mill', label: 'Мельница' },
      { id: '26_19', slug: 'monopoly', label: 'Монополия' },
      { id: '26_20', slug: 'battleship', label: 'Морской бой' },
      { id: '26_21', slug: 'thimbles', label: 'Наперстки' },
      { id: '26_22', slug: 'backgammon', label: 'Нарды' },
      { id: '26_23', slug: 'answerIn5Seconds', label: 'Ответь за 5 секунд' },
      { id: '26_24', slug: 'poker', label: 'Покер' },
      { id: '26_25', slug: 'preferans', label: 'Преферанс' },
      { id: '26_26', slug: 'russianRoulette', label: 'Русская рулетка' },
      { id: '26_27', slug: 'uno', label: 'Уно' },
      { id: '26_28', slug: 'hearthstone', label: 'Хартстоун' },
      { id: '26_29', slug: 'chess', label: 'Шахматы' },
      { id: '26_30', slug: 'checkers', label: 'Шашки' }
    ]
  },
  {
    id: '27',
    slug: 'autoAndMoto',
    label: 'Авто и мото',
    children: [
      { id: '27_0', slug: 'autoAndMoto', label: 'Авто и мото' },
      { id: '27_1', slug: 'demolitionDerby', label: 'Demolition Derby' },
      { id: '27_2', slug: 'figure8Racing', label: 'Figure 8 Racing' },
      { id: '27_3', slug: 'autoAndMotoRacingOnIce', label: 'Авто и мотогонки на льду' },
      { id: '27_4', slug: 'autoracing', label: 'Автогонки' },
      { id: '27_5', slug: 'autosound', label: 'Автозвук' },
      { id: '27_6', slug: 'autocross', label: 'Автокросс' },
      { id: '27_7', slug: 'bikeShow', label: 'Байк-шоу' },
      { id: '27_8', slug: 'offRoadMotorcycles', label: 'Внедорожные мотоциклы' },
      { id: '27_9', slug: 'snowmobileRacingOnWater', label: 'Гонки на снегоходе по воде' },
      { id: '27_10', slug: 'detailing', label: 'Детейлинг' },
      { id: '27_11', slug: 'drift', label: 'Дрифт' },
      { id: '27_12', slug: 'dragRacing', label: 'Дрэг-рейсинг' },
      { id: '27_13', slug: 'carposting', label: 'Карпостинг' },
      { id: '27_14', slug: 'karting', label: 'Картинг' },
      { id: '27_15', slug: 'quads', label: 'Квадроциклы' },
      { id: '27_16', slug: 'motocross', label: 'Мотокросс' },
      { id: '27_17', slug: 'motorsport', label: 'Мотоспорт' },
      { id: '27_18', slug: 'rally', label: 'Ралли' },
      { id: '27_19', slug: 'retroAutomoto', label: 'Ретроавтомото' },
      { id: '27_20', slug: 'styling', label: 'Стайлинг' },
      { id: '27_21', slug: 'trials', label: 'Триалы' },
      { id: '27_22', slug: 'trophyTrucks', label: 'Трофи траки' },
      { id: '27_23', slug: 'tuning', label: 'Тюнинг' },
      { id: '27_24', slug: 'streetRacing', label: 'Уличные гонки' },
      { id: '27_25', slug: 'freestyleMotocross', label: 'Фристайл мотокросс' }
    ]
  },
  {
    id: '28',
    slug: 'languageLearning',
    label: 'Изучение языков',
    children: [
      { id: '28_0', slug: 'languageLearning', label: 'Изучение языков' },
      { id: '28_1', slug: 'azerbaijani', label: 'Азербайджанский' },
      { id: '28_2', slug: 'albanian', label: 'Албанский' },
      { id: '28_3', slug: 'english', label: 'Английский' },
      { id: '28_4', slug: 'arabic', label: 'Арабский' },
      { id: '28_5', slug: 'armenian', label: 'Армянский' },
      { id: '28_6', slug: 'belarusian', label: 'Белорусский' },
      { id: '28_7', slug: 'bulgarian', label: 'Болгарский' },
      { id: '28_8', slug: 'hungarian', label: 'Венгерский' },
      { id: '28_9', slug: 'vietnamese', label: 'Вьетнамский' },
      { id: '28_10', slug: 'dutch', label: 'Голландский' },
      { id: '28_11', slug: 'greek', label: 'Греческий' },
      { id: '28_12', slug: 'georgian', label: 'Грузинский' },
      { id: '28_13', slug: 'dari', label: 'Дари' },
      { id: '28_14', slug: 'danish', label: 'Датский' },
      { id: '28_15', slug: 'ancientGreek', label: 'Древнегреческий' },
      { id: '28_16', slug: 'ancientLanguages', label: 'Древние языки' },
      { id: '28_17', slug: 'hebrew', label: 'Иврит' },
      { id: '28_18', slug: 'spanish', label: 'Испанский' },
      { id: '28_19', slug: 'italian', label: 'Итальянский' },
      { id: '28_20', slug: 'kazakh', label: 'Казахский' },
      { id: '28_21', slug: 'chinese', label: 'Китайский' },
      { id: '28_22', slug: 'korean', label: 'Корейский' },
      { id: '28_23', slug: 'kyrgyz', label: 'Кыргызский' },
      { id: '28_24', slug: 'latin', label: 'Латинский' },
      { id: '28_25', slug: 'latvian', label: 'Латышский' },
      { id: '28_26', slug: 'lithuanian', label: 'Литовский' },
      { id: '28_27', slug: 'malay', label: 'Малазийский' },
      { id: '28_28', slug: 'moldovan', label: 'Молдавский' },
      { id: '28_29', slug: 'mongolian', label: 'Монгольский' },
      { id: '28_30', slug: 'german', label: 'Немецкий' },
      { id: '28_31', slug: 'nepali', label: 'Непальский' },
      { id: '28_32', slug: 'dutch', label: 'Нидерландский' },
      { id: '28_33', slug: 'norwegian', label: 'Норвежский' },
      { id: '28_34', slug: 'persian', label: 'Персидский' },
      { id: '28_35', slug: 'polish', label: 'Польский' },
      { id: '28_36', slug: 'portuguese', label: 'Португальский' },
      { id: '28_37', slug: 'romanian', label: 'Румынский' },
      { id: '28_38', slug: 'russian', label: 'Русский' },
      { id: '28_39', slug: 'serbian', label: 'Сербский' },
      { id: '28_40', slug: 'slovak', label: 'Словацкий' },
      { id: '28_41', slug: 'slovenian', label: 'Словенский' },
      { id: '28_42', slug: 'tajik', label: 'Таджикский' },
      { id: '28_43', slug: 'thai', label: 'Тайский' },
      { id: '28_44', slug: 'tatar', label: 'Татарский' },
      { id: '28_45', slug: 'turkish', label: 'Турецкий' },
      { id: '28_46', slug: 'turkmen', label: 'Туркменский' },
      { id: '28_47', slug: 'uzbek', label: 'Узбекский' },
      { id: '28_48', slug: 'ukrainian', label: 'Украинский' },
      { id: '28_49', slug: 'farsi', label: 'Фарси' },
      { id: '28_50', slug: 'finnish', label: 'Финский' },
      { id: '28_51', slug: 'flemish', label: 'Фламандский' },
      { id: '28_52', slug: 'french', label: 'Французский' },
      { id: '28_53', slug: 'hindi', label: 'Хинди' },
      { id: '28_54', slug: 'khorvat', label: 'Хороватский' },
      { id: '28_55', slug: 'czech', label: 'Чешский' },
      { id: '28_56', slug: 'swedish', label: 'Шведский' },
      { id: '28_57', slug: 'estonian', label: 'Эстонский' },
      { id: '28_58', slug: 'japanese', label: 'Японский' }
    ]
  },
  {
    id: '29',
    slug: 'scienceLearning',
    label: 'Изучение наук',
    children: [
      { id: '29_0', slug: 'scienceLearning', label: 'Изучение наук' },
      { id: '29_1', slug: 'agronomy', label: 'Агрономия' },
      { id: '29_2', slug: 'archaeology', label: 'Археология' },
      { id: '29_3', slug: 'architecture', label: 'Архитектура' },
      { id: '29_4', slug: 'astrology', label: 'Астрология' },
      { id: '29_5', slug: 'astronomy', label: 'Астрономия' },
      { id: '29_6', slug: 'astrophysics', label: 'Астрофизика' },
      { id: '29_7', slug: 'aeronautics', label: 'Аэронавтика' },
      { id: '29_8', slug: 'ballistics', label: 'Баллистика' },
      { id: '29_9', slug: 'biology', label: 'Биология' },
      { id: '29_10', slug: 'botany', label: 'Ботаника' },
      { id: '29_11', slug: 'geography', label: 'География' },
      { id: '29_12', slug: 'geology', label: 'Геология' },
      { id: '29_13', slug: 'geophysics', label: 'Геофизика' },
      { id: '29_14', slug: 'hydrology', label: 'Гидрология' },
      { id: '29_15', slug: 'zoology', label: 'Зоология' },
      { id: '29_16', slug: 'informatics', label: 'Информатика' },
      { id: '29_17', slug: 'artStudies', label: 'Исскуствоведение' },
      { id: '29_18', slug: 'history', label: 'История' },
      { id: '29_19', slug: 'bibliology', label: 'Книговедение' },
      { id: '29_20', slug: 'localHistory', label: 'Краеведение' },
      { id: '29_21', slug: 'cryptography', label: 'Криптография' },
      { id: '29_22', slug: 'culturalAnthropology', label: 'Культурная антропология' },
      { id: '29_23', slug: 'culturalStudies', label: 'Культурология' },
      { id: '29_24', slug: 'linguistics', label: 'Лингвистика' },
      { id: '29_25', slug: 'literaryStudies', label: 'Литературоведение' },
      { id: '29_26', slug: 'mathematics', label: 'Математика' },
      { id: '29_27', slug: 'medicine', label: 'Медицина' },
      { id: '29_28', slug: 'paleontology', label: 'Палонтология' },
      { id: '29_29', slug: 'pedagogy', label: 'Педагогика' },
      { id: '29_30', slug: 'soilScience', label: 'Почвоведение' },
      { id: '29_31', slug: 'psychology', label: 'Психология' },
      { id: '29_32', slug: 'religiousStudies', label: 'Религиоведение' },
      { id: '29_33', slug: 'robotics', label: 'Робототехника' },
      { id: '29_34', slug: 'sociology', label: 'Социология' },
      { id: '29_35', slug: 'physiognomy', label: 'Физиагномика' },
      { id: '29_36', slug: 'philosophy', label: 'Философия' },
      { id: '29_37', slug: 'chemistry', label: 'Химия' }
    ]
  },
  {
    id: '30',
    slug: 'esoterics',
    label: 'Эзотерика',
    children: [
      { id: '30_0', slug: 'esoterics', label: 'Эзотерика' },
      { id: '30_1', slug: 'alchemy', label: 'Алхимия' },
      { id: '30_2', slug: 'astrology', label: 'Астрология' },
      { id: '30_3', slug: 'bioenergy', label: 'Биоэнергетика' },
      { id: '30_4', slug: 'buddhism', label: 'Буддизм' },
      { id: '30_5', slug: 'divination', label: 'Гадание' },
      { id: '30_6', slug: 'gnosticism', label: 'Гностицизм' },
      { id: '30_7', slug: 'taoism', label: 'Даосизм' },
      { id: '30_8', slug: 'humanDesign', label: 'Дизайн человека' },
      { id: '30_9', slug: 'glukofonGame', label: 'Игра на глюкофоне' },
      { id: '30_10', slug: 'kabbalah', label: 'Каббала' },
      { id: '30_11', slug: 'cosmoenergy', label: 'Космоэнергетика' },
      { id: '30_12', slug: 'magicAndRituals', label: 'Магия и ритуалы' },
      { id: '30_13', slug: 'masonry', label: 'Масонство' },
      { id: '30_14', slug: 'matrixOfDestiny', label: 'Матрица Судьбы' },
      { id: '30_15', slug: 'meditations', label: 'Медитации' },
      { id: '30_16', slug: 'numerology', label: 'Нумерология' },
      { id: '30_17', slug: 'occultism', label: 'Оккультизм' },
      { id: '30_18', slug: 'consciousDreaming', label: 'Осознанные сновидения' },
      { id: '30_19', slug: 'parapsychology', label: 'Парапсихология' },
      { id: '30_20', slug: 'singingBowls', label: 'Поющие чаши' },
      { id: '30_21', slug: 'psychosomatics', label: 'Психосоматика' },
      { id: '30_22', slug: 'regression', label: 'Регресс' },
      { id: '30_23', slug: 'sacredInstruments', label: 'Сакральные инструменты' },
      { id: '30_24', slug: 'spiritism', label: 'Спиритизм' },
      { id: '30_25', slug: 'tarot', label: 'Таро' },
      { id: '30_26', slug: 'thetaHealing', label: 'Тетахилинг' },
      { id: '30_27', slug: 'transurfing', label: 'Трансерфинг' },
      { id: '30_28', slug: 'transformationalGames', label: 'Трансформационные игры' },
      { id: '30_29', slug: 'fengShui', label: 'Фэн-шуй' },
      { id: '30_30', slug: 'palmistry', label: 'Хиромантия' },
      { id: '30_31', slug: 'healing', label: 'Целительство' },
      { id: '30_32', slug: 'channeling', label: 'Ченелинг' },
      { id: '30_33', slug: 'energyTherapy', label: 'Энерготерапия' },
      { id: '30_34', slug: 'clairvoyance', label: 'Ясновидение' }
    ]
  },
  {
    id: '31',
    slug: 'travelAndTourism',
    label: 'Путешествия и туризм',
    children: [
      { id: '31_0', slug: 'travelAndTourism', label: 'Путешествия и туризм' },
      { id: '31_1', slug: 'backpackerTourism', label: 'Бэкпэкер-туризм' },
      { id: '31_2', slug: 'bicycleTours', label: 'Велосипедные туры' },
      { id: '31_3', slug: 'climbing', label: 'Восхождения' },
      { id: '31_4', slug: 'gastronomicTourism', label: 'Гастрономический туризм' },
      { id: '31_5', slug: 'glamping', label: 'Глемпинг' },
      { id: '31_6', slug: 'skiingDownhill', label: 'Горнолыжные спуски' },
      { id: '31_7', slug: 'businessTourism', label: 'Деловой туризм' },
      { id: '31_8', slug: 'spiritualTourism', label: 'Духовный туризм' },
      { id: '31_9', slug: 'winterTourism', label: 'Зимние виды туризма' },
      { id: '31_10', slug: 'industrialTourism', label: 'Индустриальный туризм' },
      { id: '31_11', slug: 'caravanning', label: 'Караванинг' },
      { id: '31_12', slug: 'couchsurfing', label: 'Каучсерфинг' },
      { id: '31_13', slug: 'camping', label: 'Кемпинг' },
      { id: '31_14', slug: 'filmTourism', label: 'Кинотуризм' },
      { id: '31_15', slug: 'horseAndHiking', label: 'Конные и пешие прогулки' },
      { id: '31_16', slug: 'seaCruises', label: 'Морские круизы' },
      { id: '31_17', slug: 'seaTourism', label: 'Морской туризм' },
      { id: '31_18', slug: 'healthTourism', label: 'Оздоровительный туризм' },
      { id: '31_19', slug: 'pilgrimageTourism', label: 'Паломнический туризм' },
      { id: '31_20', slug: 'educationalTourism', label: 'Познавательный туризм' },
      { id: '31_21', slug: 'mountainHiking', label: 'Походы в горы' },
      { id: '31_22', slug: 'adventureTourism', label: 'Приключенческий туризм' },
      { id: '31_23', slug: 'hitchhiking', label: 'Путешествие автостопом' },
      { id: '31_24', slug: 'recreationalTourism', label: 'Рекреационный туризм' },
      { id: '31_25', slug: 'eventTourism', label: 'Событийный туризм' },
      { id: '31_26', slug: 'rafting', label: 'Сплавы' },
      { id: '31_27', slug: 'sportsTourism', label: 'Спортивный туризм' },
      { id: '31_28', slug: 'festivalTourism', label: 'Фестивальный туризм' },
      { id: '31_29', slug: 'photoTourism', label: 'Фототуризм' },
      { id: '31_30', slug: 'shoppingTourism', label: 'Шопинг-туризм' },
      { id: '31_31', slug: 'exoticTourism', label: 'Экзотический туризм' },
      { id: '31_32', slug: 'ecoTourism', label: 'Эко-туризм' },
      { id: '31_33', slug: 'extremeTourism', label: 'Экстремальный туризм' },
      { id: '31_34', slug: 'ethnicTourism', label: 'Этнический туризм' }
    ]
  },
  {
    id: '32',
    slug: 'animalWorld',
    label: 'Животный мир',
    children: [
      { id: '32_0', slug: 'animalWorld', label: 'Животный мир' },
      { id: '32_1', slug: 'aquariumKeeping', label: 'Аквариумистика' },
      { id: '32_2', slug: 'butterflyWatching', label: 'Бабочкарий' },
      { id: '32_3', slug: 'birdwatching', label: 'Бердвочинг' },
      { id: '32_4', slug: 'volunteering', label: 'Волонтерство' },
      { id: '32_5', slug: 'animalExhibition', label: 'Выставка животных' },
      { id: '32_6', slug: 'grooming', label: 'Грумминг' },
      { id: '32_7', slug: 'animalTraining', label: 'Дрессировка животных' },
      { id: '32_8', slug: 'animalProtection', label: 'Защита животных' },
      { id: '32_9', slug: 'zoology', label: 'Зоология' },
      { id: '32_10', slug: 'zoopsychology', label: 'Зоопсихология' },
      { id: '32_11', slug: 'zooShow', label: 'Зоошоу' },
      { id: '32_12', slug: 'canicross', label: 'Каникросс' },
      { id: '32_13', slug: 'canineTraining', label: 'Кинология' },
      { id: '32_14', slug: 'horseBreeding', label: 'Коневодство' },
      { id: '32_15', slug: 'equestrianSport', label: 'Конный спорт' },
      { id: '32_16', slug: 'contactZoo', label: 'Контактный зоопарк' },
      { id: '32_17', slug: 'rabbitBreeding', label: 'Кролиководство' },
      { id: '32_18', slug: 'antFarms', label: 'Муравьиные фермы' },
      { id: '32_19', slug: 'petClothing', label: 'Одежда для питомцев' },
      { id: '32_20', slug: 'cockfighting', label: 'Петушиные бои' },
      { id: '32_21', slug: 'feedProduction', label: 'Производство кормов' },
      { id: '32_22', slug: 'beekeeping', label: 'Пчеловодство' },
      { id: '32_23', slug: 'rodentBreeding', label: 'Разведение грызунов' },
      { id: '32_24', slug: 'domesticAnimalBreeding', label: 'Разведение домашних животных' },
      { id: '32_25', slug: 'wildAnimalBreeding', label: 'Разведение зверей' },
      { id: '32_26', slug: 'marineAnimalBreeding', label: 'Разведение морских животных' },
      { id: '32_27', slug: 'birdBreeding', label: 'Разведение птиц' },
      { id: '32_28', slug: 'exoticAnimalBreeding', label: 'Разведение экзотических животных' },
      { id: '32_29', slug: 'dogBreeding', label: 'Собаководство' },
      { id: '32_30', slug: 'roachRacing', label: 'Тараканьи бега' },
      { id: '32_31', slug: 'terrariumKeeping', label: 'Террариумистика' }
    ]
  },
  {
    id: '33',
    slug: 'dogLovers',
    label: 'Собачники',
    children: [
      { id: '33_0', slug: 'dogLovers', label: 'Собачники' },
      { id: '33_1', slug: 'australianShepherd', label: 'Австралийская овчарка (Аусси)' },
      { id: '33_2', slug: 'australianCattleDog', label: 'Австралийский келли' },
      { id: '33_3', slug: 'australianSilkyTerrier', label: 'Австралийский шелковистый терьер' },
      { id: '33_4', slug: 'alaskanKleeKai', label: 'Аляскинский кликай' },
      { id: '33_5', slug: 'alaskanMalamute', label: 'Аляскинский маламут' },
      { id: '33_6', slug: 'americanAkitaInu', label: 'Американская акита-ину' },
      { id: '33_7', slug: 'americanBulldog', label: 'Американский бульдог' },
      { id: '33_8', slug: 'americanHairlessTerrier', label: 'Американский голый терьер' },
      { id: '33_9', slug: 'americanCockerSpaniel', label: 'Американский кокер-спаниель' },
      { id: '33_10', slug: 'americanPitBullTerrier', label: 'Американский питбультерьер' },
      { id: '33_11', slug: 'americanStaffordshireTerrier', label: 'Американский стаффордширский терьер' },
      { id: '33_12', slug: 'americanEskimoDog', label: 'Американский эскимосский шпиц' },
      { id: '33_13', slug: 'englishBulldog', label: 'Английский бульдог' },
      { id: '33_14', slug: 'englishCockerSpaniel', label: 'Английский кокер-спаниель' },
      { id: '33_15', slug: 'englishMastiff', label: 'Английский мастиф' },
      { id: '33_16', slug: 'englishSetter', label: 'Английский сеттер' },
      { id: '33_17', slug: 'englishSpringerSpaniel', label: 'Английский спрингер-спаниель' },
      { id: '33_18', slug: 'argentineDogo', label: 'Аргентинский дог' },
      { id: '33_19', slug: 'afghanHound', label: 'Афганская борзая' },
      { id: '33_20', slug: 'affenpinscher', label: 'Аффепинчер' },
      { id: '33_21', slug: 'basenji', label: 'Басенджи' },
      { id: '33_22', slug: 'bassetHound', label: 'Бассет-хаунд' },
      { id: '33_23', slug: 'bedlingtonTerrier', label: 'Бедлингтон-терьер' },
      { id: '33_24', slug: 'swissWhiteShepherd', label: 'Белая швейцарская овчарка' },
      { id: '33_25', slug: 'belgianShepherd', label: 'Бельгийская овчарка' },
      { id: '33_26', slug: 'beaverYork', label: 'Бивер-йорк' },
      { id: '33_27', slug: 'beagle', label: 'Бигль' },
      { id: '33_28', slug: 'bichonFrise', label: 'Бишон фризе' },
      { id: '33_29', slug: 'bobtail', label: 'Бобтейл' },
      { id: '33_30', slug: 'boxer', label: 'Боксер' },
      { id: '33_31', slug: 'borderCollie', label: 'Бордер-колли' },
      { id: '33_32', slug: 'borderTerrier', label: 'Бордер-терьер' },
      { id: '33_33', slug: 'bordeauxDog', label: 'Бордоский дог' },
      { id: '33_34', slug: 'beardedCollie', label: 'Бородатый колли' },
      { id: '33_35', slug: 'bouvierDesFlandres', label: 'Босерон' },
      { id: '33_36', slug: 'bostonTerrier', label: 'Бостон-терьер' },
      { id: '33_37', slug: 'brusselsGriffon', label: 'Брюссельский гриффон' },
      { id: '33_38', slug: 'bullmastiff', label: 'Бульмастиф' },
      { id: '33_39', slug: 'bullTerrier', label: 'Бультерьер' },
      { id: '33_40', slug: 'weimaraner', label: 'Веймаранер' },
      { id: '33_41', slug: 'welshCorgi', label: 'Вельш-корги' },
      { id: '33_42', slug: 'welshTerrier', label: 'Вельштерьер' },
      { id: '33_43', slug: 'hungarianVizsla', label: 'Венгерская выжла (легавая)' },
      { id: '33_44', slug: 'hungarianKuvasz', label: 'Венгерский кувас' },
      { id: '33_45', slug: 'westHighlandWhiteTerrier', label: 'Вест-хайленд-уайт-терьер' },
      { id: '33_46', slug: 'eastSiberianLaika', label: 'Восточно-сибирская лайка' },
      { id: '33_47', slug: 'eastEuropeanShepherd', label: 'Восточноевропейская овчарка' },
      { id: '33_48', slug: 'pyreneanMountainDog', label: 'Горная пиренейская собака' },
      { id: '33_49', slug: 'greyhound', label: 'Грейхаунд' },
      { id: '33_50', slug: 'dalmatian', label: 'Далматинец' },
      { id: '33_51', slug: 'jackRussellTerrier', label: 'Джек рассел терьер' },
      { id: '33_52', slug: 'doberman', label: 'Доберман' },
      { id: '33_53', slug: 'drahthaar', label: 'Дратхаар или немецкая жесткошерстная легавая' },
      { id: '33_54', slug: 'eurasier', label: 'Евразиер' },
      { id: '33_55', slug: 'westSiberianLaika', label: 'Западно-сибирская лайка' },
      { id: '33_56', slug: 'goldenRetriever', label: 'Золотистый ретривер' },
      { id: '33_57', slug: 'irishWolfhound', label: 'Ирландский волкодав' },
      { id: '33_58', slug: 'irishSoftCoatedWheatenTerrier', label: 'Ирландский мягкошерстный пшеничный терьер' },
      { id: '33_59', slug: 'irishSetter', label: 'Ирландский сеттер' },
      { id: '33_60', slug: 'irishTerrier', label: 'Ирландский терьер' },
      { id: '33_61', slug: 'italianBolognese', label: 'Итальянская болонка (болоньез, болонский бишон)' },
      { id: '33_62', slug: 'italianGreyhound', label: 'Итальянская лавретка (итальянская борзая)' },
      { id: '33_63', slug: 'yorkshireTerrier', label: 'Йокширский терьер' },
      { id: '33_64', slug: 'cadeBou', label: 'Каде Бо (майорский мастиф)' },
      { id: '33_65', slug: 'cavalierKingCharlesSpaniel', label: 'Кавалер-кинг-чарльз-спаниель' },
      { id: '33_66', slug: 'cavapoo', label: 'Кавапу дизайнерская порода' },
      { id: '33_67', slug: 'caucasianShepherdDog', label: 'Кавказская овчарка' },
      { id: '33_68', slug: 'caneCorso', label: 'Кане-корсо' },
      { id: '33_69', slug: 'karelianBearDog', label: 'Карело-финская лайка' },
      { id: '33_70', slug: 'karelianBearDog', label: 'Карельская медвежья' },
      { id: '33_71', slug: 'keeshond', label: 'Кеесхонд (вольфшпиц)' },
      { id: '33_72', slug: 'kerryBlueTerrier', label: 'Керн-терьер' },
      { id: '33_73', slug: 'kerryBlueTerrier', label: 'Керри-блю-терьер' },
      { id: '33_74', slug: 'kishu', label: 'Кисю' },
      { id: '33_75', slug: 'chineseCrested', label: 'Китайская хохлатая' },
      { id: '33_76', slug: 'collieLonghaired', label: 'Колли длинношерстный' },
      { id: '33_77', slug: 'collieShorthaired', label: 'Колли короткошерстный' },
      { id: '33_78', slug: 'komondor', label: 'Комондор (венгерская овчарка)' },
      { id: '33_79', slug: 'continentalToySpaniel', label: 'Континентальный той-спаниель (паийон и фален)' },
      { id: '33_80', slug: 'germanShorthairedPointer', label: 'Курцхаар' },
      { id: '33_81', slug: 'labradorRetriever', label: 'Лабрадор ретривер' },
      { id: '33_82', slug: 'leonberger', label: 'Леонбергер' },
      { id: '33_83', slug: 'maltese', label: 'Мальтийская болонка' },
      { id: '33_84', slug: 'neapolitanMastiff', label: 'Мастино наполетано' },
      { id: '33_85', slug: 'mexicanHairlessDog', label: 'Мексиканская голая собака (ксоло)' },
      { id: '33_86', slug: 'miniatureSchnauzer', label: 'Миттельшнауцер' },
      { id: '33_87', slug: 'pug', label: 'Мопс' },
      { id: '33_88', slug: 'moscowWatchdog', label: 'Московская сторожева' },
      { id: '33_89', slug: 'germanShepherd', label: 'Немецкая овчарка' },
      { id: '33_90', slug: 'germanMastiff', label: 'Немецкий дог' },
      { id: '33_91', slug: 'germanHuntingTerrier', label: 'Немецкий ягдтерьер' },
      { id: '33_92', slug: 'novaScotiaDuckTollingRetriever', label: 'Новошотландский ретривер (толлер)' },
      { id: '33_93', slug: 'norwichTerrier', label: 'Норвич-терьер' },
      { id: '33_94', slug: 'newfoundland', label: 'Ньюфаундленд' },
      { id: '33_95', slug: 'pekingese', label: 'Пекинес' },
      { id: '33_96', slug: 'pointer', label: 'Пойнтер' },
      { id: '33_97', slug: 'polishLowlandSheepdog', label: 'Польская подгалянская овчарка' },
      { id: '33_98', slug: 'pomeranian', label: 'Померанский шпиц' },
      { id: '33_99', slug: 'portugueseWaterDog', label: 'Португальская водяная собака' },
      { id: '33_100', slug: 'brabant', label: 'Пти брабансон' },
      { id: '33_101', slug: 'poodle', label: 'Пудель' },
      { id: '33_102', slug: 'pumi', label: 'Пуми' },
      { id: '33_103', slug: 'giantSchnauzer', label: 'Ризеншнауцер' },
      { id: '33_104', slug: 'rhodesianRidgeback', label: 'Родезийский риджбек' },
      { id: '33_105', slug: 'rottweiler', label: 'Ротвейлер' },
      { id: '33_106', slug: 'russianHound', label: 'Русская пегая гончая' },
      { id: '33_107', slug: 'russianBorzoi', label: 'Русская псовая борзая' },
      { id: '33_108', slug: 'russianToyTerrier', label: 'Русская салонная собака (русалка)' },
      { id: '33_109', slug: 'russianSpaniel', label: 'Русский охотничий спаниель' },
      { id: '33_110', slug: 'russianToyTerrier', label: 'Русский той-терьер' },
      { id: '33_111', slug: 'blackRussianTerrier', label: 'Русский черный терьер' },
      { id: '33_112', slug: 'samoyed', label: 'Самоедская лайка' },
      { id: '33_113', slug: 'berneseMountainDog', label: 'Сенбернар' },
      { id: '33_114', slug: 'shibaInu', label: 'Сиба-ину' },
      { id: '33_115', slug: 'silkyTerrier', label: 'Силихем-терьер' },
      { id: '33_116', slug: 'skyTerrier', label: 'Скай-терьер' },
      { id: '33_117', slug: 'scottishTerrier', label: 'Скотч-терьер' },
      { id: '33_118', slug: 'centralAsianShepherdDog', label: 'Среднеазиатская овчарка (алабай)' },
      { id: '33_119', slug: 'staffordshireBullTerrier', label: 'Стаффордширский бультерьер' },
      { id: '33_120', slug: 'thaiRidgeback', label: 'Тайский риджбек' },
      { id: '33_121', slug: 'dachshund', label: 'Такса' },
      { id: '33_122', slug: 'tibetanMastiff', label: 'Тибетский мастиф' },
      { id: '33_123', slug: 'tosaInu', label: 'Тоса-ину' },
      { id: '33_124', slug: 'kangal', label: 'Турецкий кангал' },
      { id: '33_125', slug: 'whippet', label: 'Уиппет' },
      { id: '33_126', slug: 'pharaohHound', label: 'Фараоновая собака' },
      { id: '33_127', slug: 'foxTerrier', label: 'Фокстерьер' },
      { id: '33_128', slug: 'frenchBulldog', label: 'Французский бульдог' },
      { id: '33_129', slug: 'husky', label: 'Хаски' },
      { id: '33_130', slug: 'hovawart', label: 'Ховаварт' },
      { id: '33_131', slug: 'hokkaido', label: 'Хоккайдо' },
      { id: '33_132', slug: 'miniaturePinscher', label: 'Цвергпинчер' },
      { id: '33_133', slug: 'miniatureSchnauzer', label: 'Цвергшнауцер' },
      { id: '33_134', slug: 'chowChow', label: 'Чау-чау' },
      { id: '33_135', slug: 'chesapeakeBayRetriever', label: 'Чесапик-бей-ретривер' },
      { id: '33_136', slug: 'czechoslovakianWolfhound', label: 'Чехословацкая волчья' },
      { id: '33_137', slug: 'chihuahua', label: 'Чихуахуа' },
      { id: '33_138', slug: 'sharPei', label: 'Шарпей' },
      { id: '33_139', slug: 'swedishVallhund', label: 'Шведский вальхунд' },
      { id: '33_140', slug: 'swedishLapphund', label: 'шведский лаппхунд' },
      { id: '33_141', slug: 'swissMountainDog', label: 'Швейцарский зенненхунд' },
      { id: '33_142', slug: 'sheltie', label: 'Шелти' },
      { id: '33_143', slug: 'shihtzu', label: 'Ши-тцу' },
      { id: '33_144', slug: 'schipperke', label: 'Шипперке' },
      { id: '33_145', slug: 'scottishSetter', label: 'Шотландский сеттер (гордон)' },
      { id: '33_146', slug: 'erdelterrier', label: 'Эрдельтерьер' },
      { id: '33_147', slug: 'estonianHound', label: 'Эстонская гончая' },
      { id: '33_148', slug: 'southAfricanBoerboel', label: 'Южноафриканский бурбуль' },
      { id: '33_149', slug: 'southRussianOvcharka', label: 'Южнорусская овчарка' },
      { id: '33_150', slug: 'akitaInu', label: 'Японская акита-ину' },
      { id: '33_151', slug: 'japaneseChin', label: 'Японский хин' },
      { id: '33_152', slug: 'spitz', label: 'Шпиц' }
    ]
  },
  {
    id: '34',
    slug: 'koshatniki',
    label: 'Кошатники',
    children: [
      { id: '34_0', slug: 'koshatniki', label: 'Кошатники' },
      { id: '34_1', slug: 'abyssinian', label: 'Абиссинская' },
      { id: '34_2', slug: 'australianMist', label: 'Австралийский мист' },
      { id: '34_3', slug: 'asianTabby', label: 'Азиатская (табби)' },
      { id: '34_4', slug: 'americanWirehair', label: 'Американсая жесткошерсная' },
      { id: '34_5', slug: 'americanShorthair', label: 'Американская короткошерстная' },
      { id: '34_6', slug: 'americanBobtailShorthair', label: 'Американский бобтей короткошерстный' },
      { id: '34_7', slug: 'americanBobtailLonghair', label: 'Американский бобтейл длинношерстный' },
      { id: '34_8', slug: 'americanCurlLonghair', label: 'Американский кёрл длинношерстный' },
      { id: '34_9', slug: 'americanCurlShorthair', label: 'Американский кёрл короткошерстный' },
      { id: '34_10', slug: 'anatolian', label: 'Анатолийская' },
      { id: '34_11', slug: 'arabianMau', label: 'Аравийский мау' },
      { id: '34_12', slug: 'balinese', label: 'Балинезийская' },
      { id: '34_13', slug: 'bengal', label: 'Бенгальская' },
      { id: '34_14', slug: 'bombay', label: 'Бомбейная' },
      { id: '34_15', slug: 'brazilianShorthair', label: 'Бразильская короткошерстная' },
      { id: '34_16', slug: 'britishLonghair', label: 'Британская длинношерстная' },
      { id: '34_17', slug: 'britishShorthair', label: 'Британская короткошерстная' },
      { id: '34_18', slug: 'burmese', label: 'Бурманская' },
      { id: '34_19', slug: 'burmillaLonghair', label: 'Бурмилла длинношерстный' },
      { id: '34_20', slug: 'burmillaShorthair', label: 'Бурмилла короткошерстный' },
      { id: '34_21', slug: 'havanaBrown', label: 'Гаванна' },
      { id: '34_22', slug: 'himalayan', label: 'Гималайская' },
      { id: '34_23', slug: 'devonRex', label: 'Девон рекс' },
      { id: '34_24', slug: 'domestic', label: 'Домашняя' },
      { id: '34_25', slug: 'donSphynx', label: 'Донской сфинкс' },
      { id: '34_26', slug: 'egyptianMau', label: 'Египетская мау' },
      { id: '34_27', slug: 'york', label: 'Йорк' },
      { id: '34_28', slug: 'kaani', label: 'Каани' },
      { id: '34_29', slug: 'californiaSpangled', label: 'Калифорнийская сияющая' },
      { id: '34_30', slug: 'karelianBobtailLonghair', label: 'Карельский бобтейл длинношерстный' },
      { id: '34_31', slug: 'karelianBobtailShorthair', label: 'Карельский бобтейл короткошерстный' },
      { id: '34_32', slug: 'celtic', label: 'Кельтская' },
      { id: '34_33', slug: 'kimrik', label: 'Кимрик' },
      { id: '34_34', slug: 'kokoke', label: 'Кококе' },
      { id: '34_35', slug: 'colorpoint', label: 'Колорпоинт' },
      { id: '34_36', slug: 'korat', label: 'Корниш рекс' },
      { id: '34_37', slug: 'kurilianBobtail', label: 'Курильский бобтейл' },
      { id: '34_38', slug: 'kurilianBobtailLonghair', label: 'Курильский бобтейл длинношерстный' },
      { id: '34_39', slug: 'kurilianBobtailShorthair', label: 'Курильский бобтейл короткошерстный' },
      { id: '34_40', slug: 'lapermLonghair', label: 'Лаперм длинношерстный' },
      { id: '34_41', slug: 'lapermShorthair', label: 'Лаперм короткошерстный' },
      { id: '34_42', slug: 'munchkinLonghair', label: 'Манчкин длинношерстный' },
      { id: '34_43', slug: 'munchkinShorthair', label: 'Манчкин короткошерстная' },
      { id: '34_44', slug: 'maineCoon', label: 'Мейн-кун' },
      { id: '34_45', slug: 'mekongBobtail', label: 'Меконгский бобтейл' },
      { id: '34_46', slug: 'minskin', label: 'Минскин' },
      { id: '34_47', slug: 'munchkin', label: 'Мэнкс' },
      { id: '34_48', slug: 'napoleon', label: 'Наполеон' },
      { id: '34_49', slug: 'nevaMasquerade', label: 'Невская маскарадная' },
      { id: '34_50', slug: 'germanRex', label: 'Немецкий рекс' },
      { id: '34_51', slug: 'nebelung', label: 'Нибелунг' },
      { id: '34_52', slug: 'norwegianForest', label: 'Норвежская лесная' },
      { id: '34_53', slug: 'oregonRex', label: 'Орегон рекс' },
      { id: '34_54', slug: 'orientalLonghair', label: 'Ориентальная длинношерстная' },
      { id: '34_55', slug: 'orientalShorthair', label: 'Ориентальная короткошерстная' },
      { id: '34_56', slug: 'ochosAzules', label: 'Охос азулес' },
      { id: '34_57', slug: 'ochosAzulesLonghair', label: 'Охос азулес длинношерстный' },
      { id: '34_58', slug: 'ocicat', label: 'Оцикет' },
      { id: '34_59', slug: 'persian', label: 'Персидская' },
      { id: '34_60', slug: 'peterbald', label: 'Петерболд' },
      { id: '34_61', slug: 'pixiebobLonghair', label: 'Пиксибоб длинношерстный' },
      { id: '34_62', slug: 'pixiebobShorthair', label: 'Пиксибоб короткошерстный' },
      { id: '34_63', slug: 'ragamuffin', label: 'Рагамаффин' },
      { id: '34_64', slug: 'russianBlue', label: 'Русская голубая' },
      { id: '34_65', slug: 'ragdoll', label: 'Рэкдолл' },
      { id: '34_66', slug: 'savannah', label: 'Саванна' },
      { id: '34_67', slug: 'sarekgeti', label: 'Саренгети' },
      { id: '34_68', slug: 'sacredBirman', label: 'Священная бирманская' },
      { id: '34_69', slug: 'seychelloisLonghair', label: 'Сейшельская длинношерстная' },
      { id: '34_70', slug: 'seychelloisShorthair', label: 'Сейшельская короткошерстная' },
      { id: '34_71', slug: 'selkirkRexLonghair', label: 'Селкирк рекс длинношерстный' },
      { id: '34_72', slug: 'selkirkRexShorthair', label: 'Селкирк рекс короткошерстный' },
      { id: '34_73', slug: 'siamese', label: 'Сиамская' },
      { id: '34_74', slug: 'siberian', label: 'Сибирская' },
      { id: '34_75', slug: 'singaporean', label: 'Сингапурская' },
      { id: '34_76', slug: 'scottishStraight', label: 'Скоттиш страйт' },
      { id: '34_77', slug: 'scottishFold', label: 'Скоттиш фолд' },
      { id: '34_78', slug: 'snowshoe', label: 'Сноу-Шу' },
      { id: '34_79', slug: 'somali', label: 'Сомали' },
      { id: '34_80', slug: 'sphinxCanadian', label: 'Сфинкс (канадский)' },
      { id: '34_81', slug: 'thai', label: 'Тайская' },
      { id: '34_82', slug: 'toyger', label: 'Тойгер' },
      { id: '34_83', slug: 'tonkinese', label: 'Тонкинская' },
      { id: '34_84', slug: 'turkishAngora', label: 'Турецкая ангора' },
      { id: '34_85', slug: 'turkishVan', label: 'Турецкий ван' },
      { id: '34_86', slug: 'ukrainianLevkoy', label: 'Украинский левкой' },
      { id: '34_87', slug: 'uralRexLonghair', label: 'Уральский рекс длинношерстный' },
      { id: '34_88', slug: 'uralRexShorthair', label: 'Уральский рекс короткошерстный' },
      { id: '34_89', slug: 'forinWhite', label: 'Форин Вайт' },
      { id: '34_90', slug: 'highlandFold', label: 'Хайленд фолд' },
      { id: '34_91', slug: 'chantillyTiffany', label: 'Шантильи-тиффани' },
      { id: '34_92', slug: 'chartreux', label: 'Шартрез' },
      { id: '34_93', slug: 'aegean', label: 'Эгейская' },
      { id: '34_94', slug: 'exotic', label: 'Экзотическая' },
      { id: '34_95', slug: 'javanese', label: 'Яванез' },
      { id: '34_96', slug: 'japaneseBobtailLonghair', label: 'Японский бобтейл длинношерстный' },
      { id: '34_97', slug: 'japaneseBobtailShorthair', label: 'Японский бобтейл короткошерстный' }
    ]
  },
  {
    id: '35',
    slug: 'motherhood',
    label: 'Материнство',
    children: [
      { id: '35_0', slug: 'motherhood', label: 'Материнство' },
      { id: '35_1', slug: 'childrensClothing', label: 'Детская одежда' },
      { id: '35_2', slug: 'childPsychology', label: 'Детская психология' },
      { id: '35_3', slug: 'childrensBooks', label: 'Детские книги' },
      { id: '35_4', slug: 'educationalGamesForChildren', label: 'Детские развивающие игры' },
      { id: '35_5', slug: 'childrensLeisure', label: 'Детский досуг' },
      { id: '35_6', slug: 'childrensHealth', label: 'Детское здоровье' },
      { id: '35_7', slug: 'babyFood', label: 'Детское питание' },
      {
        id: '35_8',
        slug: 'developmentOfEmotionalIntelligenceInChildren',
        label: 'Развитие эмоционального интеллекта у детей'
      },
      { id: '35_9', slug: 'haircutsHairstylesForChildren', label: 'Стрижки, прически' }
    ]
  },
  {
    id: '36',
    slug: 'Garder',
    label: 'Сад и огород',
    children: [{ id: '36_0', slug: 'Garder', label: 'Сад и огород' }]
  },
  {
    id: '37',
    slug: 'HauntingFishing',
    label: 'Охота и Рыбалка',
    children: [
      { id: '37_0', slug: 'HauntingFishing', label: 'Охота и Рыбалка' },
      { id: '37_1', slug: 'Haunting', label: 'Охота' },
      { id: '37_2', slug: 'Fishing', label: 'Рыбалка' }
    ]
  },
  {
    id: '38',
    slug: 'businessAndEntrepreneurship',
    label: 'Бизнес и предпринимательство',
    children: [
      { id: '38_0', slug: 'businessAndEntrepreneurship', label: 'Бизнес и предпринимательство' },
      { id: '38_1', slug: 'handmade', label: 'Handmade' },
      { id: '38_2', slug: 'it', label: 'IT' },
      { id: '38_3', slug: 'seo', label: 'SEO' },
      { id: '38_4', slug: 'smm', label: 'SMM' },
      { id: '38_5', slug: 'businessForChildren', label: 'Бизнес для детей' },
      { id: '38_6', slug: 'businessWithChina', label: 'Бизнес с Китаем' },
      { id: '38_7', slug: 'charitableFoundations', label: 'Благотворительные фонды' },
      { id: '38_8', slug: 'beautyIndustry', label: 'Бьюти - сфера' },
      { id: '38_9', slug: 'designAndTailoringOfClothes', label: 'Дизайн и пошив одежды' },
      { id: '38_10', slug: 'interiorDesign', label: 'Дизайн интерьеров' },
      { id: '38_11', slug: 'dropshipping', label: 'Дропшипинг' },
      { id: '38_12', slug: 'investments', label: 'Инвестиции' },
      { id: '38_13', slug: 'artAndCreativity', label: 'Исскусство и творчество' },
      { id: '38_14', slug: 'cleaning', label: 'Клининг' },
      { id: '38_15', slug: 'cosmetology', label: 'Косметология' },
      { id: '38_16', slug: 'consultingAccountingLegal', label: 'Консалтинг, бухгалтерия, юриспруденция' },
      { id: '38_17', slug: 'cryptocurrency', label: 'Криптовалюта' },
      { id: '38_18', slug: 'marathons', label: 'Марафоны' },
      { id: '38_19', slug: 'marketplaces', label: 'Маркетплейсы' },
      { id: '38_20', slug: 'medicalServices', label: 'Медицинские услуги' },
      { id: '38_21', slug: 'realEstate', label: 'Недвижимость' },
      { id: '38_22', slug: 'nutrition', label: 'Нутрициология' },
      { id: '38_23', slug: 'catering', label: 'Общепит' },
      { id: '38_24', slug: 'onlineEducation', label: 'Онлайн - обучения' },
      { id: '38_25', slug: 'staffRecruitment', label: 'Подбор персонала' },
      { id: '38_26', slug: 'production', label: 'Продюссирование' },
      { id: '38_27', slug: 'furnitureManufacturing', label: 'Производство мебели' },
      { id: '38_28', slug: 'psychology', label: 'Психология' },
      { id: '38_29', slug: 'animalBreeding', label: 'Разведение животных' },
      { id: '38_30', slug: 'boardGameDevelopment', label: 'Разработка настольных игр' },
      { id: '38_31', slug: 'advertisingPromotion', label: 'Реклама, продвижение' },
      { id: '38_32', slug: 'repairAndConstruction', label: 'Ремонт и строительство' },
      { id: '38_33', slug: 'selfDevelopment', label: 'Саморазвитие' },
      { id: '38_34', slug: 'startups', label: 'Стартапы' },
      { id: '38_35', slug: 'stylingAndFashion', label: 'Стилистика и мода' },
      { id: '38_36', slug: 'transportAndLogistics', label: 'Транспорт и логистика' },
      { id: '38_37', slug: 'tourism', label: 'Туризм' },
      { id: '38_38', slug: 'goodsAndServicesForAnimals', label: 'Услуги и товары для животных' },
      { id: '38_39', slug: 'farming', label: 'Фермерство' },
      { id: '38_40', slug: 'photoAndVideo', label: 'Фото и видео' },
      { id: '38_41', slug: 'franchises', label: 'Франшизы' },
      { id: '38_42', slug: 'esoterics', label: 'Эзотерика' },
      { id: '38_43', slug: 'ecoProducts', label: 'ЭКО-товары' },
      { id: '38_44', slug: 'languageCourses', label: 'Языковые курсы' }
    ]
  },
  {
    id: '39',
    slug: 'volonteer',
    label: 'Волонтерство',
    children: [{ id: '39_1', slug: 'volonteer', label: 'Волонтерство' }]
  },
  {
    id: '40',
    slug: 'footballClubs',
    label: 'Футбольные клубы',
    children: [
      { id: '40_0', slug: 'footballClubs', label: 'Футбольные клубы' },
      { id: '40_1', slug: 'bayern', label: 'Бавария' },
      { id: '40_2', slug: 'barcelona', label: 'Барселона' },
      { id: '40_3', slug: 'dynamo', label: 'Динамо' },
      { id: '40_4', slug: 'zenit', label: 'Зенит' },
      { id: '40_5', slug: 'kryliaSovetov', label: 'Крылья Советов' },
      { id: '40_6', slug: 'lokomotiv', label: 'Локомотив' },
      { id: '40_7', slug: 'manchesterUnited', label: 'Манчестер Юнайтед' },
      { id: '40_8', slug: 'marseille', label: 'Марсель' },
      { id: '40_9', slug: 'milan', label: 'Милан' },
      { id: '40_10', slug: 'parisSaintGermain', label: 'Пари Сен-Жермен' },
      { id: '40_11', slug: 'realMadrid', label: 'Реал Мадрид' },
      { id: '40_12', slug: 'rostov', label: 'Ростов' },
      { id: '40_13', slug: 'spartak', label: 'Спартак' },
      { id: '40_14', slug: 'torpedo', label: 'Торпедо' },
      { id: '40_15', slug: 'fakel', label: 'Факел' },
      { id: '40_16', slug: 'fenerbahce', label: 'Фенербахче' },
      { id: '40_17', slug: 'cska', label: 'ЦСКА' },
      { id: '40_18', slug: 'chelsea', label: 'Челси' },
      { id: '40_19', slug: 'juventus', label: 'Ювентус' }
    ]
  },
  {
    id: '41',
    slug: 'collecting',
    label: 'Коллекционирование',
    children: [
      { id: '41_0', slug: 'collecting', label: 'Коллекционирование' },
      { id: '41_1', slug: 'barbiphilia', label: 'Барбифилия' },
      { id: '41_2', slug: 'vectorism', label: 'Вектуризм' },
      { id: '41_3', slug: 'cactusophilia', label: 'Кактусофилия' },
      { id: '41_4', slug: 'kindophilia', label: 'Киндерфилия' },
      { id: '41_5', slug: 'bookophilia', label: 'Книгофилия' },
      { id: '41_6', slug: 'artCollecting', label: 'Коллекционирование предметов исскуства' },
      { id: '41_7', slug: 'collectingVariousGroups', label: 'Коллекционирование разных групп' },
      { id: '41_8', slug: 'loteristics', label: 'Лотеристика' },
      { id: '41_9', slug: 'memomagnetism', label: 'Мемомагнетика' },
      { id: '41_10', slug: 'numismatics', label: 'Нумизматика' },
      { id: '41_11', slug: 'saculmistics', label: 'Сакулумистика' },
      { id: '41_12', slug: 'scripophilia', label: 'Скрипофилия' },
      { id: '41_13', slug: 'stilophilia', label: 'Стилофилия' },
      { id: '41_14', slug: 'tokenophilia', label: 'Токенофилия' },
      { id: '41_15', slug: 'phaleristics', label: 'Фалеристика' },
      { id: '41_16', slug: 'philately', label: 'Филателия' },
      { id: '41_17', slug: 'philobutonistics', label: 'Филобутонистика' },
      { id: '41_18', slug: 'philography', label: 'Филография' },
      { id: '41_19', slug: 'philokartia', label: 'Филокартия' },
      { id: '41_20', slug: 'exonumia', label: 'Экзонумия' },
      { id: '41_21', slug: 'exlibrism', label: 'Экслибристика' },
      { id: '41_22', slug: 'errorism', label: 'Эрроризм' }
    ]
  }
]

import { GET_MY_CHATS_REQUEST, GET_MY_CHATS_SUCCESS, GetMyChatsActionTypes, GetMyChatsState } from '@redux/types'

const initialState: GetMyChatsState = { data: [], counter: 0 }

export const notificationHistoryReducer = (state = initialState, action: GetMyChatsActionTypes): GetMyChatsState => {
  switch (action.type) {
    case GET_MY_CHATS_REQUEST:
      return state
    case GET_MY_CHATS_SUCCESS:
      return action.payload.data
    default:
      return state
  }
}

import { CHECK_CODE_REQUEST, CHECK_CODE_SUCCESS, CheckCodeActionTypes } from '@redux/types'

export const checkCodeReducer = (state = '', action: CheckCodeActionTypes): string => {
  switch (action.type) {
    case CHECK_CODE_REQUEST:
      return ''
    case CHECK_CODE_SUCCESS:
      return action.payload
    default:
      return state
  }
}

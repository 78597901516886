import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Keyboard, Pressable, StyleSheet, View } from 'react-native'

import { Button } from '@components'
import { isAndroid } from '@components/utils'

const styles = StyleSheet.create({
  button: {
    width: '100%'
  },
  container: {
    zIndex: 1
  }
})

interface SubmitButtonProps {
  onPress: () => void
  isEditing: boolean | undefined
  buttonLabelCondition?: boolean
  center?: boolean
  disabled?: boolean
  marginTop?: number
  children?: ReactNode
  label?: string
}

export const SubmitButton = ({
  buttonLabelCondition,
  center,
  disabled,
  label,
  onPress,
  isEditing,
  marginTop,
  children
}: SubmitButtonProps) => {
  const [keyboardShown, setKeyboardShown] = useState(false)

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => setKeyboardShown(true))
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => setKeyboardShown(false))

    return () => {
      keyboardDidShowListener.remove()
      keyboardDidHideListener.remove()
    }
  }, [])

  const submitLabel = useMemo(() => {
    if (isEditing) {
      return 'Готово'
    }

    return label || (buttonLabelCondition ? 'Продолжить' : 'Пропустить')
  }, [buttonLabelCondition, isEditing, label])

  return (
    <View
      style={[
        styles.container,
        isAndroid && keyboardShown && { marginBottom: -200 },
        center && { alignItems: 'center' }
      ]}
    >
      {children}
      <Pressable style={[styles.button, !!marginTop && { marginTop }]}>
        <Button text={submitLabel} onPress={onPress} disabled={disabled} />
      </Pressable>
    </View>
  )
}

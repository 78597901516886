import { useEffect, useState } from 'react'
import { SafeAreaView, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { BackSvg } from '@assets/icons'
import { CustomNotification, Typography } from '@components'
import { formatUserName, isAndroid } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectNotificationHistoryData, selectPhone } from '@redux/selectors'

const styles = StyleSheet.create({
  mainWrapper: {
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: 15
  },
  topWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    paddingHorizontal: 30,
    paddingTop: isAndroid ? 15 : 5
  }
})

interface NotificationAtributes {
  textNotifaction: string
  photoNotifaction: string
  time: string
}

export const Notification = () => {
  const { navigation } = useNavigator<'Main'>()
  const { getNotificationHistory } = useDataLayer()
  const currentUserPhone = useSelector(selectPhone)
  const myChats = useSelector(selectNotificationHistoryData)
  const [notifications, setNotifications] = useState<NotificationAtributes[]>([])

  useEffect(() => {
    getNotificationHistory()
  }, [])

  const getNotificationsFromChats = () => {
    const notificationsList = [] as NotificationAtributes[]

    myChats.forEach(chat => {
      if (!chat.interlocutor) {
        return
      }

      if (chat.status !== 'accepted') {
        notificationsList.push({
          time: chat.timeInvited,
          textNotifaction: `${formatUserName(chat.interlocutor.name)} отправил(ла) вам запрос`,
          photoNotifaction: chat.interlocutor.profileImage[0].name
        })
        return
      }

      if (chat.invited === currentUserPhone) {
        notificationsList.push({
          time: chat.timeInvited,
          textNotifaction: `${formatUserName(chat.interlocutor.name)} отправил(ла) вам запрос`,
          photoNotifaction: chat.interlocutor.profileImage[0].name
        })
        return
      }

      notificationsList.push({
        time: chat.timeAccepted,
        textNotifaction: `${chat.interlocutor.name} принял(ла) ваш запрос`,
        photoNotifaction: chat.interlocutor.profileImage[0].name
      })
    })

    notificationsList.sort((a, b) => moment(b.time).valueOf() - moment(a.time).valueOf())
    return notificationsList
  }

  useEffect(() => {
    setNotifications(getNotificationsFromChats())
  }, [myChats])

  const goToMain = () => {
    navigation.navigate('Main')
  }

  const renderNotifications = () =>
    notifications.map(notification => (
      <CustomNotification
        key={notification.time}
        textNotifaction={notification.textNotifaction}
        photoNotifaction={notification.photoNotifaction}
      />
    ))

  return (
    <SafeAreaView style={{ backgroundColor: '#F3F2F9' }}>
      <StatusBar backgroundColor='#F3F2F9' barStyle='dark-content' />
      <View style={styles.topWrapper}>
        <TouchableOpacity onPress={goToMain}>
          <BackSvg />
        </TouchableOpacity>
        <Typography f17 semibold>
          Уведомления
        </Typography>
        <View style={{ width: 40, height: 40 }} />
      </View>
      <View style={styles.mainWrapper}>{renderNotifications()}</View>
    </SafeAreaView>
  )
}

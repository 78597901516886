import { useEffect, useMemo, useRef, useState } from 'react'
import { Animated, KeyboardTypeOptions, StyleSheet, TextInput, View } from 'react-native'

import { Colors, Fonts, typography } from '@components/utils'

const styles = StyleSheet.create({
  input: {
    borderRadius: 40,
    borderWidth: 1,
    color: Colors.DarkGray,
    height: 50,
    paddingBottom: 5,
    paddingHorizontal: 20,
    ...typography.description
  },
  inputWrapper: {
    position: 'relative'
  },
  label: {
    fontFamily: Fonts.medium,
    lineHeight: 32,
    paddingHorizontal: 20
  },
  labelContainer: {
    flexDirection: 'row',
    left: 0,
    marginBottom: 41,
    position: 'absolute'
  }
})

interface InputProps {
  type: 'text' | 'password'
  mask?: string
  errorText?: string | null
  placeholder?: string
  keyboardType?: KeyboardTypeOptions | undefined
  defaultValue?: string
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
  maxLength?: number
  autoFocus?: boolean
  valid?: boolean
  onChangeText: (value: string) => void
  onBlur?: (e: any) => void
  onForgotPassword?: () => void
}

export const Input = ({
  type,
  errorText,
  placeholder,
  defaultValue,
  keyboardType,
  marginTop = 0,
  marginBottom = 0,
  marginLeft = 0,
  marginRight = 0,
  maxLength,
  autoFocus,
  valid,
  onBlur,
  onChangeText
}: InputProps) => {
  const [isActive, setIsActive] = useState(false)
  const focusAnimated = useRef(new Animated.Value(0)).current

  const marginStyles = { marginTop, marginBottom, marginLeft, marginRight }

  useEffect(() => {
    Animated.timing(focusAnimated, {
      toValue: isActive || defaultValue || errorText ? 1 : 0,
      duration: 150,
      useNativeDriver: false
    }).start()
  }, [focusAnimated, defaultValue, isActive, errorText])

  const onFocusHandler = () => {}

  const onBlurHandler = (e: any) => {
    if (onBlur) {
      onBlur(e)
    }
  }

  const animatedTextColor = useMemo(() => {
    if (valid && defaultValue) {
      return Colors.DarkGray
    }

    return errorText ? Colors.Red : Colors.Gray
  }, [])

  return (
    <View style={[styles.inputWrapper, marginStyles]}>
      <Animated.View
        style={[
          {
            top: focusAnimated.interpolate({
              inputRange: [0, 1],
              outputRange: [8, -16]
            })
          },
          styles.labelContainer
        ]}
      >
        <Animated.Text
          style={[
            {
              fontSize: focusAnimated.interpolate({
                inputRange: [0, 1],
                outputRange: [16, 14]
              }),
              color: animatedTextColor
            },
            styles.label
          ]}
        >
          {isActive && errorText ? errorText : placeholder}
        </Animated.Text>
      </Animated.View>
      {type === 'text' && (
        <TextInput
          onChangeText={e => {
            onChangeText(e)
            if (!isActive) {
              setIsActive(true)
            }
          }}
          defaultValue={defaultValue}
          placeholder={errorText ? placeholder : ''}
          keyboardType={keyboardType}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          autoFocus={autoFocus}
          style={[
            styles.input,
            marginStyles,
            {
              borderColor: errorText ? Colors.Red : Colors.Gray
            }
          ]}
          placeholderTextColor={Colors.Gray}
          maxLength={maxLength}
        />
      )}
    </View>
  )
}

import { Linking, Pressable, StyleSheet } from 'react-native'

import { Typography } from '@components'
import { LINKS } from '@utils'

const styles = StyleSheet.create({
  descriptionLink: {
    marginLeft: 2
  }
})

export const TermsMessage = () => {
  const openUrlByRoute = (route: LINKS) => {
    Linking.openURL('https://info.connectapp.me' + route)
  }

  return (
    <>
      <Typography f12 medium textAlign='center' color='#A2A0AC'>
        Продолжая, вы принимаете наши{' '}
        <Pressable style={styles.descriptionLink} onPress={() => openUrlByRoute(LINKS.termsOfUse)}>
          <Typography underline f10 medium textAlign='center' color='#A2A0AC'>
            Условия
          </Typography>
        </Pressable>
      </Typography>
      <Typography f12 medium textAlign='center' color='#A2A0AC'>
        Узнайте как мы обрабатываем ваши данные -
        <Pressable style={styles.descriptionLink} onPress={() => openUrlByRoute(LINKS.privacyPolicy)}>
          <Typography underline f10 medium textAlign='center' color='#A2A0AC'>
            Политика
          </Typography>
        </Pressable>
      </Typography>
    </>
  )
}

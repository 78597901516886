import { useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { EnvelopeSvg, LocationSvg } from '@assets/icons'
import { Chip, MoodScaleModal, SFlex, Typography } from '@components'
import { formatUserName, getMoodIconByMoodValue } from '@components/utils'
import { useNavigator } from '@navigation'
import { selectUserChatId } from '@redux/selectors'
import { Mood, UserChat } from '@types'
import { getStatusById, StatusItem } from '@utils'

interface ModelMainInfoProps {
  name: string
  city: string
  chat: UserChat | undefined
  age: number
  moodStatusId: number
  mood: Mood
}

const styles = StyleSheet.create({
  chip: {
    backgroundColor: 'transparent',
    borderColor: '#fff',
    borderWidth: 2,
    height: 40
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginTop: 10
  }
})

export const ModelMainInfo = ({ name, city, age, moodStatusId, mood, chat }: ModelMainInfoProps) => {
  const [moodModalOpen, setMoodModalOpen] = useState<boolean>(false)
  const userId = useSelector(selectUserChatId)
  const { navigation } = useNavigator<'ModelScreen'>()

  const MoodIcon = useMemo(() => getMoodIconByMoodValue(mood.average), [mood.average])
  const moodStatus: StatusItem = useMemo(() => getStatusById(moodStatusId), [moodStatusId])
  const { icon: StatusIcon, text } = moodStatus

  const openModal = () => setMoodModalOpen(true)

  return (
    <>
      <SFlex justifyContent='space-between' zIndex={1}>
        <View>
          <SFlex gap={10} marginBottom={8}>
            <LocationSvg />
            <Typography f14 normal color='#ffffff'>
              {city}
            </Typography>
          </SFlex>
          <SFlex>
            <Typography f24 bold color='#ffffff'>
              {formatUserName(name)}
            </Typography>
            <Typography f24 light color='#ffffff' marginLeft={12}>
              {age}
            </Typography>
          </SFlex>
          <View style={styles.statusContainer}>
            {chat && (
              <Chip
                label='сообщение'
                style={{ height: 40 }}
                bgColor='#fff'
                leftIcon={<EnvelopeSvg />}
                onPress={() => navigation.navigate('Chat', { chat, userId })}
              />
            )}
            {moodStatus && (
              <Chip
                label={text.ru}
                style={styles.chip}
                textColor='#fff'
                leftIcon={<StatusIcon fill='white' />}
                onPress={openModal}
              />
            )}
            <Chip style={styles.chip} textColor='#fff' leftIcon={<MoodIcon fill='white' />} onPress={openModal} />
          </View>
        </View>
      </SFlex>
      <MoodScaleModal
        chat={chat}
        isVisible={moodModalOpen}
        moodText={mood.text}
        moodStatus={moodStatus}
        close={() => setMoodModalOpen(false)}
        average={mood.average}
      />
    </>
  )
}

export { default as BasketBlockSvg } from './basketBlock.svg'
export { default as BusinessBlockSvg } from './BusinessBlock.svg'
export { default as DocumentBlockSvg } from './documentBlock.svg'
export { default as ExitBlockSvg } from './exitBlock.svg'
export { default as FriendsBlockSvg } from './FriendsBlock.svg'
export { default as LockBlockSvg } from './lockBlock.svg'
export { default as LoveBlockSvg } from './LoveBlock.svg'
export { default as SexBlockSvg } from './SexBlock.svg'
export { default as ShareBlockSvg } from './ShareBlock.svg'
export { default as TranslateBlockSvg } from './translateBlock.svg'
export { default as WriteBlockSvg } from './WriteBlock.svg'

import { useState } from 'react'
import { Image, Pressable, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { HideEye, ShowEye } from '@assets/images'
import { Button, HeaderInfo, TextInput, Typography } from '@components'
import { isAndroid } from '@components/utils'
import { useDataLayer } from '@redux/dataLayer'
import { selectPhone } from '@redux/selectors'

const styles = StyleSheet.create({
  MainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isAndroid ? 15 : 50
  },
  centerWrapper: {
    flex: 1
  },
  inputWrapper: {
    height: 50,
    marginBottom: 24,
    position: 'relative'
  },
  passwordWrapper: {
    position: 'absolute',
    right: 20,
    top: 23
  }
})

export const ChangePassword = () => {
  const { changePassword, restore } = useDataLayer()
  const restorePhone = useSelector(selectPhone)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [isHidden, setIsHidden] = useState(true)
  const toggleIsHidden = () => setIsHidden(current => !current)
  const toggleIcon = isHidden ? HideEye : ShowEye

  return (
    <View style={styles.MainWrapper}>
      <View style={styles.centerWrapper}>
        <HeaderInfo />
        <Typography f27 bold marginBottom={24} marginTop={44}>
          {' '}
          Смена пароля
        </Typography>
        <View style={styles.inputWrapper}>
          <TextInput
            onChange={value => setOldPassword(value)}
            label='Старый пароль'
            placeholder='Введите старый пароль'
            isHidden={isHidden}
            rightIcon={() => (
              <Pressable onPress={toggleIsHidden} style={styles.passwordWrapper}>
                <Image source={toggleIcon} style={{ width: 20, height: 20 }} />
              </Pressable>
            )}
          />
        </View>

        <View style={styles.inputWrapper}>
          <TextInput
            onChange={value => setNewPassword(value)}
            label='Новый пароль'
            placeholder='Придумайте пароль'
            isHidden={isHidden}
            rightIcon={() => (
              <Pressable onPress={toggleIsHidden} style={styles.passwordWrapper}>
                <Image source={toggleIcon} style={{ width: 20, height: 20 }} />
              </Pressable>
            )}
          />
        </View>
      </View>
      <View>
        <TouchableOpacity
          onPress={() => {
            if (!restorePhone) {
              return
            }

            restore(restorePhone as string, {
              page: 'EnterCode',
              params: {
                phone: restorePhone,
                change: true,
                isLogin: true
              }
            })
          }}
        >
          <Typography f15 normal textAlign='center' marginBottom={16} underline>
            Забыли пароль?
          </Typography>
        </TouchableOpacity>
        <Button
          text='Продолжить'
          disabled={oldPassword === '' || newPassword === ''}
          onPress={() => {
            changePassword(restorePhone as string, oldPassword, newPassword, {
              page: 'Settings'
            })
          }}
        />
      </View>
    </View>
  )
}

import { ProfileOptions } from '@utils'

type Genres = Record<string, ProfileOptions>

export enum GenreTypes {
  Cinema = 'Cinema',
  Music = 'Music',
  Literature = 'Literature'
}

export const GENRES: Genres = {
  [GenreTypes.Cinema]: [
    { id: 0, slug: 'dcComics', label: 'DC комикс' },
    { id: 1, slug: 'marvelMovies', label: 'Marvel кино' },
    { id: 2, slug: 'anime', label: 'Аниме' },
    { id: 3, slug: 'arthouse', label: 'Артхаус' },
    { id: 4, slug: 'biographies', label: 'Биографии' },
    { id: 5, slug: 'blockbusters', label: 'Блокбастеры' },
    { id: 6, slug: 'action', label: 'Боевик' },
    { id: 7, slug: 'western', label: 'Вестерн' },
    { id: 8, slug: 'lordOfTheRings', label: 'Властелин колец New' },
    { id: 9, slug: 'war', label: 'Военный' },
    { id: 10, slug: 'detective', label: 'Детектив' },
    { id: 11, slug: 'jamesBond', label: 'Джеймс Бонд' },
    { id: 12, slug: 'harryPotter', label: 'Гари Поттер New' },
    { id: 13, slug: 'documentary', label: 'Документальный' },
    { id: 14, slug: 'drama', label: 'Драма' },
    { id: 15, slug: 'gameOfThrones', label: 'Игра престолов' },
    { id: 16, slug: 'indianCinema', label: 'Индийское кино' },
    { id: 17, slug: 'historical', label: 'Исторический' },
    { id: 18, slug: 'movieComedy', label: 'Кинокомедия' },
    { id: 19, slug: 'comicBookMovies', label: 'Кинокомикс' },
    { id: 20, slug: 'movieNovel', label: 'Кинороман' },
    { id: 21, slug: 'melodrama', label: 'Мелодрама' },
    { id: 22, slug: 'cartoon', label: 'Мультфильм' },
    { id: 23, slug: 'musical', label: 'Мюзикл' },
    { id: 24, slug: 'scientific', label: 'Научный' },
    { id: 25, slug: 'silentMovies', label: 'Немое кино' },
    { id: 26, slug: 'adventure', label: 'Приключения' },
    { id: 27, slug: 'psychologicalThrillers', label: 'Психологические триллеры' },
    { id: 28, slug: 'family', label: 'Семейный' },
    { id: 29, slug: 'foreignSeries', label: 'Зарубежные Сериалы New' },
    { id: 30, slug: 'russianSeries', label: 'Русские Сериалы New' },
    { id: 31, slug: 'tragicomedies', label: 'Трагикомедии' },
    { id: 32, slug: 'thrillers', label: 'Триллеры' },
    { id: 33, slug: 'fantasy', label: 'Фантастика' },
    { id: 34, slug: 'horrorMovies', label: 'Фильмы ужасов' },
    { id: 35, slug: 'frenchCinema', label: 'Французкое кино' },
    { id: 36, slug: 'fantasy', label: 'Фэнтези' },
    { id: 37, slug: 'blackAndWhiteMovies', label: 'Чёрно-белое кино' },
    { id: 38, slug: 'action', label: 'Экшены' },
    { id: 39, slug: 'eroticMovies', label: 'Эротическое кино' },
    { id: 40, slug: 'turkishCinema', label: 'Турецкое кино' },
    { id: 41, slug: 'koreanCinema', label: 'Корейское кино' }
  ],
  [GenreTypes.Music]: [
    { id: 0, slug: 'danceElectronicHouse', label: 'Dance / Electronic / House' },
    { id: 1, slug: 'authorSong', label: 'Авторская песня' },
    { id: 2, slug: 'alternativeRock', label: 'Альтернативный рок' },
    { id: 3, slug: 'blues', label: 'Блюз' },
    { id: 4, slug: 'bossaNova', label: 'Босса-нова' },
    { id: 5, slug: 'gothicMetal', label: 'Готик-метал' },
    { id: 6, slug: 'gothicRock', label: 'Готик-рок' },
    { id: 7, slug: 'jazz', label: 'Джаз' },
    { id: 8, slug: 'jazzRock', label: 'Джаз-рок' },
    { id: 9, slug: 'disco', label: 'Диско' },
    { id: 10, slug: 'drumAndBass', label: 'Драм-н-бейс' },
    { id: 11, slug: 'spiritualMusic', label: 'Духовная музыка' },
    { id: 12, slug: 'indieRock', label: 'Инди-рок' },
    { id: 13, slug: 'instrumentalMusic', label: 'Инструментальная музыка' },
    { id: 14, slug: 'classicalMusic', label: 'Классическая музыка' },
    { id: 15, slug: 'clubMusic', label: 'Клубная музыка' },
    { id: 16, slug: 'latinMusic', label: 'Латиноамериканская музыка' },
    { id: 17, slug: 'lounge', label: 'Лаунж' },
    { id: 18, slug: 'metal', label: 'Метал' },
    { id: 19, slug: 'musicOfThe80s', label: 'Музыка 80-х' },
    { id: 20, slug: 'musicOfThe90s', label: 'Музыка 90-х' },
    { id: 21, slug: 'musicOfThe2000s', label: 'Музыка 00-х' },
    { id: 22, slug: 'opera', label: 'Опера' },
    { id: 23, slug: 'punkRock', label: 'Панк-рок' },
    { id: 24, slug: 'popMusic', label: 'Поп музыка' },
    { id: 25, slug: 'popRock', label: 'Поп-рок' },
    { id: 26, slug: 'rap', label: 'Реп' },
    { id: 27, slug: 'retro', label: 'Ретро' },
    { id: 28, slug: 'rockMusic', label: 'Рок музыка' },
    { id: 29, slug: 'rockNRoll', label: 'Рок-н-ролл' },
    { id: 30, slug: 'romance', label: 'Романс' },
    { id: 31, slug: 'hardAndHeavy', label: 'Хард\'н\'хэви' },
    { id: 32, slug: 'hipHop', label: 'Хип-хоп' },
    { id: 33, slug: 'chillout', label: 'Чилаут' },
    { id: 34, slug: 'chanson', label: 'Шансон' },
    { id: 35, slug: 'electronicMusic', label: 'Электронная музыка' },
    { id: 36, slug: 'ethnicMusic', label: 'Этническая музыка' }
  ],
  [GenreTypes.Literature]: [
    { id: 0, slug: 'ancientLiterature', label: 'Античная литература' },
    { id: 1, slug: 'businessBooks', label: 'Бизнес-книги' },
    { id: 2, slug: 'biographiesAndMemoirs', label: 'Биографии и мемуары' },
    { id: 3, slug: 'actionThrillers', label: 'Боевик, остросюжетные' },
    { id: 4, slug: 'militaryLiterature', label: 'Военная литература' },
    { id: 5, slug: 'detectives', label: 'Детективы' },
    { id: 6, slug: 'documentaryLiterature', label: 'Документальная литература' },
    { id: 7, slug: 'europeanLiterature', label: 'Европейская литература' },
    { id: 8, slug: 'foreignLiterature', label: 'Зарубежная литература' },
    { id: 9, slug: 'historicalProse', label: 'Историческая проза' },
    { id: 10, slug: 'historicalNovel', label: 'Исторический роман' },
    { id: 11, slug: 'cyberpunk', label: 'Киберпанк' },
    { id: 12, slug: 'classicalPoetry', label: 'Классическая поэзия' },
    { id: 13, slug: 'psychologyBooks', label: 'Книги по психологии' },
    { id: 14, slug: 'vampireBooks', label: 'Книги про вампиров' },
    { id: 15, slug: 'comics', label: 'Комиксы' },
    { id: 16, slug: 'beautyHealthAndSports', label: 'Красота, здоровье и спорт' },
    { id: 17, slug: 'romanceNovel', label: 'Любовный роман' },
    { id: 18, slug: 'medicine', label: 'Медицина' },
    { id: 19, slug: 'mysticalProse', label: 'Мистическая проза' },
    { id: 20, slug: 'mythsAndEpics', label: 'Мифы, эпос' },
    { id: 21, slug: 'scienceFiction', label: 'Научная фантастика' },
    { id: 22, slug: 'podcasts', label: 'Подкасты' },
    { id: 23, slug: 'adventures', label: 'Приключения' },
    { id: 24, slug: 'publicisticLiterature', label: 'Публицистическая литература' },
    { id: 25, slug: 'playsDramaturgy', label: 'Пьесы, драматургия' },
    { id: 26, slug: 'russianClassicLiterature', label: 'Русская классическая литература' },
    { id: 27, slug: 'selfDevelopmentPersonalGrowth', label: 'Саморазвитие, личностный рост' },
    { id: 28, slug: 'modernProse', label: 'Современная проза' },
    { id: 29, slug: 'thriller', label: 'Триллер' },
    { id: 30, slug: 'educationalAndScientificLiterature', label: 'Учебная и научная литература' },
    { id: 31, slug: 'fantasy', label: 'Фантастика' },
    { id: 32, slug: 'financeAndInvestments', label: 'Финансы и инвестиции' },
    { id: 33, slug: 'fantasy', label: 'Фэнтези' },
    { id: 34, slug: 'esoterics', label: 'Эзотерика' }
  ]
}

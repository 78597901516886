import {
  DELETE_MESSAGES_REQUEST,
  DELETE_MESSAGES_SUCCESS,
  DeleteMessagesRequestAction,
  DeleteMessagesSuccessAction,
  EXPLORE_MESSAGES,
  ExploreMessagesRequestAction,
  GET_MESSAGES_FOR_CHAT_REQUEST,
  GetMessagesForChatRequestAction,
  RECEIVE_MESSAGES_FOR_CHANNEL,
  RECEIVE_MESSAGES_FOR_CHAT,
  ReceiveMessagesForChannelAction,
  ReceiveMessagesForChatAction
} from '@redux/types'
import { ChannelMessage, DeleteMessagesAttributes, MessagesAttributes } from '@types'

export const getMessagesForChatRequest = (chatId: number): GetMessagesForChatRequestAction => ({
  type: GET_MESSAGES_FOR_CHAT_REQUEST,
  payload: chatId
})

export const exploreMessagesRequest = (message: MessagesAttributes): ExploreMessagesRequestAction => ({
  type: EXPLORE_MESSAGES,
  payload: message
})

export const receiveMessagesForChat = (payload: MessagesAttributes[]): ReceiveMessagesForChatAction => ({
  type: RECEIVE_MESSAGES_FOR_CHAT,
  payload
})

export const receiveMessagesForChannel = (payload: ChannelMessage[]): ReceiveMessagesForChannelAction => ({
  type: RECEIVE_MESSAGES_FOR_CHANNEL,
  payload
})

export const deleteMessagesRequest = (payload: DeleteMessagesAttributes): DeleteMessagesRequestAction => ({
  type: DELETE_MESSAGES_REQUEST,
  payload
})

export const deleteMessagesSuccess = (payload: DeleteMessagesAttributes): DeleteMessagesSuccessAction => ({
  type: DELETE_MESSAGES_SUCCESS,
  payload
})

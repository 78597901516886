import { StyleSheet, View } from 'react-native'
import * as Notifications from 'expo-notifications'

import { BellSvg, ConnectTextLogoSvg } from '@assets/icons'
import { Button, Typography } from '@components'
import { useNavigator } from '@navigation'

const styles = StyleSheet.create({
  LogoWrapper: {
    marginBottom: 80
  },

  bottomButton: {
    alignSelf: 'center',
    width: '100%'
  },
  centerWrapper: {
    alignItems: 'center',
    flex: 1
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 50
  }
})

export const NotificationScreen = () => {
  const { navigation } = useNavigator<'Tabs'>()

  const goToTellYourself = async () => {
    await Notifications.getPermissionsAsync()
    navigation.navigate('Tabs')
  }

  return (
    <View style={styles.mainWrapper}>
      <View style={styles.centerWrapper}>
        <View style={styles.LogoWrapper}>
          <ConnectTextLogoSvg />
        </View>
        <BellSvg width={157} height={199} />
        <Typography f29 extraBold marginTop={116} marginBottom={12}>
          Уведомления
        </Typography>
        <Typography f15 normal textAlign='center'>
          Разрешите отправку уведомлений вам, чтобы получать рекомендации, сообщения от пользователей
        </Typography>
      </View>
      <Button style={styles.bottomButton} text='Разрешить' onPress={goToTellYourself} />
    </View>
  )
}

export interface CountryInfo {
  dialCode: string
  len: number
  name: string
}

export const LIMITED_CODE_DICTS: CountryInfo[] = [{ dialCode: '+971', len: 9, name: 'United Arab Emirates' }]

export const CODE_DICTS: CountryInfo[][] = [
  [
    { dialCode: '+93', len: 9, name: 'Afghanistan (افغانستان)' },
    { dialCode: '+355', len: 9, name: 'Albania (Shqipëri)' },
    { dialCode: '+213', len: 9, name: 'Algeria (الجزائر)' },
    { dialCode: '+376', len: 6, name: 'Andorra' },
    { dialCode: '+244', len: 9, name: 'Angola' },
    { dialCode: '+374', len: 8, name: 'Armenia (Հայաստան)' },
    { dialCode: '+1268', len: 7, name: 'Antigua and Barbuda' },
    { dialCode: '+54', len: 11, name: 'Argentina' },
    { dialCode: '+61', len: 10, name: 'Australia' },
    { dialCode: '+43', len: 11, name: 'Austria (Österreich)' },
    { dialCode: '+994', len: 9, name: 'Azerbaijan (Azərbaycan)' }
  ],
  [
    { dialCode: '+1242', len: 7, name: 'Bahamas' },
    { dialCode: '+973', len: 8, name: 'Bahrain (البحرين)' },
    { dialCode: '+880', len: 10, name: 'Bangladesh (বাংলাদেশ)' },
    { dialCode: '+1246', len: 7, name: 'Barbados' },
    { dialCode: '+375', len: 9, name: 'Belarus (Беларусь)' },
    { dialCode: '+32', len: 9, name: 'Belgium (België)' },
    { dialCode: '+501', len: 7, name: 'Belize' },
    { dialCode: '+229', len: 8, name: 'Benin (Bénin)' },
    { dialCode: '+975', len: 8, name: 'Bhutan (འབྲུག)' },
    { dialCode: '+591', len: 8, name: 'Bolivia' },
    { dialCode: '+387', len: 8, name: 'Bosnia and Herzegovina' },
    { dialCode: '+267', len: 8, name: 'Botswana' },
    { dialCode: '+55', len: 11, name: 'Brazil (Brasil)' },
    { dialCode: '+673', len: 7, name: 'Brunei' },
    { dialCode: '+359', len: 9, name: 'Bulgaria (България)' },
    { dialCode: '+226', len: 8, name: 'Burkina Faso' },
    { dialCode: '+257', len: 8, name: 'Burundi (Uburundi)' }
  ],
  [
    { dialCode: '+855', len: 8, name: 'Cambodia (កម្ពុជា)' },
    { dialCode: '+237', len: 9, name: 'Cameroon (Cameroun)' },
    { dialCode: '+1', len: 10, name: 'Canada' },
    { dialCode: '+238', len: 7, name: 'Cape Verde (Kabu Verdi)' },
    { dialCode: '+236', len: 8, name: 'Central African Republic (République centrafricaine)' },
    { dialCode: '+235', len: 8, name: 'Chad (Tchad)' },
    { dialCode: '+56', len: 9, name: 'Chile' },
    { dialCode: '+86', len: 11, name: 'China (中国)' },
    { dialCode: '+57', len: 10, name: 'Colombia' },
    { dialCode: '+269', len: 7, name: 'Comoros (جزر القمر)' },
    { dialCode: '+243', len: 9, name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)' },
    { dialCode: '+242', len: 9, name: 'Congo (Republic) (Congo-Brazzaville)' },
    { dialCode: '+682', len: 5, name: 'Cook Islands' },
    { dialCode: '+506', len: 8, name: 'Costa Rica' },
    { dialCode: '+225', len: 8, name: 'Cote d’Ivoire' },
    { dialCode: '+385', len: 9, name: 'Croatia (Hrvatska)' },
    { dialCode: '+53', len: 8, name: 'Cuba' },
    { dialCode: '+357', len: 8, name: 'Cyprus (Κύπρος)' },
    { dialCode: '+420', len: 9, name: 'Czech Republic (Česká republika)' }
  ],
  [
    { dialCode: '+45', len: 8, name: 'Denmark (Danmark)' },
    { dialCode: '+253', len: 8, name: 'Djibouti' },
    { dialCode: '+1767', len: 7, name: 'Dominica' },
    { dialCode: '+1', len: 10, name: 'Dominican Republic' }
  ],
  [
    { dialCode: '+593', len: 9, name: 'Ecuador' },
    { dialCode: '+20', len: 10, name: 'Egypt (مصر)' },
    { dialCode: '+503', len: 8, name: 'El Salvador' },
    { dialCode: '+240', len: 9, name: 'Equatorial Guinea' },
    { dialCode: '+291', len: 7, name: 'Eritrea' },
    { dialCode: '+372', len: 8, name: 'Estonia (Eesti)' },
    { dialCode: '+251', len: 9, name: 'Ethiopia' }
  ],
  [
    { dialCode: '+679', len: 7, name: 'Fiji' },
    { dialCode: '+358', len: 10, name: 'Finland (Suomi)' },
    { dialCode: '+33', len: 10, name: 'France' }
  ],
  [
    { dialCode: '+241', len: 7, name: 'Gabon' },
    { dialCode: '+220', len: 7, name: 'Gambia' },
    { dialCode: '+995', len: 9, name: 'Georgia (საქართველო)' },
    { dialCode: '+49', len: 11, name: 'Germany (Deutschland)' },
    { dialCode: '+233', len: 9, name: 'Ghana (Gaana)' },
    { dialCode: '+30', len: 10, name: 'Greece (Ελλάδα)' },
    { dialCode: '+1473', len: 7, name: 'Grenada' },
    { dialCode: '+502', len: 8, name: 'Guatemala' },
    { dialCode: '+224', len: 9, name: 'Guinea (Guinée)' },
    { dialCode: '+245', len: 7, name: 'Guinea-Bissau (Guiné Bissau)' },
    { dialCode: '+592', len: 7, name: 'Guyana' }
  ],
  [
    { dialCode: '+509', len: 8, name: 'Haiti' },
    { dialCode: '+504', len: 8, name: 'Honduras' },
    { dialCode: '+852', len: 8, name: 'Hong Kong (香港)' },
    { dialCode: '+36', len: 9, name: 'Hungary (Magyarország)' }
  ],
  [
    { dialCode: '+354', len: 7, name: 'Iceland (Ísland)' },
    { dialCode: '+91', len: 10, name: 'India (भारत)' },
    { dialCode: '+62', len: 12, name: 'Indonesia' },
    { dialCode: '+98', len: 10, name: 'Iran (ایران)' },
    { dialCode: '+964', len: 10, name: 'Iraq (العراق)' },
    { dialCode: '+353', len: 9, name: 'Ireland' },
    { dialCode: '+972', len: 10, name: 'Israel (ישראל)' },
    { dialCode: '+39', len: 10, name: 'Italy (Italia)' }
  ],
  [
    { dialCode: '+1', len: 10, name: 'Jamaica' },
    { dialCode: '+81', len: 10, name: 'Japan (日本)' },
    { dialCode: '+962', len: 9, name: 'Jordan (الأردن)' }
  ],
  [
    { dialCode: '+7', len: 10, name: 'Kazakhstan (Казахстан)' },
    { dialCode: '+254', len: 9, name: 'Kenya' },
    { dialCode: '+686', len: 5, name: 'Kiribati' },
    { dialCode: '+383', len: 8, name: 'Kosovo (Republic)' },
    { dialCode: '+965', len: 8, name: 'Kuwait (الكويت)' },
    { dialCode: '+996', len: 9, name: 'Kyrgyzstan (Кыргызстан)' }
  ],
  [
    { dialCode: '+856', len: 8, name: 'Laos (ລາວ)' },
    { dialCode: '+371', len: 8, name: 'Latvia (Latvija)' },
    { dialCode: '+961', len: 8, name: 'Lebanon (لبنان)' },
    { dialCode: '+266', len: 8, name: 'Lesotho' },
    { dialCode: '+231', len: 9, name: 'Liberia' },
    { dialCode: '+218', len: 8, name: 'Libya (ليبيا)' },
    { dialCode: '+423', len: 7, name: 'Liechtenstein' },
    { dialCode: '+370', len: 8, name: 'Lithuania (Lietuva)' },
    { dialCode: '+352', len: 9, name: 'Luxembourg' }
  ],
  [
    { dialCode: '+853', len: 8, name: 'Macao' },
    { dialCode: '+389', len: 8, name: 'Macedonia (FYROM) (Македонија)' },
    { dialCode: '+261', len: 9, name: 'Madagascar (Madagasikara)' },
    { dialCode: '+265', len: 9, name: 'Malawi' },
    { dialCode: '+60', len: 10, name: 'Malaysia' },
    { dialCode: '+960', len: 7, name: 'Maldives' },
    { dialCode: '+223', len: 8, name: 'Mali' },
    { dialCode: '+356', len: 8, name: 'Malta' },
    { dialCode: '+692', len: 7, name: 'Marshall Islands' },
    { dialCode: '+222', len: 8, name: 'Mauritania (موريتانيا)' },
    { dialCode: '+230', len: 8, name: 'Mauritius (Moris)' },
    { dialCode: '+52', len: 10, name: 'Mexico (México)' },
    { dialCode: '+521', len: 10, name: 'Mexico (México) + 1' },
    { dialCode: '+691', len: 7, name: 'Micronesia' },
    { dialCode: '+373', len: 8, name: 'Moldova (Republica Moldova)' },
    { dialCode: '+377', len: 8, name: 'Monaco' },
    { dialCode: '+976', len: 8, name: 'Mongolia (Монгол)' },
    { dialCode: '+382', len: 8, name: 'Montenegro (Crna Gora)' },
    { dialCode: '+212', len: 9, name: 'Morocco (المغرب)' },
    { dialCode: '+258', len: 8, name: 'Mozambique (Moçambique)' },
    { dialCode: '+95', len: 8, name: 'Myanmar (Burma)' }
  ],
  [
    { dialCode: '+264', len: 9, name: 'Namibia (Namibië)' },
    { dialCode: '+674', len: 7, name: 'Nauru' },
    { dialCode: '+977', len: 10, name: 'Nepal (नेपाल)' },
    { dialCode: '+31', len: 9, name: 'Netherlands (Nederland)' },
    { dialCode: '+64', len: 9, name: 'New Zealand' },
    { dialCode: '+505', len: 8, name: 'Nicaragua' },
    { dialCode: '+227', len: 8, name: 'Niger (Nijar)' },
    { dialCode: '+234', len: 10, name: 'Nigeria' },
    { dialCode: '+683', len: 4, name: 'Niue' },
    { dialCode: '+850', len: 8, name: 'North Korea' },
    { dialCode: '+47', len: 8, name: 'Norway (Norge)' }
  ],
  [{ dialCode: '+968', len: 8, name: 'Oman (عُمان)' }],
  [
    { dialCode: '+507', len: 8, name: 'Panama' },
    { dialCode: '+92', len: 10, name: 'Pakistan (پاکستان)' },
    { dialCode: '+680', len: 7, name: 'Palau' },
    { dialCode: '+970', len: 9, name: 'Palestinian Territory' },
    { dialCode: '+675', len: 8, name: 'Papua New Guinea' },
    { dialCode: '+595', len: 9, name: 'Paraguay' },
    { dialCode: '+51', len: 9, name: 'Peru (Perú)' },
    { dialCode: '+63', len: 10, name: 'Philippines' },
    { dialCode: '+48', len: 9, name: 'Poland (Polska)' },
    { dialCode: '+351', len: 9, name: 'Portugal' }
  ],
  [{ dialCode: '+974', len: 8, name: 'Qatar (قطر)' }],
  [
    { dialCode: '+40', len: 9, name: 'Romania (România)' },
    { dialCode: '+7', len: 10, name: 'Russian Federation' },
    { dialCode: '+250', len: 9, name: 'Rwanda' }
  ],
  [
    { dialCode: '+1869', len: 7, name: 'Saint Kitts and Nevis' },
    { dialCode: '+1758', len: 7, name: 'Saint Lucia' },
    { dialCode: '+1784', len: 7, name: 'Saint Vincent and the Grenadines' },
    { dialCode: '+685', len: 6, name: 'Samoa' },
    { dialCode: '+378', len: 10, name: 'San Marino' },
    { dialCode: '+239', len: 7, name: 'Sao Tome and Principe' },
    { dialCode: '+966', len: 9, name: 'Saudi Arabia' },
    { dialCode: '+221', len: 9, name: 'Senegal (Sénégal)' },
    { dialCode: '+381', len: 9, name: 'Serbia (Србија)' },
    { dialCode: '+248', len: 7, name: 'Seychelles' },
    { dialCode: '+232', len: 8, name: 'Sierra Leone' },
    { dialCode: '+65', len: 8, name: 'Singapore' },
    { dialCode: '+421', len: 9, name: 'Slovakia (Slovensko)' },
    { dialCode: '+386', len: 8, name: 'Slovenia (Slovenija)' },
    { dialCode: '+677', len: 7, name: 'Solomon Islands' },
    { dialCode: '+252', len: 8, name: 'Somalia (Soomaaliya)' },
    { dialCode: '+27', len: 9, name: 'South Africa' },
    { dialCode: '+82', len: 10, name: 'South Korea (대한민국)' },
    { dialCode: '+211', len: 9, name: 'South Sudan (جنوب السودان)' },
    { dialCode: '+34', len: 9, name: 'Spain (España)' },
    { dialCode: '+94', len: 9, name: 'Sri Lanka (ශ්‍රී ලංකාව)' },
    { dialCode: '+249', len: 9, name: 'Sudan (السودان)' },
    { dialCode: '+597', len: 7, name: 'Suriname' },
    { dialCode: '+268', len: 8, name: 'Swaziland' },
    { dialCode: '+46', len: 9, name: 'Sweden (Sverige)' },
    { dialCode: '+41', len: 9, name: 'Switzerland (Schweiz)' },
    { dialCode: '+963', len: 9, name: 'Syria (سوريا)' }
  ],
  [
    { dialCode: '+886', len: 8, name: 'Taiwan (台灣)' },
    { dialCode: '+992', len: 9, name: 'Tajikistan' },
    { dialCode: '+255', len: 9, name: 'Tanzania' },
    { dialCode: '+66', len: 9, name: 'Thailand (ไทย)' },
    { dialCode: '+228', len: 8, name: 'Togo' },
    { dialCode: '+676', len: 5, name: 'Tonga' },
    { dialCode: '+1868', len: 7, name: 'Trinidad and Tobago' },
    { dialCode: '+216', len: 8, name: 'Tunisia (تونس)' },
    { dialCode: '+90', len: 11, name: 'Turkey (Türkiye)' },
    { dialCode: '+993', len: 8, name: 'Turkmenistan' },
    { dialCode: '+688', len: 6, name: 'Tuvalu' }
  ],
  [
    { dialCode: '+256', len: 9, name: 'Uganda' },
    { dialCode: '+380', len: 9, name: 'Ukraine (Україна)' },
    { dialCode: '+971', len: 9, name: 'United Arab Emirates' },
    { dialCode: '+44', len: 11, name: 'United Kingdom' },
    { dialCode: '+1', len: 10, name: 'USA' },
    { dialCode: '+598', len: 8, name: 'Uruguay' },
    { dialCode: '+998', len: 9, name: 'Uzbekistan (Oʻzbekiston)' }
  ],
  [
    { dialCode: '+678', len: 7, name: 'Vanuatu' },
    { dialCode: '+39', len: 9, name: 'Vatican City' },
    { dialCode: '+58', len: 10, name: 'Venezuela' },
    { dialCode: '+84', len: 9, name: 'Vietnam (Việt Nam)' }
  ],
  [{ dialCode: '+967', len: 7, name: 'Yemen (اليمن)' }],
  [
    { dialCode: '+260', len: 9, name: 'Zambia' },
    { dialCode: '+263', len: 7, name: 'Zimbabwe' }
  ]
]

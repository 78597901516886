/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getFullChatUserSuccess } from '@redux/actions'
import { GET_FULL_CHAT_USER_REQUEST, GetFullChatUserRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { UserProfile } from '@types'

type SagaEffects = CallEffect | PutEffect

function* getFullChatUser(action: GetFullChatUserRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.getUserByChatId], action.payload)
    const user: UserProfile = response.data
    yield put(getFullChatUserSuccess(user))
  } catch (error: any) {
    console.log('Error getFullchatUserSaga', error)
  }
}

export function* getFullChatUserSaga() {
  yield takeLatest(GET_FULL_CHAT_USER_REQUEST, getFullChatUser)
}

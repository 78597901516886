/* eslint-disable func-style */
import { call, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects'

import { CREATE_COMPLAINT_REQUEST, CreateComplaintRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect

function* createComplaint(action: CreateComplaintRequestAction): Generator<SagaEffects, void, any> {
  try {
    yield call([apiService, apiService.createComplaint], action.payload.data)
  } catch (error: any) {
    console.log('createComplaintSaga', error)
  }
}

export function* createComplaintSaga() {
  yield takeLatest<CreateComplaintRequestAction>(CREATE_COMPLAINT_REQUEST, createComplaint)
}

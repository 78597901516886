import { useEffect, useMemo, useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { Typography } from '@components'
import { OptionId, ProfileOption } from '@utils'

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderRadius: 9,
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 22,
    paddingVertical: 12
  },
  childrenWrapper: {
    flexDirection: 'row-reverse',
    gap: 14,
    justifyContent: 'space-between',
    marginTop: 16,
    paddingBottom: 1
  },
  container: {
    borderColor: '#A2A0AC',
    borderRadius: 40,
    borderWidth: 0.3,
    paddingHorizontal: 20,
    paddingVertical: 16
  },
  containerIsHighlighted: {
    borderColor: '#2F2F2F',
    borderWidth: 1
  },
  containerShowChildren: {
    borderRadius: 20
  }
})

interface ProfileOptionItemProps {
  data: ProfileOption
  selected: OptionId[]
  onSelect: (id: OptionId) => void
}

export const ProfileOptionItem = ({ data, selected, onSelect }: ProfileOptionItemProps) => {
  const isParent = !!data.children
  const [showChildren, setShowChildren] = useState(false)
  const [isChildSelected, setIsChildSelected] = useState(false)

  const handleOptionSelect = (id: OptionId) => {
    if (isParent && id === data.id) {
      setShowChildren(prev => !prev)
      return
    }

    onSelect(id)
  }

  const selectedContainsChild = useMemo(
    () => selected.some(id => data.children?.map(child => child.id).includes(id)),
    [selected]
  )

  useEffect(() => {
    if (!data.children) {
      return
    }

    if (selectedContainsChild) {
      setShowChildren(true)
    }

    setIsChildSelected(isParent && selectedContainsChild)
  }, [selectedContainsChild])

  const isHighlighted = selected.includes(data.id) || showChildren || isChildSelected

  const renderChildren = () =>
    data.children?.map(item => (
      <TouchableOpacity
        key={item.id}
        style={[styles.button, { backgroundColor: selected.includes(item.id) ? '#000' : '#fff' }]}
        onPress={e => {
          e.stopPropagation()
          handleOptionSelect(item.id)
        }}
      >
        <Typography f14 {...(selected.includes(item.id) ? { semibold: true, color: '#fff' } : { color: '#000' })}>
          {item.label}
        </Typography>
      </TouchableOpacity>
    ))

  return (
    <TouchableOpacity
      style={[
        styles.container,
        isHighlighted && styles.containerIsHighlighted,
        showChildren && styles.containerShowChildren
      ]}
      onPress={() => handleOptionSelect(data.id)}
    >
      <Typography f14 color={isHighlighted ? '#2c2c2c' : '#aeaeb2'}>
        {data.label}
      </Typography>
      {showChildren && <View style={styles.childrenWrapper}>{renderChildren()}</View>}
    </TouchableOpacity>
  )
}

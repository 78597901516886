import { DateComp, UserChat, UserProfile } from '@types'

export type RootStackParamList = {
  none: undefined
  ChangeGeneralInformation: undefined
  ChangePassword: { phone: string }
  ChangeTellYourself: undefined
  Chat: { chat: UserChat; userId: number }
  ChoseCountry: undefined
  Match: { user: UserProfile }
  Congratulations: undefined
  CreatePassword: { phone: string; change?: boolean }
  DateCompResult: { comp: DateComp; date: string }
  Education?: { edit?: boolean }
  EndSign: undefined
  EnterCode: { phone: string; change?: boolean; isLogin?: boolean }
  RestoreAccount: { phone: string }
  EnterPhone?: { dialCode: string; len?: number }
  EnterPhoneLogin?: { dialCode: string; len?: number }
  Financial?: { edit?: boolean }
  FirstScreen: undefined
  GeneralInformation: undefined
  Goals?: { edit?: boolean }
  Hobbies?: { edit?: boolean }
  InformationYourself: undefined
  Languages?: { edit?: boolean }
  Location?: { edit?: boolean }
  MainProfile: undefined
  ModelScreen: {
    user: UserProfile
    showModal?: boolean
    hideButtons?: boolean
    isRequest?: boolean
    removeUser?: (id: string) => void
  }
  Notification: undefined
  NotificationScreen: undefined
  PasswordLogin: { phone: string }
  PersonalInformation: undefined
  PersonalPhoto?: { edit?: boolean }
  Quality?: { edit?: boolean }
  Redirecting: undefined
  Preferences?: { edit?: boolean }
  ReligionChildren?: { edit?: boolean }
  Settings: undefined
  SexualOrientation?: { edit?: boolean }
  StartSelection: undefined
  Tabs: undefined
  TellYourself?: { edit?: boolean }
  VisitedPlaces?: { edit?: boolean }
  Works?: { edit?: boolean }
  Message: undefined
  ProfileActionModal: undefined
  Main: undefined
}

import {
  AllDeletedUsersState,
  GET_ALL_DELETED_USERS_REQUEST,
  GET_ALL_DELETED_USERS_SUCCESS,
  GetAllDeletedUsersSuccessAction
} from '@redux/types'

const initialState: AllDeletedUsersState = []

export const allDeletedUsersReducer = (state = initialState, action: GetAllDeletedUsersSuccessAction) => {
  switch (action.type) {
    case GET_ALL_DELETED_USERS_REQUEST:
      return state
    case GET_ALL_DELETED_USERS_SUCCESS:
      return action.payload
    default:
      return state
  }
}

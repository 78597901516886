/* eslint-disable func-style */
import { call, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects'

import { USER_DELETE_REQUEST, UserDeleteRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'

type SagaEffects = CallEffect | PutEffect

function* userDelete(action: UserDeleteRequestAction): Generator<SagaEffects, void, any> {
  try {
    yield call([apiService, apiService.userDelete], action.payload.userId)
  } catch (error: any) {
    console.log('userDeleteSaga', error)
  }
}

export function* userDeleteSaga() {
  yield takeLatest<UserDeleteRequestAction>(USER_DELETE_REQUEST, userDelete)
}

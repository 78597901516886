import { useMemo } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import Animated from 'react-native-reanimated'

import { ScaleSvg } from '@assets/icons'
import { Typography } from '@components'
import { getMoodIconByMoodValue, MOOD_RANGE } from '@components/utils'

const ARROW_CONTAINER_WIDTH = 156

interface Point2D {
  x: number
  y: number
}

const transformOriginWorklet = (anchorPoint: Point2D, originalCenterPoint: Point2D, transforms: any) => {
  'worklet'
  return [
    { translateX: anchorPoint.x - originalCenterPoint.x },
    { translateY: anchorPoint.y - originalCenterPoint.y },
    ...transforms,
    { translateX: -(anchorPoint.x - originalCenterPoint.x) },
    { translateY: -(anchorPoint.y - originalCenterPoint.y) }
  ]
}

const styles = StyleSheet.create({
  arrow: {
    height: 25,
    objectFit: 'contain',
    width: 55
  },
  arrowContainer: {
    bottom: -10,
    height: 25,
    left: -20,
    position: 'absolute',
    width: ARROW_CONTAINER_WIDTH
  },
  iconScaleWrapper: {
    alignItems: 'center',
    bottom: -5,
    position: 'absolute',
    width: '100%'
  }
})

interface MoodScaleProps {
  value: number
}

export const MoodScale = ({ value }: MoodScaleProps) => {
  const MoodIcon = useMemo(() => getMoodIconByMoodValue(value), [value])
  const rotationAngle = useMemo(() => ((value - MOOD_RANGE[0]) / (MOOD_RANGE[1] - MOOD_RANGE[0])) * 180, [value])

  const animatedStyle = () => {
    return {
      transform: transformOriginWorklet(
        {
          x: ARROW_CONTAINER_WIDTH,
          y: 25
        },
        {
          x: ARROW_CONTAINER_WIDTH / 2,
          y: 25
        },
        [{ rotateZ: `${rotationAngle}deg` }]
      )
    }
  }

  return (
    <View>
      <View>
        <ScaleSvg />
        <Animated.View style={{ ...styles.arrowContainer, ...animatedStyle() }}>
          <Image source={require('../../assets/images/scaleArrow.png')} style={styles.arrow} />
        </Animated.View>
        <View style={styles.iconScaleWrapper}>
          <MoodIcon />
          <Typography f22 semibold marginTop={0}>
            {value}
          </Typography>
        </View>
      </View>
    </View>
  )
}

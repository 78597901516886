/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getMyLikesSuccess } from '@redux/actions'
import { GET_MY_LIKES_REQUEST, GetMyLikesRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { Chat } from '@types'

type SagaEffects = CallEffect | PutEffect

function* getMyLikes(): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.getMyLikes])
    const likes: Chat[] = response.data
    yield put(getMyLikesSuccess(likes))
  } catch (error: any) {
    console.log('getMyLikesSaga', error)
  }
}

export function* getMyLikesSaga() {
  yield takeLatest<GetMyLikesRequestAction>(GET_MY_LIKES_REQUEST, getMyLikes)
}

import { ScrollView, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import { WriteBlockSvg } from '@assets/icons'
import { ContentItem, DateCompatibility, InfoBlock, MoodBlock, Typography } from '@components'
import { userProfileSelector } from '@redux/selectors'
import { PersonDescription } from '@screens'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 26
  }
})

const personalArray: ContentItem[] = [
  {
    photoBlock: <WriteBlockSvg />,
    title: 'Данные о себе',
    nextScreen: 'InformationYourself'
  }
]

export const ProfileInfo = () => {
  const { description } = useSelector(userProfileSelector)

  return (
    <ScrollView style={styles.container}>
      <MoodBlock />
      <PersonDescription description={description} />
      <Typography f14 semibold marginTop={24} marginBottom={12}>
        Редактировать информацию
      </Typography>
      <InfoBlock contentArray={personalArray} />
      <Typography f14 semibold marginTop={24} marginBottom={12}>
        Совместимость со мной
      </Typography>
      <DateCompatibility />
    </ScrollView>
  )
}

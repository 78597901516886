import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { Typography } from '@components'

const styles = StyleSheet.create({
  buttons: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 16,
    width: '100%'
  },
  option: {
    alignItems: 'center',
    borderTopColor: '#fff',
    borderTopWidth: 1,
    flexGrow: 1,
    justifyContent: 'center',
    padding: 18,
    width: '100%'
  }
})

interface ModalUserActionsChatProps {
  close: () => void
}

export const ModalUserActionsChat = ({ close }: ModalUserActionsChatProps) => {
  const deleteChat = () => {
    console.log('deleteChat')
  }

  return (
    <>
      <Typography f16 style={{ fontWeight: '700', marginTop: 16 }}>
        Удалить чат
      </Typography>
      <Typography f13 style={{ marginTop: 8 }}>
        Вы уверенны что хотите удалить выбранный чат?
      </Typography>
      <View style={styles.buttons}>
        <TouchableOpacity style={[styles.option, { width: 'auto' }]} onPress={close}>
          <Typography f16 style={{ opacity: 0.5 }}>
            Отмена
          </Typography>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.option, { width: 'auto' }]} onPress={deleteChat}>
          <Typography f16 style={{ fontWeight: '700', color: '#F64A3F' }}>
            Удалить
          </Typography>
        </TouchableOpacity>
      </View>
    </>
  )
}

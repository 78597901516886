import { ReactNode } from 'react'
import { StyleSheet } from 'react-native'
import {
  DefaultTheme,
  TextInput as TextInputComponent,
  TextInputProps as TextInputComponentProps
} from 'react-native-paper'

interface TextInputProps extends Omit<TextInputComponentProps, 'onChange'> {
  onChange?: (value: string) => void
  searchButton?: () => ReactNode
  rightIcon?: () => ReactNode
  leftIcon?: () => ReactNode
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters'
}

const styles = StyleSheet.create({
  input: {
    backgroundColor: '#ffffff',
    fontSize: 14,
    fontWeight: '400',
    height: 46,
    paddingLeft: 25,
    width: '100%'
  }
})

export const SearchInput = ({
  onChange,
  autoCapitalize,
  rightIcon,
  leftIcon,
  searchButton,
  value,
  ...props
}: TextInputProps) => {
  const inputTheme = {
    ...DefaultTheme,
    roundness: 12,
    colors: {
      ...DefaultTheme.colors,
      primary: '#fdfcfe',
      outline: 'transparent'
    }
  }

  return (
    <>
      <TextInputComponent
        mode='outlined'
        placeholderTextColor='#18181C'
        style={styles.input}
        theme={inputTheme}
        onChangeText={onChange}
        value={value}
        autoCapitalize={autoCapitalize}
        {...props}
      />
      {leftIcon && leftIcon()}
      {rightIcon && rightIcon()}
      {searchButton && searchButton()}
    </>
  )
}

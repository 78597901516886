import { ReactNode, useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { ArrowDownBlackSvg } from '@assets/icons'
import { Typography } from '@components'

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: 'center',
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8
  },
  contentWithSubtitle: {
    flex: 1,
    justifyContent: 'space-between'
  },
  horizontalLine: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  leftContent: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  mainWrapper: {
    backgroundColor: '#fff',
    borderRadius: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 8
  },
  noBorder: {
    borderBottomWidth: 0
  }
})

export type ExpandableContentItem = {
  title: string
  photoBlock: ReactNode
  content: string
}

interface IProps {
  contentArray: ExpandableContentItem[]
}

export const ExpandableInfoBlock = ({ contentArray }: IProps) => {
  const [selectedItem, setSelectedItem] = useState<ExpandableContentItem | null>(null)

  const handleItemPress = (item: ExpandableContentItem) => {
    setSelectedItem(selectedItem === item ? null : item)
  }

  const renderContent = () =>
    contentArray.map((content, index) => (
      <View key={content.title}>
        <TouchableOpacity
          onPress={() => handleItemPress(content)}
          style={[styles.contentContainer, index === contentArray.length - 1 && styles.noBorder]}
        >
          <View style={styles.contentWithSubtitle}>
            <View style={styles.horizontalLine}>
              <View style={styles.leftContent}>
                {content.photoBlock}
                <Typography f14 medium marginLeft={20}>
                  {content.title}
                </Typography>
              </View>
              <ArrowDownBlackSvg />
            </View>
            {selectedItem === content && (
              <View>
                <Typography f12 medium>
                  {content.content}
                </Typography>
              </View>
            )}
          </View>
        </TouchableOpacity>
      </View>
    ))

  return <View style={styles.mainWrapper}>{renderContent()}</View>
}

import { GET_USERS_REQUEST, GET_USERS_SUCCESS, GetUsersActionTypes, UsersState } from '@redux/types'

const initialState: UsersState = []

export const usersReducer = (state = initialState, action: GetUsersActionTypes) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return state
    case GET_USERS_SUCCESS:
      return action.payload
    default:
      return state
  }
}

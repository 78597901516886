import { useMemo, useState } from 'react'
import { Image, StatusBar, StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Carousel, { Pagination } from 'react-native-snap-carousel'
import { useSelector } from 'react-redux'
import { LinearGradient } from 'expo-linear-gradient'

import { ConnectionModal, ModelBottomSheet, ModelInfoHeader, ModelMainInfo } from '@components'
import { baseStyle, Colors, screenHeight, screenWidth } from '@components/utils'
import { useNavigator } from '@navigation'
import { selectChatsState, selectUserChatId } from '@redux/selectors'
import { SERVER_URL } from '@services/apiService'
import { calculateAgeByDateOfBirth } from '@utils'

const styles = StyleSheet.create({
  dot: {
    backgroundColor: Colors.White,
    borderRadius: 4,
    flex: 1,
    height: 2
  },
  header: {
    left: 0,
    paddingBottom: 40,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 9999
  },
  mainInfo: {
    bottom: 0,
    left: 0,
    paddingBottom: screenHeight * 0.19,
    paddingHorizontal: 16,
    paddingTop: 80,
    position: 'absolute',
    right: 0,
    zIndex: 0
  },
  paginationContainer: {
    paddingVertical: 14
  },
  photo: {
    height: screenHeight,
    resizeMode: 'cover',
    width: screenWidth
  }
})

export const ModelScreen = () => {
  const { route, navigation } = useNavigator<'ModelScreen'>()
  const chats = useSelector(selectChatsState)
  const userChatId = useSelector(selectUserChatId)
  const { user, showModal, hideButtons, removeUser, isRequest } = route.params

  const [activeSlide, setActiveSlide] = useState(0)
  const [activeTab, setActiveTab] = useState(0)
  const [showConnectionModal, setShowConnectionModal] = useState(!!showModal)

  const photoList = useMemo(() => user.profileImage.map(el => `${SERVER_URL}/${el.name}`), [])
  const paginationDotLength = useMemo(() => (screenWidth - 52) / photoList.length - 14, [])
  const age = useMemo(() => calculateAgeByDateOfBirth(user.dof), [user.dof])

  const insets = useSafeAreaInsets()
  const goBack = () => navigation.goBack()

  if (!user) {
    goBack()
  }

  const chat = (chats || []).find(
    chatEl =>
      (chatEl.chatType === 'private' &&
        chatEl.creator.user_id === userChatId &&
        chatEl.participants[0].user_id === user.userChatId) ||
      (chatEl.creator.user_id === user.userChatId && chatEl.participants[0].user_id === userChatId)
  )

  return (
    <View style={baseStyle.screenView}>
      <StatusBar barStyle='light-content' translucent backgroundColor='transparent' />
      <Carousel
        layout='default'
        data={photoList}
        renderItem={({ item }) => <Image source={{ uri: item }} style={styles.photo} />}
        sliderWidth={screenWidth}
        itemWidth={screenWidth}
        itemHeight={screenHeight}
        sliderHeight={screenHeight}
        activeSlideOffset={0}
        inactiveSlideScale={1}
        vertical={false}
        onSnapToItem={index => setActiveSlide(index)}
      />
      <LinearGradient
        colors={['rgba(0,0,0,0.55)', 'rgba(0,0,0,0)']}
        style={[styles.header, { paddingTop: insets.top }]}
      >
        <Pagination
          dotsLength={photoList.length}
          activeDotIndex={activeSlide}
          containerStyle={[styles.paginationContainer]}
          dotContainerStyle={{ width: paginationDotLength }}
          dotStyle={[styles.dot, { width: paginationDotLength }]}
          inactiveDotStyle={[styles.dot, { width: paginationDotLength, backgroundColor: Colors.InactiveDot }]}
          inactiveDotOpacity={1}
          inactiveDotScale={1}
        />
        <ModelInfoHeader goBack={goBack} name={'@' + user.login} />
      </LinearGradient>
      <LinearGradient colors={['rgba(0,0,0,0.0)', 'rgba(0,0,0,0.55)', 'rgba(0,0,0,0.55)']} style={styles.mainInfo}>
        <ModelMainInfo
          name={user.name}
          city={user.profile.userGeo.name}
          moodStatusId={user.moodStatus[0]}
          age={age}
          mood={user.mood}
          chat={chat}
        />
      </LinearGradient>
      <ModelBottomSheet
        user={user}
        goBack={goBack}
        close={goBack}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        openConnection={() => setShowConnectionModal(true)}
        hideButtons={hideButtons}
        isRequest={!!isRequest}
      />
      <ConnectionModal
        removeUser={removeUser}
        id={user._id}
        isVisible={showConnectionModal}
        close={() => setShowConnectionModal(false)}
      />
    </View>
  )
}

/* eslint-disable func-style */
import { Asset } from 'expo-asset'
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getCurrentUserSuccess } from '@redux/actions'
import { GET_CURRENT_USER_REQUEST } from '@redux/types'
import { apiService, SERVER_URL } from '@services/apiService'
import { UserProfile } from '@types'

type SagaEffects = CallEffect | PutEffect

const preloadImage = async (images: UserProfile['profileImage']) => {
  const imageAssets = images.map(({ name }) => {
    return Asset.fromURI(`${SERVER_URL}/${name}`)
  })

  await Promise.all(imageAssets.map(asset => asset.downloadAsync()))
}

function* getCurrentUser(): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.getCurrentUser])
    const currentUser: UserProfile = response.data
    yield put(getCurrentUserSuccess(currentUser))
    preloadImage(currentUser.profileImage)
  } catch (error: any) {
    console.log('getCurrentUserSaga', error)
  }
}

export function* getCurrentUserSaga() {
  yield takeLatest(GET_CURRENT_USER_REQUEST, getCurrentUser)
}

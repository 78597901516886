import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { CustomRadioButton, HeaderInfo, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectCurrentUserData } from '@redux/selectors'
import { SubmitButton } from '@screens'
import { EDUCATION, OptionId } from '@utils'

const styles = StyleSheet.create({
  centerWrapper: {
    flex: 1
  },
  mainWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

export const Education = () => {
  const { route } = useNavigator<'Education'>()
  const edit = route.params?.edit
  const { userUpdate } = useDataLayer()
  const currentUser = useSelector(selectCurrentUserData)
  const [selectedEducation, setSelectedEducation] = useState<OptionId>(-1)

  const handleEducationSelect = (education: OptionId) => {
    setSelectedEducation(education)
  }

  useEffect(() => {
    if (currentUser) {
      setSelectedEducation(currentUser.profile.educationGrade)
    }
  }, [currentUser])

  const goToHobbies = () => {
    if (!currentUser) {
      return
    }

    userUpdate(
      {
        profile: {
          ...currentUser.profile,
          educationGrade: selectedEducation
        }
      },
      { page: edit ? 'InformationYourself' : 'Hobbies' }
    )
  }

  const renderEducationOptions = () =>
    EDUCATION.map(education => (
      <CustomRadioButton
        key={education.id}
        textRadio={education.label}
        isSelected={selectedEducation === education.id}
        onPress={() => handleEducationSelect(education.id)}
      />
    ))

  return (
    <View style={styles.mainWrapper}>
      <HeaderInfo showProgressBar={!edit} step={10} />
      <View style={styles.centerWrapper}>
        <Typography f27 bold marginTop={28} marginBottom={20}>
          Степень образования
        </Typography>
        {renderEducationOptions()}
      </View>
      <SubmitButton isEditing={edit} onPress={goToHobbies} buttonLabelCondition={selectedEducation !== -1} />
    </View>
  )
}

import { useEffect, useState } from 'react'
import { Image, ScrollView, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'
import { LinearGradient } from 'expo-linear-gradient'

import { SearchSvg, XCircleSvg } from '@assets/icons'
import { EmptySearch, SearchInput, Typography } from '@components'
import { formatUserName } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectAllUsers } from '@redux/selectors'
import { SERVER_URL } from '@services/apiService'
import { UserProfile } from '@types'

const styles = StyleSheet.create({
  container: {
    height: '100%',
    paddingHorizontal: 26,
    paddingVertical: 10
  },
  inputWrapper: {
    height: 50,
    width: '100%'
  },
  leftIconWrapper: {
    left: 14,
    position: 'absolute',
    top: '30%'
  },
  messageContainer: {
    flexDirection: 'row'
  },
  messageTextWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%'
  },
  rightIconWrapper: {
    position: 'absolute',
    right: 14,
    top: '26%'
  },
  searcIconWrapper: {
    backgroundColor: '#2f2f2f',
    borderRadius: 6,
    height: '100%',
    justifyContent: 'center',
    maxHeight: 30,
    maxWidth: 72,
    position: 'absolute',
    right: 8,
    top: '25%',
    transform: [{ translateY: -4 }],
    width: '100%'
  },
  singleMessage: {
    marginBottom: 15,
    position: 'relative'
  }
})

const getMessageWrapperStyle = (singleItem: boolean): ViewStyle => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: singleItem ? 'flex-start' : 'space-around'
})

export const Search = () => {
  const { navigation } = useNavigator<'ModelScreen'>()
  const { getAllUsers } = useDataLayer()
  const allUsers = useSelector(selectAllUsers)
  const [usersList, setUsersList] = useState<UserProfile[]>([])
  const [text, setText] = useState('')
  const marginBottomValue = text.length !== 0 && usersList.length > 0 ? 26 : 115

  useEffect(() => {
    getAllUsers(text)
  }, [])

  const handleSearch = () => {
    if (text.length >= 4 && allUsers.length > 0) {
      setUsersList(allUsers.filter(el => el.login.includes(text)))
    }
  }

  const goToModel = (user: UserProfile) => {
    navigation.navigate('ModelScreen', { user })
  }

  const renderUsersList = () =>
    usersList.map(story => (
      <TouchableOpacity onPress={() => goToModel(story)} key={story._id}>
        <View style={styles.messageContainer}>
          <View style={styles.singleMessage}>
            <View style={{ width: 161, height: 191, borderRadius: 20, overflow: 'hidden' }}>
              <Image
                source={{
                  uri: `${SERVER_URL}/${story.profileImage[0]?.name}`
                }}
                style={{ width: 161, height: 191, position: 'absolute' }}
              />
              <View
                style={{
                  width: 161,
                  height: 191,
                  position: 'absolute'
                }}
              />
            </View>
            <LinearGradient
              colors={['rgba(0,0,0,0.6)', 'rgba(0, 0, 0, 0)']}
              style={{ position: 'absolute', width: '100%', height: '30%', borderRadius: 20 }}
            >
              <View style={[styles.messageTextWrapper, { top: 10 }]}>
                <Typography f10 normal color='#ffffff' textAlign='center'>
                  @{story.login}
                </Typography>
              </View>
            </LinearGradient>

            <LinearGradient
              colors={['rgba(0,0,0,0)', 'rgba(0,0,0,0.6)']}
              style={{ position: 'absolute', top: 120, width: '100%', height: '37%', borderRadius: 20 }}
            >
              <View style={[styles.messageTextWrapper, { bottom: 16 }]}>
                <Typography color='#ffffff' f10 normal textAlign='center' marginBottom={2}>
                  {story.profile.userGeo.name}
                </Typography>
                <Typography color='#ffffff' f17 semibold textAlign='center'>
                  {formatUserName(story.name)}
                </Typography>
              </View>
            </LinearGradient>
          </View>
        </View>
      </TouchableOpacity>
    ))

  const getSearchButton = () => (
    <TouchableOpacity onPress={handleSearch} style={styles.searcIconWrapper}>
      <Typography f14 normal textAlign='center' color='#ffffff'>
        Найти
      </Typography>
    </TouchableOpacity>
  )

  return (
    <View style={styles.container}>
      <View style={[styles.inputWrapper, { marginBottom: marginBottomValue }]}>
        <SearchInput
          placeholder='Поиск по логину'
          onChangeText={value => setText(value.toLocaleLowerCase())}
          value={text}
          leftIcon={() => (
            <View style={styles.leftIconWrapper}>
              <SearchSvg width={17.8} height={17.8} />
            </View>
          )}
          rightIcon={() => (
            <TouchableOpacity
              style={[styles.rightIconWrapper, { right: text.length >= 4 ? 89 : 14, opacity: text ? 1 : 0 }]}
              onPress={() => {
                setText('')
                setUsersList([])
              }}
            >
              <XCircleSvg width={20} height={20} />
            </TouchableOpacity>
          )}
          searchButton={text.length >= 4 ? getSearchButton : undefined}
        />
      </View>
      {usersList.length > 0 ? (
        <ScrollView>
          {text.length !== 0 && <View style={getMessageWrapperStyle(usersList.length === 1)}>{renderUsersList()}</View>}
        </ScrollView>
      ) : (
        <EmptySearch mainTitle='Connect - умный поиск' subTitle='Ищите людей по имени, фамилии, а также по никнейму' />
      )}
    </View>
  )
}

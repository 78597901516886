import { Pressable, StatusBar, StyleSheet, View } from 'react-native'

import { BackSvg } from '@assets/icons'
import { Button, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'

const styles = StyleSheet.create({
  bottomButton: {
    width: '100%'
  },
  bottomButtonContainer: {
    alignItems: 'center'
  },
  centerWrapper: {
    flex: 1,
    justifyContent: 'space-between'
  },
  mainWrapper: {
    flex: 1,
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: isIOS ? 50 : 15
  }
})

export const ChangeGeneralInformation = () => {
  const { navigation } = useNavigator<'PersonalInformation'>()

  const goToPersonalInformation = () => {
    navigation.navigate('PersonalInformation')
  }

  return (
    <View style={styles.mainWrapper}>
      <StatusBar backgroundColor='#F3F2F9' barStyle='dark-content' />
      <Pressable onPress={goToPersonalInformation}>
        <BackSvg />
      </Pressable>
      <View style={styles.centerWrapper}>
        <Typography f27 bold marginTop={24} marginBottom={12}>
          Общая информация
        </Typography>
        <View style={styles.bottomButtonContainer}>
          <Button style={styles.bottomButton} text='Изменить' onPress={goToPersonalInformation} />
        </View>
      </View>
    </View>
  )
}

import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import DateTimePickerWeb from 'react-native-ui-datepicker'

import { Button, ModalWrapper, Typography } from '@components'
import { isAndroid, isIOS, isWeb } from '@components/utils'
import DateTimePicker from '@react-native-community/datetimepicker'

const today = new Date()
const maxDate = new Date(today.setFullYear(today.getFullYear() - 12))
const minDate = new Date(today.setFullYear(today.getFullYear() - 120))

const styles = StyleSheet.create({
  pickerWrapper: {
    paddingVertical: 70
  }
})

interface DatePickerProps {
  isVisible: boolean
  handleResult: (date?: Date) => void
}

export const DatePicker = ({ isVisible, handleResult }: DatePickerProps) => {
  const [date, setDate] = useState(maxDate)

  if (!isVisible) {
    return null
  }

  const handleDateChange = (selectedDate: Date | undefined) => {
    if (!selectedDate) {
      handleResult()
      return
    }

    if (isIOS) {
      setDate(selectedDate)
      return
    }

    setDate(selectedDate)
    handleResult(selectedDate)
  }

  const DateTimePickerMobile = () => (
    <DateTimePicker
      value={date}
      textColor='#000000'
      mode='date'
      display='spinner'
      onChange={(_, _date) => handleDateChange(_date)}
      maximumDate={maxDate}
      minimumDate={minDate}
    />
  )

  if (isAndroid) {
    return <DateTimePickerMobile />
  }

  return (
    <ModalWrapper isVisible close={handleResult}>
      <Typography f17 semibold>
        Выберите дату
      </Typography>
      <View style={styles.pickerWrapper}>
        {isIOS && <DateTimePickerMobile />}
        {isWeb && (
          <DateTimePickerWeb
            mode='single'
            locale='ru'
            date={date}
            minDate={minDate}
            maxDate={maxDate}
            selectedItemColor='#2f2f2f'
            onChange={params => handleDateChange(new Date(params.date as string))}
          />
        )}
      </View>
      <Button text='Выбрать' onPress={() => handleResult(date)} />
    </ModalWrapper>
  )
}

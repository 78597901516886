import { KeyboardAvoidingView, StyleSheet, View } from 'react-native'

import { Button, HeaderInfo, Typography } from '@components'
import { isIOS } from '@components/utils'
import { useNavigator } from '@navigation'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingHorizontal: 26,
    paddingTop: 50
  }
})

export const RestoreAccount = () => {
  const { route, navigation } = useNavigator<'RestoreAccount'>()
  const phone = route.params.phone

  return (
    <View style={styles.container}>
      <HeaderInfo />
      <KeyboardAvoidingView behavior={isIOS ? 'padding' : undefined} style={{ flex: 1 }}>
        <Typography f27 bold marginBottom={8} marginTop={52}>
          Востанволение аккаунта
        </Typography>
        <Typography f15 normal color='#535354' marginBottom={24}>
          По номеру телефона {phone} найден аккаунт, который был удален. Востановить?
        </Typography>
        <Button
          text='Востановить'
          onPress={() => {
            navigation.navigate('PasswordLogin', { phone })
          }}
        />
      </KeyboardAvoidingView>
    </View>
  )
}

import {
  DELETE_FULL_CHAT_USER,
  DeleteFullChatUserAction,
  GET_FULL_CHAT_USER_REQUEST,
  GET_FULL_CHAT_USER_SUCCESS,
  GetFullChatUserRequestAction,
  GetFullChatUserSuccessAction
} from '@redux/types'
import { UserProfile } from '@types'

export const getFullChatUserRequest = (id: number): GetFullChatUserRequestAction => ({
  type: GET_FULL_CHAT_USER_REQUEST,
  payload: id
})

export const getFullChatUserSuccess = (user: UserProfile): GetFullChatUserSuccessAction => {
  return {
    type: GET_FULL_CHAT_USER_SUCCESS,
    payload: user
  }
}

export const deleteFullChatUserRequest = (): DeleteFullChatUserAction => ({
  type: DELETE_FULL_CHAT_USER
})

import { Image, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import { useNavigator } from '@navigation'
import { SERVER_URL } from '@services/apiService'
import { UserProfile } from '@types'

const styles = StyleSheet.create({
  imageBorder: {
    alignItems: 'center',
    border: 1,
    borderRadius: 50,
    height: 64,
    justifyContent: 'center',
    width: 64
  },
  storyWrapper: {
    flexDirection: 'row',
    gap: 12
  },
  textStory: {
    fontSize: 13,
    fontWeight: '500',
    marginTop: 7,
    maxWidth: 64,
    overflow: 'hidden',
    textAlign: 'center'
  }
})

const formatName = (name: string) => {
  const [firstname, lastname] = name.split(' ')
  return `${firstname} ${lastname[0]}.`
}

interface StoryMenuProps {
  requests: any[]
}

export const ConnectRequests = ({ requests }: StoryMenuProps) => {
  const { navigation } = useNavigator<'ModelScreen'>()

  const goToAccept = (user: UserProfile) => {
    navigation.navigate('ModelScreen', { user, isRequest: true })
  }

  if (!requests.length) {
    return null
  }

  const renderRequests = () =>
    requests.map((story, index) => (
      <View key={story.user._id} style={index > 0 && { marginLeft: 2 }}>
        <TouchableOpacity onPress={() => goToAccept(story.user)}>
          <View style={styles.imageBorder}>
            <Image
              source={{
                uri: `${SERVER_URL}/${story.user.profileImage[0].name}`
              }}
              style={{ width: 50, height: 50, borderRadius: 50 }}
            />
          </View>
          <Text style={styles.textStory} numberOfLines={1}>
            {formatName(story.user.name)}
          </Text>
        </TouchableOpacity>
      </View>
    ))

  return (
    <View style={{ marginBottom: 24, marginRight: -24 }}>
      <Text style={{ fontSize: 22, fontWeight: '500', marginBottom: 16 }}>Запросы коннект</Text>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <View style={styles.storyWrapper}>{renderRequests()}</View>
      </ScrollView>
    </View>
  )
}

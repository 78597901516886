import AsyncStorage from '@react-native-async-storage/async-storage'

export const saveToLS = async (key: string, value: any) => {
  try {
    await AsyncStorage.setItem(key, value)
  } catch (e) {
    // saving error
    console.error('Error saving data', e)
  }
}

export const getFromLS = async (key: string) => {
  try {
    const value = await AsyncStorage.getItem(key)
    if (value !== null) {
      // value previously stored
      return value
    }
    return null
  } catch (e) {
    // error reading value
    console.error('Error reading data', e)
  }
}

// TODO: is it useful?
export const removeFromLS = async (key: string) => {
  try {
    await AsyncStorage.removeItem(key)
  } catch (e) {
    // error removing value
    console.error('Error removing data', e)
  }
}

/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getCurrentUserSuccess } from '@redux/actions'
import { CONNECT_SOCIAL_REQUEST, ConnectSocialRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { UserProfile } from '@types'

type SagaEffects = CallEffect | PutEffect

function* connectSocial(action: ConnectSocialRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.connectSocial], action.payload)
    const user: UserProfile = response.data
    yield put(getCurrentUserSuccess(user))
  } catch (error: any) {
    console.log('connectSocialSaga error:', error)
  }
}

export function* connectSocialSaga() {
  yield takeLatest<ConnectSocialRequestAction>(CONNECT_SOCIAL_REQUEST, connectSocial)
}

import { useState } from 'react'

export const useToggle = (defaultValue: boolean) => {
  const [value, setValue] = useState(defaultValue)

  const toggleValue = (newValue?: boolean) => {
    setValue(currentValue => newValue ?? !currentValue)
  }

  return [ value, toggleValue ] as const
}

/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { getChannelByUserIdSuccess } from '@redux/actions'
import { GET_CHANNEL_BY_USER_ID_REQUEST, GetChannelByUserIdRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { Channel } from '@types'

type SagaEffects = CallEffect | PutEffect

function* getChannelByUserId(action: GetChannelByUserIdRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.getChannelByUserId], action.payload.chatUserId)
    const channel: Channel = response.data
    yield put(getChannelByUserIdSuccess(channel))
  } catch (error: any) {
    console.log('getChannelByUserIdSaga', error)
  }
}

export function* getChannelByUserIdSaga() {
  yield takeLatest<GetChannelByUserIdRequestAction>(GET_CHANNEL_BY_USER_ID_REQUEST, getChannelByUserId)
}

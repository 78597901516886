import { useEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'

import { Button, Chip, ModalWrapper, MoodScale, SelectStatusModal, TextInput, Typography } from '@components'
import { normalizeMoodValue } from '@components/utils'
import { useNavigator } from '@navigation'
import { useDataLayer } from '@redux/dataLayer'
import { selectUserChatId } from '@redux/selectors'
import { UserChat } from '@types'
import { StatusItem } from '@utils'

const styles = StyleSheet.create({
  input: {
    marginBottom: 40
  },
  modalContent: {
    flex: 1,
    maxHeight: 600,
    paddingTop: 55
  },
  statusChip: {
    alignSelf: 'center',
    marginBottom: 40,
    marginTop: 16
  }
})

interface MoodScaleModalProps {
  isVisible: boolean
  isMyProfile?: boolean
  chat?: UserChat
  close: () => void
  moodStatus: StatusItem
  moodText?: string
  average: string
  setStatus?: (status: number) => void
}

export const MoodScaleModal = ({
  isVisible,
  isMyProfile,
  chat,
  close,
  average,
  moodStatus,
  moodText,
  setStatus
}: MoodScaleModalProps) => {
  const { navigation } = useNavigator<'Tabs'>()
  const [redirect, setRedirect] = useState(false)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [inputText, setInputText] = useState('')

  const userChatId = useSelector(selectUserChatId)
  const { sendMessage } = useDataLayer()

  const moodValue = useMemo(() => normalizeMoodValue(average), [average])
  const { id, icon: Icon, text } = moodStatus

  const onSelectStatus = (statusId: number) => {
    if (setStatus) {
      setStatus(statusId)
    }
  }

  useEffect(() => {
    if (!redirect) {
      return
    }

    setRedirect(false)
    navigation.navigate('Tabs')
  }, [redirect])

  const sendMessageHandler = () => {
    if (!chat) {
      return
    }

    const formatted = inputText.trim()
    if (!formatted) {
      return
    }

    sendMessage({
      content: formatted,
      user_id: userChatId,
      chat_id: chat.id,
      message_type: 'text'
    })

    setInputText('')
    close()

    navigation.navigate('Chat', { chat, userId: userChatId })
  }

  const getButtonText = () => {
    if (isMyProfile) {
      return 'Выбрать другой статус'
    }

    if (chat) {
      return 'Отправить'
    }

    return 'Понятно'
  }

  const buttonAction = () => {
    if (isMyProfile) {
      return setShowStatusModal(true)
    }

    if (chat) {
      return sendMessageHandler()
    }

    return close()
  }

  return (
    <ModalWrapper isVisible={isVisible} close={close} contentStyle={styles.modalContent}>
      <MoodScale value={moodValue} />
      <Typography f20 semibold textAlign='center' color='#2C2C2C' marginTop={30} marginBottom={12}>
        Шкала настроения
      </Typography>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false}>
        {moodText && (
          <TouchableOpacity activeOpacity={1}>
            <Typography f15 normal textAlign='center' color='#7b7b7d'>
              {moodText}
            </Typography>
          </TouchableOpacity>
        )}
      </ScrollView>
      <Chip
        leftIcon={<Icon fill='black' />}
        label={text.ru}
        bgColor={id ? '#rgba(255, 255, 255, 0.8)' : '#E8E7ED'}
        fontSize={16}
        textColor={id ? '#000' : '#rgba(44, 44, 44, 0.6)'}
        style={styles.statusChip}
      />
      {!isMyProfile && chat && (
        <TextInput style={styles.input} placeholder='Введите сообщение' onChange={e => setInputText(e)} />
      )}
      <Button text={getButtonText()} onPress={buttonAction} />
      {isMyProfile && (
        <SelectStatusModal
          isVisible={showStatusModal}
          close={() => setShowStatusModal(false)}
          onSelectStatus={onSelectStatus}
        />
      )}
    </ModalWrapper>
  )
}

import { PageAttributes } from '@navigation'
import { UPDATE_USER_REQUEST, UpdateUserRequestAction } from '@redux/types'

export const updateUserRequest = (update: any, next: PageAttributes): UpdateUserRequestAction => ({
  type: UPDATE_USER_REQUEST,
  payload: {
    update,
    next
  }
})

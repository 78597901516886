/* eslint-disable func-style */
import { call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects'

import { navigate } from '@navigation'
import { getCurrentUserSuccess } from '@redux/actions'
import { UPDATE_USER_REQUEST, UpdateUserRequestAction } from '@redux/types'
import { apiService } from '@services/apiService'
import { UserProfile } from '@types'

type SagaEffects = CallEffect | PutEffect

function* updateUser(action: UpdateUserRequestAction): Generator<SagaEffects, void, any> {
  try {
    const response = yield call([apiService, apiService.updateUser], action.payload.update)
    const user: UserProfile = response.data
    if (action.payload.next.page !== 'none') {
      navigate(action.payload.next)
    }
    yield put(getCurrentUserSuccess(user))
  } catch (error: any) {
    console.log('updateUserSaga', error)
  }
}

export function* updateUserSaga() {
  yield takeLatest<UpdateUserRequestAction>(UPDATE_USER_REQUEST, updateUser)
}

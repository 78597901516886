import { GET_MY_LIKES_REQUEST, GET_MY_LIKES_SUCCESS, GetMyLikesActionTypes, GetMyLikesState } from '@redux/types'

const initialState: GetMyLikesState = []

export const connectRequestsReducer = (state = initialState, action: GetMyLikesActionTypes): GetMyLikesState => {
  switch (action.type) {
    case GET_MY_LIKES_REQUEST:
      return state
    case GET_MY_LIKES_SUCCESS:
      return action.payload.likes
    default:
      return state
  }
}

import { useEffect, useState } from 'react'
import { Pressable, ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { SmallArrowLeftSvg } from '@assets/icons'
import { Button, Chip, ModalWrapper, Typography } from '@components'
import { selectCurrentUserMoodStatus } from '@redux/selectors'
import { STATUSES } from '@utils'

const styles = StyleSheet.create({
  button: {
    marginTop: 12
  },
  close: {
    left: 21,
    position: 'absolute',
    top: 21,
    zIndex: 1
  },
  modalContent: {
    flex: 1,
    maxHeight: 600
  },
  сhipWrapper: {
    alignItems: 'flex-start',
    columnGap: 8,
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: 12,
    rowGap: 12
  }
})

interface SelectStatusModalProps {
  isVisible: boolean
  close: () => void
  onSelectStatus: (status: number) => void
}

export const SelectStatusModal = ({ isVisible, close, onSelectStatus }: SelectStatusModalProps) => {
  const [selectedStatus, setSelectedStatus] = useState<number>(-1)
  const moodStatusId = useSelector(selectCurrentUserMoodStatus)

  useEffect(() => setSelectedStatus(moodStatusId), [])

  const handleApplyChanges = () => {
    if (moodStatusId !== selectedStatus) {
      onSelectStatus(selectedStatus)
    }

    close()
  }

  const renderMoodStatuses = () =>
    STATUSES.map(status => (
      <Chip
        key={status.id}
        leftIcon={<status.icon fill={status.id === selectedStatus ? 'black' : '#rgba(44, 44, 44, 0.6)'} />}
        label={status.text.ru}
        bgColor={status.id === selectedStatus ? '#fff' : '#rgba(255, 255, 255, 0.5)'}
        textColor={status.id === selectedStatus ? '#000' : '#rgba(44, 44, 44, 0.6)'}
        onPress={() => setSelectedStatus(status.id)}
      />
    ))

  return (
    <ModalWrapper isVisible={isVisible} close={close} contentStyle={styles.modalContent} hideCloseButton>
      <Pressable style={styles.close} onPress={close}>
        <SmallArrowLeftSvg />
      </Pressable>
      <Typography f17 bold textAlign='center' style={{ paddingBottom: 23 }}>
        Выбор статуса
      </Typography>
      <ScrollView>
        <View style={styles.сhipWrapper}>{renderMoodStatuses()}</View>
      </ScrollView>
      <Button style={styles.button} text='Применить изменения' onPress={handleApplyChanges} />
    </ModalWrapper>
  )
}
